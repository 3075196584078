<template>
     <div>
          <customHeader title="Times do Guru" subtitle="Aqui é onde os mitos moram! Seleção do Guru, Time Valorização e Times da Guruzada." :mobile="mobile" :showBanner="showBanner"></customHeader>
          <div class="">
               <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                         <a :class="{ smallFont: mobile }" @click="tab='time_guruzada'" class="nav-link active" id="timeguruzada-tab" data-toggle="tab" href="#timeguruzada" role="tab" aria-controls="timeguruzada" aria-selected="true">
                              Time Guruzada
                         </a>
                    </li>
                    <li class="nav-item">
                         <a :class="{ smallFont: mobile }" @click="tab='selecao_do_guru'" class="nav-link" id="selecaodoguru-tab" data-toggle="tab" href="#selecaodoguru" role="tab" aria-controls="selecaodoguru" aria-selected="false">
                              Seleção do Guru
                         </a>
                    </li>
                    <li class="nav-item">
                         <a :class="{ smallFont: mobile }" @click="tab='time_valorizacao'" class="nav-link" id="timevalorizacao-tab" data-toggle="tab" href="#timevalorizacao" role="tab" aria-controls="timevalorizacao" aria-selected="false">
                              Time Valorização
                         </a>
                    </li>
               </ul>
               <div class="row">
                    <div class="col-12 text-center" id="rounds">
                         <div v-if="!mobile">
                              <div class="rounds-title">
                                   <span >Escolha a rodada para ver nossa seleção: <b>Rodada {{round}}</b></span>
                              </div>

                              <template v-for="rodada in market_status.rodada_atual">
                                   <i v-if="round==rodada" data-toggle="tooltip" data-placement="bottom" :title="'Rodada '+rodada" class="fas fa-circle" @click="round=rodada" :key="'i1_'+rodada"></i>
                                   <i v-else-if="rodada==market_status.rodada_atual" data-toggle="tooltip" data-placement="bottom" title="Próxima Rodada" class="far fa-circle" @click="round=rodada" :key="'i2_'+rodada"></i>
                                   <i v-else data-toggle="tooltip" data-placement="bottom" :title="'Rodada '+rodada" class="far fa-circle" @click="round=rodada" :key="'i3_'+rodada"></i>
                              </template>
                         </div>
                         <div v-else>
                              <p class="text-left">Escolha a rodada para ver nossa seleção:</p>
                              <select class="form-control" v-model="round">
                                   <option v-for="rodada in market_status.rodada_atual" :selected="round==rodada" :value="rodada" :key="rodada" :class="{'round-active':round==rodada}">{{rodada}}</option>
                              </select>
                         </div>
                         <hr>
                    </div>
                    <div class="col-12" v-if="tab == 'time_guruzada' || round != market_status.rodada_atual || (user != null  && user.membro == 1)">
                         <div class="row">
                              <div :class="[mobile ? 'col-12' : 'col-8']" v-if="fieldView == 'both' || fieldView == 'field'">
                                   <div class="field-box">
                                        <div id="field" style="margin:0 auto;">
                                             <div v-if="mobile" style="float:right;">
                                                  <button class="btn btn-sm btn-light btn-selected" @click="fieldView='field'">
                                                       <i class="far fa-futbol"></i>
                                                  </button>&nbsp;
                                                  <button class="btn btn-sm btn-light" @click="fieldView='table'">
                                                       <i class="fas fa-table"></i>
                                                  </button>
                                             </div>
                                             <br><br>
                                             <div id="team">
                                                  <template v-if="computed_data">
                                                  <div id="attack" class="row">
                                                       <template v-for="athlete in computed_data" >
                                                            <div v-if="athlete.titular==1 && athlete.position == 'ATA'" class="col" :key="athlete.player_id">
                                                                 <i v-if="athlete.captain == 1" 
                                                                      style="background-color:orange"
                                                                      class="fab fa-cuttlefish"></i>
                                                                 <img :src="athlete_photo(athlete.player_photo,220)" class="img img-fluid">
                                                                 <img v-if="clubs[athlete.club_id] != undefined" :src="clubs[athlete.club_id].escudo" class="club2">
                                                                 <br>
                                                                 <span>{{athlete.player_name}}</span>
                                                            </div>
                                                       </template>
                                                  </div>
                                                  <div id="medium" class="row">
                                                       <template v-for="athlete in computed_data" >
                                                            <div v-if="athlete.titular==1 && athlete.position == 'MEI'" class="col" :key="athlete.player_id">
                                                                 <i v-if="athlete.captain == 1" 
                                                                      style="background-color:orange"
                                                                      class="fab fa-cuttlefish"></i>
                                                                 <img :src="athlete_photo(athlete.player_photo,220)" class="img img-fluid">
                                                                 <img v-if="clubs[athlete.club_id] != undefined" :src="clubs[athlete.club_id].escudo" class="club2">
                                                                 <br>
                                                                 <span>{{athlete.player_name}}</span>
                                                            </div>
                                                       </template>
                                                  </div>
                                                  <div id="back" class="row">
                                                       <template v-for="(athlete,index) in computed_data" >
                                                            <div v-if="athlete.titular==1 && (athlete.position == 'LAT' || athlete.position =='ZAG')" :key="athlete.player_id"
                                                                 :class="[athlete.position == 'LAT' && index == 2 ? 'col order-first' : 'col order-' + parseInt(12-index)]">
                                                                 <i v-if="athlete.captain == 1" 
                                                                      style="background-color:orange"
                                                                      class="fab fa-cuttlefish"></i>
                                                                 <img :src="athlete_photo(athlete.player_photo,220)" class="img img-fluid">
                                                                 <img v-if="clubs[athlete.club_id] != undefined" :src="clubs[athlete.club_id].escudo" class="club2">
                                                                 <br>
                                                                 <span>{{athlete.player_name}}</span>
                                                            </div>
                                                       </template>
                                                  </div>
                                                  <div id="goalkeeper" class="row">
                                                       <template v-for="athlete in computed_data" >
                                                            <div v-if="athlete.titular==1 && athlete.position == 'GOL'" class="col" :key="athlete.player_id">
                                                                 <i v-if="athlete.captain == 1" 
                                                                      style="background-color:orange"
                                                                      class="fab fa-cuttlefish"></i>
                                                                 <img :src="athlete_photo(athlete.player_photo,220)" class="img img-fluid">
                                                                 <img v-if="clubs[athlete.club_id] != undefined" :src="clubs[athlete.club_id].escudo" class="club2">
                                                                 <br>
                                                                 <span>{{athlete.player_name}}</span>
                                                            </div>
                                                       </template>
                                                  </div>
                                                  <div id="coach" class="row">
                                                       <template v-for="athlete in computed_data" >
                                                            <div v-if="athlete.position == 'TEC'" class="col col-coach" :key="athlete.player_id">
                                                                 <i v-if="athlete.captain == 1" 
                                                                      style="background-color:orange"
                                                                      class="fab fa-cuttlefish"></i>
                                                                 <img :src="athlete_photo(athlete.player_photo,220)" class="img img-fluid">
                                                                 <img v-if="clubs[athlete.club_id] != undefined" :src="clubs[athlete.club_id].escudo" class="club2">
                                                                 <br>
                                                                 <span>{{athlete.player_name}}</span>
                                                            </div>
                                                       </template>
                                                  </div>
                                                  </template>
                                                  <template v-else>
                                                       <h6 :style="[mobile ? {'padding':'25px'} : {'padding': '75px 125px'}]">
                                                            Ainda não definimos os times para a próxima rodada. <br><br>
                                                            Disponibilizaremos os times com pelo menos 24 horas de antecedência.<br><br>
                                                            Os times podem ter alterações depois disso.
                                                       </h6>
                                                  </template>
                                             </div>
                                        </div>
                                   </div>
                                   <div id="reservas" style="margin-left:1em; margin-right:1em;" >
                                        <div class="row">
                                             <div class="col-12" style="padding: 0px 25px;">
                                                  <h5 class="titulo-reservas">BANCO DE RESERVAS</h5>
                                             </div>
                                        </div>
                                        <div class="row" style="max-height: 105px; clear:left;">
                                             <template v-if="reserva1!=null">
                                                  <div class="col order-1 text-center">  
                                                       <!-- class="col order-1 text-center res"> -->
                                                       <img :src="athlete_photo(reserva1.player_photo,220)" class="reserva img img-fluid">
                                                       <img v-if="clubs[reserva1.club_id] != undefined" :src="clubs[reserva1.club_id].escudo" class="club2reserva">
                                                       <br>
                                                       <span class="span-reserva">{{reserva1.player_name}}</span>
                                                       <br/>
                                                       <p class="respos">GOL</p>
                                                  </div>
                                             </template>
                                              <template v-else>
                                                  <div class="col order-1 text-center">
                                                       <img src="https://s.glbimg.com/es/sde/f/2019/02/15/7f94dd0a492218c6dcc1fbc02473637c_220x220.png" class="reserva img img-fluid">
                                                       <br>
                                                       <span class="span-reserva">-</span>
                                                       <br/>
                                                       <p class="respos">GOL</p>
                                                  </div>
                                             </template>
                                             <!-- {{desired_tactic}} -->
                                             <!-- <template v-if="esquemaPossuiLateral()"> -->
                                             <template v-if="reserva2!=null">
                                             <div class="col order-2 text-center">  
                                                  <!-- class="col order-1 text-center res"> -->
                                                  <img :src="athlete_photo(reserva2.player_photo,220)" class="reserva img img-fluid">
                                                  <img v-if="clubs[reserva2.club_id] != undefined" :src="clubs[reserva2.club_id].escudo" class="club2reserva">
                                                  <br>
                                                  <span class="span-reserva">{{reserva2.player_name}}</span>
                                                  <br/>
                                                  <p class="respos">LAT</p>
                                             </div>
                                             </template>
                                             <template v-else>
                                                  <div class="col order-2 text-center">
                                                       <img src="https://s.glbimg.com/es/sde/f/2019/02/15/7f94dd0a492218c6dcc1fbc02473637c_220x220.png" class="reserva img img-fluid">
                                                       <br>
                                                       <span class="span-reserva">-</span>
                                                       <br/>
                                                       <p class="respos">LAT</p>
                                                  </div>
                                             </template>
                                             <!-- </template> -->
                                             <template v-if="reserva3!=null">
                                                  <div class="col order-3 text-center">  
                                                       <!-- class="col order-1 text-center res"> -->
                                                       <img :src="athlete_photo(reserva3.player_photo,220)" class="reserva img img-fluid">
                                                       <img v-if="clubs[reserva3.club_id] != undefined" :src="clubs[reserva3.club_id].escudo" class="club2reserva">
                                                       <br>
                                                       <span class="span-reserva">{{reserva3.player_name}}</span>
                                                       <br/>
                                                       <p class="respos">ZAG</p>
                                                  </div>
                                             </template>
                                             <template v-else>
                                                  <div class="col order-3 text-center">
                                                       <img src="https://s.glbimg.com/es/sde/f/2019/02/15/7f94dd0a492218c6dcc1fbc02473637c_220x220.png" class="reserva img img-fluid">
                                                       <br>
                                                       <span class="span-reserva">-</span>
                                                       <br/>
                                                       <p class="respos">ZAG</p>
                                                  </div>
                                             </template>
                                             <template v-if="reserva4!=null">
                                                  <div class="col order-4 text-center">   
                                                       <!-- class="col order-1 text-center res"> -->
                                                       <img :src="athlete_photo(reserva4.player_photo,220)" class="reserva img img-fluid">
                                                       <img v-if="clubs[reserva4.club_id] != undefined" :src="clubs[reserva4.club_id].escudo" class="club2reserva">
                                                       <br>
                                                       <span class="span-reserva">{{reserva4.player_name}}</span>
                                                       <br/>
                                                       <p class="respos">MEI</p>
                                                  </div>
                                             </template>
                                             <template v-else>
                                                  <div class="col order-4 text-center">
                                                       <img src="https://s.glbimg.com/es/sde/f/2019/02/15/7f94dd0a492218c6dcc1fbc02473637c_220x220.png" class="reserva img img-fluid">
                                                       <br>
                                                       <span class="span-reserva">-</span>
                                                       <br/>
                                                       <p class="respos">MEI</p>
                                                  </div>
                                             </template>
                                             <template v-if="reserva5!=null">
                                                  <div class="col order-5 text-center">  
                                                       <!-- class="col order-1 text-center res"> -->
                                                       <img :src="athlete_photo(reserva5.player_photo,220)" class="reserva img img-fluid">
                                                       <img v-if="clubs[reserva5.club_id] != undefined" :src="clubs[reserva5.club_id].escudo" class="club2reserva">
                                                       <br>
                                                       <span class="span-reserva">{{reserva5.player_name}}</span>
                                                       <br/>
                                                       <p class="respos">ATA</p>
                                                  </div>
                                             </template>
                                             <template v-else>
                                                  <div class="col order-5 text-center">
                                                       <img src="https://s.glbimg.com/es/sde/f/2019/02/15/7f94dd0a492218c6dcc1fbc02473637c_220x220.png" class="reserva img img-fluid">
                                                       <br>
                                                       <span class="span-reserva">-</span>
                                                       <br/>
                                                       <p class="respos">ATA</p>
                                                  </div>
                                             </template>
                                        </div>
                                   </div>
                              </div>
                              <div :class="[mobile ? 'col-12' : 'col-4']" v-if="fieldView == 'both' || fieldView == 'table'">
                                   <div v-if="mobile" style="float:right;">
                                        <button class="btn btn-sm btn-light" @click="fieldView='field'">
                                             <i class="far fa-futbol"></i>
                                        </button>&nbsp;
                                        <button class="btn btn-sm btn-light btn-selected" @click="fieldView='table'">
                                             <i class="fas fa-table"></i>
                                        </button>
                                   </div>
                                   <table class="table table-light">
                                        <thead class="">
                                             <th style="width: 35px;"></th>
                                             <th></th>

                                             <th> 
                                                  <small>Preço</small>
                                             </th>

                                             <th v-if="round != market_status.rodada_atual">
                                                  Valorização
                                             </th>
                                             <th v-else>
                                                  <small>Valorização Esperada</small>
                                             </th>

                                             <th v-if="round != market_status.rodada_atual">
                                                  <small>Pontuação</small>
                                             </th>
                                             <th v-else>
                                                  <small> Pontuação Esperada</small>
                                             </th>

                                             <th></th>
                                        </thead>
                                        <tbody>
                                             <template v-for="athlete in computed_data">
                                                  <tr v-if="athlete.titular==1" :key="'tr1_'+athlete.player_id">
                                                       <td>
                                                            <img :src="athlete_photo(athlete.player_photo,220)" class="img img-fluid img-rounded">
                                                       </td>
                                                       <td>
                                                            <p style="white-space: nowrap; text-align:left;">
                                                                 {{athlete.player_name}}
                                                                 <small>({{athlete.position}})</small>
                                                            </p>
                                                       </td>
                                                       <td>{{athlete.price}}</td>
                                                       <td>{{athlete.price_change}}</td>
                                                       <td>{{athlete.points}}</td>
                                                       <td v-if="round == market_status.rodada_atual" @click="[moreInfo == athlete.player_id ? moreInfo = '' : moreInfo = athlete.player_id]">
                                                            <span v-if="moreInfo == athlete.player_id">
                                                                 <i class="fas fa-minus"></i> info
                                                            </span>
                                                            <span v-else>
                                                                 <i class="fas fa-plus"></i> info
                                                            </span>
                                                       </td>
                                                       <td v-else></td>
                                                  </tr>
                                                  <tr v-if="moreInfo == athlete.player_id" :key="'tr2_'+athlete.player_id">
                                                       <td colspan=6>
                                                            <div>
                                                                 <p v-if="description">
                                                                      {{description}}
                                                                 </p>
                                                                 <p v-else>
                                                                      Mais informações está indisponível para esse jogador.
                                                                 </p>
                                                            </div>     
                                                       </td>
                                                  </tr>
                                             </template>
                                        </tbody>
                                        <tfoot>
                                             <tr>
                                                  <td>Totais:</td>
                                                  <td></td>
                                                  <td>{{total_price.toFixed(2)}}</td>
                                                  <td>{{total_price_change.toFixed(2)}}</td>
                                                  <td>{{total_points.toFixed(2)}}</td>
                                                  <td></td>
                                             </tr>
                                        </tfoot>
                                   </table>
                                   <!-- ------------------------------------------------------------------------------------- -->
                                   <!-- ---------------------------------RESERVAS-------------------------------------------- -->
                                   <!-- ------------------------------------------------------------------------------------- -->
                                   <h5 class="titulo-reservas2">RESERVAS</h5>
                                   <table id="tbl-reservas" class="table-hover table table-light" style="margin-top:-8px;"> 
                                        <!-- style="border-bottom:2px solid lightgray; padding-bottom: 10px; margin-bottom: 5px;"> -->
                                         <thead class="" style="line-height:.3; visibility: hidden;">
                                             <th style="width: 35px;"></th>
                                             <th></th>

                                             <th> 
                                                  <small>Preço</small>
                                             </th>

                                             <th v-if="round != market_status.rodada_atual">
                                                  Valorização
                                             </th>
                                             <th v-else>
                                                  <small>Valorização Esperada</small>
                                             </th>

                                             <th v-if="round != market_status.rodada_atual">
                                                  <small>Pontuação</small>
                                             </th>
                                             <th v-else>
                                                  <small> Pontuação Esperada</small>
                                             </th>

                                             <th></th>
                                        </thead>
                                        <tbody>
                                             <tr v-if="reserva1!=null">

                                                   <td style="border-top: 0px;">
                                                       <img :src="athlete_photo(reserva1.player_photo,220)" class="img img-fluid img-rounded">
                                                  </td>
                                                  <td  style="border-top: 0px;">
                                                       <p style="white-space: nowrap; text-align:left;">
                                                            {{reserva1.player_name}}
                                                            <small>({{reserva1.position}})</small>
                                                       </p>
                                                  </td>
                                                  <td  style="border-top: 0px;">{{reserva1.price}}</td>
                                                  <td  style="border-top: 0px;">{{reserva1.price_change}}</td>
                                                  <td  style="border-top: 0px;">{{reserva1.points}}</td>
                                                  <td v-if="round == market_status.rodada_atual" @click="[moreInfoRes == reserva1.player_id ? moreInfoRes = '' : moreInfoRes = reserva1.player_id]"  style="border-top: 0px;">
                                                       <span v-if="moreInfoRes == reserva1.player_id">
                                                            <i class="fas fa-minus"></i> info
                                                       </span>
                                                       <span v-else>
                                                            <i class="fas fa-plus"></i> info
                                                       </span>
                                                  </td>
                                                  <td v-else style="border-top: 0px;"></td>
                                             </tr>
                                              <tr v-if="reserva1!=null && moreInfoRes == reserva1.player_id" :key="'tr2_'+reserva1.player_id">
                                                  <td colspan=6>
                                                       <div>
                                                            <p v-if="description">
                                                                 {{description}}
                                                            </p>
                                                            <p v-else>
                                                                 Mais informações está indisponível para esse jogador.
                                                            </p>
                                                       </div>     
                                                  </td>
                                             </tr>
                                             <!-- <template v-if="esquemaPossuiLateral()"> -->
                                             <tr v-if="reserva2!=null">
                                                  <td>
                                                       <img :src="athlete_photo(reserva2.player_photo,220)" class="img img-fluid img-rounded">
                                                  </td>
                                                  <td>
                                                       <p style="white-space: nowrap; text-align:left;">
                                                            {{reserva2.player_name}}
                                                            <small>({{reserva2.position}})</small>
                                                       </p>
                                                  </td>
                                                  <td>{{reserva2.price}}</td>
                                                  <td>{{reserva2.price_change}}</td>
                                                  <td>{{reserva2.points}}</td>
                                                  <td v-if="round == market_status.rodada_atual" @click="[moreInfoRes == reserva2.player_id ? moreInfoRes = '' : moreInfoRes = reserva2.player_id]">
                                                       <span v-if="moreInfoRes == reserva2.player_id">
                                                            <i class="fas fa-minus"></i> info
                                                       </span>
                                                       <span v-else>
                                                            <i class="fas fa-plus"></i> info
                                                       </span>
                                                  </td>
                                                  <td v-else></td>
                                             </tr>
                                             <tr v-if="reserva2!=null && moreInfoRes == reserva2.player_id" :key="'tr2_'+reserva2.player_id">
                                                  <td colspan=6>
                                                       <div>
                                                            <p v-if="description">
                                                                 {{description}}
                                                            </p>
                                                            <p v-else>
                                                                 Mais informações está indisponível para esse jogador.
                                                            </p>
                                                       </div>     
                                                  </td>
                                             </tr>
                                             <!-- </template> -->
                                             
                                             <tr v-if="reserva3!=null">

                                                   <td>
                                                       <img :src="athlete_photo(reserva3.player_photo,220)" class="img img-fluid img-rounded">
                                                  </td>
                                                  <td>
                                                       <p style="white-space: nowrap; text-align:left;">
                                                            {{reserva3.player_name}}
                                                            <small>({{reserva3.position}})</small>
                                                       </p>
                                                  </td>
                                                  <td>{{reserva3.price}}</td>
                                                  <td>{{reserva3.price_change}}</td>
                                                  <td>{{reserva3.points}}</td>
                                                  <td v-if="round == market_status.rodada_atual" @click="[moreInfoRes == reserva3.player_id ? moreInfoRes = '' : moreInfoRes = reserva3.player_id]">
                                                       <span v-if="moreInfoRes == reserva3.player_id">
                                                            <i class="fas fa-minus"></i> info
                                                       </span>
                                                       <span v-else>
                                                            <i class="fas fa-plus"></i> info
                                                       </span>
                                                  </td>
                                                  <td v-else></td>
                                             </tr>
                                             <tr v-if="reserva3!=null && moreInfoRes == reserva3.player_id" :key="'tr2_'+reserva3.player_id">
                                                  <td colspan=6>
                                                       <div>
                                                            <p v-if="description">
                                                                 {{description}}
                                                            </p>
                                                            <p v-else>
                                                                 Mais informações está indisponível para esse jogador.
                                                            </p>
                                                       </div>     
                                                  </td>
                                             </tr>
                                             
                                             
                                             <tr v-if="reserva4!=null">

                                                   <td>
                                                       <img :src="athlete_photo(reserva4.player_photo,220)" class="img img-fluid img-rounded">
                                                  </td>
                                                  <td>
                                                       <p style="white-space: nowrap; text-align:left;">
                                                            {{reserva4.player_name}}
                                                            <small>({{reserva4.position}})</small>
                                                       </p>
                                                  </td>
                                                  <td>{{reserva4.price}}</td>
                                                  <td>{{reserva4.price_change}}</td>
                                                  <td>{{reserva4.points}}</td>
                                                  <td v-if="round == market_status.rodada_atual" @click="[moreInfoRes == reserva4.player_id ? moreInfoRes = '' : moreInfoRes = reserva4.player_id]">
                                                       <span v-if="moreInfoRes == reserva4.player_id">
                                                            <i class="fas fa-minus"></i> info
                                                       </span>
                                                       <span v-else>
                                                            <i class="fas fa-plus"></i> info
                                                       </span>
                                                  </td>
                                                  <td v-else></td>
                                             </tr>
                                             <tr v-if="reserva4!=null && moreInfoRes == reserva4.player_id" :key="'tr2_'+reserva4.player_id">
                                                  <td colspan=6>
                                                       <div>
                                                            <p v-if="description">
                                                                 {{description}}
                                                            </p>
                                                            <p v-else>
                                                                 Mais informações está indisponível para esse jogador.
                                                            </p>
                                                       </div>     
                                                  </td>
                                             </tr>
                                             
                                             <tr v-if="reserva5!=null">

                                                   <td>
                                                       <img :src="athlete_photo(reserva5.player_photo,220)" class="img img-fluid img-rounded">
                                                  </td>
                                                  <td>
                                                       <p style="white-space: nowrap; text-align:left;">
                                                            {{reserva5.player_name}}
                                                            <small>({{reserva5.position}})</small>
                                                       </p>
                                                  </td>
                                                  <td>{{reserva5.price}}</td>
                                                  <td>{{reserva5.price_change}}</td>
                                                  <td>{{reserva5.points}}</td>
                                                  <td v-if="round == market_status.rodada_atual" @click="[moreInfoRes == reserva5.player_id ? moreInfoRes = '' : moreInfoRes = reserva5.player_id]">
                                                       <span v-if="moreInfoRes == reserva5.player_id">
                                                            <i class="fas fa-minus"></i> info
                                                       </span>
                                                       <span v-else>
                                                            <i class="fas fa-plus"></i> info
                                                       </span>
                                                  </td>
                                                  <td v-else></td>
                                             </tr>
                                             <tr v-if="reserva5!=null && moreInfoRes == reserva5.player_id" :key="'tr2_'+reserva5.player_id">
                                                  <td colspan=6>
                                                       <div>
                                                            <p v-if="description">
                                                                 {{description}}
                                                            </p>
                                                            <p v-else>
                                                                 Mais informações está indisponível para esse jogador.
                                                            </p>
                                                       </div>     
                                                  </td>
                                             </tr>
                                        </tbody>
                                   </table>
                              </div>
                         </div>

                         
                    </div>
                    <div v-else class="col-12 warning text-center">
                         <!-- <div class="swal2-icon swal2-warning swal2-animate-warning-icon" style="display: flex;"><span class="swal2-icon-text">!</span></div>
                         <h5>Ops!</h5>
                         <h6>
                              Apenas os membros do Time Guru tem acesso aos times exclusivos.
                         </h6><br> -->
                        
                         <div class="d-flex mb-5 row col-12 justify-content-center">
                              <h3 class="ops w-100 text-center">Ops!</h3>
                              <!-- <img src="/img/cartao_amarelo.svg" :style="mobile?'width:100px;':'width:200px;'"/> -->
                              <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" class="cartao"/>
                              <h4 class="w-100 text-center texto-cinza">Apenas os membros do Time Guru tem acesso aos times exclusivos.</h4>
                         </div>
                          <a :href="selling_page">
                              <button class="btn btn-md btn-success">
                                   QUERO SER TIME GURU
                              </button>
                         </a>
                         <!-- <login :mobile="mobile" :redirect="'estatisticas-meu-time-cartola'"></login> -->
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import customHeader from '@/components/header.vue';
import util from '@/mixin/util';
// import clubs_json from '@/json/clubes.json';

export default {
     name: 'TimesDoGuru',
     components:{
          customHeader
     },
     mixins: [util],
     data(){
          return{
               //Dados
               user:null,
               total_price:0,//Calculado conforme o time guru selecionado
               total_points:0,//Calculado conforme o time guru selecionado
               total_price_change:0,//Calculado conforme o time guru selecionado

               clubs: [],//Os clubes existentes no campeonato

               tab:'time_guruzada',//Qual time deseja visualizar
               round:1,//Qual a rodada desse time deseja visualizar

               all_teams:null,//Todos os 3 times do guru que serão exibidos na página de acordo com a [rodada][time]S
               market_status:{},//API do cartola - Status do mercado

               //'both' => Campinho + Tabela. 'field' => Campinho. 'table' => Tabela.
               fieldView:'both',

               
               moreInfo:'',//Visualizando mais informações de qual atleta?
               description:'',//Descrição do atleta definido em moreInfo
               moreInfoRes: '', //Visualizando mais informações de qual atleta reserva?

               //Banner
               showBanner: false,

               //Reserva
               reserva1: null,
               reserva2: null,
               reserva3: null,
               reserva4: null,
               reserva5: null,

               //Verificação mobile
               mobileAt:1000,///Mobile a partir de qual width?
               mobile:false,//Status inicial do mobile
          }
     },
     watch:{
          moreInfo: function () {
               var vm = this;
               vm.description = '';

               this.$http
               // .get(this.$_PATH+'/escalar/jogadores/'+this.moreInfo+'/texto')
               .get(this.$API+'escalar/jogadores/'+this.moreInfo)
               .then(response => {
                     vm.description = response.data
               })
               .catch(function (error) {
                    console.log(error);
               });   
          },
          moreInfoRes: function () {
               var vm = this;
               vm.description = '';

               this.$http
               // .get(this.$_PATH+'/escalar/jogadores/'+this.moreInfoRes+'/texto')
               .get(this.$API+'escalar/jogadores/'+this.moreInfoRes)
               .then(response => {
                     vm.description = response.data
               })
               .catch(function (error) {
                    console.log(error);
               });   
          },
          market_status: function(newValue){
               if(newValue){
                    this.round = this.market_status.rodada_atual;
               }
          }
     },
     created(){
          //Verificação mobile
          (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;
          //Definindo o campo de visão (campinho ou tabela + campinho) dependendo de ser mobile ou não
          (window.innerWidth < this.mobileAt) ? this.fieldView = 'table' : this.fieldView = 'both'

          var vm = this;

          //Usuário autenticado
          vm.user = vm.get_usuario_logado();

          //market_status
          this.$http
          .get(this.$API+'mercado-status')
          .then(response => {
               vm.market_status = response.data
          })
          .catch(function (error) {
               console.log(error);
          });   


          //todos os clubes
          this.$http
          // .get(this.$_PATH+'/info-clubes/')
          .get(this.$API+'info-clubes/')
          .then(response => {
               vm.clubs = response.data
          })
          .catch(function (error) {
               console.log(error);
          });   

          //Carrega todos os 3 times do guru em todas as rodadas
          this.$http
          // .get(this.$_PATH+'/times_do_guru')
          .get(this.$API+'times-do-guru')
          .then(response => {
               vm.all_teams = response.data
          })
          .catch(function (error) {
               console.log(error);
          });   
          
          //Banner
          if (!(this.user && this.user.membro == 1)) {
               vm.showBanner = true; 
          }

         
     },
     methods:{
          //Retorna a foto do atleta, se existir.
          athlete_photo(src,size){
               if(src){
                    if(src.includes('emptystate'))
                         return 'https://cartolafc.globo.com/dist/4.5.13/img/emptystate_jogador.svg';
                    else
                         return src.replace('FORMATO',size+'x'+size);
               }
               else
                    return 'https://s.glbimg.com/es/sde/f/2019/02/15/7f94dd0a492218c6dcc1fbc02473637c_220x220.png';
          },
          //Ajusta a ordem do time de acordo com GOL - LAT - ZAG - MEI - ATA - TEC
          adjust_team_order(){
               var gol = [];
               var lat = [];
               var zag = [];
               var mei = [];
               var ata = [];
               var tec = [];

               $.each(this.all_teams[this.tab][this.round],function(index,value){
                    value.position == 'GOL' ? gol.push(value) : null;
                    value.position == 'LAT' ? lat.push(value) : null;
                    value.position == 'ZAG' ? zag.push(value) : null;
                    value.position == 'MEI' ? mei.push(value) : null;
                    value.position == 'ATA' ? ata.push(value) : null;
                    value.position == 'TEC' ? tec.push(value) : null;
               });

               this.all_teams[this.tab][this.round] = gol.concat(lat,zag,mei,ata,tec);
          },
          getReservas(lista) {
               this.reserva1 = null
               this.reserva2 = null
               this.reserva3 = null
               this.reserva4 = null
               this.reserva5 = null

               lista.forEach(a => {
                    switch(a.position_id) {
                         case 1:
                              this.reserva1 = a;
                              break;
                         case 2:
                              this.reserva2 = a;
                              break;
                         case 3:
                              this.reserva3 = a;
                              break;
                         case 4:
                              this.reserva4 = a;
                              break;
                         case 5:
                              this.reserva5 = a;
                              break;
                    }
               })
          },
     },
     computed:{
          computed_data(){
               // eslint-disable-next-line vue/no-side-effects-in-computed-properties
               this.total_price = 0;
               // eslint-disable-next-line vue/no-side-effects-in-computed-properties
               this.total_points = 0;
               // eslint-disable-next-line vue/no-side-effects-in-computed-properties
               this.total_price_change = 0;
               
               if(this.all_teams && this.all_teams[this.tab][this.round]){
                    var reservas = []
                    this.all_teams[this.tab][this.round].filter((item)=>{
                         if (item.titular == 1) {
                                   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                   this.total_price += item.price;
                                   if(item.captain)
                                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                        this.total_points += item.points * 1.5;
                                   else
                                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                        this.total_points += item.points;

                                   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                   this.total_price_change += item.price_change;
                         } else {
                              reservas.push(item);
                         }
                    }); 

                    this.adjust_team_order();
                    this.getReservas(reservas) 
                    
                    return this.all_teams[this.tab][this.round];
               }  else {
                    // eslint-disable-next-line
                    this.reserva1 = null
                    // eslint-disable-next-line
                    this.reserva2 = null
                    // eslint-disable-next-line
                    this.reserva3 = null
                    // eslint-disable-next-line
                    this.reserva4 = null
                    // eslint-disable-next-line
                    this.reserva5 = null
               }
               return null;
          }
     }
}
</script>
<style scoped lang="scss">
@import '@/assets/sass/variables.scss';
@import '@/assets/scss/field.scss';
// @import '@/assets/scss/general.scss';

     #rounds{
          i{
               // color: $verde;
               color: #4E4E4E;
               padding: 0px 5px;
          }
     }

     .warning{
          color: $laranja;
     }

     table{
          thead,tfoot{
               font-size: $table_header_font_size;
               text-align: center;
          }
          tbody{
               font-size: $table_body_font_size;    
          }
          td,th{
               padding:0px;
          }
          th{
               background-color: #4e4e4e;
               color: $light_text_color;
               border: none;
               font-weight: normal;
          }
          td{
               text-align:center;
          }
          th{
               padding: 0px 5px;
          }
          img{
               width:30px;
               height:30px;
          }
          tfoot{               
               background-color: #4e4e4e;
               color: $light_text_color;
               tr{
                    td{
                         padding:5px;
                    }
               }
          }
     }
     #coach{
          position: relative !important;
          bottom: auto !important;
          left: auto !important;
          float: left;
          margin-top: -95px;
          margin-left: 25px;
          top: -45px !important;
     }
     .col-coach {
          margin-left: 25px;
     }
     @media only screen and (max-width: 1000px) {
          #coach{
               margin-top: -70px;
               margin-left: 10px;
          }
     }
     .nav-tabs {
          border-bottom: 2px solid $laranja;
     }
     .nav-link {
          background-color: #4E4E4E;
     }
     .field-box {
          // background: radial-gradient(circle, rgba(145,130,162,1) 0%, rgba(233,233,233,1) 70%, rgba(245,245,245,1) 100%);
          // background: radial-gradient( circle farthest-corner at 50% 50%, rgb(1, 115, 77) 0%, rgb(205, 237, 246) 70%, rgb(227, 251, 252) 78%, rgb(230, 247, 235) 90% )
          background: radial-gradient( circle farthest-corner at 50% 50%, rgb(204, 204, 204) 0%, rgb(205, 237, 246) 70%, rgb(227, 251, 252) 78%, rgb(255, 255, 255) 90% );
     }
     .btn-selected {
          background-color: $laranja;
          color:white;
          border:1px solid  $laranja;
     }
     .rounds-title {
          margin-bottom: 10px;
     }
     .rounds-title span b {
          color: $verde;
     }
     .reserva {
          width: 60px;
          background-color: #fff;
          padding: 2px;
          border-radius: 50%;
          margin-bottom: 5px;
          transition: .2s all ease;
     }
     .club2reserva {
          width: 20px;
          padding: 0px;
          background-color: transparent;
          margin: 0;
          border: none;
          position: relative;
          margin-left: -20px;
          margin-top: 20px;
     }
     .span-reserva{
          white-space: nowrap;
          font-weight: bolder;
          background-color: white;
          color: #373e5b;
          padding: 2px 10px;
          border-radius: 10px;
          font-size: 12px;
     }
     .respos {
          color: #4F4F4F;
          // background-color: #F5F5F5;
          font-weight: bold;
          font-family: "Montserrat", sans-serif;
          font-size: 13px;
     }
     
     .titulo-reservas {
          border-bottom: 2px solid lightgray;
          color: $cinza_escuro;
          font-weight: bold;
          font-family: "Montserrat", sans-serif;
          margin-bottom: .8em;
          font-size: 1.2em;
          margin-top: .4em;
     }

     .titulo-reservas2 {
          border-bottom: 2px solid lightgray;
          color: $cinza_escuro;
          font-weight: bold;
          font-family: "Montserrat", sans-serif;
          font-size: 1em;
          // margin-bottom: .5em;
     }
     .round-active {
          color: $verde;
     }
     .texto-cinza {
          color: $cinza_escuro;
     }

</style>
