<template>
  <div>
    <customHeader
      :mobile="mobile"
      title="Premiação do Guru 2024"
      subtitle=""
    ></customHeader>
    <div class="">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a
           :class="{ smallFont: mobile }"
            class="nav-link active" 
            id="premiacao-tab"
            data-toggle="tab"
            href="#premiacao"
            role="tab"
            aria-controls="premiacao"
            aria-selected="true"
            >Premiação</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="{ smallFont: mobile }"
            class="nav-link"
            id="hallfama-tab"
            data-toggle="tab"
            href="#hallfama"
            role="tab"
            aria-controls="hallfama"
            aria-selected="false"
            >Hall da Fama</a
          >
        </li>
        <li class="nav-item">
          <a
            :class="{ smallFont: mobile }"
            class="nav-link"
            id="ranking-tab"
            data-toggle="tab"
            href="#ranking"
            role="tab"
            aria-controls="ranking"
            aria-selected="false"
            >Ranking - Geral</a
          >
        </li>
        <!-- <li class="nav-item">
          <a
            :class="{ smallFont: mobile }"
            class="nav-link"
            id="ranking-tab"
            data-toggle="tab"
            href="#ranking2turno"
            role="tab"
            aria-controls="ranking"
            aria-selected="false"
            >Ranking - 2º Turno</a
          >
        </li>
         <li class="nav-item">
          <a
            :class="{ smallFont: mobile }"
            class="nav-link"
            id="ranking-tab"
            data-toggle="tab"
            href="#rankingFevereiro"
            role="tab"
            aria-controls="ranking"
            aria-selected="false"
            
            >Ranking - Fevereiro</a
          >
        </li> -->
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show tab-body active"
          id="premiacao"
          role="tabpanel"
          aria-labelledby="premiacao-tab"
        >
          <TabPremiacao :mobile="mobile"></TabPremiacao>
        </div>
        <div
          class="tab-pane fade show"
          id="hallfama"
          role="tabpanel"
          aria-labelledby="hallfama-tab"
        >
          <TabHallFama :mobile="mobile"></TabHallFama>
        </div>
        <div
          class="tab-pane fade show"
          id="ranking"
          role="tabpanel"
          aria-labelledby="ranking-tab"
        >
          <TabRanking :mobile="mobile" :user="user"></TabRanking>
        </div>
        <!-- <div
          class="tab-pane fade show"
          id="ranking2turno"
          role="tabpanel"
          aria-labelledby="ranking-tab"
        >
          <TabRanking2Turno :mobile="mobile" :user="user"></TabRanking2Turno>
        </div>
        <div
          class="tab-pane fade show"
          id="rankingFevereiro"
          role="tabpanel"
          aria-labelledby="ranking-tab"
        >
          <TabRankingFevereiro :mobile="mobile" :user="user"></TabRankingFevereiro>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import customHeader from '@/components/header.vue';
import TabPremiacao from '@/views/tabs/tabPremiacao.vue';
import TabHallFama from '@/views/tabs/tabHallFama.vue';
import TabRanking from '@/views/tabs/tabRanking.vue';
// import TabRanking2Turno from '@/views/tabs/tabRanking2Turno.vue';
// import TabRankingFevereiro from '@/views/tabs/tabRankingFevereiro.vue';
import util from '@/mixin/util';

export default {
  name: "Mais",
  components: {
    customHeader,
    TabPremiacao,
    TabHallFama,
    TabRanking,
    // TabRanking2Turno,
    // TabRankingFevereiro,
  },
  mixins: [util],
  data() {
      return {
        user: {},
        //Verificação mobile
        mobileAt:1000,//Mobile a partir de qual width?
        mobile:false,//Status inicial do mobile
      }
  },
  created() {
    //Verificação mobile
    (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

    this.user = this.get_usuario_logado();
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/variables.scss';
@import '@/assets/scss/field.scss';
@import '@/assets/scss/general.scss';

#myTab .nav-item {
    margin-bottom: 10px;
    margin-right: 5px;
   border-radius: 5px 16px; 
}

#myTab .nav-link {
    border-radius: 5px 16px;
    background-color: #4e4e4e;
}

#myTab .nav-link.active {
  background-color:  $laranja;
}

.attention {
  color: $laranja;
  background-color: #f5f5f5 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e3e3e3' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  padding: 10px;
  border-radius:10px;
  font-weight: bold;
}

.attention-inverse {
  background-color: $laranja; 
  padding: 10px;
  border-radius:10px; 
}

.tab-body {
    /* background-color: #525a7c; */
    border-radius:10px;
    padding: 5px;
    /* color: white; */
}

.nav-tabs .nav-link.active {
    background-color: $laranja;
}

.smallFont {
    font-size: 0.6em;
}

.nav-tabs {
  border-bottom: 2px solid $laranja;
}

</style>
