import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './axios'
import VueSSE from 'vue-sse';

Vue.selling_page = Vue.prototype.selling_page = 'http://www.gurudocartola.com/timeguru/';//'http://www.gurudocartola.kpages.online/timeguru';

Vue.cors_page = Vue.prototype.cors_page = 'https://gurudocartola.com/app/cors?url=';

Vue.config.productionTip = false

Vue.prototype.$API_GLOBO = 'https://api.cartola.globo.com'

//Producao
Vue.prototype.$PATH = 'https://gurudocartola.com/app'

//Desenvolvimento Local
// Vue.prototype.$PATH = 'http://localhost:8444' 

//API AWS
// Vue.prototype.$API = 'https://pb89hpsof3.execute-api.us-east-1.amazonaws.com/beta/'
Vue.prototype.$API = 'https://pb89hpsof3.execute-api.us-east-1.amazonaws.com/prod/'



Vue.use(VueSSE);

new Vue({
  router,
  axios,
  render: h => h(App)
}).$mount('#app')