<template>
     <div>
          <customHeader title="Partidas ao vivo" :subtitle="subtitle" :mobile="mobile"></customHeader>
          
          <div class="container">
               <div class="row">
                    <div class="col-12 d-flex flex-column justify-content-center">
                         <template v-if="data!=null && Object.keys(data).length>0">
                              <div v-for="jogo in data"  :item="jogo" :key="jogo.id" class="row d-flex justify-content-center jogo">
                                   <template v-if="jogo.sde.equipe_mandante && jogo.sde.equipe_visitante">
                                        <div class="col-12 d-flex justify-content-center">
                                             <span class="d-flex justify-content-center rodada">{{jogo.rodada}}ª rodada - {{jogo.dataDaPartida.hour}}:{{jogo.dataDaPartida.minute}}h</span>
                                        </div>
                                        <div class="col-4 d-flex justify-content-end align-items-center"> 
                                             <span class="nomeClube" v-if="!mobile">{{jogo.sde.equipe_mandante.nome}}</span> 
                                             <img  :src="jogo.sde.equipe_mandante.escudo" class="img img-fluid escudo" >
                                        </div>
                                        <div :class="mobile?'col-4':'col-2'" class="d-flex flex-column align-items-center">
                                             <div class="d-flex">
                                                  <span class="placar">{{jogo.placar.golsMandante}}</span>
                                                  <span class="d-flex align-items-center x">X</span>
                                                  <span class="placar">{{jogo.placar.golsVisitante}}</span>
                                             </div>
                                        </div>
                                        <div class="col-4 d-flex justify-content-start align-items-center"> 
                                             <img  :src="jogo.sde.equipe_visitante.escudo" class="img img-fluid escudo" >
                                             <span class="nomeClube" v-if="!mobile">{{jogo.sde.equipe_visitante.nome}}</span> 
                                        </div>
                                        <div v-if="jogo.hasNarracao" class="col-12 d-flex justify-content-center">
                                             <a v-if="jogo.partidaEncerrada" :href="'/partida/'+jogo.id" class="btn btn-sm btn-cinza-claro">COMO FOI</a>
                                             <a v-else :href="'/partida/'+jogo.id" class="btn btn-sm btn-verde">AO VIVO</a>
                                        </div>
                                   </template> 
                              </div>
                         </template>
                         <template v-else>
                              <div class="w-100 d-flex justify-content-center">
                                   <h3>Ops! Ainda não há nenhum jogo programado para hoje :(</h3>
                              </div>
                         </template>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';

     export default {
          name: 'Partidas',
          components:{
               customHeader
          },
         mounted: function () {
               // window.setInterval(() => {
               //      this.getData()
               // }, 30000)
          },
          data(){
               return{
                    //Dados via API
                    data: [],
                    clubes: [],

                    subtitle: '',
                    
                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          watch:{
              
          },
          created(){
               //Verificação mobile
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

               // let vm = this;
               this.getData()
               this.carregaClubes()
               
               var today = new Date();
               var dd = String(today.getDate()).padStart(2, '0');
               var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
               var yyyy = today.getFullYear();
               today = dd + '/' + mm + '/' + yyyy;
               this.subtitle = 'Acompanhe os jogos de hoje (' + today + ') e confira os lances em tempo real!'
               
          },
          methods:{
               getData() {
                   let vm = this;
                    // var settings = {
                    //      "url": "https://aovivo.gurudocartola.com/partida/",
                    //      "method": "GET",
                    //      "timeout": 0,
                    //      "headers": {
                    //           "Content-Type": "application/json"
                    //      }
                    // };

                    // $.ajax(settings)
                    // .done(function(response) {
                    //     vm.data = response;
                    // })
                    // .fail(function(error) {
                    //      console.log('[error #1]:')
                    //      console.log(error)
                    // });
                    this.$http
                    .get('https://aovivo.gurudocartola.com/partida/')
                    .then(response => {
                         vm.data = response.data
                    })
                    .catch(function (error) {
                         console.log('[error #1]:')
                         console.log(error);
                    });   
              }, 
              carregaClubes() {
                    let vm = this;
                    this.$http
                    // .get(this.$_PATH+'/twitter/')
                    .get(this.$API+'twitter')
                    .then(response => {
                         vm.clubes = response.data
                    })
                    .catch(function (error) {
                         console.log(error);
                    });   
              }
          }
     }
</script>

<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';

     .rodada {
          font-size: 0.8em,;
          padding-top: 10px;
     }
     .data {
          font-size: 0.8em;
     }
     .nomeClube {
          font-family: "Open Sans",sans-serif;
          font-size: 1.6em;
          text-transform: uppercase;
     }
     .x {
          padding-left: 2em;
          padding-right: 2em;
          color: darkgray
     }
     .placar {
          font-size: 2em;
          color: green;
          font-weight: bold;
     }
     .escudo {
          padding-left: 1em;
          padding-right: 1em;
          max-height: 45px;
     }
     .jogo {
          padding-bottom: 20px;
          border-bottom: 1px dotted orange;
     }


     .btn-verde {
          border: 0px;
          background-color: $verde;
          color: white;
          box-shadow: 0 4px 12px 0px rgba(0,0,0,.1);
     }

     .btn-verde:hover {
          cursor: pointer;
          background-color: $verde_claro;
     }

     .btn-laranja {
          border: 0px;
          background-color: $laranja;
          color: white;
          box-shadow: 0 4px 12px 0px rgba(0,0,0,.1);
     }
     .btn-laranja:hover {
          cursor: pointer;
          background-color: $laranja_claro;
     }

     .btn-cinza-claro {
          border: 0px;
          background-color: $cinza_claro;
          color: white;
          box-shadow: 0 4px 12px 0px rgba(0,0,0,.1);
     }
     .btn-cinza-claro:hover {
          cursor: pointer;
          background-color: $cinza;
     }
</style>