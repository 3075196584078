<template>
     <div>
          <h3 class="text-center"> Página da Liga de Boas Vindas </h3>
          <hr>
          <div class="container">
               <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
               tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
               quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
               consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
               cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
               proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
     </div>
</template>
<script>

     export default {
          name: 'Liga1',
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     h3{
          margin-top:100px;
          color:$light_text_color;
     }
     p{
          color:$light_text_color;
     }
</style>