<template>
   <!-- Mapa de Calor -->
    <!-- <div class="modal fade" id="heatmapModal" tabindex="-1" role="dialog" aria-labelledby="heatmapModal" aria-hidden="true">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="moreInfo != ''">
                            Mapa de calor
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center"> -->
                    <div class="heatmap"></div>
                <!-- </div>
            </div>
        </div>
    </div> -->
</template>

<script>
export default {
    props: [
        'athlete_id',
        'round'
    ],
    methods: {
        heatmap(athlete_id, round){
            var vm = this;

            this.$http
            // .get(this.$_PATH+'/mapacalor/'+athlete_id+'/'+round)
            .get(this.$API+'mapacalor/'+athlete_id+'/'+round+'/')
            .then(response => {
                vm.heatmap_coordinates = [];
                vm.heatmap_coordinates = response.data;

                $('.heatmap').empty();
                // eslint-disable-next-line no-undef
                var heatmapInstance = h337.create({container: document.querySelector('.heatmap')});

                var width = 320;
                var height = 500;

                if(vm.mobile){
                    width= 225;
                    height= 350;
                }

                var points = [];

                if(vm.heatmap_coordinates != null){
                    $.each(vm.heatmap_coordinates,function(index,value){
                            points.push({
                                y: (1-value.x/100) * height,
                                x: value.y/100 * width,
                                value: 1
                            });
                    });
                }

                heatmapInstance.setData({
                    max: 3, 
                    data: points 
                });

                $('#heatmapModal').modal('show');
            })
            .catch(function (error) {
                console.log(error);
            }); 
        },
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/scss/heatmap.scss';
</style>