<template>
  <div class="container">
    <div class="w-100 text-center" id="rowLogin">
      <!-- <h4>Escale o seu time no Cartola FC com as estatísticas, mapas de calor, comparações, scouts e análises do adversário aqui no Guru do Cartola.</h4>
      <br /> -->
      <span>Para escalar o seu time no Cartola FC usando as nossa estatísticas, faça download do app Guru do Cartola agora mesmo na sua loja de aplicativos:</span>
      <br/>
      <div class="d-flex justify-content-center mt-3">
        <a target="_blank">
            <i class="fab fa-android m-3" style="font-size: 4em; color: green;"></i>
        </a>
        <a href="https://apps.apple.com/br/app/guru-do-cartola/id1434262914" target="_blank">
          <i class="fab fa-apple m-3" style="font-size: 4em; color: #4E4E4E;"></i>
        </a>
      </div>
      <template v-if="!mobile">
        <div class="d-flex">
          <div class="col-6" style="background-color: lightgray; padding-top:4em;">
              <span>Para escalar aqui pelo site, abra o app Guru do Cartola, clique em <b>Gerar código Guru Web</b>, leia o QR Code ao lado e pronto!</span>
              <br/><br/>
              <span> Você poderá escalar seu time por aqui também! :)</span>
          </div>
          <div class="col-6" style="border: 5px solid lightgray;">
            <template v-if="market_status">
              <template v-if="market_status.status_mercado == 4">
                    <!-- <h4 style="color:black; font-weight:bold;">OPS!</h4> -->
                    <h5 class="mercado-fechado">O mercado está em Manutenção.</h5>
                    <img src="https://images2.imgbox.com/11/86/u7fpusqz_o.png" style="width:20%;"/>
                    <h5 class="texto-cinza">Aguarde até que esteja disponível novamente para realizar o login.</h5>
              </template>
              <!-- <template v-else-if="market_status.status_mercado == 2">
                    <h5 class="mercado-fechado">O mercado está fechado.</h5>
                    <img src="https://images2.imgbox.com/11/86/u7fpusqz_o.png" style="width:20%;"/>
                    <h5 class="texto-cinza">Aguarde até que esteja aberto novamente para realizar o login.</h5>
              </template> -->
              <template v-else>
                  <template v-if="expirou">
                      <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" style="width:20%;"/>
                        <h4 class="texto-cinza" style="font-weight:bold;">OPS!</h4>
                        <h5 class="texto-cinza">O QR code do código de acesso expirou.  <br/> Atualize a página e tente novamente.</h5>
                  </template>
                  <template v-else>
                    <timer :countdown="60" style="width:200px; margin-top: 20px;" @onTimer="handleTimeout"/>
                    <br/>
                    <!-- {{accesscode}}
                    <br/> -->
                    <vue-qrcode v-if="accesscode" :value="accesscode" style="width:200px" />
                    <div v-else>
                      <loading id="loading" class="col-12 text-center" :loading="loading" ></loading>
                      <div v-if="!loading">
                        <img src="https://images2.imgbox.com/62/95/II0ciY6R_o.png" style="width:20%;"/>
                        <h4 class="texto-cinza" style="font-weight:bold;">OPS!</h4>
                        <h5 class="texto-cinza">Ocorreu um erro inesperado.  <br/> Tente novamente e caso o erro persista contate o suporte.</h5>
                      </div>
                    </div>
                  </template>
              </template>
            </template>
            <template v-else>
              C A R R E G A N D O . . . 
            </template>
          </div>
        </div>
        
        <br/><br/>
        
      </template>
      
      <br/>
    </div>
    
    <!-- Modal -->
    <div
      class="modal fade"
      id="socialLoginModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="socialLoginModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="socialLoginModalLabel">
              Acesso com Face / Gmail
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              No site só é possível entrar com login e senha (do Cartola).
              Coloque então seu e-mail abaixo e cadastre uma senha no Cartola!
            </p>
            <iframe
              id="globoIframe"
              src="https://login.globo.com/recuperacaoSenha/438?url=https://cartolafc.globo.com"
              style="width:100%;height:550px;"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Router from '@/router';
import util from '@/mixin/util';
import VueQrcode from 'vue-qrcode';
import Timer from '@/components/Timer.vue';
import loading from '@/components/Loading.vue';

export default {
  mixins: [util],
  name: "login",
  components:{
    loading, VueQrcode, Timer
  },
  props: {
    mobile: {
      type: Boolean,
      required: false,
      default: false
    },
    redirect: {
      type: String,
      required: false,
      default: 'escale-seu-time-cartola'
    }
  },
  sse: {
        cleanup: true,
  },
  data() {
    return {
      accesscode: '',
      token: null,
      user: null,
      expirou: false,

      //Variável para permitir carregamento na página
      loading: true,

      //Status do mercado via API do CARTOLA
      market_status:null,
    };
  },
  created() {
    this.get_usuario_logado();
    this.requestNewUUID();
    this.getMarketStatus();
  },
  watch: {
    accesscode:  function(newValue){
      
      // console.log('ac:', newValue)
      
      if (newValue != null && newValue != undefined) {
        var path = this.$PATH+'/auth/stream/'+ newValue
        this.$sse(path, { format: 'json' }).then(sse => {
              // var value = newValue
              sse.subscribe('login-event', (message) => {
                  this.user = message.usuario
                  this.user.contasVinculadas = message.contasVinculadas
                  this.set_usuario_logado(this.user)
                  window.location.href = "/"+this.redirect;
              });

              setTimeout(() => {
                // console.log('unsuscribed from: ', value)
                sse.unsuscribe('login-event');
                sse.close()
              }, 60000)

              sse.onError(() => {
                // console.log('sse error: ', value, e)
                sse.close()
              });

        });

      }
    }
  },
  methods: {
    handleTimeout() {
      // this.requestNewUUID()
      this.expirou = true
    },
    requestNewUUID() {
      var vm = this
      this.$http
      .get(this.$PATH+'/auth/request-uuid')
      .then(response => {
          vm.accesscode = response.data;
          // console.log('ac: ' + response.data) // @TODO remova-me
          vm.loading = false;
      })
      .catch(function (error) {
          vm.loading = false
          vm.accesscode = null
          console.log('ERROR #LGN01:')
          console.log(error);
      });  
    },
    getMarketStatus() {
      var vm = this
      this.$http
      // .get(this.$_PATH+'/mercado/status')
      .get(this.$API+'mercado-status')
      .then(response => {
          vm.market_status = response.data;
      })
      .catch(function (error) {
          console.log('ERROR #LGN02:')
          console.log(error);
      });  
    },
    beforeDestroy() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    this.$sse.disconnect();

    // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
    // and the SSEManager would have automatically disconnected during beforeDestroy.
  },
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

#loading {
  color: $light_text_color;
  img {
    margin-top: 50px;
    width: 75px;
  }
}
#login {
  background-color: white;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  padding: 25px;
}
#socialLoginModal {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
}
.remember {
  font-size: 13px;
  margin-bottom: 15px;
}
.divider {
  padding: 20px 0px;
  text-align: center;
  span {
    position: absolute;
    left: 30%;
    background-color: white;
    padding: 0px 15px;
    margin-top: 2px;
  }
}

input,
button {
  border-radius: 3px !important;
}

.btn {
  font-weight: bolder;
  box-shadow: inset 0 -2px rgba(0, 0, 0, 0.25);
  font-size: 15px;
}
.btn-facebook {
  background-color: #3b5998;
  color: white;
  i {
    font-weight: normal;
  }
}
.btn-google {
  span {
    background: url("https://s.glbimg.com/pc/cf/images/google-icon.png")
      no-repeat;
    width: 10px;
  }
  background-color: white;
  color: #666;
}
.modal-content {
  height: 100%;
}
h3 {
  color: $laranja;
}

.mercado-fechado {
  color: $cinza_escuro;
  text-transform: uppercase;
  margin-top: .4em;
  padding-bottom: .4em;
  border-bottom: 1px dotted $cinza_escuro;
}

.texto-cinza {
  color: $cinza_escuro;
}
</style>
