<template>
     <div>
          <customHeader title="Partida ao vivo" :subtitle="subtitulo" :mobile="mobile"></customHeader>
          
          <div class="container">
               <div class="row">
                    <div class="col-6 d-flex justify-content-center">
                         <template v-if="data.narracao && data.narracao.length > 0">
                              <timeline :items="data.narracao"></timeline>
                         </template>
                         <template v-else>
                              <div colspan="3" class="text-center breve d-flex flex-column">
                                   <i class="guru-icon icon-guru-whistle" style="font-size: 5em;"></i>
                                   <span style="font-size: 3em;">Em Breve</span>
                              </div>
                         </template>
                    </div>
                    <div class="col-6 d-flex justify-content-center">
                         <div class="d-flex flex-column w-100">
                              <div class="d-flex justify-content-center placar w-100"> 
                                  <div class="d-flex align-items-center">
                                        <template v-if="clubeMandante">
                                             <span class="siglaClube">{{clubeMandante.sigla}}</span>
                                             <img :src="clubeMandante.escudo" class="escudo-lg">
                                             <span class="placarValor">{{data.partida.placar.golsMandante}}</span>
                                        </template>
                                        <template v-else>
                                             <span class="siglaClube">GURU</span>
                                             <img src="https://images2.imgbox.com/b2/9e/b6UDNUZ8_o.png" style="mix-blend-mode: multiply;" class="escudo-lg">
                                             <span class="placarValor">?</span>
                                        </template>
                                  </div>
                                  <div class="d-flex align-items-center">
                                   <span class="x">x</span>
                                  </div>
                                   <div class="d-flex align-items-center">
                                        <template v-if="clubeVisitante">
                                             <span class="placarValor">{{data.partida.placar.golsVisitante}}</span>
                                             <img :src="clubeVisitante.escudo" class="escudo-lg">
                                             <span class="siglaClube">{{clubeVisitante.sigla}}</span>
                                        </template>
                                        <template v-else>
                                             <span class="siglaClube">GURU</span>
                                             <img src="https://images2.imgbox.com/b2/9e/b6UDNUZ8_o.png" style="mix-blend-mode: multiply;" class="escudo-lg">
                                             <span class="placarValor">?</span>
                                        </template>
                                   </div>
                              </div>
                              <!-- Timer Bar -->
                              <div class="round-time-bar" data-style="smooth" style="--duration: 30;">
                                   <div></div>
                              </div>
                              <div class="w-100 text-center escalacao">
                                   <span>Escalações</span>
                              </div>
                              <div v-if="data.escalacao" class="row">
                                   <div class="col-12">
                                        <div class="td-clube">
                                             <template v-if="clubeMandante">
                                                  <img :src="clubeMandante.escudo" class="escudo">
                                                  <span class="nomeClube"> {{clubeMandante.nome}} </span>
                                             </template>
                                             <template v-else>
                                                  <img src="https://images2.imgbox.com/b2/9e/b6UDNUZ8_o.png" class="escudo">
                                                  <span class="nomeClube"> Carregando.. </span>
                                             </template>
                                             
                                        </div>
                                        <div>
                                             <table class="w-100">
                                                  <thead class="w-100" style="border-bottom: 2px solid orange;">
                                                       <th class="td w-25 text-center" style="width:25%">Jogador</th>
                                                       <th class="td w-50 text-center" style="width:50%">Scouts</th>
                                                       <th class="td w-15 text-center" style="width:15%">Val.</th>
                                                  </thead>
                                                  <tr v-for="jogador in data.escalacao.equipeMandante" :item="jogador" :key="jogador.id" class="tr">
                                                       <template v-if="(jogador.titular || (jogador.substituto && jogador.tempoDeJogoEmSegundosEntrada>0)) && jogador.stats">
                                                            <td class="tbl-item" :class="{'substituido':jogador.foiSubstituido}">
                                                                 {{jogador.stats.apelido}}
                                                                 <i v-if="jogador.foiSubstituido" class="fas fa-exchange-alt red"></i>
                                                                 <i v-if="jogador.substituto && jogador.tempoDeJogoEmSegundosEntrada>0" class="fas fa-exchange-alt green"></i>
                                                            </td>
                                                            <td class="tbl-item" v-if="jogador.stats.scout">
                                                                 <div class="d-flex justify-content-start">
                                                                 <span v-for="(scout,index) in jogador.stats.scout" :key="index">
                                                                      <small :style="[neg_scouts.indexOf(index) == -1 ?  {'color':'green'} : {'color':'red'}]">
                                                                           {{index}}({{scout}}) &nbsp;
                                                                      </small>
                                                                 </span>
                                                                 </div>
                                                            </td>
                                                            <td v-else>
                                                                 -
                                                            </td>
                                                            <td class="tbl-item text-center" :class="jogador.stats.valorizacao>0?'green':'red'">
                                                                 {{jogador.stats.valorizacao}}
                                                            </td>
                                                       </template>
                                                       <template v-else-if="(jogador.titular || (jogador.substituto && jogador.tempoDeJogoEmSegundosEntrada>0))">
                                                            <td v-if="atletasMandante[jogador.sde.atleta_id]" class="tbl-item">
                                                                 {{atletasMandante[jogador.sde.atleta_id].apelido}}
                                                                 <i v-if="jogador.foiSubstituido" class="fas fa-exchange-alt red"></i>
                                                                 <i v-if="jogador.substituto && jogador.tempoDeJogoEmSegundosEntrada>0" class="fas fa-exchange-alt green"></i>
                                                            </td>
                                                            <td v-else class="tbl-item">{{jogador.sde.atleta_id}}</td>
                                                            <td class="tbl-item text-center"> - </td>
                                                            <td class="tbl-item text-center"> - </td>
                                                       </template>
                                                  </tr>
                                                  <tr v-if="data.escalacao &&  data.escalacao.equipeMandante &&  data.escalacao.equipeMandante.length == 0"> 
                                                       <td colspan="3" class="text-center breve"><span>Em Breve</span></td>
                                                  </tr>
                                             </table>
                                        </div>
                                   </div>
                                   <div class="col-12">
                                        <div class="td-clube">
                                             <template v-if="clubeVisitante">
                                                  <img :src="clubeVisitante.escudo" class="escudo">
                                                  <span class="nomeClube"> {{clubeVisitante.nome}} </span>
                                             </template>
                                             <template v-else>
                                                  <img src="https://images2.imgbox.com/b2/9e/b6UDNUZ8_o.png" class="escudo">
                                                  <span class="nomeClube"> Carregando.. </span>
                                             </template>
                                             
                                        </div>
                                        <div>
                                             <table class="w-100">
                                                  <thead class="w-100" style="border-bottom: 2px solid orange;">
                                                       <th class="td w-25 text-center" style="width:25%">Jogador</th>
                                                       <th class="td w-50 text-center" style="width:50%">Scouts</th>
                                                       <th class="td w-15 text-center" style="width:15%">Val.</th>
                                                  </thead>
                                                  <tr v-for="jogador in data.escalacao.equipeVisitante" :item="jogador" :key="jogador.id" class="tr">
                                                       <template v-if="(jogador.titular || (jogador.substituto && jogador.tempoDeJogoEmSegundosEntrada>0)) && jogador.stats">
                                                            <td class="tbl-item" :class="{'substituido':jogador.foiSubstituido}">
                                                                 {{jogador.stats.apelido}}
                                                                 <i v-if="jogador.foiSubstituido" class="fas fa-exchange-alt red"></i>
                                                                 <i v-if="jogador.substituto && jogador.tempoDeJogoEmSegundosEntrada>0" class="fas fa-exchange-alt green"></i>
                                                            </td>
                                                            <td class="tbl-item" v-if="jogador.stats.scout">
                                                                 <div class="d-flex justify-content-start">
                                                                 <span v-for="(scout,index) in jogador.stats.scout" :key="index">
                                                                      <small :style="[neg_scouts.indexOf(index) == -1 ?  {'color':'green'} : {'color':'red'}]">
                                                                           {{index}}({{scout}}) &nbsp;
                                                                      </small>
                                                                 </span>
                                                                 </div>
                                                            </td>
                                                            <td v-else>
                                                                 -
                                                            </td>
                                                            <td class="tbl-item text-center" :class="jogador.stats.valorizacao>0?'green':'red'">
                                                                 {{jogador.stats.valorizacao}}
                                                            </td>
                                                       </template>
                                                       <template v-else-if="(jogador.titular || (jogador.substituto && jogador.tempoDeJogoEmSegundosEntrada>0))">
                                                            <td v-if="atletasVisitante[jogador.sde.atleta_id]" class="tbl-item">
                                                                 {{atletasVisitante[jogador.sde.atleta_id].apelido}}
                                                                 <i v-if="jogador.foiSubstituido" class="fas fa-exchange-alt red"></i>
                                                                 <i v-if="jogador.substituto && jogador.tempoDeJogoEmSegundosEntrada>0" class="fas fa-exchange-alt green"></i>
                                                            </td>
                                                            <td v-else class="tbl-item">{{jogador.sde.atleta_id}}</td>
                                                            <td class="tbl-item text-center"> - </td>
                                                            <td class="tbl-item text-center"> - </td>
                                                       </template>
                                                  </tr>
                                                  <tr v-if="data.escalacao &&  data.escalacao.equipeMandante &&  data.escalacao.equipeMandante.length == 0"> 
                                                       <td colspan="3" class="text-center breve"><span>Em Breve</span></td>
                                                  </tr>
                                             </table>
                                        </div>
                                   </div>
                              </div>
                              <div v-else class="d-flex justify-content-center">
                                   Carregando...
                              </div>
                         </div>

                    </div>
               </div>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import timeline from '@/components/Timeline.vue';
     import util from '@/mixin/util';

     export default {
          name: 'Noticias',
          props: ['id'],
          mixins: [util],
          components:{
               customHeader, timeline
          },
          mounted: function () {
               window.setInterval(() => {
                    this.getNarracao()
                    // this.getPontuacaoAtletas()
                    
                    const bars = document.querySelectorAll(".round-time-bar");
                    bars.forEach((bar) => {
                         bar.classList.remove("round-time-bar");
                         bar.offsetWidth;
                         bar.classList.add("round-time-bar");
                    });
               }, 30000)
          },
          data(){
               return{
                    //Dados via API
                    titulo: 'Carregando...',
                    subtitulo: '',
                    data: [],
                    equipeMandante: {},
                    equipeVisitante: {},

                    clubeMandante: null,
                    clubeVisitante: null,

                    atletasMandante: [],
                    atletasVisitante: [],

                    neg_scouts:['GC','CV','PP','CA','GS','FC','I','PE','PI', 'PC'],
                    // pontuadosArray: [],

                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          created(){
               //Verificação mobile
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

               this.getNarracao()
               // this.getPontuacaoAtletas()

          },
          watch:{
               // clubeMandante: function (newValue) {
               //      this.getAtletas(newValue.id, true)
               // },
               // clubeVisitante: function (newValue) {
               //      this.getAtletas(newValue.id, false)
               // },
          },
          methods:{
               getNarracao() {
                   let vm = this;

                    this.$http
                    .get(this.$PATH+'/partida/'+vm.id)
                    .then(response => {
                         vm.data = response.data
                         if (vm.data.partida.sde.equipe_mandante) {
                              vm.clubeMandante = vm.data.partida.sde.equipe_mandante
                         }
                         if (vm.data.partida.sde.equipe_visitante) {
                                   vm.clubeVisitante = vm.data.partida.sde.equipe_visitante
                         }
                         vm.titulo = vm.clubeMandante.nome + ' x ' +  vm.clubeVisitante.nome
                         vm.subtitulo = '[' + vm.data.partida.dataDaPartida.dayOfMonth + '/' + vm.data.partida.dataDaPartida.monthValue + '/' + vm.data.partida.dataDaPartida.year + ' ' + vm.data.partida.dataDaPartida.hour + ':' + vm.data.partida.dataDaPartida.minute+'h] '
                                             + 'Estádio ' + vm.data.partida.estadio + ': ' + vm.clubeMandante.nome + ' x ' +  vm.clubeVisitante.nome + ', ' 
                                             + vm.data.partida.rodada+'ª Rodada - ' + vm.data.partida.nomeDaTaca 
                    })
                    .catch(function (error) {
                         console.log('[error #1]:')
                         console.log(error);
                    });   
              },
          },
     }
</script>

<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';

     #progress {
          background:orange;
          height:100%;
          width:0;
          text-align:right;
          font:bold 12px arial;
          border-right:1px silver solid;
          border-top-right-radius:4px;
          border-bottom-right-radius:4px;
          line-height:30px;
          color:#444;
          -webkit-transition:width 5s linear; /* For Safari 3.1 to 6.0 */
          transition:width 5s linear;
     }
     #progressContainer {
          width:90%;
          margin:0 auto;
          height:30px;
          border:1px silver solid;
          border-radius:4px;
          background:white;
     }
     .nomeClube {
          font-family: "Open Sans",sans-serif;
          text-transform: uppercase;
     }
     .escalacao {
          font-family: "Open Sans",sans-serif;
          text-align: center;
          text-transform: uppercase;
          background-color: green;
          // border: 2px solid black;
          color: white;
          font-size: 1.1em;
          font-weight: bold;
          padding: 2px;
          margin-bottom: 5px;
     }
     .td-clube {
          text-align: center;
          text-transform: uppercase;
          border-bottom: 2px solid orange;
          // margin-bottom: 20px;
          font-weight: bold;
     }
     .tbl-item {
          font-size: 0.8em;
     }
     .td {
          font-weight: normal;
          text-transform: uppercase;
          text-align: center;
          font-size: 0.8em;
     }
     .tr {
          border-bottom: 1px solid #CCC;
     }
     .green {
          color: green;
     }
     .red {
          color: red;
     }
     .escudo {
          height: 1.5em;
          margin-top: 5px;
          margin-bottom: 5px;
     }
     .escudo-lg {
          height: 2em;
     }
     .substituido {
          color: $cinza_claro;
     }
     .siglaClube {
          text-transform: uppercase;
          font-weight: bold;
          font-family: "Open Sans",sans-serif;
          margin: 0px 10px;
          font-size: 2em;
     }
     .placarValor {
          font-size: 2.5em;
          font-weight: bolder;
          // font-family: "Open Sans",sans-serif;
          color: green;
          margin: 0px 10px;
     }
     .x {
          color: grey;
     }
     .breve {
          padding-top:30px;
          padding-bottom:30px;
          font-weight: bold;
          font-family: "Open Sans",sans-serif;
          text-transform: uppercase;
     }
     .breve > span {
          border-bottom: 1px dotted orange;
     }

     /* Countdown Bar */

     .round-time-bar {
          margin-bottom: 0.5rem;
          overflow: hidden;
     }
     .round-time-bar div {
          height: 5px;
          animation: roundtime calc(var(--duration) * 1s) steps(var(--duration))
          forwards;
          transform-origin: left center;
          background: linear-gradient(to bottom, $laranja_claro, $laranja);
     }

     .round-time-bar[data-style="smooth"] div {
          animation: roundtime calc(var(--duration) * 1s) linear forwards;
     }

     .round-time-bar[data-style="fixed"] div {
      width: calc(var(--duration) * 5%);
     }

     .round-time-bar[data-color="blue"] div {
          background: linear-gradient(to bottom, #64b5f6, #1565c0);
     }

     @keyframes roundtime {
          to {
               /* More performant than `width` */
               transform: scaleX(0);
          }
     }
</style>