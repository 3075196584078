<template>
     <div class="container" style="background-color:#F7F7F7;">
          <div id="section_01" :style="[mobile ? {'height':'auto'} : {}]">
               <div class="row">
                    <div class="col-lg-12 col-md-12 col-xs-12 section-div text-center home-banner">
                         <img src="https://images2.imgbox.com/5c/c8/c1zyhnos_o.png" class="banner-home"/>
                    </div>
               </div>
               
               <div class="row"> 
                    <div class="col-12 d-flex justify-content-center" style="padding-left:0px; padding-right:0px;">
                         <div data-v-f52fcba4="" id="CTA_1" class="form-inline custom_CTA d-flex flex-row w-50 box-shadow justify-content-center" :class="{'w-75':mobile}" >
                              <div class="d-flex flex-row w-100 justify-content-center">
                                   <a id="submit_1" class="styled_button btn btn-warning mb-2" :class="{'mobile-btn':mobile}" href="/escale-seu-time-cartola">
                                        QUERO ESCALAR O MEU TIME NO CARTOLA FC!
                                   </a>
                              </div>
                         </div>
                    </div>
               </div>

               <div id="section_comments" class="mb-4 mt-4">
                    <div class="card-deck text-center">
                         <div class="card mb-4 box-shadow">
                              <div class="card-header">
                                   <img class="guru-card-img" src="https://images2.imgbox.com/12/2a/Q7OXLdzf_o.jpeg" />
                              </div>
                              <div class="card-body d-flex flex-column">
                                   <div class="card-body-bg"></div>
                                   <ul class="list-unstyled mt-3 mb-4">
                                        <li class="guru-card-text">Escale o seu time no Cartola FC com as estatísticas, mapas de calor, comparações, scouts e análises do adversário aqui no Guru do Cartola.</li>
                                   </ul>
                                   <a type="button" class="align-self-end btn btn-lg btn-block guru-card-btn" href="/escale-seu-time-cartola">ESCALAR</a>
                              </div>
                         </div>
                         <div class="card mb-4 box-shadow">
                              <div class="card-header">
                                   <img class="guru-card-img" src="https://images2.imgbox.com/a7/ae/TjC3TdIw_o.jpeg" />
                              </div>
                              <div class="card-body d-flex flex-column">
                                   <div class="card-body-bg"></div>
                                   <ul class="list-unstyled mt-3 mb-4">
                                        <li class="guru-card-text">Acompanhe quantas cartoletas o seu time está valorizando no Cartola FC em tempo real.</li>
                                   </ul>
                                   <a type="button" class="align-self-end btn btn-lg btn-block guru-card-btn" style="margin-top: auto;" href="/valorizacao-ao-vivo-cartola">VALORIZAÇÃO</a>
                              </div>
                         </div>
                         <div class="card mb-4 box-shadow">
                              <div class="card-header">
                                   <img class="guru-card-img" src="https://images2.imgbox.com/01/59/8moEync7_o.jpeg" />
                              </div>
                              <div class="card-body d-flex flex-column">
                                   <div class="card-body-bg"></div>
                                   <ul class="list-unstyled mt-3 mb-4">
                                        <li class="guru-card-text">Não entre em campo com o seu time desfalcado! Veja as últimas notícias de cada clube.</li>
                                   </ul>
                                   <a type="button" class="align-self-end btn btn-lg btn-block guru-card-btn" style="margin-top: auto;" href="/noticias-cartola">NOTÍCIAS</a>
                              </div>
                         </div>
                    </div>
                    
               </div>

               
          </div>

          <div id="section_2">
               <div class="row">
                    <div class="col-12 text-center section-div">
                         <div id="myCarousel" class="carousel slide" data-ride="carousel">
                              <div class="carousel-inner container section" style="min-height2:200px;">
                                   <div class="carousel-item" :class="{'active':idx==0}" v-for="(n, idx) in rows" :key="idx">
                                        <div class="d-flex flex-row justify-content-center" v-if="idx*cols+1<=comentarios.length">
                                             <template  v-for="(i, coluna) in cols">
                                                  <div class="d-flex align-items-stretch" :class="{'col-5':!mobile, 'col-12':mobile}" :key="idx*cols+coluna" v-if="idx*cols+coluna<comentarios.length">
                                                       <div class="comentario mb-0 card-body d-flex flex-column align-self-stretch">
                                                            <!-- <div class="comentario-footer mt-1 mb-1 row d-flex justify-content-center"> 
                                                                 <img class="comentario-img box-shadow" :src="'https://gurudocartola.com/img/premiacoes/'+comentarios[idx*cols+coluna].foto">
                                                                 <div class="small w-100 text-center mt-2" >
                                                                      <span class="comentario-nome">{{ comentarios[idx*cols+coluna].nome }}</span>
                                                                      <hr class="comentario-hr">
                                                                      <span class="comentario-time">{{ comentarios[idx*cols+coluna].time }} </span>
                                                                 </div>
                                                            </div> -->
                                                            <!-- |rows={{rows}}, n={{n}}, idx={{idx}}| cols={{cols}}, i={{i}}, coluna={{coluna}} | idx*cols+coluna={{idx*cols+coluna}} -->
                                                            <div class="comentario-footer mt-1 mb-1 row d-flex align-items-center" :class="{'align-items-center':!mobile, 'justify-content-center':mobile}"> 
                                                                 <img class="comentario-img box-shadow" style="margin-right:0px;" :src="'https://gurudocartola.com/img/premiacoes/'+comentarios[idx*cols+coluna].foto">
                                                                 <div class="small" style="width:79%;">
                                                                      <span class="comentario-nome" style="padding-left:20px; font-size:1.3em;">{{ comentarios[idx*cols+coluna].nome }}</span>
                                                                      <hr class="comentario-hr">
                                                                      <span class="comentario-time" style="padding-left:20px;">{{ comentarios[idx*cols+coluna].time }}</span>
                                                                 </div>
                                                            </div>
                                                            <div class="d-flex mt-auto mb-auto align-self-stretch text-justify">
                                                                 <span>"{{ comentarios[idx*cols+coluna].texto }}"</span>
                                                            </div>
                                                           
                                                       </div>
                                                  </div>
                                             </template>
                                        </div>
                                   </div>
                              </div>
                              <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                                   <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                   <span class="sr-only">Previous</span>
                              </a>
                              <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                                   <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                   <span class="sr-only">Next</span>
                              </a>
                         </div>
                    </div>
               </div>

               <div class="row">
                    <div class="col-lg-12 col-md-12 col-xs-12">
                         <div class="row justify-content-center frase2s mb-4 mt-4">
                              <div class="col-md-5 col-xs-12 box-shadow"> 
                                   <div class="section section-box home-box">
                                        <h2 class="section-header">Vão ter que nos engolir!</h2>
                                   </div>
                                   <br/>
                                   <p style="text-align:justify">
                                        Com as nossas análises, estatísticas e resenhas dos grupos de Whatsapp, ajudamos os nossos assinantes a serem <strong>CAMPEÕES DE 7.006 LIGAS EM 2021</strong>.
                                   </p>
                                   <p style="text-align:justify">
                                        O que você vai ver aqui são as ferramentas que mais fizeram os cartoleiros terem resultado nos últimos anos. Além disso, trouxemos também novidades pedidas pelos nossos assinantes.
                                   </p>
                                   <!-- <p class="text-center">
                                        <button id="submit_1" type="button" class="styled_button btn btn-warning mb-2" v-on:click="saveUserAtQueue()">
                                             Quero participar do Guru em 2020!
                                        </button>
                                   </p> -->
                              </div>
                              <div class="col-md-1">
                              </div>
                              <div class="col-md-5 col-xs-12 box-shadow home-box">
                                   <div class="section section-box">
                                        <h2 class="section-header">É TETRAAA! <br/> É TETRAAAAAA!</h2>
                                   </div>
                                   <br/>
                                   <p style="text-align:justify">
                                        O Guru do Cartola foi criado em 2017 para trazer números extremamente precisos aos Cartoleiros e te ajudar a mitar no Cartola FC.
                                   </p>
                                   <p style="text-align:justify">
                                        Ao longo desse tempo, sempre escutamos os nossos amigos cartoleiros e montamos a melhor versão do site para 2021.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>

               <div class="row">
                    <div class="col-12 d-flex justify-content-center mb-4" style="padding-left:0px; padding-right:0px;">
                         <div data-v-f52fcba4="" id="CTA_1" class="form-inline custom_CTA d-flex flex-row w-100 box-shadow" style="margin-top:0px;">
                              <!-- style="margin-top:0px;border-top:3px solid orange;border-bottom:3px solid orange;" -->
                              <div class="d-flex flex-row w-100 justify-content-center">
                                   <a id="submit_1" class="styled_button btn btn-warning mb-2" :class="{'mobile-btn':mobile}" href="/escale-seu-time-cartola" >
                                        Que tal escalar o seu time usando nossas estatísticas? Clique aqui!
                                   </a>
                              </div>
                         </div>
                    </div>
               </div>
             
          </div> 

         

          <div id="section_3">
               <div>
                    <div class="card-deck">
                         <div class="card box-shadow">
                         <div id="iframe_twitter" class="twitter"
                              :class="[mobile ? 'order-2' : '']"
                              :style="[mobile ? {'max-height':'400px'} : {}]">
                              <a class="twitter-timeline" href="https://twitter.com/Gurudocartola_?ref_src=twsrc%5Etfw">Tweets by Gurudocartola_</a>
                         </div>
                         </div>
                         <div class="bloxg card box-shadow"  style="flex-grow: 2"
                              :class="[mobile ? 'order-1' : '']"
                              :style="[mobile ? {'height':'auto'} : {}]">
                              <h2 class="blog-header">Última postagem no blog</h2>
                              <hr>
                              <div class="text-center" v-if="last_post_wp">
                                   <img :src="'http://gurudocartola.com/blog/wp-content/uploads/' + last_post_wp.meta_value" class="img img-fluid">
                                   <h3>{{last_post_wp.post_title}}</h3>
                                   <!-- <hr> -->
                                   <p>
                                        {{clean_string_from_html_tags(last_post_wp.post_content.substr(0,300))}} ...
                                   </p>
                                   <a :href="last_post_wp.guid" target="_blank">
                                        <button class="btn btn-light" style="float:right;">
                                             Acessar
                                        </button>
                                   </a>
                              </div>
                         </div>
                    </div>
               </div>
          </div>

          <div id="section_hallfame">
               <div class="foo">
                    <div class="row">
                         <div class="col-12 section">
                              <h2 class="section-header">Sabe de queeem??</h2>
                         </div>
                          <!-- <p class="section-sublines" style="text-align:justify; color:#f5f5f5; text-align:center;"> -->
                         <div class="col-12 text-center">
                              <b>Toda rodada</b> um membro do Time Guru ganha uma <b>camisa oficial</b>!
                         </div>
                         <hr>
                    </div>
                    <div class="row">
                         <div class="col-12 text-center">
                              <TabHallFama :mobile="mobile"></TabHallFama>
                         </div>
                    </div>
               </div>
          </div>

     </div>
</template>
<script>
     import TabHallFama from '@/views/tabs/tabHallFama.vue';
     export default {
          name: 'Home',
          components: {
               TabHallFama
          },
          data(){
               return {
                    last_post_wp:null,

                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
                    // col:null,

                    comentarios: [],
                    // [
                    //      {'id':1,'nome':'Breno 1', 'foto':'1_t.jpg', 'texto':'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.'},
                    //      {'id':2,'nome':'Byankye 2', 'foto':'1_rodada_Byankye_Pereira_t.jpeg', 'texto':'Quisque euismod, nulla vel sollicitudin dictum, ex lorem ultricies dolor, a malesuada eros sapien vitae nibh.'},
                    //      {'id':3,'nome':'Júlio 3', 'foto':'2_rodada_Tiago_Oliveira_t.jpeg', 'texto':'Vivamus hendrerit nibh sed nunc cursus lacinia eu eu lacus. Integer sodales, urna in rhoncus accumsan, arcu ipsum euismod enim, eget iaculis dui nunc non urna.'},
                    //      {'id':4,'nome':'Vinícius 4', 'foto':'3_rodada_Byankye_Pereira_t.jpg', 'texto':'Quisque eu lobortis odio, lobortis blandit augue. Sed ut sem turpis.'},
                    //      {'id':5,'nome':'Flávio 5', 'foto':'4_t.jpeg', 'texto':'Suspendisse pretium auctor lobortis. Cras eleifend nulla fringilla enim egestas, mollis viverra nibh euismod. Donec semper nunc tortor, a tristique mi condimentum in. Etiam pulvinar sollicitudin lacus, eu tincidunt justo ac.  Ipsum Lorem Es.'},
                    //      {'id':6,'nome':'Fernando 6', 'foto':'4_rodada_Renato_Barbosa_t.jpg', 'texto':'ras efficitur arcu turpis, ac fringilla lorem pellentesque et. Aliquam erat volutpat. Proin a faucibus purus.'},
                    //      {'id':7,'nome':'Dennis 7', 'foto':'5_rodada_Joao_Vitor_Batista_t.jpg', 'texto':'Nunc leo mauris, placerat pharetra placerat ut, cursus eget augue.'},
                    //      {'id':8,'nome':'Renato 8', 'foto':'6_rodada_Nilo_Otavio_t.jpg', 'texto':'Donec rutrum, est suscipit ornare cursus, velit neque auctor turpis, sit amet maximus felis sem vel sem. Morbi id ex vitae sem suscipit dapibus sed sit amet tellus.'},
                    //      {'id':9,'nome':'Suelen 9', 'foto':'12_rodada_Andre_Vilela_t.jpg', 'texto':'Donec vehicula, dolor eget iaculis hendrerit, lorem est sollicitudin sapien, vitae dignissim lacus orci eget dolor.'},
                    //      {'id':10,'nome':'Fulano de 10', 'foto':'13_rodada_Vinicius_Gomes_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':11,'nome':'Fulano de 11', 'foto':'14_rodada_Michel_Queiroz_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':12,'nome':'Fulano de 12', 'foto':'16_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':13,'nome':'Fulano de 13', 'foto':'17_rodada_Flavio_Rodrigues_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':14,'nome':'Fulano de 14', 'foto':'20_rodada_Julio_Fondora_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':15,'nome':'Fulano de 15', 'foto':'21_rodada_Vinicius_Gomes_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':16,'nome':'Fulano de 16', 'foto':'22_rodada_Carlos_Alexandre_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':17,'nome':'Fulano de 17', 'foto':'23_rodada_Mizael_Marcos_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':18,'nome':'Fulano de 18', 'foto':'25_rodada_Breno_Moreira_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':19,'nome':'Fulano de 19', 'foto':'26_rodada_Diego_Ferber_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':20,'nome':'Fulano de 20', 'foto':'MM_15_Joao_Lima_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':21,'nome':'Fulano de 21', 'foto':'Tiago_Macedo_liga_rodada_9_1_lugar_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':22,'nome':'Fulano de 22', 'foto':'27_Martins_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':23,'nome':'Fulano de 23', 'foto':'29_Gothardo_Santos_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':24,'nome':'Fulano de 24', 'foto':'30_Fernando_Magalhaes_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':25,'nome':'Fulano de 25', 'foto':'31_Cristian_Collares_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':26,'nome':'Fulano de 26', 'foto':'32_Wilson_Filho_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':27,'nome':'Fulano de 27', 'foto':'33_Felipe_Gomes_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':28,'nome':'Fulano de 28', 'foto':'34_Thiago_Venancio_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':29,'nome':'Fulano de 29', 'foto':'36_Suelen_Ramos_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':30,'nome':'Fulano de 30', 'foto':'MM_Bruno_Milano_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':31,'nome':'Fulano de 31', 'foto':'MM_Denis_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':32,'nome':'Fulano de 32', 'foto':'MM_Leonardo_Saraiva_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':33,'nome':'Fulano de 33', 'foto':'MM_Vitor_Souza_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':34,'nome':'Fulano de 34', 'foto':'MM_Caio_Brilha_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':35,'nome':'Fulano de 35', 'foto':'MM_Jhonata_Noronha_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':36,'nome':'Fulano de 36', 'foto':'MM_Guilherme_Amaro_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':37,'nome':'Fulano de 37', 'foto':'MM_Ph_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':38,'nome':'Fulano de 38', 'foto':'MM_Andrade_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':39,'nome':'Fulano de 39', 'foto':'MM_Junior_Souza_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':40,'nome':'Fulano de 40', 'foto':'MM_15_Joao_Lima_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':41,'nome':'Fulano de 41', 'foto':'MM_Leandro_Alonso_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':42,'nome':'Fulano de 42', 'foto':'MM_Jorge_Borges_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':43,'nome':'Fulano de 43', 'foto':'ps4_Joao_Lima_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    //      {'id':44,'nome':'Fulano de 44', 'foto':'Joao_Libertadores_t.jpg', 'texto':' Aliquam erat volutpat. Proin a faucibus Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
                    // ],
                    cols: 2,
                    rows: 0,
               }
          },
          created(){
               //Verificação mobile
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

               var vm = this;

               //Recarregue o twitter depois de meio segundo da criação da página
               setTimeout(function(){
                    vm.reload_twitter_iframe();
               },500);

               //Carregue o último post no wordpress
               // $.ajax({
               //      type: "GET",
               //      url: 'https://gurudocartola.com/last_post_wp.php',
               //      success: function (result) {
               //           vm.last_post_wp = JSON.parse(result);
               //      },
               //      error: function (error) {
               //           console.log('erro:')
               //           console.log(error);
               //      }
               // });
               this.$http
               .get('https://gurudocartola.com/last_post_wp.php')
               .then(response => {
                    vm.last_post_wp = response.data
               })
               .catch(function (error) {
                    console.log('last post wp error:')
                    console.log(error);
               }); 

               this.getDepoimentos();

               vm.rows = Math.ceil(vm.comentarios.length/vm.cols);

               
          },
          
          mounted(){
               //Depois que a página estiver com o DOM implementado, defina o valor de intervalo do slider
               $('.carousel').carousel({
                    interval: 3000999
               })
          },
    
          methods:{
               //Recarregue o iframe do twitter
               reload_twitter_iframe(){
                    $("#twitter-wjs").remove();
                    $("#iframe_twitter").html('');

                    setTimeout(function(){
                         window.twttr = (function (d,s,id) {
                              
                              var e = d.getElementsByTagName(s);
                              e[0].parentNode.removeChild(e[0]);
                              var t, js, fjs = d.getElementsByTagName(s)[0];
                              if (d.getElementById(id)) return; js=d.createElement(s); js.id=id;
                              js.src="https://platform.twitter.com/widgets.js";
                              fjs.parentNode.insertBefore(js, fjs);

                              return window.twttr || (t = { _e: [], ready: function(f){ t._e.push(f) } });

                         }(document, "script", "twitter-wjs"));
                    },100);

                    $("#iframe_twitter").html('<a class="twitter-timeline" href="https://twitter.com/Gurudocartola_?ref_src=twsrc%5Etfw"></a>');
               },
               //Recupera os depoimentos
               getDepoimentos() {
                    this.$http
                    // .get(this.$_PATH+"/depoimentos/")
                    .get(this.$API+"depoimentos/")
                    .then(response => {
                         this.comentarios = response.data;
                    });
               },
               //Remove as tags html de uma string "s"
               clean_string_from_html_tags(s){
                    return s.replace(/<\/?[^>]+(>|$)/g, "");
               }
          },
          watch: {
               mobile(newValue) {
                    this.cols = newValue==true ? 1 : 2
                    this.rows = Math.ceil(this.comentarios.length/this.cols)
               },
               comentarios() {
                    this.cols = this.mobile ? 1 : 2
                    this.rows = Math.ceil(this.comentarios.length/this.cols)
               },
          }
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     .home-banner {
          background: url("https://images2.imgbox.com/0d/f6/1127pgyl_o.png"); // center center fixed;
          background-color: $verde;          
     }
     .home-box {
          background-color: #FFFFFF;
     }
     #submit_1 {
          background-image: linear-gradient(135deg,#01734d 10%,#0aa75e);
          border: $laranja;
          color: white;
          text-shadow: 0 1em 1em rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
          margin-top: 8px;
     }

     #CTA_1 {
          border-radius:15px; 
          // border:3px solid $laranja;
          background-image: url('https://images2.imgbox.com/72/92/q81ldK8G_o.gif');
          padding: 15px 15px;
     }
     // #section_comments{
     //      margin-top: 40px;
     //      margin-bottom: 40px;
     // }
     #section_3{
          // padding: 30px 0px 40px 0px;
          padding: 0px 0px 40px 0px;
          .twitter{
               background-color:white;
               max-height:550px;
               overflow:auto;
          }
          .blog{
               height: 550px;
               background: #606c88; 
               background: -webkit-linear-gradient(to right, #3f4c6b, #606c88); 
               background: linear-gradient(to right, #3f4c6b, #606c88); 
               color: #f2f2f2;
               padding:25px;
               img{
                    max-width: 325px;
                    margin: 25px;
               }
          }
     
     }
     .content {
          background-color: #f5f5f5 !important;
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e3e3e3' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
     }
     .frases {
          margin-top: 1.5em;
          margin-bottom: 1.5em;
     }
     .section {
          // background: url("/img/soccer_horizontal_field_sm.jpg") no-repeat center center fixed;
          // background: url("https://images2.imgbox.com/df/36/JqPJSlJB_o.gif") no-repeat center center fixed;
          // -webkit-background-size: cover;
          // -moz-background-size: cover;
          // -o-background-size: cover;
          // background-size: cover;
          background: url("https://images2.imgbox.com/0d/f6/1127pgyl_o.png"); // center center fixed;
          background-color: $verde;          
          border-top: 3px solid $laranja;
          border-bottom: 3px solid $laranja;
     }
     .section-box {
          margin: 0px -15px;
     }
     .section-div {
          padding: 0px;
     }
     .section-header {
          font-family: "Montserrat", sans-serif;
          text-align: center;
          text-transform: uppercase;
          padding: 15px;
          font-weight: 200;
          letter-spacing: 5px;
          color: #f5f5f5;
          text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.95);
     }
     .section-sublines {
          text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.95);
     }
     .comentario {
          background-color: rgba(255, 255, 255, 0.6);
          margin-top: 25px;
          margin-bottom: 25px !important;
          min-height: 266px;
          border-radius: 50px;
     }
     .comentario-footer {
          margin: 0px;
          text-align:left;
     }
     .comentario-img {
          width:75px;
          border-radius: 50%;
          margin-right: 10px;
          border: 2px solid $laranja;
     }
     .comentario-nome {
          font-weight: bold;
          text-transform: uppercase;
     }
     .comentario-hr {
          margin:0px;
          border-top: 2px solid $laranja;
     }
     .comentario-time {
         font-weight: bold;
         font-style: italic;
     }
     .guru-card {
          box-shadow: 0 0 20px 5px rgba(91,91,91,.37);
          min-height:200px;
     }
     .guru-card-text {
          font-size: 1.2rem;
     }
     .guru-card-img {
          width: 100%;
          margin: 0px 0px 20px 0px;
          border-radius: 8%;
     }
     .guru-card-btn {
          width:100%;
          padding: 10px 0px;
          border-bottom: 3px solid $laranja;
          border-radius: 0px;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 1.25rem;
          letter-spacing: 5px;
          background-color: transparent;
          color: $cinza_escuro;
          
     }
     .guru-card-btn:hover {
          background-color: $laranja;
          border-radius: .3rem;
          color: white;
     }
     .carousel-inner {
          // border: 2px solid red;
          padding: 0px;
          // border-bottom: 0px;
     }
     .card {
          background-color: transparent;
          border-radius: 8%;
     }
     .card-header {
          background-image: url('https://images2.imgbox.com/72/92/q81ldK8G_o.gif');
          border-top-right-radius: 8%;
          border-top-left-radius: 8%;
     }
     .card-body {
          // background-image: url('https://images2.imgbox.com/b5/de/76XMD8lG_o.gif');
          font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
          font-weight: bold;
          position: relative;
          z-index: 1;
     }
     .card-body-bg {
          position: absolute;
          z-index: -1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: url('https://images2.imgbox.com/b5/de/76XMD8lG_o.gif') center center;
          opacity: .5;
          width: 100%;
          height: 100%;
     }
     .box-shadow {
          box-shadow: 0 0 20px 5px rgba(91,91,91,.37);
     }
     .banner-home {
          width: 100%;
          padding-bottom: 29px;
          border-bottom: 3px solid $laranja;
     }
     .blog-header {
          font-family: "Montserrat", sans-serif;
          letter-spacing: 5px;
          text-transform: uppercase;
          border-bottom: 3px solid $laranja;
          padding: 0.5em 0em;
          text-align: center;
          font-size: 1.2rem;
          margin-bottom: -16px;
     }
     /* CTA */
     .custom_CTA {
          // background-color: #0b0b0b;
          // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          // background-image: linear-gradient(135deg,#01734d 10%,#0aa75e);
          background-color: $laranja;
          // border-bottom: 3px solid orange;
          // border-top: 3px solid orange;
          padding: 10px 10px 5px 10px;
          z-index: 1;
          -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          margin-top: -32px;
     }
     .styled_button {
          font-size: 1vw;
          background-color: $laranja; 
          font-weight:bold;
     }
     .mobile-btn {
          font-size: 2vw;
          background-color: $laranja; 
          font-weight:bold;
     }

</style>
