<template>
     <div>
          <customHeader title="TIME GURU COM MEIO KILO" subtitle="O MAIOR PLANO DA HISTÓRIA PARA O REI DO PITACO!" :mobile="mobile"></customHeader>
          <div class="row align-items-center overlay" v-if="processando">
               <div class="col-md-12">
                    <div class="load-info">
                         <div class="c-loader"></div>
                    </div>
               </div>
          </div>
          <div class="container">
               <section> 
                    <div class="row div-dados">
                         <div class="col-12 col-md-6 offset-md-3 text-center">
                              <img src="https://images2.imgbox.com/e1/c4/U8Q5Nc1c_o.png" width="100%"/>
                         </div>
                    </div>
               </section>
               <section style="" class="pt-4 div-verde">
                    <div class="row div-dados mb-3">
                         <div class="col-12 text-center">
                              <h2 class="sucesso align-self-center">A MAIOR PARCERIA POSSÍVEL</h2>
                         </div>
                    </div>
                    <div class="row div-dados pb-3">
                         <div class="col-10 offset-1 ">
                              <p class="sucesso">A parceria entre o Meio Kilo, melhor jogador de Rei do Pitaco, e o Guru do Cartola iniciou na Copa do Mundo e foi um sucesso!</p>
                              <p class="sucesso">Os assinantes do plano já faturaram <b>centenas de milhares de reais</b> entre a Copa do Mundo 2022 e os Estaduais de 2023!</p>
                              <p class="sucesso">O plano oferece a qualidade do Time Guru aliada à técnica do melhor jogador de Rei do Pitaco, com mais de 400 mil reais faturados no game!</p>
                         </div>
                    </div>
               </section>
               <section class="div-preta">
                    <div class="row div-dados mb-3 pt-4">
                         <div class="col-12 text-center">
                              <h2 class="sucesso align-self-center">Confira alguns resultados dos participantes</h2>
                         </div>
                    </div>
                    <div class="row div-dados">
                         <div class="col-12 col-md-4 text-center">
                              <img src="https://images2.imgbox.com/12/da/TRscxIbt_o.jpg" width="90%"/>
                         </div>
                         <div class="col-12 col-md-4 text-center">
                              <img src="https://images2.imgbox.com/83/5f/kadOfX2F_o.jpg" width="90%"/>
                         </div>
                         <div class="col-12 col-md-4 text-center">
                              <img src="https://images2.imgbox.com/5b/5e/UPL1Nw0Q_o.jpg" width="90%"/>
                         </div>
                         <div class="col-12 col-md-4 text-center">
                              <img src="https://images2.imgbox.com/65/9c/JP85vVCl_o.jpg" width="90%"/>
                         </div>
                         <div class="col-12 col-md-4 text-center">
                              <img src="https://images2.imgbox.com/d1/6d/CmubknPj_o.jpg" width="90%"/>
                         </div>
                         <div class="col-12 col-md-4 text-center">
                              <img src="https://images2.imgbox.com/d9/b7/vmAsC931_o.jpg" width="90%"/>
                         </div>
                    </div>
                    <div class="row div-dados mt-5"> 
                         <div class="col-12 d-flex">
                         <div class="cta-div col-12 d-flex justify-content-center" style="padding-left:0px; padding-right:0px;" >
                              <div data-v-f52fcba4="" id="CTA_1" class="form-inline custom_CTA d-flex flex-row w-50 box-shadow justify-content-center" :class="{'w-75':mobile}" >
                                   <div class="d-flex flex-row w-100 justify-content-center">
                                        <a id="submit_1" class="styled_button btn btn-warning mb-2" :class="{'mobile-btn':mobile}" href="/getnet">
                                             QUERO FAZER PARTE DESSE GRUPO
                                        </a>
                                   </div>
                              </div>
                         </div>
                         </div>
                    </div>
               </section>
               <section class="div-preta">
                    <div class="row div-dados mb-3 pt-4">
                         <div class="col-12 text-center">
                              <h2 class="sucesso align-self-center">Teremos dicas para quais campeonatos?</h2>
                         </div>
                    </div>
                    <div class="row div-dados pb-3">
                         <div class="col-10 offset-1 ">
                              <p class="sucesso">O plano irá cobrir TODOS OS CAMPEONATOS do Rei do Pitaco, incluindo:</p>
                              <p class="sucesso">
                                   <ul class="guru-li">
                                        <li>✓ Estaduais</li>
                                        <li>✓ Libertadores</li>
                                        <li>✓ Copa do Brasil</li>
                                        <li>✓ Copa do Nordeste</li>
                                        <li>✓ Brasileirão</li>
                                        <li>✓ Série B</li>
                                        <li>✓ Champions League</li>
                                        <li>✓ Premier League</li>
                                   </ul>
                              </p>
                         </div>
                    </div>
               </section>
               <section class="div-verde">
                    <div class="row div-dados mb-3 pt-4">
                         <div class="col-12 text-center">
                              <h2 class="sucesso align-self-center">Você está começando a jogar e precisa de mais apoio?? <br/> Conte com a gente!</h2>
                         </div>
                    </div>
                    <div class="row div-dados pb-3">
                         <div class="col-10 offset-1 ">
                              <p class="sucesso">
                                   <ul class="guru-li">
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Apoio para iniciantes em nossos grupos
                                        </li>
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Tutorial para iniciantes
                                        </li>
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Qual liga você deve jogar, de acordo com o seu objetivo e o tamanho da sua banca
                                        </li>
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             A melhor estratégia para cada tipo de liga
                                        </li>
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Nova regra de como funciona o Banco de Reservas
                                        </li>
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Explicação de todo o material do grupo
                                        </li>
                                   </ul>
                              </p>
                         </div>
                    </div>
                    <div class="row div-dados mt-5"> 
                         <div class="col-12 d-flex">
                         <div class="cta-div col-12 d-flex justify-content-center" style="padding-left:0px; padding-right:0px; background-color:black;">
                              <div data-v-f52fcba4="" id="CTA_1" class="form-inline custom_CTA d-flex flex-row w-50 box-shadow justify-content-center" :class="{'w-75':mobile}" >
                                   <div class="d-flex flex-row w-100 justify-content-center">
                                        <a id="submit_1" class="styled_button btn btn-warning mb-2" :class="{'mobile-btn':mobile}" href="/getnet">
                                             QUERO APROVEITAR O MENOR PREÇO
                                        </a>
                                   </div>
                              </div>
                         </div>
                         </div>
                    </div>
               </section>
               <section class="div-preta">
                    <div class="row div-dados mb-3 pt-4">
                         <div class="col-12 text-center">
                              <h2 class="sucesso align-self-center">Quais serão os conteúdos que eu irei receber diariamente?</h2>
                         </div>
                    </div>
                    <div class="row div-dados pb-3">
                         <div class="col-10 offset-1 ">
                              <p class="sucesso">
                                   <ul class="guru-li">
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Modelos de times prontos e áudio com o raciocínio da estratégia utilizada
                                        </li>
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Podcast sobre a rodada, separado por posições
                                        </li>
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Tabela de probabilidades (SGs seguros e difenciados)
                                        </li>
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Análise da Rodada separando jogadores por níveis
                                        </li>
                                   </ul>
                              </p>
                         </div>
                    </div>
               </section>
               <section class="div-verde">
                    <div class="row div-dados mb-3 pt-4">
                         <div class="col-12 text-center">
                              <h2 class="sucesso align-self-center">E por onde vou receber o conteúdo?</h2>
                         </div>
                    </div>
                    <div class="row div-dados pb-3">
                         <div class="col-10 offset-1 ">
                              <p class="sucesso">
                                   <ul class="guru-li">
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Grupo no whatsapp de dicas para os campeonatos do Brasil e América do Sul
                                        </li>
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Grupo no whatsapp de dicas para os campeonatos da Europa e restante do mundo
                                        </li>
                                        <li>
                                             <i class="fa-solid fa-circle-check guru-check"></i>
                                             Grupo no whatsapp de resenha para interação entre os participantes
                                        </li>
                                   </ul>
                              </p>
                         </div>
                    </div>
               </section>
               <section id="pre-checkout" class="div-preta">
                    <div class="row div-dados pt-4 pb-3">
                         <div class="col-10 offset-1 text-center">
                              <p class="sucesso">E agora vamos revelar o segredo de todos os nossos assinantes que já faturaram milhares de reais nas ligas do Rei do Pitaco: eles aproveitaram a nossa super promoção!</p>
                              <p class="sucesso">Todos eles pagam somente <b>27 reais/mês</b> para ter acesso às melhores e mais certeiras dicas para o Rei do Pitaco!</p>
                              <p class="sucesso">Então se liga! Agora é a sua chance para aproveitar nossa promoção excepcional para fazer parte do grupo exclusivo do Time Guru com o Meio Kilo!</p>
                         </div>
                    </div>
                    <div class="row div-dados mt-5"> 
                         <div class="col-12 d-flex">
                         <div class="cta-div col-12 d-flex justify-content-center" style="padding-left:0px; padding-right:0px; background-color:white;" >
                              <div data-v-f52fcba4="" id="CTA_1" class="form-inline custom_CTA d-flex flex-row w-50 box-shadow justify-content-center" :class="{'w-75':mobile}" >
                                   <div class="d-flex flex-row w-100 justify-content-center">
                                        <a id="submit_1" class="styled_button btn btn-warning mb-2" :class="{'mobile-btn':mobile}" href="/getnet">
                                             QUERO FAZER PARTE DESSE GRUPO
                                        </a>
                                   </div>
                              </div>
                         </div>
                         </div>
                    </div>
               </section>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import util from '@/mixin/util';

     export default {
          name: 'Compra',
          mixins: [util],
          components:{
               customHeader, 
          },
          data(){
               return{
                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          computed: {
               isConfirmActive: function() {
                    return this.isBtnConfirmDisponivel && this.validateNome() && this.validateCpf() 
                              && this.validateEmail() && this.compareEmails() && !this.processando;
               }
          },
          mounted(){
               //Verificação mobile
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false
          },
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     @import '@/assets/scss/news.scss';
     .div-preta {
          background-color: #000;
          background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(3,34,24,1) 50%, rgba(0,0,0,1) 100%);
     }
     .div-verde {
          background-color: #01734D; 
          color: #ffffff; 
          background: linear-gradient(90deg, rgba(1,115,77,1) 0%, rgba(24,145,105,1) 50%, rgba(1,115,77,1) 100%);
     }
     .guru-check {
          color: $verde_claro;
     }
     .guru-li {
          list-style-type: none;
     }
     .link-getnet {
          font-weight: bold;
          color: red;
     }
     .cta-div {
          border-top: 3px solid $laranja;
     }
     #submit_1 {
          background-image: linear-gradient(135deg,#01734d 10%,#0aa75e);
          border: $laranja;
          color: white;
          text-shadow: 0 1em 1em rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
          margin-top: 8px;
     }
     .w-75 a {
          font-size: .8em;
     }
     #CTA_1 {
          border-radius:15px; 
          // border:3px solid $laranja;
          background-image: url('https://images2.imgbox.com/72/92/q81ldK8G_o.gif');
          padding: 15px 15px;
          
     }
     .custom_CTA {
          background-color: $laranja;
          padding: 10px 10px 5px 10px;
          z-index: 1;
          -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          margin-top: -32px;
     }
     .div-cartao {
          background-color:#d9d9d9; 
          box-shadow:0 6px 12px 0 rgba(0,0,0,.2);
     }
     h2.sucesso {
          font-size: 1.8em;
          text-transform: uppercase;
     }
     .sucesso {
          color: white;
          font-size: 1.3em;
     }
     .logo-getnet {
          width: 8em;
          margin-top: 1.5em;
          margin-bottom: 1em;
     }
     .btn-confirm {
          background-color: $verde_claro;
          color: white !important;
          border: none;
     }
     .btn-confirm:hover {
          background-color: $verde;
     }
     .btn-confirm:active {
          background-color: $laranja !important; 
     }
     .disabled-confirm {
          background-color: $cinza_claro;
     }
     .disabled-confirm:hover {
      background-color: $cinza;
     }

     .btn-confirm:active {
          background-color: $cinza !important; 
     }
     .guru-icone-compra {
          width: 25%;
     }
    
     .selected {
         background-color: $verde_claro;
         color: white;
     }
     .selected path {
         fill: white;
     }
     .selected .btn-primary {
          background-color: $laranja;
     }
     .btn-primary {
          background-color: $verde_claro;
          border: none;
     }
     .btn-primary:hover {
          background-color: $verde;
     }

     .btn-confirm {
          color: white !important; 
          border: none !important;
     }

     .btn-confirm .disabled {
          background-color: $cinza;
     }

     .disabled-confirm {
          background-color: $cinza_claro;
     }
     .disabled-confirm:hover {
          background-color: $cinza;
     }

     /* form */
     body{
          color: #000;
          overflow-x: hidden;
          height: 100%;
          background-image: url("https://i.imgur.com/GMmCQHC.png");
          background-repeat: no-repeat;
          background-size: 100% 100%
     }
     .card{
          padding: 15px 40px;
          margin-top: 15px;
          // margin-bottom: 60px;
          border: none !important;
          box-shadow: 0 6px 12px 0 rgba(0,0,0,0.2);
          cursor: pointer;
     }
     .form-control-label{
          margin-bottom: 0
     }
     // input, textarea, button{
     //      padding: 8px 15px;
     //      border-radius: 5px !important;
     //      margin: 5px 0px;
     //      box-sizing: border-box;
     //      border: 1px solid #ccc;
     //      font-size: 18px !important;
     //      font-weight: 300
     // }
     input:focus, textarea:focus{
          -moz-box-shadow: none !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          border: 1px solid #00BCD4;
          outline-width: 0;
          font-weight: 400
     }
     button:focus{
          -moz-box-shadow: none !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          outline-width: 0
     }
     .h1 {
          font-size: 2.1em;
     }
     .pay-select {
          background-color: #6c757d;
          color: white;
          padding-bottom: 1em;
          padding-top: 2em;
          border-radius: 10px;
          height: 100%;
          font-size: 1rem;
          
     }
     .pay-select.selected {
          background-color: $verde_claro;
     }
     .pay-select:hover {
          cursor: pointer;
          background-color: $verde;
     }
     .method-box, .method-box p {
          text-decoration: none;
          color: white;
     }
     .method-box i {
          font-size: 3.5em;
          margin-bottom: .3em;
     }
     .method-box p {
          text-transform: uppercase;
     }
     .pay-chose {
          padding-bottom: 1em;
          border-bottom: 3px solid #6c757d;
     }
     .titulo-escolha {
          font-size: 1.4em;
     }
     .pix-conteudo {
          background-color: white;
          border-bottom: 3px solid #6c757d;
     }
     .pix-label {
          font-weight: bold;
     }
     .pix-value {
          font-weight: bolder;
     }
     .pix-last-line {
          border-top: 2px dashed #ccc;
          padding-top: 1em;
     }
     .mobile-prod {
          box-shadow: 0 6px 12px 0 rgb(0 0 0 / 20%);
          // background-color: #CCC;
          border: 1px solid #c9c9c9;
     }
     .mobile-periodo {
          font-size: 1em;
     }
     .mobile-preco {
          font-size: 1.2em;
          font-weight: bold;
     }
     .mobile-cta {
          font-size:.5em; 
          background-color: $laranja; 
          padding: 1em;
          color: white;
     }
     .info-dados-cartao, .info-campos-obrigatorios {
          font-size: .8em;
     }
     .c-loader {
          
          animation: is-rotating 1s infinite;
          border: 6px solid #e5e5e5;
          border-radius: 50%;
          border-top-color: #27a745;
          height: 50px;
          width: 50px;
          // background-color: red;
     }
     .load-info {
          position: fixed;
          top:50%;
          left:50%;
          z-index: 999;
          text-align: center;
     }
     // .load-label {
     //      font-weight: bold;
     //      margin-top: 2em;
     // }
     .overlay{
          position: fixed;
          height: 100%;
          width: 100%;
          display: block;
          z-index: 998;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255,255,255,0.5);
          // opacity: .5;
          // background-color: yellow;
          
     }
     @keyframes is-rotating {
          to {
               transform: rotate(1turn);
          }
     }


</style>
