
import CryptoJS from 'crypto-js'
// import axios from 'axios'
// import format from 'date-fns/format'
const key = 'guru-teste-16bit' // change to your key
const iv = 'guru-teste-16bit' // change to your iv
// const apiKey = '123xxxyyyzzz' // change to your api key
// const username = 'chhumsina'
// const password = 'chhumsina@123'
// const reqTime = format(new Date(), 'yyyyMMddHHmmss')

export default {
    
    methods: {
        
        aesEncrypt(txt) {
            const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(key), {
                iv: CryptoJS.enc.Utf8.parse(iv),
                mode: CryptoJS.mode.CBC
            })
            
            return cipher.toString()
        },
        
        aesDencrypt(txt) {
            const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(key), {
              iv: CryptoJS.enc.Utf8.parse(iv),
              mode: CryptoJS.mode.CBC
            })
      
            return CryptoJS.enc.Utf8.stringify(cipher).toString()
          },
        
    }
}