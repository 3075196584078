<template>
     <div>
          <customHeader title="Meu Time" :subtitle="'Esse é o time do usuário '+$route.params.userId" :mobile="mobile"></customHeader>
          <div class="container">
               <div class="text-center">
                    <img 
                         v-if="base64image != null" 
                         :src="base64image"
                         class="img img-fluid"
                    >
                    <p v-if="base64image == null" style="margin-top:10%;">
                         <i class="far fa-sad-tear" style="font-size:150px;"></i><br><br>
                         Parece que você ainda não compartilhou seu time!
                    </p>
               </div>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';

     export default {
          name: 'Time',
          components:{
               customHeader
          },
          data(){
               return {
                    base64image:null,
               }
          },
          created(){
               // var vm = this;

               // var myTeamBase64 = firebase.database().ref('app/prints_de_times/'+this.$route.params.userId);
               // myTeamBase64.on('value', function(snapshot) {
               //      vm.base64image = snapshot.val();
               // });
          }
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';

     p{
          color:$light_text_color;
     }
</style>