<template>
     <div>
          <customHeader :title="title" :subtitle="subtitle" :mobile="mobile"></customHeader>
          <div id="whatsappPage" class="container">

               <div class="row">
                    <div class="col-12 text-center" v-if="user">
                         <div v-if="user_whatsapp!=null && modify==false" style="margin: 0 auto;">
                         <!-- <div v-if="true" style="margin: 0 auto;"> -->
                              <h3>Você registrou seu Whatsapp com o número:</h3>
                              <label ><div class="destaque">{{user_whatsapp}}</div></label>
                              
                              <hr style="border-bottom:1px solid #F47607;" class="w-100"/>
                              <div  class="d-flex flex-column">
                                   <p>Em breve, você será adicionado em dois grupos de Whatsapp.</p>
                                   <ul class="text-left">
                                        <li>Em um dos grupos, só os administradores irão enviar mensagens. Esse é o grupo exclusivo para te enviar as dicas de cada rodada.</li>
                                        <li>O segundo grupo é aberto. Feito para você interagir com outros membros do Time Guru sobre <b>CARTOLA</b>.</li>
                                   </ul> 
                                   <p>Para que o grupo de resenha sobre Cartola funcione da melhor maneira possível, todos deverão seguir as seguintes regras:</p>
                                   <ul class="text-left">
                                        <li><b>a) Proibido provocação/zoação entre times ou ficar comemorando vitória do seu time.</b></li>
                                        <li>b) O assunto aqui é exclusivamente Cartola FC.</li>
                                        <li>c) É proibido fazer qualquer tipo de propaganda – grupos, páginas, ligas, sites, casa de apostas, etc.</li>
                                        <li>d) Proibido Fake News.</li>
                                   </ul>
                                   <p>O membro que descumprir qualquer das regras acima será banido <b>permanentemente</b> do grupo de resenha.</p>
                                   <p class="destaque ml-4 mr-4"><b>IMPORTANTE:</b> Qualquer dúvida ou sugestão sobre o Time Guru, mandem no privado do Whatsapp ou no contato@gurudocartola.com, pois não conseguimos responder todas as mensagens nos grupos.</p>
                                   <p>Além disso, o conteúdo do grupo de dicas é exclusivo dos assinantes, por isso <b>é PROIBIDO compartilhar qualquer conteúdo recebido no grupo.</b></p>
                                   <p>Te vejo em breve no Whatsapp!</p>
                                   <p>Grande abraço,</p>
                                   <p>Luís – Equipe Guru do Cartola</p>
                              </div>
                              <!--<button :class="[mobile ? 'col-12' : 'col-3']" style="margin:0 auto;" class="btn btn-block btn-success" @click="modify = true">Alterar</button>-->
                         </div>
                         <div v-else>
                              <h3>{{text}}</h3>
                              <small>Somente números</small>
                              <div class="row" style="margin: 0 auto;" :class="[mobile ? 'col-12' : 'col-4']">
                                   <div class="col-12">
                                        <hr>
                                   </div> 
                                   <div class="col-3">
                                        <input type="text" placeholder="DDD" maxlength="2" v-model="ddd" class="form-control" style="margin:0 auto;" @keypress="isNumber($event)">
                                   </div>
                                   <div class="col-9">
                                        <input type="text" placeholder="Número do Whatsapp" maxlength="9" v-model="whatsapp" class="form-control" style="margin:0 auto;" @keypress="isNumber($event)">
                                   </div>
                                   <div class="col-12">
                                        <br>
                                        <button style="margin:0 auto;" class="btn btn-block btn-primary" @click="save_user_whatsapp()">Enviar</button>
                                   </div>
                              </div>
                              
                         </div>
                    </div>
                    <div class="text-center col-12" v-else>
                         <div class="d-flex mb-5 row col-12 justify-content-center">
                              <h3 class="ops w-100 text-center">Ops!</h3>
                              <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" class="cartao"/>
                              <h5 class="w-100 text-center btd">Você precisa logar para utilizar essa funcionalidade!</h5>
                         </div>
                         <login :mobile="mobile" :redirect="'avaliacao-time-cartola'"></login>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import util from '@/mixin/util';

     export default {
          name: 'Whatsapp',
          components:{
               customHeader
          },
          mixins: [util],
          data(){
               return{
                    title:'Whatsapp',
                    subtitle:'',
                    text:'Venha fazer parte da resenha exclusiva para o Time Guru no grupo do Whatsapp. Iremos te adicionar em até 48 horas.',

                    //Data
                    user:null,
                    user_whatsapp:null,

                    ddd:null,
                    whatsapp:null,

                    //Action
                    modify:false,

                    //Mobile
                    mobileAt:1000,//When mobile starts? < 1000 in that case
                    mobile:false,
               }
          },
          created(){
               //windowWidth defining mobile and fieldView
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

               var vm = this;

               //auth user
               vm.user = vm.get_usuario_logado();

               this.load_user_whatsapp();
          },
          methods:{
               save_user_whatsapp(){
                    var vm = this;

                    if(this.user != null){
                         if (this.user.membro == 1) {
                              var isnum1 = /^\d+$/.test(this.ddd);
                              var isnum2 = /^\d+$/.test(this.whatsapp);
                              if(isnum1 && isnum2){
                                   if(this.whatsapp.length >= 8 && this.ddd.length >= 2){

                                        var data = {
                                             'uuid' : this.user.uuid,
                                             'celular' : this.ddd + this.whatsapp
                                        };
                                       
                                        this.$http
                                        .post(this.$PATH+'/user/whatsapp', data)
                                        .then(() => {
                                             localStorage.setItem('whatsapp', vm.ddd + vm.whatsapp);
                                             //eslint-disable-next-line
                                             Swal.fire(
                                                  'Tudo certo!',
                                                  'Em breve você será adicionado em um de nossos grupos.',
                                                  'success'
                                             );
                                             vm.user['telefone']=vm.ddd+vm.whatsapp;
                                        })
                                        .catch(function (error) {
                                             console.log('error');
                                             console.log(error);
                                             //eslint-disable-next-line
                                             Swal.fire(
                                                  'Ops!',
                                                  'Ocorreu um erro. Tente novamente mais tarde.',
                                                  'error'
                                             );
                                        }); 

                                        this.load_user_whatsapp();

                                        this.modify = false;
                                   }else{
                                        //eslint-disable-next-line
                                        Swal.fire(
                                             'Ops!',
                                             'Confere o seu número aí por favor.',
                                             'error'
                                        );
                                   }
                              }else{
                                   //eslint-disable-next-line
                                   Swal.fire(
                                        'Ops!',
                                        'Por favor, digite somente números',
                                        'error'
                                   );
                              }
                         } else {
                              //eslint-disable-next-line
                               Swal.fire(
                                        'Ops!',
                                        'Os grupos de Whatsapp são exclusivos para os membros do Time Guru',
                                        'error'
                                   );
                         }
                    }
               },
               load_user_whatsapp(){
                    var vm = this;
                    if(this.user != null){
                         if(localStorage.getItem('whatsapp')){
                              vm.user_whatsapp = localStorage.getItem('whatsapp');
                         } else 
                         if (this.user['telefone'] != null) {
                              localStorage.setItem('whatsapp', this.user['telefone']);
                              vm.user_whatsapp = localStorage.getItem('whatsapp');
                         } else {
                              vm.user_whatsapp = null;
                         }
                    }
               },
               isNumber: function(evt) {
                    evt = (evt) ? evt : window.event;
                    var charCode = (evt.which) ? evt.which : evt.keyCode;
                    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                         evt.preventDefault();
                    } else {
                         return true;
                    }
               }
          }
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';

     #whatsappPage {
          // color: $light_text_color;
          color: #4F4F4F;
          label{
               letter-spacing: 2px;
          }
     }

     .destaque {
          background-color: $laranja;
          color: white;
          padding: 5px 15px;
          border-radius: 10px;
     }
     .btd {
          margin-top: 1em;
          padding-top: 1em;
          border-top: 1px dotted $cinza_escuro;
          font-weight: bold;
          text-transform: uppercase;
     }
</style>
