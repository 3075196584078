<template>
    <div class="d-flex flex-column mt-5 pt-5 text-center">
         <section id="header" class="mt-5 mb-3 pb-3" style="border-bottom: 2px solid orange;">
               <div class="text-center">
                <div class="row">
                        <div class="col-12">
                            <img src="https://images2.imgbox.com/b2/9e/b6UDNUZ8_o.png" class="img img-fluid" style="width: 200px">
                            <br><br>
                            <h1 style="font-family: 'Ubuntu', sans-serif; color:#ff7e00; font-size: 2.5em">
                                Guru do Cartola
                            </h1>
                        </div>
                </div>
            </div>
        </section>
        <div class="mt-5">
            <h2 class="mb-5">Seja bem-vindo ao <b>GRUPO VIP</b></h2>

            <h3>Aguarde um instante...</h3>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            url: ''
        }
    },
    created(){
        this.$http
        // .get(this.$_PATH+'/temp/')
        .get(this.$API+'temp/')
        .then(response => {
            window.location.href = response.data
        })
        .catch(function (error) {
            console.log(error);
        });
    }
}
</script>

<style scoped>
h1 {
    color: #ff7e00;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: bolder;
}
h2 {
    color: #ff7e00;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}
h3 {
    font-family: "Montserrat", sans-serif;
}
</style>