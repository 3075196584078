<template>
     <div>
          <customHeader title="Termos de uso do App - Android" subtitle="" :mobile="mobile"></customHeader>
          <div class="container">
               <p>Guru do Cartola desenvolveu o aplicativo Cartoleiro como uma aplicação suportada por propagandas. Este SERVIÇO é provido por Guru do Cartola sem nenhum custo e é projetado para o uso como está.
                  </p> <p>Esta página tem o intuito de informar aos visitantes desta página sobre as políticas de retenção, uso e divulgação de dados pessoais a qualquer pessoa que decida utilizar nosso Serviço.
                  </p> <p>Se você escolheu utilizar nosso Serviço, então você concorda com a coleta e uso de informações descritas por esta política de privacidade. As informações pessoais que coletamos são usadas para prover e melhorar o Serviço. Nós não usamos nem compartilhamos nenhum tipo de informação com terceiros, exceto nos casos descritos nesta Política de Privacidade.
                  </p> <p>Os termos usados nesta Política de Privacidade tem o mesmo valor da Política de Privacidade apresentada na aplicação Cartoleiro.
                  </p> <p><strong>Coleta e Uso de Informações</strong></p> <p>Para melhorar a experiência do usuário, ao utilizar o Serviço, nós podemos exigir que você forneça informações que podem te identificar. As informações que nós pedimos são retidas em seu dispositivo e não são coletadas por nós de qualquer modo.
                  </p> <p>A aplicação usa serviços de terceiros que podem coletar informações usadas para te identificar.</p> <div><p>Link para as políticas de privacidade de serviços de terceiros que a aplicação utiliza:</p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a></li> <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank">AdMob</a></li> <li><a href="https://firebase.google.com/policies/analytics" target="_blank">Firebase Analytics</a></li> <li><a href="https://fabric.io/privacy" target="_blank">Fabric</a></li> <li><a href="http://try.crashlytics.com/terms/privacy-policy.pdf" target="_blank">Crashlytics</a></li> <!----></ul></div> <p><strong>Registro de Dados</strong></p> <p>Queremos informar você que sempre que você utiliza o nosso Serviço, no caso de erro de execução, nós coletamos dados e informações (usando serviços de terceiros) de seu dispositivo, chamados de Registro de Dados (Log Data). Este Registro de dados pode incluir informações do dispositivo como endereço IP (Internet Protocol), nome do dispositivo, versão do sistema operacional, configurações da aplicação ao utilizar o Serviço, data e hora em que ocorreu e outras estatísticas.
                  </p> <p><strong>Cookies</strong></p> <p>Cookies são arquivos com pequena quantidade de dados que normalmente são utilizados como identificador único anônimo de usuários. Os cookies são enviados para seu navegador pelos sites que você visita e são armazenados na memória interna do seu dispositivo.
                  </p> <p>Este serviço utiliza estes "cookies" para obter e atualizar suas informações no fantasy game. A aplicação também pode utilizar código e biblioteca de terceiros que usam "cookies" para coletar informações com o intuito de melhorar seus serviços. Você tem a opção de aceitar ou rejeitar estes "cookies" e saber quando um "cookie" é enviado para seu dispositivo. Se você optar por rejeitar estes "cookies", você pode não ter acesso à algumas funcionalidades de nosso Serviço.
                  </p> <p><strong>Provedores de Serviços</strong></p> <p>Nós podemos contratar companhias e indivíduos terceiros pelos seguintes motivos:</p> <ul><li>Para facilitar nosso Serviço;</li> <li>Para prover o serviço em nosso favor;</li> <li>Para executar atividades relacionadas ao Serviço; ou</li> <li>Para nos auxiliar na análise de como nosso Serviço é utilizado.</li></ul> <p>Queremos informar aos usuários deste Serviço que estes terceiros tem acesso à suas informações pessoais. Isso é necessário para que eles executem serviços em nosso favor. Contudo, eles são obrigados a não divulgar ou usar as informações para qualquer outro propósito.
                  </p> <p><strong>Segurança</strong></p> <p>Nós valorizamos sua confiança ao nos prover suas informações pessoais, assim estamos nos esforçando em utilizar meios comercialmente aceitáveis para protegê-las. Mas lembre-se de que nenhum método de transmissão de dados pela Internet ou método de armazenamento de dados é 100% seguro e confiável e nós não podemos garantir segurança absoluta.
                  </p> <p><strong>Links para Outros Sites</strong></p> <p>Este Serviço pode conter links para outros sites. Caso você clique em um link de terceiros, você será levado para o site apontado pelo link. Tenha em mente que estes sites externos não são operados por nós. Assim sendo, recomendo fortemente a você verificar a Política de Privacidade destes sites. Nós não temos nenhum controle sobre eles, nem assumimos responsabilidade por seus conteúdos, políticas de privacidade ou práticas de sites ou serviços de terceiros.
                  </p> <p><strong>Privacidade de Crianças</strong></p> <p>Este Serviço não é direcionado para ninguém menor de 13 anos. Nos não coletamos intencionalmente dados pessoais de crianças menores de 13 anos de idade. Caso nós descubramos que uma criança menor de 13 anos tenha nos provido informações pessoais, comprometemos-nos a excluí-las imediatamente de nosso servidores. Se você é pai ou tutor e sabe que sua criança forneceu informações pessoais, por favor, entre em contato para que nós possamos tomar as ações necessárias.
                  </p> <p><strong>Modificações desta Política de Privacidade</strong></p> <p>Nós podemos atualizar nossa Política de Privacidade de tempos em tempos. Contudo você será informado para revisá-la. Iremos notificá-lo de qualquer mudança através da atualização deste documento. Estas alterações terão efeito imediato assim que forem publicadas neste site ou na aplicação.
                  </p> <p><strong>Entre em Contato</strong></p> <p>Caso você tenha alguma dúvida ou sugestão sobre a Política de Privacidade, não hesite em entrar em contato.
                  </p> <p>Política de privacidade gerada em <a href="https://privacypolicytemplate.net" target="_blank">privacypolicytemplate.net</a>                    e modificada/gerada por <a href="https://app-privacy-policy-generator.firebaseapp.com/" target="_blank">App Privacy Policy Generator</a></p>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';

     export default {
          name: 'Termos',
          components:{
               customHeader
          },
          data(){
               return {
                    
               }
          },
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     p, ul li{
          color: #4E4E4E;
     }
     strong{
          display: block;
          margin-top: 75px;
     }
</style>