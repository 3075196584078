<template>
  <div>
    <div
      v-if="mobile && user != null && user.membro == 0 && showFloatBtn"
      v-bind:class="{ floatexpanded: isFloatExpanded }"
      class="float "
    >
      <i
        v-if="isFloatExpanded"
        class="guru-icon far fa-times-circle"
        v-on:click="showFloatBtn = false"
      ></i>
      <a v-if="isFloatExpanded" :href="selling_page" class="time-guru"
        >Faça Parte do Time Guru</a
      >
      <i
        class="guru-icon icon-guru-whistle"
        v-on:click="isFloatExpanded = !isFloatExpanded"
      ></i>
    </div>
    <nav v-if="mobile" class="navbar sticky-top navbar-light bg-light">
      <a class="navbar-brand" :href="$router.resolve({ name: 'home' }).href">
        <img src="https://images2.imgbox.com/b2/9e/b6UDNUZ8_o.png" style="width:35px;" />
        <span style="color:#f47607;"> Guru do Cartola</span>
      </a>
      <div class="nav-item" v-if="user == null">
        <button
          @click="$router.push('login')"
          type="button"
          class="btn btn-default text-center btn-login"
        >
          <span class="guru-text">Login</span>
        </button>
      </div>
      <div class="nav-item dropdown" v-else>
        <button
          class="btn btn-default text-center dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Olá, {{ user.nome }}
        </button>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdown"
        >
          
          <button class="btn-menu-right text-center">
            <a class="nav-link" :href="$router.resolve({ name: 'contas' }).href">Gerenciar Contas</a>
          </button>
          
          <hr v-if="contas!=null && contas!=undefined && contas.length>0"/>

          <template v-for="c in contas">
            <button class="btn-contas text-center mb-2" :key="c.uuid" 
                :disabled="c.uuid == user.uuid" :class="{'btn-contas-disable':c.uuid == user.uuid}"
                @click="alterna_conta_logada(c.uuid, href)">
                
                
                <span class="fas fa-retweet" aria-hidden="true" v-if="c.uuid != user.uuid"></span>
                <span class="fas fa-futbol" aria-hidden="true" v-if="c.uuid == user.uuid"></span>
                <br/> 
                <span class="mobile-nome-clube">{{c.nomeTime}}</span>
            </button>
          </template>
          
          <hr />

          <button class="btn-logout">
            <a class="nav-link" @click="logout()">Sair</a>
          </button>
        </div>
      </div>
    </nav>
    <nav
      v-if="mobile"
      class="navbar fixed-bottom navbar-light bg-light guru-nav"
      v-on-clickaway="hideAll"
    >
      <div
        v-if="showEscalacao"
        class="btn-group btn-group-justified btn-block"
        style="width:100%;"
      >
        <a
          :href="$router.resolve({ name: 'escalacao' }).href"
          class="btn btn-secondary btn-xs btn-block text-center guru-sub-btn"
        >
          <span>Escalar</span>
        </a>
        <a
          :href="$router.resolve({ name: 'avaliacao' }).href"
          class="btn btn-secondary btn-xs btn-block text-center guru-sub-btn"
        >
          <span>Avaliação</span>
        </a>
        <a
          :href="$router.resolve({ name: 'timesdoguru' }).href"
          class="btn btn-secondary btn-xs btn-block text-center guru-sub-btn"
        >
          <span>Times do Guru</span>
        </a>
      </div>
      <div
        v-if="showMeuTime"
        class="btn-group btn-group-justified btn-block"
        style="width:100%;"
      >
        <a
          :href="$router.resolve({ name: 'meutime' }).href"
          class="btn btn-secondary btn-xs btn-block text-center guru-sub-btn"
        >
          <span>Estatísticas</span>
        </a>
        <a
          :href="$router.resolve({ name: 'Premiacao' }).href"
          class="btn btn-secondary btn-xs btn-block text-center guru-sub-btn"
        >
          <span>Prêmios</span>
        </a>
      </div>
      <div
        v-if="showMais"
        class="btn-group btn-group-justified btn-block"
        style="width:100%;"
      >
        <a
          href="http://gurudocartola.com/blog/"
          target="_blank"
          class="btn btn-secondary btn-xs btn-block text-center guru-sub-btn"
          v-if="!mobile"
        >
          <span>Blog</span>
        </a>
        <a
          :href="$router.resolve({ name: 'whatsapp' }).href"
          class="btn btn-secondary btn-xs btn-block text-center guru-sub-btn"
        >
          <span>Grupo do Whatsapp</span>
        </a>
      </div>
      <div class="container-fluid guru-nav-div">
        <div class="btn-group guru-bottom-group" role="group">
          <!-- :href="$router.resolve({ name: 'escalacao' }).href" -->
          <a
            @click="clickEscalacao()"
            class="btn btn-default text-center guru-btn"
            :class="{ activeGuruBtn: showEscalacao }"
          >
            <span class="guru-icon icon-guru-field-outline"></span><br /><span
              class="guru-text"
              >Escalação</span
            >
          </a>
          <!-- @click="clickParciais()" -->
          <!-- :class="{ activeGuruBtn: showParciais }" -->
          <a
            :href="$router.resolve({ name: 'parciaisValorizacao' }).href"
            class="btn btn-default text-center guru-btn"
          >
            <span class="guru-icon icon-guru-tv-ball"></span><br /><span
              class="guru-text"
              >Parciais</span
            >
          </a>
          <a
            :href="$router.resolve({ name: 'noticias' }).href"
            class="btn btn-default text-center guru-btn"
          >
            <span class="guru-icon icon-guru-speaker"></span><br /><span
              class="guru-text"
              >Notícias</span
            >
          </a>
          <!-- :href="$router.resolve({ name: 'avaliacao' }).href" -->
          <a
            @click="clickMeuTime()"
            :class="{ activeGuruBtn: showMeuTime }"
            class="btn btn-default text-center guru-btn"
          >
            <span class="guru-icon icon-guru-player"></span><br /><span
              class="guru-text"
              >Meu Time</span
            >
          </a>
          <!-- :href="$router.resolve({ name: 'mais' }).href" -->
          <a
            @click="clickMais()"
            :class="{ activeGuruBtn: showMais }"
            class="btn btn-default text-center guru-btn"
          >
            <span class="guru-icon far fa-plus-square"></span><br /><span
              class="guru-text"
              >Mais</span
            >
          </a>
        </div>
      </div>
    </nav>

    <!--  ------------------------------------------------------------------ -->
    <!--  ------------------------------ SITE ------------------------------ -->
    <!--  ------------------------------------------------------------------ -->
    <nav v-else class="navbar fixed-top navbar-expand-lg navbar-light bg-light nav-guru">
        <a class="navbar-brand" :href="$router.resolve({ name: 'home' }).href">
          <img src="https://images2.imgbox.com/b2/9e/b6UDNUZ8_o.png" style="width:35px;" />
          <span style="color:#f47607;"> Guru do Cartola</span>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="dropdown">
              <button
                  class="btn guru-btn-site dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="icon-guru-field-outline"></i> Escalação
                </button>
                 <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    class="nav-link siteSubMenu"
                    :href="$router.resolve({ name: 'escalacao' }).href"
                  >
                    <span>Escalar</span>
                  </a>
                  <a
                    class="nav-link siteSubMenu"
                    :href="$router.resolve({ name: 'avaliacao' }).href"
                  >
                    Avaliações</a
                  >
                  <a
                    class="nav-link siteSubMenu"
                    :href="$router.resolve({ name: 'timesdoguru' }).href"
                  >
                    Times do Guru
                  </a>
                </div>
            </li>
            <li>
             <button
                @click="$router.push('valorizacao-ao-vivo-cartola')"
                class="btn guru-btn-site"
              >
                <i class="icon-guru-tv-ball"></i> Parciais
              </button>
            </li>
            <li>
              <button
                class="btn guru-btn-site"
                @click="$router.push('noticias-cartola')"
              >
                <i class="icon-guru-speaker"></i> Notícias
              </button>
            </li>
            <li class="dropdown">
               <button
                  class="btn guru-btn-site  dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="icon-guru-player"></i> Meu Time
                </button>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    class="nav-link siteSubMenu"
                     :href="$router.resolve({ name: 'meutime' }).href"
                  >
                    <span>Estatísticas</span>
                  </a>
                  <a
                    class="nav-link siteSubMenu"
                    :href="$router.resolve({ name: 'Premiacao' }).href"
                  >
                    Prêmios</a
                  >
                </div>
            </li>
            <li class="dropdown">
                <!-- onclick="window.open('http://gurudocartola.com/blog/', '_blank');" -->
              <button
                class="btn guru-btn-site  dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                 <span class=" far fa-plus-square"></span> Mais
              </button>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    class="nav-link siteSubMenu"
                    :href="selling_page"
                  >
                    Faça Parte</a
                  >
                  <a
                    class="nav-link siteSubMenu"
                    href="http://gurudocartola.com/blog/"
                    target="_blank"
                  >
                    <span>Blog</span>
                  </a>
                  <a
                    class="nav-link siteSubMenu"
                    :href="$router.resolve({ name: 'whatsapp' }).href"
                  >
                    Grupo do Whatsapp</a
                  >
                </div>
            </li>
          </ul>
          <!-- <div class="my-2 my-lg-0"> -->
             <a :href="selling_page" v-if="user == null || user.membro == 0" class="btn btn-sell mr-sm-2">
                <!-- <button
                  class="btn btn-sell"
                  :style="[mobile ? { margin: '0px' } : {}]"
                > -->
                  <i class="icon-guru-whistle"></i> Faça parte do Time Guru
                <!-- </button> -->
              </a>
              <button
                v-if="user == null"
                class="btn guru-btn-site my-2 my-sm-0"
                @click="$router.push('login')"
              >
                <i class="fa fa-sign-in-alt"></i> Login
              </button>
              <div v-else>
                <button
                  class="btn btn-default text-center dropdown-toggle my-2 my-sm-0"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Olá, {{ user.nome }}
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdown"
                  :style="cssVars"
                >
                  <button class="btn-menu-right text-center mb-2">
                    <a class="nav-link" :href="$router.resolve({ name: 'contas' }).href">Gerenciar Contas</a>
                  </button>

                 <hr v-if="contas!=null && contas!=undefined && contas.length>0"/>

                 <template v-if="contas.length < maxContas">
                    <template v-for="c in contas">
                      <button class="btn-contas text-center mb-2" :key="c.uuid" 
                          :disabled="c.uuid == user.uuid" :class="{'btn-contas-disable':c.uuid == user.uuid}"
                          @click="alterna_conta_logada(c.uuid, href)">
                          
                          {{c.nomeTime}}  
                          <span class="fas fa-retweet" aria-hidden="true" v-if="c.uuid != user.uuid"></span>
                          <span class="fas fa-futbol" aria-hidden="true" v-if="c.uuid == user.uuid"></span>

                      </button>
                     </template>
                 </template>
                 <template v-else>
                    <div class="d-flex justify-content-center flex-wrap">
                      <template v-for="c in contas">
                        <button class="btn-contas text-center btn-sm mb-2 m-1" :key="c.uuid" 
                            :disabled="c.uuid == user.uuid" :class="{'btn-contas-disable':c.uuid == user.uuid}"
                            @click="alterna_conta_logada(c.uuid, href)" style="width:120px;">
                            
                            <span style="font-size:.8em">{{c.nomeTime}}</span>
                            <!-- <span class="fas fa-retweet" aria-hidden="true" v-if="c.uuid != user.uuid"></span>
                            <span class="fas fa-futbol" aria-hidden="true" v-if="c.uuid == user.uuid"></span> -->

                        </button>
                      </template>
                    </div>
                  </template>
                 
                  <hr />
                 
                  <button class="btn-logout ">
                    <a class="nav-link" @click="logout()">Sair</a>
                  </button>
                </div>
              </div>
          <!-- </div> -->
        </div>
    </nav>
  </div>
</template>

<script>
import util from "@/mixin/util";
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [util, clickaway],
  props: {
    mobile: {
      type: Boolean,
      required: true
    },
    user: {
      required: true
    }
  },
  data() {
    return {
      maxContas:  11,
      isFloatExpanded: false,
      showFloatBtn: true,
      showEscalacao: false,
      showParciais: false,
      showMeuTime: false,
      showMais: false,
      contas: [],
      href: '/',
    };
  },
  created(){
    this.contas = this.get_contasvinculadas();
    this.href = window.location.href;
  },
  computed: {
    cssVars() {
      return {
        '--menu-width': this.contas.length<this.maxContas ? '308px' : '508px'
      }
    }
  },
  methods: {
    logout() {
      this.reset_usuario_logado();
      document.location.reload(true);
    },
    hideAll() {
      this.showEscalacao = false;
      this.showParciais = false;
      this.showMeuTime = false;
      this.showMais = false;
    },
    clickEscalacao() {
      this.showEscalacao = !this.showEscalacao;
      this.showParciais = false;
      this.showMeuTime = false;
      this.showMais = false;
    },
    clickParciais() {
      this.showEscalacao = false;
      this.showParciais = !this.showParciais;
      this.showMeuTime = false;
      this.showMais = false;
    },
    clickMeuTime() {
      this.showEscalacao = false;
      this.showParciais = false;
      this.showMeuTime = !this.showMeuTime;
      this.showMais = false;
    },
    clickMais() {
      this.showEscalacao = false;
      this.showParciais = false;
      this.showMeuTime = false;
      this.showMais = !this.showMais;
    }
  }
};
</script>

<style lang="scss">
  @import '@/assets/sass/variables.scss';

.dropdown-menu {
    min-width: 12rem;
}
.dropdown-menu-right {
    flex-direction: column;
}
.guru-nav {
  padding: 0px;
}
.guru-nav-div {
  padding: 0px;
  // background-color: red;
}
.guru-bottom-menu {
  flex-direction: row;
}
.guru-bottom-group {
  width: 100%;
}
.guru-btn {
  width: 20%;
  background-color: #f8f9fa;
  color: black;
}
.guru-btn-site {
  margin-right: 5px;
  border-radius: 10px;
}
.guru-btn-site:hover {
  color: white;
  background-color: #f47607;
}
.guru-icon {
  font-size: 2em;
}
.guru-text {
  font-size: 0.8em;
}

.btn-login {
  background-color: rgb(255, 149, 0);
  color: white;
  font-weight: bold;
}

/* Floating Button */
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  top: 5em;
  left: 1em;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 999999;
  padding-top: 10px;
}

.floatexpanded {
  width: 280px;
}

.my-float {
  margin-top: 22px;
}

.time-guru {
  color: white;
  text-decoration: underline;
  margin: 0px 10px;
  position: relative;
  top: -5px;
}
.time-guru:active {
  color: white;
}

.guru-sub-btn {
  // color: black;
  padding: 0.7em;
  font-size: 0.8em;
  margin: 0.1em !important;
  background-color: #f8f9fa;
  color: rgb(255, 149, 0);
  border-color: rgb(255, 149, 0);
}

.activeGuruBtn span {
  color: rgb(255, 149, 0);
}

.siteSubMenu {
  color: rgb(255, 149, 0);
}

.siteSubMenu:hover {
  background-color: rgb(255, 149, 0);
  color: white;
}
.btn-contas {
    border: none;
    background-color: #6c757d;
    padding: 5px;
    width: 100%;
    -webkit-transition: .33s ease all;
    transition: .33s ease all;
    color: white;
}
.btn-contas:hover {
  background-color: #5a6268;
  color: white;
  cursor: pointer;
}
.btn-contas-disable {
  background-color: green !important;
  color: black;
}
.btn-contas-disable:hover {
  color:darkgreen;
  cursor: default;
}
.dropdown-menu.show {
  // max-width: 308px;
  max-width: var(--menu-width);
}
.mobile-nome-clube {
  font-size: 1em;
}

.nav-guru {
  border-bottom: 3px solid $laranja; //rgb(255, 149, 0);
  // background: url("http://localhost/guru-banner-white.gif");
}

/* ICONS */
@font-face {
  font-family: "icomoon";
  src: url("~@/assets/fonts/icomoon.eot?7obaat");
  src: url("~@/assets/fonts/icomoon.eot?7obaat#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/icomoon.ttf?7obaat") format("truetype"),
    url("~@/assets/fonts/icomoon.woff?7obaat") format("woff"),
    url("~@/assets/fonts/icomoon.svg?7obaat#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-guru-color-jersey .path1:before {
  content: "\e900";
  color: rgb(255, 149, 0);
}
.icon-guru-color-jersey .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(254, 130, 5);
}
.icon-guru-color-jersey .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(63, 97, 153);
}
.icon-guru-color-jersey .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(53, 83, 130);
}
.icon-guru-color-jersey .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(61, 126, 217);
}
.icon-guru-color-jersey .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(70, 110, 178);
}
.icon-guru-color-jersey .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(228, 242, 246);
}
.icon-guru-color-jersey .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(228, 242, 246);
}
.icon-guru-color-jersey .path9:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(213, 236, 242);
}
.icon-guru-color-jersey .path10:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(63, 97, 153);
}
.icon-guru-color-jersey .path11:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(53, 83, 130);
}
.icon-guru-color-kick .path1:before {
  content: "\e90b";
  color: rgb(147, 214, 50);
}
.icon-guru-color-kick .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(134, 196, 43);
}
.icon-guru-color-kick .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(89, 89, 89);
}
.icon-guru-color-kick .path4:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(71, 71, 71);
}
.icon-guru-color-kick .path5:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(110, 110, 110);
}
.icon-guru-color-kick .path6:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(110, 110, 110);
}
.icon-guru-color-kick .path7:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(110, 110, 110);
}
.icon-guru-color-kick .path8:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(132, 132, 132);
}
.icon-guru-color-kick .path9:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(99, 105, 120);
}
.icon-guru-color-kick .path10:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(85, 90, 102);
}
.icon-guru-color-kick .path11:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(99, 105, 120);
}
.icon-guru-color-kick .path12:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(71, 71, 71);
}
.icon-guru-color-kick .path13:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(56, 56, 56);
}
.icon-guru-color-kick .path14:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-guru-color-kick .path15:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(240, 240, 240);
}
.icon-guru-color-kick .path16:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(85, 90, 102);
}
.icon-guru-color-kick .path17:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(65, 73, 82);
}
.icon-guru-color-kick .path18:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(85, 90, 102);
}
.icon-guru-color-kick .path19:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(65, 73, 82);
}
.icon-guru-color-kick .path20:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(65, 73, 82);
}
.icon-guru-color-kick .path21:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(85, 90, 102);
}
.icon-guru-color-kick .path22:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(85, 90, 102);
}
.icon-guru-color-kick .path23:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(65, 73, 82);
}
.icon-guru-color-kick .path24:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(255, 245, 115);
}
.icon-guru-color-kick .path25:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(255, 245, 115);
}
.icon-guru-color-kick .path26:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(255, 235, 80);
}
.icon-guru-color-kick .path27:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(255, 235, 80);
}
.icon-guru-color-red-card .path1:before {
  content: "\e926";
  color: rgb(61, 126, 217);
}
.icon-guru-color-red-card .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(70, 110, 178);
}
.icon-guru-color-red-card .path3:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(237, 196, 114);
}
.icon-guru-color-red-card .path4:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(232, 186, 100);
}
.icon-guru-color-red-card .path5:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(240, 94, 58);
}
.icon-guru-color-red-card .path6:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(222, 71, 38);
}
.icon-guru-color-red-card .path7:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(237, 196, 114);
}
.icon-guru-color-red-card .path8:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(232, 186, 100);
}
.icon-guru-color-yellow-card .path1:before {
  content: "\e92e";
  color: rgb(61, 126, 217);
}
.icon-guru-color-yellow-card .path2:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(70, 110, 178);
}
.icon-guru-color-yellow-card .path3:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(237, 196, 114);
}
.icon-guru-color-yellow-card .path4:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(237, 196, 114);
}
.icon-guru-color-yellow-card .path5:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(232, 186, 100);
}
.icon-guru-color-yellow-card .path6:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(255, 231, 51);
}
.icon-guru-color-yellow-card .path7:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(242, 216, 19);
}
.icon-guru-color-yellow-card .path8:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(237, 196, 114);
}
.icon-guru-color-yellow-card .path9:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(237, 196, 114);
}
.icon-guru-color-yellow-card .path10:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(232, 186, 100);
}
.icon-guru-dribbling:before {
  content: "\e938";
}
.icon-guru-fans:before {
  content: "\e939";
}
.icon-guru-field:before {
  content: "\e93a";
}
.icon-guru-field-horizontal:before {
  content: "\e93b";
}
.icon-guru-field-outline:before {
  content: "\e93c";
}
.icon-guru-injuried:before {
  content: "\e93d";
}
.icon-guru-offside:before {
  content: "\e93e";
}
.icon-guru-player:before {
  content: "\e93f";
}
.icon-guru-referee-card:before {
  content: "\e940";
}
.icon-guru-shirt:before {
  content: "\e941";
}
.icon-guru-speaker:before {
  content: "\e942";
}
.icon-guru-stats:before {
  content: "\e943";
}
.icon-guru-trophy:before {
  content: "\e944";
}
.icon-guru-trophy-cup:before {
  content: "\e945";
}
.icon-guru-tv-ball:before {
  content: "\e946";
}
.icon-guru-whistle:before {
  content: "\e947";
}
</style>
