<template>
     <header :class="[mobile ? 'headerMobile':'headerSite']">
          <div class="head-container">
               <div class="bg-grass bg-verde page-header">
                    <h1>{{title}}</h1>
               </div>
               <div class="w-100 mt-0">
                    <Banner :show="showBanner"/>
               </div>
               <div class="page-subheader"> 
                    <h5>{{subtitle}}</h5>
               </div>
               
               <hr class="hr-header" />
          </div>
     </header>
</template>
<script>
     import Banner from '@/components/Banner.vue';
     export default {
          name: 'customHeader',
          components:{
              Banner
          },
          props: {
               title: {
                    type: String,
                    required: true
               },
               subtitle: {
                    type: String,
                    required: true
               },
               mobile: {
                    type: Boolean,
                    default: false
               },
               showBanner: {
                    type: Boolean,
                    default: false
               }
          },
     }
</script>
<style lang="scss">
     @import '@/assets/sass/variables.scss';
     @import '@/assets/scss/general.scss';

     // header{
     //      color: $light_text_color;
     //      background: url(http://dev-gurudocartola.s3-website-sa-east-1.amazonaws.com/img/bg.jpg);
     //      background-repeat: no-repeat;
     //      background-size: cover;
     //      // padding: 100px 0;
     //      // background-position-y: -100px;
     //      text-align: center;
     //      h2{
     //           font-size:15px;
     //      }
     // }

     // .head-container {
     //      background-color: red;
     // }
   
     .page-header {
          font-family: "Montserrat", sans-serif;
          text-align: center;
          text-transform: uppercase;
          padding: 15px;
          font-weight: 200;
          letter-spacing: 5px;
          color: #f5f5f5;
          text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.95);
          border-bottom: 0px;
          margin: 0px;
          margin-left: -15px;
          margin-right: -15px;
     }

     .page-subheader {
          text-align: center;
          font-family: "Montserrat", sans-serif;
          padding-top: 15px;
          // padding-bottom: 15px;
          // border-bottom: 3px solid orange;
     }

     .headerMobile {
          padding: 0px;
     }

     .headerSite {
          padding: 67px 0px 0px 0px;
          // background-position-y: -100px;
     }
     .hr-header {
          border-top: 2px solid $laranja;
          // margin-left:-15px;
          // margin-right:-15px;
     }

     // @media only screen and (max-width: 600px) {
     //      header{
     //           background-position-y: 50px;
     //      }
     // }
</style>