<template>
  <div class="row" :class="{'justify-content-center':mobile}">
    <template v-if="error">
        <div class="offset-md-4 col-4 text-center" >
          <img src="https://images2.imgbox.com/09/a1/CFhJTR85_o.png" style="width:20%;"/>
          <h4 style="color:black; font-weight:bold;">OPS!</h4>
          <h5 style="color:black;">Ocorreu um erro ao buscar os ganhadores dos prêmios do guru.</h5>
        </div>
    </template>
    <template v-else v-for="image in images">
      <a
        v-bind:key="image.id"
        data-fancybox="gallery"
        :data-caption="image.nome + ' (' + image.time + ')'"
        v-bind:src="image"
        :href="image.imagem"
      >
        <!-- :href="'https://gurudocartola.com/img/premiacoes/' + image.imagem" -->
        <!-- 'https://gurudocartola.com/img/premiacoes/' + image.thumbnail -->
        <img v-bind:src="image.thumbnail" class="miniature" />
      </a>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      images: [],
      error: false
    };
  },
  created() {
    this.getImagens();
  },
  methods: {
    getImagens() {
      var vm = this
      this.$http
      // .get(this.$_PATH+"/fotos-premiacao/")  
        .get(this.$API+"fotos-premiacao/")
        .then(response => {
          this.error = false
          this.images = response.data
        })
        .catch(function (error) {
          vm.error = true
          console.log('ERROR #TPM01:')
          console.log(error)
        });  
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/scss/ranking.scss";
</style>
