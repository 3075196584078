<template>
  <div id="intro">
    <div id="bg_header" class="title-header">
      <div class="mask">
        <br />
        <h1>COORDENADOR DE MARKETING <br/>NO GURU MEDIAS</h1>
        <br />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <!-- <div class="col-12">
          <h1 class="text-center">Guru do Cartola 2020</h1>
          <hr />
        </div> -->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          
          <p>
            O Grupo Guru Medias busca um coordenador de marketing para executar de forma integrada a comunicação e as campanhas de publicidade de suas 4 marcas:
          </p>
          <p>
          <ul style="list-style-type: none;">
            <li>1 - <b>Guru do Cartola</b> - referência no segmento de Fantasy Game.</li>
            <li>2 - <b>gurubets</b> – site de apostas esportivas lançado em 2022 e que já conta com milhares de clientes ativos.</li>
            <li>3 – <b>gurunews</b> – Newsletter diária via whatsapp com as notícias mais importantes do mundo do futebol.</li>
            <li>4 – <b>gurushop</b> – Loja com produtos das marcas Guru.</li>
          </ul>
          </p>
          <br />
        </div>
        <div
          id="CTA_1"
          class="form-inline custom_CTA w-75 justify-content-center"
          style="z-index:1;"
          :style="mobile?'margin-bottom:-55px':'margin-bottom:-32px'"
        >
            Topa o desafio? Então queremos você em nosso time!
        </div>
      </div>
    </div>
  <section id="section_0" :style="mobile?'padding-top:80px;':''">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12">
          </div>
          <div class="col-lg-8 col-md-8 col-sm-12">
            <!-- <p style="text-align:center;"> -->
              <div style="border-bottom: 2px solid white; text-align:center; padding-bottom:1em; margin-bottom:2em;">
                <b style="text-transform:uppercase;">
                  QUEM ESTAMOS BUSCANDO?
                </b>
              </div>
            <!-- </p> -->
            <p>
              O Coordenador ideal para esta posição deve possuir as seguintes características:
              <ul>
                <li>- Gostar e acompanhar futebol</li>
                <li>- Profundos conhecimentos de marketing, comunicação e marketing digital</li>
                <li>- Possuir casos de sucesso utilizando marketing digital</li>
                <li>- Fortes conhecimentos em Copywriting</li>
                <li>- Dominar a aplicação de experimentos e técnicas de Growth Hacking</li>
                <li>- Conhecimentos em social media</li>
                <li>- Conhecimentos em Tráfego pago</li>
                <li>- Pelo menos 3 anos de experiência trabalhando com marketing digital</li>
                <li>- Profissional organizado, analítico e orientado a resultados</li>
              </ul>
            </p>

            <p></p>
              <div style="border-bottom: 2px solid white; text-align:center; padding-bottom:.5em; margin-bottom:1em;">
                <b style="text-transform:uppercase;">
                   RESPONSABILIDADES E DEVERES DA VAGA:
                </b>
              </div>
              <ul>
                <li>- Definir as estratégias que serão utilizadas na comunicação de todas as marcas do grupo</li>
                <li>- Definir as estratégias de vendas </li>
                <li>- Estruturação de experimentos e campanhas voltadas para o crescimento do grupo</li>
                <li>- Coordenar os profissionais de sua equipe: media buyer, gestor de tráfego, social media e copywriter. </li>
                <li>- Monitorar e reportar os resultados e metas de vendas das empresas.</li>
              </ul>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12"></div>
          <div
          id="CTA_2"
          class="form-inline custom_CTA w-75 justify-content-center"
          style="z-index:1;"
          :style="mobile?'margin-bottom:-65px':'margin-bottom:-57px'"
        >
            
        CHEGOU A SUA HORA DE SER TITULAR EM NOSSA EQUIPE

        </div>
        </div>
      </div>
      
    </section>

    <section id="section_1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p style="text-align:center;">
                Interessados, favor enviar currículo para <b>luis@gurudocartola.com</b> com o assunto <b>Coordenador de Marketing</b>.
            </p>
        </div>
      </div>
      </div>
    </section>


  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {

      //Verificação mobile
      mobileAt: 1000, //Mobile a partir de qual width?
      mobile: false, //Status inicial do mobile
      col: null
    };
  },
  created() {
    //Verificação mobile
    window.innerWidth < this.mobileAt
      ? (this.mobile = true)
      : (this.mobile = false);
  },
  mounted() {
    //Depois que a página estiver com o DOM implementado, defina o valor de intervalo do slider
    //     $(".carousel").carousel({
    //       interval: 3000
    //     });
    /* Image Desactivation */
    if ($(window).width() <= 825) {
      $("#desktop_extension").remove();
    }
  },
  methods: {
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

/*Fonts*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i|Ubuntu:300,300i,400,400i,500,500i,700,700i");

body {
  font-family: "Ubuntu", sans-serif;
  background-color: #f5f5f5 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e3e3e3' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

/*Background header with image and title*/
.title-header {
  margin-top: 67px;
  border-bottom: 3px solid orange;
}
#btn-yt:hover {
     color: white;
}

#intro {
  background-color: #f5f5f5 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e3e3e3' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
#bg_header {
  background: url("https://images2.imgbox.com/0d/f6/1127pgyl_o.png");
  background-color: #01734d;
  border-top: 3px solid orange;
}
#bg_header .mask {
  background-color: rgba(0, 0, 0, 0.45);
}
#bg_header .mask h1 {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-transform: uppercase;
  padding: 35px;
  font-weight: 200;
  letter-spacing: 5px;
  color: white;
  text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.95);
}

.styled_title {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-transform: uppercase;
  padding: 35px;
  font-weight: 200;
  letter-spacing: 5px;
}

/*main div with background white*/
#tool {
  background-color: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
}
#tool h3,
p {
  padding: 10px 20px;
}
#tool h3 {
  font-weight: 400;
}
#tool button {
  border: 0px;
  border-radius: 0px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
}

#section_0 {
  background-color: #f29c00;
  color: white;
  // background-image: linear-gradient(#f29c00 40%, #f26500);
}
#section_0:before {
  content: "";
  position: absolute;
  // border-top: 50px solid #f29c00;
  width: 100%;
  // transform-origin: 0% 0%;
}

#section_0,
#section_1,
#section_2 {
  padding-top: 50px;
  padding-bottom: 25px;
}
#section_1 {
  background-color: #0aa75e;
  color: white;
  background-image: linear-gradient(#0aa75e 60%, #077140);
}
#section_1:before {
  content: "";
  position: absolute;
  // border-top: 50px solid #0aa75e;
  width: 100%;
  // transform-origin: 0% 0%;
  margin-top: -30px;
}
#section_1 span {
  border-bottom: 2px solid #2d2d2d;
}
#section_0 form,
#section_1 form,
.custom_CTA {
  padding: 20px;
  margin: 0 auto;
  // margin-bottom: -32px;
  z-index: 1;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  color: orange; 
  text-transform: uppercase;

  font-family: "Montserrat", sans-serif;
  text-align: center;
  // padding: 35px;
  font-weight: bolder;
  // letter-spacing: 5px;
  // color: white;
  // text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.95);

}
#section_0 form,
.custom_CTA {
  background-color: #0b0b0b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
#section_1 form {
  background-color: #0b0b0b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23006723' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
#section_1 form button {
  border-radius: 5px;
  color: #023c21;
  font-weight: bolder;
  letter-spacing: 1px;
  background-color: #0aa75e;
  border-bottom: 2px solid #023c21;
  white-space: normal;
}
#section_0 form button,
.custom_CTA button {
  border-radius: 5px;
  color: #753403;
  font-weight: bolder;
  letter-spacing: 1px;
  background-color: #f29c00;
  border-bottom: 2px solid #a74903;
  white-space: normal;
}
#section_0 ul li {
  list-style-type: none;
}
#section_2 {
  background-color: #2d2d2d;
  color: white;
  background-image: linear-gradient(#2d2d2d 60%, #151515);
}
#section_2:before {
  content: "";
  position: absolute;
  border-top: 50px solid #2d2d2d;
  width: 100%;
  // transform-origin: 0% 0%;
}
#section_2 h5 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  color: white;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.95);
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  border-bottom: none !important;
}
.carousel-control-next-icon {
  margin-right: -150px;
}
.carousel-control-prev-icon {
  margin-left: -150px;
}
.carousel-inner {
  -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
}
#section_2 .carousel-item img {
  width: 400px !important;
  height: 400px !important;
}
#section_2 span {
  border-bottom: 2px solid #0aa75e;
}
.styled_button {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 3px;
  border-radius: 0px;
}

#social {
  background-color: orange;
}
#social i {
  margin: 0 auto;
  color: #543701;
  font-size: 25px;
  background-color: #d08702;
  padding: 15px;
}

#social i:hover {
  color: orange;
  background-color: #543701;
}

@media only screen and (max-width: 500px) {
  #bg_header .mask h1 {
    font-size: 30px !important;
  }
  #section_0 form {
    width: 80%;
  }
  #section_1 form {
    width: 80%;
  }
}
</style>
