<template>
  <div>
      <TabPremiacao :mobile="mobile"></TabPremiacao>
  </div>
</template>

<script>
import TabPremiacao from '@/views/tabs/tabPremiacao.vue';
import util from '@/mixin/util';

export default {
  name: "Premiacao",
  components: {
    TabPremiacao,
  },
  mixins: [util],
  data() {
      return {
        user: {},
        mobile:true,//Status inicial do mobile
      }
  },
  created() {
    this.user = this.get_usuario_logado();
  }
};
</script>

<style scoped lang="scss">
  @import '@/assets/sass/variables.scss';
  @import '@/assets/scss/field.scss';
  @import '@/assets/scss/general.scss'; 
</style>
