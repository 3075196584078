<template>
     <div>
          <customHeader title="ASSINATURA PARA FAZER PARTE DO TIME GURU COM MK" subtitle="O MAIOR PLANO DA HISTÓRIA PARA O REI DO PITACO!" :mobile="mobile"></customHeader>
          <div class="row align-items-center overlay" v-if="processando">
               <div class="col-md-12">
                    <div class="load-info">
                         <div class="c-loader"></div>
                    </div>
               </div>
          </div>
          <section style="background-color: #eee;">
               <div class="row div-dados pt-3 form-group ">
                    <div class="col-12 text-center">
                         <h2 class="checkout-title">Seus Dados</h2>
                    </div>
               </div>

               <div class="row div-dados pt-3 form-group ">
                    <div class="col-12 text-center">
                         <form class="form-card">
                              <div class="row justify-content-between text-left">
                                   <div class="form-group col-sm-8 offset-sm-2 flex-column d-flex"> 
                                        <label class="col-form-label px-3">Nome Completo</label> 
                                        <input type="text" id="fname" name="fname" class="form-control" 
                                                  :class="{'is-invalid': valNome && !validateNome(), 
                                                            'is-valid': valNome && validateNome()}"
                                                  @blur="validaCampoNome" 
                                                  placeholder="Digite seu nome completo" v-model="nome"> 
                                        <div class="col-sm-8" v-if="valNome && !validateNome()">
                                             <small id="nomeHelp" class="text-danger">
                                                  Campo obrigatório
                                             </small>      
                                        </div>
                                   </div>
                              </div>
                              <div class="row justify-content-between text-left">
                                   <div class="form-group col-sm-8 offset-sm-2 flex-column d-flex"> 
                                        <label class="form-control-label px-3">CPF</label> 
                                        <cleave id="cpf" name="cpf"  v-model="cpf" :options="cpfOptions"
                                             class="form-control" placeholder="000.000.000-00" maxlength="14"
                                             @blur="validaCampoCpf" :class="{'is-invalid': valCpf && !validateCpf(), 
                                                                                'is-valid': valCpf && validateCpf()}" />
                                        <div class="col-sm-8" v-if="valCpf && !validateCpf()">
                                             <small id="cpfHelp" class="text-danger">
                                                  Campo obrigatório
                                             </small>      
                                        </div>
                                   </div>
                              </div>
                              <div class="row justify-content-between text-left">
                                   <div class="form-group col-sm-8 offset-sm-2 flex-column d-flex"> 
                                        <label class="form-control-label px-3">Seu email</label> 
                                        <input type="text" id="email" name="email" class="form-control" 
                                                  :class="{'is-invalid': valEmail && !validateEmail(), 
                                                            'is-valid': valEmail && validateEmail()}" 
                                                  @blur="validaCampoEmail" 
                                                  placeholder="Digite seu email para receber a compra" v-model="email"> 
                                        <div class="col-sm-8" v-if="valEmail && !validateEmail()">
                                             <small id="mailHelp" class="text-danger">
                                                  Campo obrigatório
                                             </small>      
                                        </div>
                                   </div>
                              </div>
                              <div class="row justify-content-between text-left">
                                   <div class="form-group col-sm-8 offset-sm-2 flex-column d-flex"> 
                                        <label class="form-control-label px-3">Confirme seu email</label> 
                                        <input type="text" id="emailConfirm" name="emailConfirm" class="form-control"  
                                             :class="{'is-invalid': valEmailConfirm && !compareEmails(), 
                                                       'is-valid': valEmailConfirm && compareEmails()}" 
                                             @blur="validaCampoEmailConfirm" 
                                             placeholder="Digite novamente seu email"  v-model="emailConfirm"> 
                                        <div class="col-sm-8" v-if="valEmailConfirm && !compareEmails()">
                                             <small id="mail2Help" class="text-danger">
                                                  Os e-mails informados estão diferentes
                                             </small>      
                                        </div>
                                   </div>
                              </div>
                         </form>
                    </div>
               </div>
               <div class="row pt-3">
                    <div class="col-12 text-center">
                         <h2 class="checkout-title">ASSINATURA</h2>
                    </div>
               </div>
               <div class="pay-method div-cartao">
                    <div class="row mt-2">
                         <div class="col-12 text-center info-cartao">
                              <img src="https://site.getnet.com.br/wp-content/uploads/2021/08/logo-getnet.png" class="logo-getnet"/>
                         </div>
                         <div class="col-12 text-center info-cartao">
                              <span class="info-dados-cartao">Informe os dados do cartão de crédito</span>
                         </div>
                         <div class="col-12 text-center info-cartao">
                              <span class="info-campos-obrigatorios">Campos marcados com * são obrigatórios.</span>
                         </div>
                    </div>
                    <form class="form-card">
                         <div class="row">
                              <div class="col-sm-12 col-md-8 offset-md-2">
                                   <label for="cartao">Número do cartão *</label>
                                   <cleave id="cartao" name="card"  v-model="cardNumber" :options="cardOptions"
                                        class="form-control" placeholder="0000 0000 0000 0000" maxlength="19"/>
                              </div>
                         </div>

                         <div class="row">
                              <div class="col-sm-12 col-md-8 offset-md-2">
                                   <label for="nome">Nome *</label>
                                   <input type="text" class="form-control" id="nome" 
                                             placeholder="Como descrito no cartão"
                                             v-model="cardName">
                              </div>   
                         </div>
                         <div class="row">
                              <div class="col-sm-12 col-md-8 offset-md-2">
                                   <label for="validade">Validade *</label>
                                   <cleave id="validade" name="validade"  v-model="cardExp" :options="expOptions"
                                        class="form-control" placeholder="00/00" maxlength="5"/>
                              </div>
                              
                              <div class="col-sm-12 col-md-8 offset-md-2">
                                   <label for="cvv">Código de Segurança *</label>
                                   <input type="password" class="form-control" id="cvv" 
                                             placeholder="CVV" maxlength="3"
                                             v-model="cardCode">
                              </div>
                         </div>
                         <div class="row">
                              <div class="col-sm-12 col-md-8 offset-md-2">
                                   <label for="valor">Valor</label>
                                   <input type="text" class="form-control" id="valor" 
                                             disabled
                                             value="R$ 27,00">
                              </div>   
                         </div>
                         <div class="row">
                              <div class="col-12 text-center mt-4">
                                   <a class="btn btn-primary btn-confirm" :class="{'disabled-confirm': !isConfirmActive}" 
                                   :disabled="!isConfirmActive" @click="salvarCompraCartao()">Pagar Agora</a>
                              </div>
                         </div>
                         <div class="row mt-2 pb-4">
                              <div class="col-12 text-center info-cartao">
                                   <span class="info-dados-cartao">Tem alguma dúvida? Clique <a class="link-getnet" href="https://api.whatsapp.com/send?phone=5591984279012&text=Oi%2C%20preciso%20de%20ajuda%20sobre%20o%20plano%20do%20Time%20Guru%20com%20o%20MK">AQUI</a> e entre em contato com o nosso atendimento</span>
                              </div>
                              <div class="col-12 text-center info-cartao">
                                   <span class="info-campos-obrigatorios">Quer pagar com PIX? Clique <a class="link-getnet"  href="https://hotm.art/time-guru-meio-kilo">AQUI</a></span>
                              </div>
                         </div>
                    </form>
               </div>
               
          </section>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import util from '@/mixin/util';
     import Cleave from 'vue-cleave-component';

     export default {
          name: 'Compra',
          mixins: [util],
          components:{
               customHeader, 
               Cleave
          },
          data(){
               return{
                    //Dados via API
                    data:null,
                    tokenHidden: '',
                    token: '',
                    getnetScript: null,
                    //Dados Cliente
                    nome: '',
                    cpf: '',
                    email: '',
                    emailConfirm: '',
                    cpfOptions: {
                         delimiters: ['.', '.', '-'],
                         blocks: [3, 3, 3, 2],
                         numericOnly: true,
                         delimiterLazyShow: true
                    },
                    produto: '',
                    valNome: false,
                    valCpf: false,
                    valEmail: false,
                    valEmailConfirm: false,
                    referencia: 0,
                    pag : '',
                    pix: {},
                    hasQRCode: false,
                    
                    cardNumber: '',
                    cardExp: '',
                    cardName: '',
                    cardCode: '',
                    cardOptions: {
                         creditCard: true,
                         delimiter: ' ',
                    },
                    expOptions: {
                         date: true,
                         datePattern: ['m', 'y']
                    },

                    isBtnConfirmDisponivel: true,

                    // path: 'http://192.168.1.240:8444/',
                    path: 'https://gurudocartola.com/app/',
                    API_URL: 'https://pb89hpsof3.execute-api.us-east-1.amazonaws.com/prod/',
                    
                    processando: false,

                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          computed: {
               isConfirmActive: function() {
                    return this.isBtnConfirmDisponivel && this.validateNome() && this.validateCpf() 
                              && this.validateEmail() && this.compareEmails() && !this.processando;
               }
          },
          mounted(){
               //Verificação mobile
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false
               
               this.produto = '0fbe9297-4d0d-486a-bb30-be057158a639'
               this.pag = 'credito'

          },
          methods:{
               validateCpf() {
                    var Soma;
                    var Resto;
                    Soma = 0;
                    if (this.cpf == "00000000000") return false;

                    for (let i=1; i<=9; i++) Soma = Soma + parseInt(this.cpf.substring(i-1, i)) * (11 - i);
                    Resto = (Soma * 10) % 11;

                    if ((Resto == 10) || (Resto == 11))  Resto = 0;
                    if (Resto != parseInt(this.cpf.substring(9, 10)) ) return false;

                    Soma = 0;
                    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(this.cpf.substring(i-1, i)) * (12 - i);
                    Resto = (Soma * 10) % 11;

                    if ((Resto == 10) || (Resto == 11))  Resto = 0;
                    if (Resto != parseInt(this.cpf.substring(10, 11) ) ) return false;
                    return true;
               },
               validateEmail() {
                    // eslint-disable-next-line
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                         return true
                    } else {
                         return false
                    }
               },
               compareEmails(){
                    return this.email == this.emailConfirm;
               }, 
               validateNome() {
                    return this.nome.trim() != ''
               },
               validaCampoNome() {
                    this.valNome = true
                    return this.validateNome()
               },
               validaCampoCpf() {
                    this.valCpf = true
                    return this.validateCpf()
               },
               validaCampoEmail() {
                    this.valEmail = true
                    return this.validateEmail()
               },
               validaCampoEmailConfirm() {
                    this.valEmailConfirm = true
                    if (this.validaCampoEmail()) {
                         return this.compareEmails()
                    }
               },
               salvarCompraCartao(){
                    var vm = this
                    vm.processando = true

                    let data = {}
                    data['customer_id'] = vm.email
                    data['produto'] = '5c486364-d04c-48c9-90a8-f9e4d0641256'
                    data['nome'] =  vm.nome
                    data['email'] = vm.email
                    data['cpf'] = vm.cpf
                    data['cartaoNumero'] = vm.cardNumber
                    data['cartaoNome'] = vm.cardName
                    data['cartaoCodigo'] = vm.cardCode
                    data['cartaoExpMes'] = vm.cardExp.substr(0,2)
                    data['cartaoExpAno'] = vm.cardExp.substr(vm.cardExp.length - 2)
                    
                    const headers = {
                         'Accept': 'text/plain',
                         'Content-Type': 'text/plain' 
                    }
                    
                    let pedidoEncriptado = this.aesEncrypt(JSON.stringify(data))

                    vm.$http
                    .post(vm.path+'getnet/pedido/assinatura', pedidoEncriptado, {headers})
                    .then(response => {
                         if (response.status == 200) {
                              let retorno = JSON.parse(this.aesDencrypt(response.data))
                              let orderId = retorno['order_id']
                              vm.$router.push({ name: 'getnet-sucesso', params: { id: orderId } })
                         } else {
                              vm.processando = false
                              //eslint-disable-next-line
                              Swal.fire('Ops!', 'Ocorreu um erro durante a transação. \n' + response)
                         }
                    })
                    .catch(function (error) {
                         vm.processando = false
                         let msgErro = 'Ocorreu um erro durante o processamento: <br/><br/>'
                         if (error) {
                              if (error.response) {
                                   if (error.response.data) {
                                        msgErro += error.response.data;
                                   } else {
                                        msgErro += error.response;
                                   }
                              } else {
                                   msgErro += error;
                              }
                         }
                         //eslint-disable-next-line
                         Swal.fire('Ops!', msgErro)
                    })
               },
          }
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     @import '@/assets/scss/news.scss';
     .checkout-title{
          font-family: "Open Sans",sans-serif;
          text-transform: uppercase;
          font-size: 1.5em;
          color: $cinza;
          background-color: #CED4DA;
          padding: 15px 0px;
          margin: 15px;
          border: 1px solid #CED4DA;
     }
     h2.sucesso {
          font-size: 1.8em;
          text-transform: uppercase;
     }
     .sucesso {
          color: white;
          font-size: 1.3em;
     }
     .logo-getnet {
          width: 8em;
          margin-top: 1.5em;
          margin-bottom: 1em;
     }
     .btn-confirm {
          background-color: $verde_claro;
          color: white !important;
          border: none;
     }
     .btn-confirm:hover {
          background-color: $verde;
     }
     .btn-confirm:active {
          background-color: $laranja !important; 
     }
     .disabled-confirm {
          background-color: $cinza_claro;
     }
     .disabled-confirm:hover {
      background-color: $cinza;
     }

     .btn-confirm:active {
          background-color: $cinza !important; 
     }
     .guru-icone-compra {
          width: 25%;
     }
    
     .selected {
         background-color: $verde_claro;
         color: white;
     }
     .selected path {
         fill: white;
     }
     .selected .btn-primary {
          background-color: $laranja;
     }
     .btn-primary {
          background-color: $verde_claro;
          border: none;
     }
     .btn-primary:hover {
          background-color: $verde;
     }

     .btn-confirm {
          color: white !important; 
          border: none !important;
     }

     .btn-confirm .disabled {
          background-color: $cinza;
     }

     .disabled-confirm {
          background-color: $cinza_claro;
     }
     .disabled-confirm:hover {
          background-color: $cinza;
     }

     /* form */
     body{
          color: #000;
          overflow-x: hidden;
          height: 100%;
          background-image: url("https://i.imgur.com/GMmCQHC.png");
          background-repeat: no-repeat;
          background-size: 100% 100%
     }
     .card{
          padding: 15px 40px;
          margin-top: 15px;
          // margin-bottom: 60px;
          border: none !important;
          box-shadow: 0 6px 12px 0 rgba(0,0,0,0.2);
          cursor: pointer;
     }
     .form-control-label{
          margin-bottom: 0
     }
     // input, textarea, button{
     //      padding: 8px 15px;
     //      border-radius: 5px !important;
     //      margin: 5px 0px;
     //      box-sizing: border-box;
     //      border: 1px solid #ccc;
     //      font-size: 18px !important;
     //      font-weight: 300
     // }
     input:focus, textarea:focus{
          -moz-box-shadow: none !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          border: 1px solid #00BCD4;
          outline-width: 0;
          font-weight: 400
     }
     button:focus{
          -moz-box-shadow: none !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          outline-width: 0
     }
     .h1 {
          font-size: 2.1em;
     }
     .pay-select {
          background-color: #6c757d;
          color: white;
          padding-bottom: 1em;
          padding-top: 2em;
          border-radius: 10px;
          height: 100%;
          font-size: 1rem;
          
     }
     .pay-select.selected {
          background-color: $verde_claro;
     }
     .pay-select:hover {
          cursor: pointer;
          background-color: $verde;
     }
     .method-box, .method-box p {
          text-decoration: none;
          color: white;
     }
     .method-box i {
          font-size: 3.5em;
          margin-bottom: .3em;
     }
     .method-box p {
          text-transform: uppercase;
     }
     .pay-chose {
          padding-bottom: 1em;
          border-bottom: 3px solid #6c757d;
     }
     .titulo-escolha {
          font-size: 1.4em;
     }
     .pix-conteudo {
          background-color: white;
          border-bottom: 3px solid #6c757d;
     }
     .pix-label {
          font-weight: bold;
     }
     .pix-value {
          font-weight: bolder;
     }
     .pix-last-line {
          border-top: 2px dashed #ccc;
          padding-top: 1em;
     }
     .mobile-prod {
          box-shadow: 0 6px 12px 0 rgb(0 0 0 / 20%);
          // background-color: #CCC;
          border: 1px solid #c9c9c9;
     }
     .mobile-periodo {
          font-size: 1em;
     }
     .mobile-preco {
          font-size: 1.2em;
          font-weight: bold;
     }
     .mobile-cta {
          font-size:.5em; 
          background-color: $laranja; 
          padding: 1em;
          color: white;
     }
     .info-dados-cartao, .info-campos-obrigatorios {
          font-size: .8em;
     }
     .c-loader {
          
          animation: is-rotating 1s infinite;
          border: 6px solid #e5e5e5;
          border-radius: 50%;
          border-top-color: #27a745;
          height: 50px;
          width: 50px;
          // background-color: red;
     }
     .load-info {
          position: fixed;
          top:50%;
          left:50%;
          z-index: 999;
          text-align: center;
     }
     // .load-label {
     //      font-weight: bold;
     //      margin-top: 2em;
     // }
     .overlay{
          position: fixed;
          height: 100%;
          width: 100%;
          display: block;
          z-index: 998;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255,255,255,0.5);
          // opacity: .5;
          // background-color: yellow;
          
     }
     @keyframes is-rotating {
          to {
               transform: rotate(1turn);
          }
     }


</style>
