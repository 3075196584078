<template>
     <div>
          <customHeader :title="title" :subtitle="subtitle" :mobile="mobile"></customHeader>
          <div id="ContasVinculadasPage" class="container">

               <div class="row">
                    <div class="col-12 text-center" v-if="user">
                              <h3>Você está logado com a seguinte conta:</h3>
                                   <h1 class="nomeTime">
                                        <img :src="user.escudo" class="img img-fluid" style="max-width:50px;">
                                        {{user.nomeTime}}
                                   </h1>
                                   <h6 class="email">{{user.email}}</h6>
                              <hr style="border-bottom:1px solid #F47607;" class="w-100"/>
                              <div  class="d-flex flex-column" v-if="!mobile">
                                   <p>As seguintes contas estão vinculadas à sua:</p>
                                  
                                   <table class="table">
                                        <tr>
                                             <th>E-mail</th>
                                             <th>Nome Time</th>
                                             <th>
                                                  <!-- <button v-if="user" @click="vincular()" class="btn btn-verde mr-2"> 
                                                       <span class="fas fa-link" aria-hidden="true"></span>
                                                       Vincular Outra Conta 
                                                  </button> -->

                                             </th>
                                        </tr>
                                        <template v-if="contas==null">
                                             <tr>
                                                  <td colspan="3" class="text-center">{{err}}</td>
                                             </tr>
                                        </template>
                                        <template v-else-if="contas.length > 0">
                                             <tr v-for="usr in contas" :key="usr.uuid">
                                                  <template v-if="usr.uuid != user.uuid">
                                                       <td>{{usr.email}}</td>
                                                       <td>{{usr.nomeTime}}</td>
                                                       <td>
                                                            <button  @click="desvincular(usr.email, usr.uuid)" class="btn btn-laranja mr-2">
                                                                 <span class="fas fa-unlink" aria-hidden="true"></span>
                                                                 Desvincular
                                                            </button>
                                                            <button  @click="alternar(usr.uuid)" class="btn btn-primary mr-2">
                                                                  <span class="fas fa-retweet" aria-hidden="true"></span>
                                                                 Entrar
                                                            </button>
                                                       </td>
                                                  </template>
                                             </tr>
                                        </template>
                                        <tr v-else>
                                             <td colspan="3"> 
                                             Não há nenhuma conta vinculada ainda.
                                             </td>
                                        </tr>
                                   </table>

                                   
                                   
                                  
                              </div>
                              <div v-else>
                                   <button v-if="user"  @click="vincular()" class="btn btn-verde mr-2"> 
                                        <span class="fas fa-link" aria-hidden="true"></span>
                                        Vincular Outra Conta 
                                   </button>
                                   <hr>
                                   <p>As seguintes contas estão vinculadas à sua:</p>
                                   <table>
                                        <tr class="mobile-tr-th">
                                             <th>Conta</th>
                                             <th>Ações</th>
                                        </tr>
                                        <template v-if="contas==null">
                                             <tr>
                                                  <td colspan="2" class="text-center">{{err}}</td>
                                             </tr>
                                        </template>
                                        <template v-else-if="contas.length > 0">
                                             <tr v-for="usr in contas" :key="usr.uuid" class="mobile-tr">
                                                  <template v-if="usr.uuid != user.uuid">
                                                       <td class="mobile-td-conta">
                                                            <span class="mobile-destaque">{{usr.nomeTime}}</span><br/>
                                                            <span class="mobile-small">({{usr.email}})</span>
                                                       </td>
                                                       <td>
                                                            <button  @click="desvincular(usr.email, usr.uuid)" class="btn btn-sm btn-laranja table-mobile-btn mb-1">
                                                                 <span class="fas fa-unlink mobile-btn-label" aria-hidden="true"></span>
                                                                 <span class="mobile-btn-label">&nbsp;Desvincular</span>
                                                            </button>
                                                            <button  @click="alternar(usr.uuid)" class="btn btn-sm btn-primary table-mobile-btn">
                                                                  <span class="fas fa-retweet mobile-btn-label" aria-hidden="true"></span>
                                                                  <span class="mobile-btn-label">&nbsp;Alternar</span>
                                                            </button>
                                                       </td>
                                                  </template>
                                             </tr>
                                        </template>
                                        <tr v-else>
                                             <td colspan="2"> 
                                             Não há nenhuma conta vinculada ainda.
                                             </td>
                                        </tr>
                                   </table>
                              </div>
                              
                              <!--<button :class="[mobile ? 'col-12' : 'col-3']" style="margin:0 auto;" class="btn btn-block btn-success" @click="modify = true">Alterar</button>-->
                         <!-- </div> -->
                         
                    </div>
                    <div class="text-center col-12" v-else>
                         <h3>Ops! Você precisa realizar o login para usar esta funcionalidade.</h3>
                    </div>
                    <!-- {{err}}
                    {{contas}} -->
               </div>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import util from '@/mixin/util';

     export default {
          name: 'ContasVinculadas',
          components:{
               customHeader
          },
          mixins: [util],
          data(){
               return{
                    title:'Contas Vinculadas',
                    subtitle:'',
                    text:'...',

                    //Data
                    user:null,
                    contas: null,
                    err: 'Aguarde! Carregando suas contas vinculadas...',
                    
                    //Swal
                    alert_titulo: '<h3 style="color:orange;font-weight:bolder;font-family:"Montserrat",sans-serif";>OPS!</h3>',
                    alert_redcard: '<img src="https://images2.imgbox.com/62/95/II0ciY6R_o.png" style="width:50%;"/> <br/><br/>',

                    //Action
                    modify:false,

                    //Mobile
                    mobileAt:1000,//When mobile starts? < 1000 in that case
                    mobile:false,
               }
          },
          created(){
               //windowWidth defining mobile and fieldView
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

               var vm = this;

               //auth user
               vm.user = vm.get_usuario_logado();

               vm.carregarContasVinculadas();
          },
          methods:{
               carregarContasVinculadas() {
                    console.log('user:')
                    console.log(this.user)
                    var vm = this;
                    if(vm.user != null){
                         // if (vm.user.vinculoId != null && vm.user.vinculoId != undefined) {
                              //contas vinculadas
                              var url = this.$PATH+'/user/contas/?uuid=' + encodeURIComponent(vm.aesEncrypt(vm.user.uuid))
                                                                           + "&vinculo="; 
                               if (vm.user.guruId != null && vm.user.guruId != undefined) {
                                   url += encodeURIComponent(vm.aesEncrypt(vm.user.guruId));
                               } else {
                                    url += encodeURIComponent(vm.aesEncrypt(0));
                               }
                              // var settings = {
                              //      url: url,
                              //      method: "GET",
                              //      headers: {
                              //      "Content-Type": "application/json"
                              //      }
                              //      // data: JSON.stringify(data)
                              // };

                              // $.ajax(settings)
                              // .done(function(response) {
                              //      try {
                              //           var retorno = vm.aesDencrypt(response);
                              //           vm.contas = JSON.parse(retorno);
                              //      } catch (error) {
                              //           vm.err = 'Ocorreu um erro inesperado ao buscar suas contas vinculadas. Tente novamente,  caso o erro persista entre em contato com o suporte. '
                              //           console.log('[error #1A]:')
                              //           console.log(error);
                              //      }
                              // })
                              // .fail(function(error) {
                              //      vm.err = 'Ocorreu um erro inesperado ao buscar suas contas vinculadas. Tente novamente,  caso o erro persista entre em contato com o suporte. '
                              //      console.log('[error #1B]:')
                              //      console.log(error);
                              // });
                              this.$http
                              .get(url)
                              .then(response => {
                                   try {
                                        var retorno = vm.aesDencrypt(response.data);
                                        vm.contas = JSON.parse(retorno);
                                   } catch (error) {
                                        vm.err = 'Ocorreu um erro inesperado ao buscar suas contas vinculadas. Tente novamente,  caso o erro persista entre em contato com o suporte. '
                                        console.log('[error #1A]:')
                                        console.log(error);
                                   }
                              })
                              .catch(function (error) {
                                   vm.err = 'Ocorreu um erro inesperado ao buscar suas contas vinculadas. Tente novamente,  caso o erro persista entre em contato com o suporte. '
                                   console.log('[error #1B]:')
                                   console.log(error);
                              }); 
                         // } else {
                         //      vm.contas = []
                         // }
                    } else {
                         vm.err = "Ops! Você precisa realizar o login antes de usar esta funcionalidade."
                    }
               },
               vincular() {
                    if (this.user.membro === 1) {
                         this.$router.push({ name: 'contasVincular' })
                    } else {
                         // eslint-disable-next-line no-undef
                         Swal.fire(this.alert_titulo, this.alert_redcard+"Essa é uma funcionalidade exclusiva para membros do time guru.")
                    }
               },
               desvincular(email, uuid) {
                    var vm = this;
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                         title: 'Tem Certeza?',
                         text: "Você deseja desvincular essa conta ("+email+") da sua?",
                         icon: 'warning',
                         showCancelButton: true,
                         confirmButtonColor: '#f47607',
                         cancelButtonColor: '#6c757d',
                         confirmButtonText: 'Sim, quero desvincular!',
                         cancelButtonText: 'Não'
                         }).then((result) => {
                              if (result.value == true) {
                                   vm.callDesvincular(uuid);
                              }
                         })
               },
               callDesvincular(uuidParaDesvincular) {
                    var vm = this;

                    var data =  {
                         uuidMestre: vm.aesEncrypt(vm.user.uuid),
                         uuidVinculado: vm.aesEncrypt(uuidParaDesvincular)

                    };

                    const headers = {
                         'Content-Type': 'application/json' 
                    };
                    this.$http
                    .post(this.$PATH+'/user/contas/desvincular', JSON.stringify(data), {headers})
                    .then(response => {
                         if (response.data != null && response.data != undefined && response.data != {}) {
                              var usuario =  vm.aesDencrypt(response.data);
                              if (usuario != null && usuario != undefined && usuario != '') {
                                   vm.set_usuario_logado(usuario)
                              }
                              vm.carregarContasVinculadas()
                         } else {
                              var  texto = 'Não foi possível desvincular essa conta. Tente novamente e caso o erro persista entre em contato com o suporte.'
                              // eslint-disable-next-line no-undef
                              Swal.fire(vm.alert_titulo, vm.alert_redcard+texto)
                         }
                    })
                    .catch(function (error) {
                         var  texto = 'Ocorreu um erro inesperado desvinculadar a conta. Tente novamente e caso o erro persista entre em contato com o suporte.'
                         // eslint-disable-next-line no-undef
                         Swal.fire(vm.alert_titulo, vm.alert_redcard+texto)

                         console.log('[error #2]:')
                         console.log(error)
                    }); 
               },
               alternar(uuidParaAlternar) {
                    this.alterna_conta_logada(uuidParaAlternar, '/escale-seu-time-cartola');
               }
          }
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     
     .nomeTime {
          font-weight: bolder;
          color: orange;
     }

     .btn-verde {
          
          border: 0px;
          background-color: $verde;
          color: white;
          box-shadow: 0 4px 12px 0px rgba(0,0,0,.1);
     }

     .btn-laranja {
          border: 0px;
          background-color: $laranja;
          color: white;
          box-shadow: 0 4px 12px 0px rgba(0,0,0,.1);
     }

     .btn-cinza-claro {
          border: 0px;
          background-color: $cinza_claro;
          color: white;
          box-shadow: 0 4px 12px 0px rgba(0,0,0,.1);
     }
     .mobile-small {
          color: $cinza_claro;
          font-size: .8em;
     }
     .mobile-td-conta {
           padding-right: 1em;
           padding-top: .5em;
           padding-bottom: .5em;
     }
     .mobile-btn-label {
          font-size: .6em;
     }
     .table-mobile-btn {
          min-width: 72px;
          padding: 0px;
     }
     .mobile-tr {
          border-bottom: 1px solid #C9C9C9;
     }
     .mobile-tr-th {
          border-bottom: 1px solid $cinza_claro;
     }
</style>

