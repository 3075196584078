<template>
     <div>
          <customHeader title="Meu Time - Estatísticas" subtitle="Estatística sobre suas escalações" :mobile="mobile"></customHeader>
           <div class="containerMeuTime">
               <!-- Abas -->
               <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                         <a :class="{ smallFont: mobile }" class="nav-link active" id="meutime-tab" data-toggle="tab" href="#meutime" role="tab" aria-controls="meutime" aria-selected="true">
                              {{ user != null && data && data.quadro_inicial.nome_time != null ? data.quadro_inicial.nome_time :'Meu Time'}}
                         </a>
                    </li>
                    <li class="nav-item">
                         <a :class="{ smallFont: mobile }" class="nav-link" id="buscatime-tab" data-toggle="tab" href="#buscatime" role="tab" aria-controls="buscatime" aria-selected="false">
                              Buscar Times
                         </a>
                    </li>
               </ul>
               <!-- Conteudos -->
               <div class="tab-content" id="myTabContent">
                    
                    <!-- ------------------------------------------------------------- -->
                    <!-- ------------- MEU TIME ::: Time do usuário ------------------ -->
                    <!-- ------------------------------------------------------------- -->
                  
                    <div class="tab-pane fade show active" id="meutime" role="tabpanel" aria-labelledby="meutime-tab">
                         <div v-if="market_status && market_status.rodada_atual == 1" class="text-center">
                              <h3>Essa funcionalidade estará disponível após a primeira rodada.</h3>
                         </div>
                         <div v-else-if="user && !loading_data && data != undefined && JSON.stringify(data) != '{}'">
                         
                              <!--Non-Member data-->
                              <div v-if="user && data">
                                   <!-- Quadro Inicial -->
                                   <div class="row d-flex justify-content-center">
                                        <div class="col-lg-3 col-md-3 col-sm-12">
                                             <img :src="data.quadro_inicial.escudo" class="img img-fluid" style="max-width:200px;">
                                        </div>
                                        <div id="quadro_inicial" class="col-lg-6 col-md-6 col-sm-12 text-center box-shadow">
                                        <!-- <br> -->
                                             <h3>{{data.quadro_inicial.nome_time}}</h3>
                                             <div class="team_txt">
                                                  <p>
                                                       Cartola: 
                                                       <span v-if="data.quadro_inicial.nome">
                                                            {{data.quadro_inicial.nome}}
                                                       </span>
                                                       <span v-else>
                                                            <small style="color:red;">Indisponível</small>
                                                       </span>
                                                       <br>

                                                       <template v-if="data.quadro_inicial.pontuacao_total && data.quadro_inicial.rodadas_total">
                                                            <span>{{data.quadro_inicial.pontuacao_total.toFixed(2)}}</span> 
                                                            pontos em 
                                                            <span>{{data.quadro_inicial.rodadas_total}}</span> rodadas
                                                       </template>
                                                       <template v-else>
                                                                 <small style="color:red;">Pontuação total está indisponível</small>
                                                       </template>
                                                       <br>

                                                       <template v-if="data.quadro_inicial.pontos_media">
                                                            Média de <span>{{data.quadro_inicial.pontos_media.toFixed(2)}}</span> pontos
                                                       </template>
                                                       <template v-else>
                                                            <small style="color:red;">Média está indisponível</small>
                                                       </template>
                                                       <br>

                                                       <template v-if="data.quadro_inicial.patrimonio_atual">
                                                            Patrimônio atual: <span> $ {{data.quadro_inicial.patrimonio_atual.toFixed(2)}} </span>
                                                       </template>
                                                       <template v-else>
                                                            <small style="color:red;">Patrimônio atual está indisponível</small>
                                                       </template>
                                                  </p>
                                             </div>
                                        </div>
                                        <!-- <div class="col-lg-4 col-md-4 col-sm-12"></div> -->
                                   </div>

                                   <br><hr class="hr"><br>

                                   <!-- Gráficos -->
                                   <div class="row d-flex">
                                        <div class="col-lg-6 col-md-6 col-sm-12 box-shadow">
                                             <canvas id="chart1" width="400" height="300" style="background-color:white;"></canvas>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 box-shadow">
                                             <canvas id="chart2" width="400" height="300" style="background-color:white;"></canvas>
                                        </div>
                                   </div>
                              </div>
                              <!--Member data-->
                              <div v-if="user && user.membro == 1">

                                   <br><hr><br>

                                   <!-- Médias por posição + Capitães -->
                                   <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                             <div class="text-center">
                                                  <h6 class="title">
                                                       Seus jogadores com as melhores médias por posição
                                                  </h6>
                                             </div>
                                             <div id="tbl_medias_posicao" class="borders">
                                                  <table class="custom_table">
                                                       <thead>
                                                            <th>Jogador</th>
                                                            <th>Posição</th>
                                                            <th>Clube</th>
                                                            <th>Jogos</th>
                                                            <th>Média de pontos</th>
                                                       </thead>
                                                       <tbody>
                                                            <tr v-for="atleta in data.medias_jogadores_por_posicao" :key="atleta.atleta_id">
                                                                 <td class="text-left">
                                                                      <img :src="athlete_photo(atleta.atleta_foto)" class="img img-fluid atleta_foto">
                                                                      {{atleta.apelido}}
                                                                 </td>
                                                                 <td style="text-transform: capitalize;">
                                                                      {{positions[atleta.posicao_id].abreviacao}}
                                                                 </td>
                                                                 <td>
                                                                      <img v-if="clubs[atleta.clube_id]" :src="clubs[atleta.clube_id].escudo" class="img img-fluid club_shield">
                                                                 </td>
                                                                 <td>
                                                                      {{atleta.jogos_time_id}}
                                                                 </td>
                                                                 <td>
                                                                      {{atleta.media_pontuacao.toFixed(2)}}
                                                                 </td>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                             <div class="text-center">
                                                  <h6 class="title">
                                                       Seus capitães
                                                  </h6>
                                             </div>
                                             <div id="tbl_capitaes" class="borders" style="overflow: auto;">
                                                  <table class="custom_table">
                                                       <thead>
                                                            <th>Rodada</th>
                                                            <th>Atleta</th>
                                                            <th>Posição</th>
                                                            <th>Local</th>
                                                            <th>Clube</th>
                                                            <th>Adversário</th>
                                                            <th>Pontos</th>
                                                       </thead>
                                                       <tbody>
                                                            <tr v-for="(atleta,key) in data.capitaes" :key="key">
                                                                 <td>
                                                                      {{key + 1}}
                                                                 </td>
                                                                 <td class="text-left">
                                                                      <img :src="athlete_photo(atleta.atleta_foto)" class="img img-fluid atleta_foto">
                                                                      {{atleta.apelido}}
                                                                 </td>
                                                                 <td style="text-transform: capitalize;">
                                                                      {{positions[atleta.posicao_id].abreviacao}}
                                                                 </td>
                                                                 <td>
                                                                      {{atleta.local}}
                                                                 </td>
                                                                 <td>
                                                                      <img v-if="clubs[atleta.clube_id]" :src="clubs[atleta.clube_id].escudo" class="img img-fluid club_shield">
                                                                 </td>
                                                                 <td>
                                                                      <img v-if="clubs[atleta.clubeadversario_id]" :src="clubs[atleta.clubeadversario_id].escudo" class="img img-fluid club_shield">
                                                                 </td>
                                                                 <td>
                                                                      {{atleta.pontos.toFixed(2)}}
                                                                 </td>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   </div>

                                   <br><hr><br>

                                   <!-- Mais escalados + Médias por posição -->
                                   <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                             <div class="text-center">
                                                  <h6 class="title">
                                                       Seus jogadores mais escalados
                                                  </h6>
                                             </div>
                                             <div class="borders">
                                                  <table class="custom_table">
                                                       <thead>
                                                            <th>Jogador</th>
                                                            <th>Escalações</th>
                                                            <th>Valorização Média</th>
                                                            <th>Média de pontos</th>
                                                       </thead>
                                                       <tbody>
                                                            <tr v-for="atleta in data.mais_escalados" :key="atleta.atleta_id">
                                                                 <td class="text-left">
                                                                      <img :src="athlete_photo(atleta.atleta_foto)" class="img img-fluid atleta_foto">
                                                                      {{atleta.apelido}}
                                                                 </td>
                                                                 <td>
                                                                      {{atleta.jogos_time_id}}
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.media_valorizacao">
                                                                           {{atleta.media_valorizacao.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.media_pontuacao">
                                                                           {{atleta.media_pontuacao.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                             <div class="text-center">
                                                  <h6 class="title">
                                                       Suas médias por posição
                                                  </h6>
                                             </div>
                                             <div class="borders">
                                                  <table class="custom_table">
                                                       <thead>
                                                            <th>Posição</th>
                                                            <th>Valorização Média</th>
                                                            <th>Média de pontos</th>
                                                       </thead>
                                                       <tbody>
                                                            <tr v-for="atleta in data.medias_posicao" :key="atleta.atleta_id">
                                                                 <td style="text-transform: capitalize;">
                                                                      {{positions[atleta.posicao_id].abreviacao}}
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.media_variacao">
                                                                           {{atleta.media_variacao.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.media_pontuacao">
                                                                           {{atleta.media_pontuacao.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   </div>

                                   <br><hr><br>

                                   <!-- Todos jogadores do cartola + Maiores médias contra time -->
                                   <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                             <div class="text-center">
                                                  <h6 class="title">
                                                       Todos os jogadores do cartola
                                                  </h6>
                                             </div>
                                             <div class="borders">
                                                  <table class="custom_table">
                                                       <thead>
                                                            <th>Posição</th>
                                                            <th>Casa</th>
                                                            <th>Fora</th>
                                                            <th>Geral</th>
                                                       </thead>
                                                       <tbody>
                                                            <tr v-for="atleta in data.medias_jogadores_cartola" :key="atleta.atleta_id">
                                                                 <td style="text-transform: capitalize;">
                                                                      {{positions[atleta.posicao_id].abreviacao}}
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.pontuacao_casa">
                                                                           {{atleta.pontuacao_casa.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.pontuacao_fora">
                                                                           {{atleta.pontuacao_fora.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.pontuacao_geral">
                                                                           {{atleta.pontuacao_geral.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                             <div class="text-center">
                                                  <h6 class="title">
                                                       Suas maiores médias são contra jogadores dos times
                                                  </h6>
                                             </div>
                                             <div class="borders">
                                                  <table class="custom_table">
                                                       <thead>
                                                            <th>Adversário</th>
                                                            <th>Onde ele estava jogando?</th>
                                                            <th>Média de pontos</th>
                                                       </thead>
                                                       <tbody>
                                                            <tr v-for="(atleta,n) in data.medias_contra_adversarios" :key="n">
                                                                 <template v-if="atleta.clubeadversario_id != null">
                                                                 <td>
                                                                      <img v-if="clubs[atleta.clubeadversario_id]" :src="clubs[atleta.clubeadversario_id].escudo" class="img img-fluid club_shield">
                                                                 </td>
                                                                 <td>
                                                                      {{atleta.local}}
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.pontuacao">
                                                                           {{atleta.pontuacao.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                                 </template>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   </div>

                                   <br><hr><br>

                                   <!-- Suas médias por clubes -->
                                   <div class="row">
                                        <div class="col-12">
                                             <div class="text-center">
                                                  <h6 class="title">
                                                       Suas médias por clubes
                                                  </h6>
                                             </div>
                                             <div class="borders">
                                                  <table class="custom_table">
                                                       <thead>
                                                            <th>Clube</th>
                                                            <th>Escalações</th>
                                                            <th>Valorização média</th>
                                                            <th>Média de pontos</th>
                                                       </thead>
                                                       <tbody>
                                                            <tr v-for="atleta in data.medias_por_clube" :key="atleta.atleta_id">
                                                                 <td>
                                                                      <img v-if="clubs[atleta.clube_id]" :src="clubs[atleta.clube_id].escudo" class="img img-fluid club_shield">
                                                                 </td>
                                                                 <td>
                                                                      {{atleta.escalacoes}}
                                                                 <td>
                                                                      <span v-if="atleta.media_valorizacao">
                                                                           {{atleta.media_valorizacao.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.media_pontuacao">
                                                                           {{atleta.media_pontuacao.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   </div>

                                   <br><hr><br>

                                   <!-- Seleções -->
                                   <div class="row">
                                        <div class="col-12">
                                             <div class="text-center">
                                                  <h6 class="title">
                                                       Seleções do {{data.quadro_inicial.nome_time}}
                                                  </h6>
                                             </div>
                                             <div class="borders">
                                                  <table class="custom_table">
                                                       <thead>
                                                            <th style="white-space: nowrap">
                                                                 Rodada: <span class="badge badge-primary">{{rodada}}</span> &nbsp;
                                                                 <input type="range" class="custom-range" min="1" :max="current_round - 1" step="1" value="1" v-model="rodada" style="width: 33%">
                                                            </th>
                                                            <th>Valorização</th>
                                                            <th>Pontos</th>
                                                       </thead>
                                                       <tbody>
                                                            <tr v-for="atleta in data.selecao_por_rodada[rodada - 1]" :key="atleta.atleta_id">
                                                                 <td class="text-left">
                                                                      <img :src="athlete_photo(atleta.atleta_foto)" class="img img-fluid atleta_foto">
                                                                      {{atleta.apelido}}
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.variacao_num">
                                                                           {{atleta.variacao_num.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                                 <td>
                                                                      <span v-if="atleta.pontos_num">
                                                                           {{atleta.pontos_num.toFixed(2)}}
                                                                      </span>
                                                                      <span v-else>
                                                                           <small>Indisponível</small>
                                                                      </span>
                                                                 </td>
                                                            </tr>
                                                       </tbody>
                                                  </table>
                                             </div>
                                        </div>
                                   </div>

                                   <br><hr><br>

                                   <!-- Ídolos e Decepções -->
                                   <div class="row" id="idolos_decepcoes">
                                        <h6 class="title w-100 text-center">Ídolos do {{data.quadro_inicial.nome_time}}</h6>
                                        <div class="col-6 text-center">
                                             <b>Maior Pontuador</b><br>
                                             <img :src="athlete_photo(data.idolos_e_decepcoes.idolo_pontuacao.atleta_foto)" class="img img-fluid"><br>
                                             {{data.idolos_e_decepcoes.idolo_pontuacao.apelido}}<br>
                                             Pontuação: <span class="badge badge-success"> {{data.idolos_e_decepcoes.idolo_pontuacao.score}} </span>
                                        </div>
                                        <div class="col-6 text-center">
                                             <b>Maior Valorização</b><br>
                                             <img :src="athlete_photo(data.idolos_e_decepcoes.idolo_valorizacao.atleta_foto)" class="img img-fluid"><br>
                                             {{data.idolos_e_decepcoes.idolo_valorizacao.apelido}}<br>
                                             Valorização: <span class="badge badge-success"> {{data.idolos_e_decepcoes.idolo_valorizacao.score}} </span>
                                        </div>
                                   </div>
                                   <div class="row" id="idolos_decepcoes">
                                        <!-- <div class="col-12 text-center">
                                             <br><br>
                                             <h6 class="title">Decepções do {{data.quadro_inicial.nome_time}}</h6>
                                        </div> -->
                                        <h6 class="title w-100 text-center">Decepções do {{data.quadro_inicial.nome_time}}</h6>
                                        <div class="col-6 text-center">
                                             <b>Menor Pontuador</b><br>
                                             <img :src="athlete_photo(data.idolos_e_decepcoes.decepcao_pontuacao.atleta_foto)" class="img img-fluid"><br>
                                             {{data.idolos_e_decepcoes.decepcao_pontuacao.apelido}}<br>
                                             Pontuação: <span class="badge badge-danger"> {{data.idolos_e_decepcoes.decepcao_pontuacao.score}} </span>
                                        </div>
                                        <div class="col-6 text-center">
                                             <b>Menor Valorização</b><br>
                                             <img :src="athlete_photo(data.idolos_e_decepcoes.decepcao_valorizacao.atleta_foto)" class="img img-fluid"><br>
                                             {{data.idolos_e_decepcoes.decepcao_valorizacao.apelido}}<br>
                                             Valorização: <span class="badge badge-danger"> {{data.idolos_e_decepcoes.decepcao_valorizacao.score}} </span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div v-else>
                              <div v-if="user == null">
                                   <div class="d-flex mb-5 row col-12 justify-content-center">
                                        <h3 class="ops w-100 text-center">Ops!</h3>
                                        <!-- <img src="/img/cartao_amarelo.svg" :style="mobile?'width:100px;':'width:200px;'"/> -->
                                        <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" class="cartao"/>
                                        <h5 class="w-100 text-center">Você precisa logar para que os dados de seu time sejam carregados!</h5>
                                   </div>
                                   <login :mobile="mobile" :redirect="'estatisticas-meu-time-cartola'"></login>
                              </div>
                              <div v-else>
                                   <!-- <h3 class="text-center">{{warning_msg}}</h3> -->
                                   <div class="d-flex mb-5 row col-12 justify-content-center">
                                        <loading v-if="loading_data" class="text-center" :loading="loading_data"></loading>
                                        <div v-else class="text-center">
                                             <h3 class="ops w-100 text-center">Ops!</h3>
                                             <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" style="width:200px;"/>
                                             <h5 class="w-100 text-center">{{warning_msg}}</h5>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>


                    <!-- ------------------------------------------------------------- -->
                    <!-- ------------- BUSCA TIMES ::: Outros Times ------------------ -->
                    <!-- ------------------------------------------------------------- -->
                    <div class="tab-pane fade show" id="buscatime" role="tabpanel" aria-labelledby="buscatime-tab">
                         <div v-if="market_status && market_status.rodada_atual == 1" class="text-center">
                              <h3>Essa funcionalidade estará disponível após a primeira rodada.</h3>
                         </div>
                         <template v-else-if="user">
                              <div class="row">
                              <template v-if="market_status && market_status.status_mercado != 4">
                              <!-- <template v-if="true"> -->
                                   <!-- Busca -->
                                   <div :class="[mobile ? 'col-12' : 'col-4']" ><!--margin: 0 auto; -->
                                        <table id="searchTeamsTable">
                                             <tbody>
                                                  <tr>
                                                       <td colspan="6">
                                                            <div class="row" style="padding: 10px 25px;">
                                                                 <div class="col-10" style="padding:0px">
                                                                      <input type="text" v-model="userTeamSearch" 
                                                                           style="border-bottom-right-radius:0px; border-top-right-radius:0px;"
                                                                           class="form-control" 
                                                                           placeholder="Buscar Time">
                                                                 </div>
                                                                 <div class="col-2" style="padding:0px">
                                                                      <button 
                                                                           style="border-bottom-left-radius:0px; border-top-left-radius:0px;border:0px;padding:0.425rem;"
                                                                           class="btn btn-block btn-laranja" 
                                                                           @click="getTeams()">
                                                                           <i class="fas fa-search"></i>
                                                                      </button>
                                                                 </div>
                                                            </div>                                   
                                                       </td>
                                                  </tr>
                                                  <div id="teamResult">
                                                       <template v-for="(team,n) in teams">
                                                            <tr @click="selectedTeam = team" :key="n" class="time-buscado col-12">
                                                                 <td width="75">
                                                                      <div style="margin-top:15px;margin-bottom:15px;">
                                                                           <img :src="team.url_escudo_png" class="img img-fluid">
                                                                      </div>
                                                                 </td>
                                                                 <td colspan="5">
                                                                      <div>
                                                                           <p style="margin:0px 25px;">
                                                                                {{team.nome}} <span v-if="team.assinante" class="pro">PRO</span><br>
                                                                                <small>({{team.nome_cartola}})</small>
                                                                           </p>
                                                                      </div>
                                                                 </td>
                                                            </tr>
                                                       </template>
                                                  </div>
                                             </tbody>
                                        </table>
                                   </div>
                                   <br><br>
                                   <div v-if="loading_search" class="col-8 text-center">
                                        <loading id="loading" class="text-center" :loading="loading_search"></loading>
                                   </div>
                                   <div v-else-if="searchedTeamData" class="col-8">
                                        <!-- Quadro Inicial -->
                                        <div class="row">
                                             <!-- <div class="col-lg-4 col-md-4 col-sm-12"></div> -->
                                             <div id="quadro_inicial" class="col-12 text-center"> <!--col-lg-4 col-md-4 col-sm-1-->
                                                  <img :src="searchedTeamData.quadro_inicial.escudo" class="img img-fluid"><br>
                                                  <h3>{{searchedTeamData.quadro_inicial.nome_time}}</h3>
                                                  <p>
                                                       Cartola: 
                                                       <span v-if="searchedTeamData.quadro_inicial.nome">
                                                            {{searchedTeamData.quadro_inicial.nome}}
                                                       </span>
                                                       <span v-else>
                                                            <small style="color:red;">Indisponível</small>
                                                       </span>
                                                       <br>

                                                       <template v-if="searchedTeamData.quadro_inicial.pontuacao_total && searchedTeamData.quadro_inicial.rodadas_total">
                                                            <span>{{searchedTeamData.quadro_inicial.pontuacao_total.toFixed(2)}}</span> 
                                                            pontos em 
                                                            <span>{{searchedTeamData.quadro_inicial.rodadas_total}}</span> rodadas
                                                       </template>
                                                       <template v-else>
                                                                 <small style="color:red;">Pontuação total está indisponível</small>
                                                       </template>
                                                       <br>

                                                       <template v-if="searchedTeamData.quadro_inicial.pontos_media">
                                                            Média de <span>{{searchedTeamData.quadro_inicial.pontos_media.toFixed(2)}}</span> pontos
                                                       </template>
                                                       <template v-else>
                                                            <small style="color:red;">Média está indisponível</small>
                                                       </template>
                                                       <br>

                                                       <template v-if="searchedTeamData.quadro_inicial.patrimonio_atual">
                                                            Patrimônio atual: <span> $ {{searchedTeamData.quadro_inicial.patrimonio_atual.toFixed(2)}} </span>
                                                       </template>
                                                       <template v-else>
                                                            <small style="color:red;">Patrimônio atual está indisponível</small>
                                                       </template>
                                                  </p>
                                             </div>
                                             <div class="col-lg-4 col-md-4 col-sm-12"></div>
                                        </div>

                                        <br><hr class="hr"><br>

                                        <!-- Gráficos -->
                                        <div class="row">
                                             <div :class="mobile?'col-12':'col-6'">
                                                  <canvas id="chart3" width="400" height="300" style="background-color:white;box-shadow: 0 0 20px 5px rgba(91,91,91,.37);"></canvas>
                                             </div>
                                             <div :class="mobile?'col-12':'col-6'">
                                                  <canvas id="chart4" width="400" height="300" style="background-color:white;box-shadow: 0 0 20px 5px rgba(91,91,91,.37);"></canvas>
                                             </div>
                                        </div>
                                   </div>
                                   <!-- <div v-else>
                                   </div> -->
                              </template>
                              <template v-else >
                                   <div class="col-12 text-center">
                                        <h3 class="ops w-100 text-center">Ops!</h3>
                                        <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" class="cartao"/>
                                        <h5 class="w-100 text-center">Esta funcionalidade não está disponível no momento</h5>
                                        <h5 class="w-100 text-center" style="color:orange;font-weight:bolder;">MERCADO FECHADO!</h5>
                                   </div>
                              </template>
                              </div>
                         </template>
                         <template v-else>
                              <div class="d-flex mb-5 row col-12 justify-content-center">
                                   <h3 class="ops w-100 text-center">Ops!</h3>
                                   <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" class="cartao"/>
                                   <h5 class="w-100 text-center">Você precisa logar para utilizar essa funcionalidade!</h5>
                              </div>
                              <login :mobile="mobile" :redirect="'avaliacao-time-cartola'"></login>
                         </template>
                    </div>

               </div>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import login from '@/components/login.vue';
     import loadChartsMyTeamPage from '@/assets/js/MeuTime.js';
     import loading from '@/components/Loading.vue';
     
     // import clubs_json from '@/json/clubes.json';
     import positions_json from '@/json/positions.json';
     import util from '@/mixin/util';

     // import json_mock from '@/json/6433f37c-cb47-41ad-86b1-cd59411918d7.json';
     //import json_data from '@/json/0662283a-49cd-48d6-a621-fdc1dc0c0c1c.json';

     export default {
          name: 'Avaliacao',
          components:{
               customHeader, login, loading
          },
          mixins: [util],
          data(){
               return{
                    //Dados
                    user:null,
                    data: null,
                    rodada:1,
                    current_round:0,

                    userTeamSearch:'',
                    teams:null,
                    selectedTeam:null,
                    searchedTeamData:null,
                    teamDetails:null,

                    //Estáticos
                    clubs: [],
                    positions: positions_json,

                    //Avisos
                    loading_data: true,
                    loading_search: false,
                    warning_msg: 'Não foi possível carregar seu time. Caso o erro persista entre em contato com o suporte.',

                    //Status do mercado via API do CARTOLA
                    market_status:null,

                    //Mobile Check
                    mobileAt:1000,
                    mobile:false,
               }
          },
          watch:{
               current_round: function(){
                    var vm = this;

                    if(vm.user && vm.user.membro == 1){
                         //Caso o usuário seja membro, carrega seu arquivo json dentro de meu_time/data
                         this.getMemberTeamData();
                    }else if(vm.user && vm.user.membro == 0){
                         //Caso usuário não seja membro, carrega o json via db2.php
                         this.$http
                         .get(this.$PATH+'/meutime/busca_time/'+vm.user.idTime+'/'+vm.current_round)
                         .then(response => {
                              vm.data = response.data;
                              vm.loading_data = false;
                         })
                         .catch(function (error) {
                              console.log(error);
                         });  
                    }

                    
               },
               selectedTeam: function(){
                    if(this.selectedTeam){
                         var vm = this;
                         this.loading_search = true;

                    //     $.ajax({
                    //           type: "GET",
                    //           url: vm.cors_page+'https://api.cartolafc.globo.com/time/id/'+vm.selectedTeam.time_id,
                    //           contentType: "application/json; charset=utf-8",
                    //           dataType: "json",
                    //           success: function (result) {
                    //                vm.teamDetails = result;
                    //                vm.getSearchedNonMemberTeamData(result.time.time_id);
                    //           },
                    //           error: function (error) {
                    //                console.log('erro ao consultar o time selecionado:')
                    //                console.log(error);
                    //           }
                    //      });
                         this.$http
                         .get(vm.cors_page+vm.$API_GLOBO+'/time/id/'+vm.selectedTeam.time_id)
                         .then(response => {
                              vm.teamDetails = response.data;
                              vm.getSearchedNonMemberTeamData(response.data.time.time_id);
                         })
                         .catch(function (error) {
                              console.log(error);
                         });  
                    }
               },

               data: function(){
                    var vm = this;
                    // console.log(JSON.stringify(vm.data))
                    if(this.user && JSON.stringify(vm.data) != '{}' 
                         && this.market_status && this.market_status.rodada_atual != 1
                         && !this.loading_data 
                         ){
                         setTimeout(function(){
                              loadChartsMyTeamPage('chart1','chart2',vm.data.graficos, vm.current_round);
                              $("#tbl_capitaes").height($("#tbl_medias_posicao").height());
                         },1500);
                    }else{
                         if(vm.user.membro == 1)
                              vm.warning_msg = 'Para visualizar os dados, aguarde até 24 horas depois de seu cadastro. Caso já tenha esperado, mande um e-mail para contato@gurudocartola.com';
                         else
                              vm.warning_msg = 'Para visualizar os dados, aguarde até 24 horas depois de seu cadastro.';
                    }
               }    
          },
          created(){
               var vm = this;

               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

               //Usuário autenticado
               vm.user = vm.get_usuario_logado();

               this.$http
               // .get(this.$_PATH+'/mercado/status')
               .get(this.$API+'mercado-status')
               .then(response => {
                    vm.market_status = response.data;
               })
               .catch(function (error) {
                    console.log(error);
               });  

               //todos os clubes
               this.$http
               // .get(this.$_PATH+'/info-clubes/')
               .get(this.$API+'info-clubes/')
               .then(response => {
                    vm.clubs = response.data
               })
               .catch(function (error) {
                    console.log(error);
               });   

               //Rodada Atual baseado no retorno do status do mercado via API do CARTOLA
               this.$http
               // .get(this.$_PATH+'/mercado/status')
               .get(this.$API+'mercado-status')
               .then(response => {
                    vm.current_round = response.data.rodada_atual
               })
               .catch(function (error) {
                    console.log(error);
               });             
          },
          methods:{
               //Retorna dados do time membro
               getMemberTeamData(){
                    var vm = this;
                    this.loading_data = true;

                    this.$http
                    // .get(this.$_PATH+'/meutime/'+vm.user.idTime)
                    .get(this.$API+'meutime/'+vm.user.idTime)
                    .then(response => {
                          if (response.data) {
                              vm.data = response.data;
                         } else {
                              vm.warning_msg = 'Para visualizar os dados, aguarde até 24 horas depois de seu cadastro.<br/>Caso já tenha esperado, mande um e-mail para contato@gurudocartola.com';
                         }
                         vm.loading_data = false;
                    })
                    .catch(function (error) {
                         console.log('erro ao consultar o time[1]:')
                         console.log(error);
                         vm.warning_msg = 'Para visualizar os dados, aguarde até 24 horas depois de seu cadastro. Caso já tenha esperado, mande um e-mail para contato@gurudocartola.com';
                         vm.loading_data = false;
                    });   
               },
               //Retorna dados do time nao membro
               getNonMemberTeamData(team_id){
                    var vm = this;
                    this.data = null;
                    this.loading_data = true;
                    
                    this.$http
                    .get(this.$PATH+'/meutime/busca_time/'+team_id+'/'+vm.current_round)
                    .then(response => {
                         vm.data = JSON.parse(response.data);
                         vm.loading_data = false;
                    })
                    .catch(function (error) {
                         console.log('erro ao consultar o time[0]:')
                         console.log(error);
                    });   
               },
               //Retorna dados do time nao membro em uma busca
               getSearchedNonMemberTeamData(team_id){
                    var vm = this;
                    vm.searchedTeamData = null;
                    vm.loading_search = true;

                    this.$http
                    .get(this.$PATH+'/meutime/busca_time/'+team_id+'/'+vm.current_round)
                    .then(response => {
                         vm.searchedTeamData = response.data;
                         vm.loading_search = false;
                         loadChartsMyTeamPage('chart3','chart4',vm.searchedTeamData.graficos, vm.current_round);
                    })
                    .catch(function (error) {
                         console.log(error);
                    });   
               },
               //Realiza a busca na API do CARTOLA de acordo com o que o usuário digitar no campo de busca
               getTeams(){
                    var vm = this;
                    // $.ajax({
                    //      type: "GET",
                    //      url: vm.cors_page+'https://api.cartolafc.globo.com/times?q='+vm.userTeamSearch,
                    //      contentType: "application/json; charset=utf-8",
                    //      dataType: "json",
                    //      success: function (result) {
                    //           vm.teams = result;
                    //      },
                    //      error: function (error) {
                    //           console.log(error);
                    //      }
                    // });
                    this.$http
                    .get(vm.cors_page+vm.$API_GLOBO+'/times?q='+vm.userTeamSearch)
                    .then(response => {
                         vm.teams = response.data
                    })
                    .catch(function (error) {
                         console.log(error);
                    });   
               },
               //Seta parâmetros na URL
               setGetParam(key,value) {
                    if (history.pushState) {
                         var params = new URLSearchParams(window.location.search);
                         params.set(key, value);
                         var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
                         window.history.pushState({path:newUrl},'',newUrl);
                    }
               },
               athlete_photo(src){
                    if(src)
                         return src.replace('FORMATO','80x80');
                    else
                         return 'https://s.glbimg.com/es/sde/f/2019/02/15/7f94dd0a492218c6dcc1fbc02473637c_80x80.png';
               },
          },
     }
</script>

<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     @import '@/assets/scss/general.scss';
     @import '@/assets/scss/field_table.scss';

     // h3{
     //      color: $light_text_color !important;
     // }
     .hr {
          border-bottom: 2px solid $laranja;
     }

     #quadro_inicial{
          background-color:white;
          padding:0px;
          box-shadow: 0 0 20px 5px rgba(91,91,91,.37);
          // color:white;
          img{
               height: 75px;
               margin: 10px;
          }
          p{
               span{
                    color: $laranja;
               }
          }
          h3 {
               background-color: #0b0b0b;
               background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
               background-color: #f27609;
               background-image: url("https://www.transparenttextures.com/patterns/dimension.png");
               background-color: #01734d;
               background-image: url("http://localhost/bg-grass-light.png");
               margin: 0px 0px;
               padding-top:5px;
               padding-bottom:5px;
               text-transform: uppercase;
               // border-top: 3px solid $laranja;
               // border-bottom: 3px solid $laranja;
               // color: $laranja;
               color: white;
               font-weight: bolder;
          }
          .team_txt {
               padding-top:30px;
          }
     }

     // .borders{
     //      padding:5px;
     //      // background-color: #23282c;
     //      border-top-right-radius: 15px;
     //      border-top-left-radius: 15px;
     // }

     .custom_table{
          background-color: white;
          text-align:center;
          color:#4E4E4E;
          width: 100%;
          thead{
               font-size: $table_header_font_size;
               background-color: #01734d;
               background-image: url("http://localhost/bg-grass-light.png");
               color: white;
               th{
                    height:50px;
                    white-space: nowrap;
                    font-weight:normal;
                    border-top: 2px solid rgba(255, 255, 255, 0.05);
               }
          }
          tbody{
               font-size: $table_body_font_size;

               tr{
                    border-bottom: 1px solid #272c42;
                    td{
                         padding: 15px 20px;
                         margin: 10px 0px;
                    }
                    transition: 0.33s
               }
               tr:hover{
                    background-color: $laranja;
               }
          }
     }

     #idolos_decepcoes{
          padding-bottom: 30px;
          margin-bottom: 30px;
          background-color: white;
          color: #4E4E4E;
          font-size: $table_header_font_size;
          // box-shadow: 0 0 20px 5px rgba(91,91,91,.37);
          span{
               font-size: 1.25*$table_header_font_size;
          }    
        
     }

     // .title{
     //      color:white;
     //      display: inline;
     //      text-align: center;
     //      background-color: #a2a9af;
     //      margin: 0;
     //      padding: 15px;
     //      padding-top: 5px;
     // }
     .title {
          background-color: #0b0b0b;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          background-color: #01734d;
          background-image: url("http://localhost/bg-grass-light.png");
          margin: 0px 0px;
          padding-top:10px;
          padding-bottom:10px;
          text-transform: uppercase;
          // border-top: 3px solid $laranja;
          // border-bottom: 3px solid $laranja;
          // color: $laranja;
          color: white;
          font-weight: bolder;
     }
     .atleta_foto{
          width: 35px;
          margin-right:10px;
     }
     .club_shield{
          width: 25px;
     }

     /* NEW */
     #loading{
          color: $light_text_color;
          img{
               margin-top: 50px;
               width: 75px;
          }
     }

     .time-buscado {
          border-bottom: 1px solid $laranja;
          margin-bottom: 20px !important;
     }

     .time-buscado:hover {
          background-color: $laranja;
          cursor: pointer;
     }

</style>
