import Vue from 'vue'
import axios from 'axios'
 
Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
        
        //Adiciona o header src=0 para setar todas as requisicoes como
        //sendo provenientes da Web(0) nas metricas
        Vue.prototype.$http.interceptors.request.use(config => {
            config.headers.common['src'] = 0;
            // console.log(config.headers.common)
            return config;
          });
    }
})

