<template>
    <div>
         <customHeader title="Guru News" subtitle="Não entre em campo com o seu time desfalcado! Veja as últimas notícias de cada clube." :mobile="mobile" v-if="!mobile"></customHeader>
         <div class="mobile-header" v-if="mobile">
               <img src="https://images2.imgbox.com/5e/39/mZWS2ZmL_o.png" class="w-100"/>
         </div>
         <div class="container">
              <div class="noticia" v-for="(noticia,i) in data" :key="i">
                   <div class="row corpo-titulo">
                        <div class="col-12 text-center">
                             <h4 class="titulo-noticia">
                                  {{noticia['titulo']}}
                             </h4>
                             <span class="tempo-noticia">{{getHoraExibicao(noticia['datahora_publicacao'])}} </span>
                        </div>
                   </div>
                   <div class="row corpo-noticia">
                        <div class="col-12 text-justify">
                             <!-- <div style="white-space: pre-line;"> {{ noticia['texto'] }}</div> -->
                             <div style="white-space: pre-line;" v-html="noticia['texto']"></div>
                        </div>
                        <div class="col-12 text-center" v-if="noticia['imagem']" style="margin-top: 2em;">
                             <img :src="noticia['imagem']" class="imagem-noticia" />
                        </div>
                   </div>
              </div>
              <div class="d-flex justify-content-center">
                    <nav aria-label="Page navigation">
                         <ul class="pagination">
                              <li class="page-item">
                                   <a class="page-link" :href="getUrl(parseInt(page)-1)" aria-label="Previous">
                                   <span aria-hidden="true">&laquo;</span>
                                   <span class="sr-only" >Anterior</span>
                                   </a>
                              </li>
                              <li class="page-item" v-for="pg in getPageCount()" :key="pg"> 
                                   <a class="page-link" :href="getUrl(pg)" >{{ pg }}</a>
                              </li>
                              <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
                              <li class="page-item"><a class="page-link" href="#">3</a></li> -->
                              <li class="page-item">
                                   <a class="page-link" :href="getUrl(parseInt(page)+1)" aria-label="Next">
                                   <span aria-hidden="true">&raquo;</span>
                                   <span class="sr-only" >Próximo</span>
                                   </a>
                              </li>
                         </ul>
                    </nav>
              </div>
         </div>
    </div>
</template>
<script>
    import customHeader from '@/components/header.vue';

    export default {
         name: 'Noticias',
         components:{
              customHeader
         },
         data(){
              return{
                   //Dados via API
                   data:null,
                   count: null,
                   URL: this.$API+'noticias',
                   page: 1,
                   //Clube selecionado
                   currentClub:null,

                   //Verificação mobile
                   mobileAt:1000,//Mobile a partir de qual width?
                   mobile:false,//Status inicial do mobile
              }
         },
         // watch:{
         //      currentClub: function () {
         //           this.reload_twitter_iframe();
         //      }
         // },
         created(){
              //Verificação mobile
              (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

              var vm = this;
              var page_param = this.$route.query.page
              if (!page_param) {
               this.page = 1
              } else {
               this.page = page_param
              }
              this.$http
              .get(this.URL + '?page=' + this.page)
              .then(response => {
                   vm.data = response.data.noticias;
                   vm.count = response.data.count;
              })
              .catch(function (error) {
                   // eslint-disable-next-line no-console
                   console.log(error);
              }); 
          
             

         },
         methods:{
          getHoraExibicao(timestamp) {
               const oneDay = 24 * 60 * 60 * 1000;
               const oneHour = 60 * 60 * 1000;
               const oneMinute = 60 * 1000;
               const oneSecond = 1000;
               let now = new Date() 
               // console.log('now 1:' + now)
               // let offset = now.getTimezoneOffset();//* 60000;
               // console.log('offset: ' + offset)
               let pub = new Date(timestamp);
               // console.log('pub:' + pub)
               // pub = pub - pub.getTimezoneOffset() * 60000;
               let days = (now - pub) / oneDay;
               // console.log('------------------------------------------')
               // console.log(timestamp)
               // console.log('dias: ' + days)
               
               if (days>1) {
                    return 'Em ' + pub.getDate() + ' de ' + this.getMes(pub.getMonth()) + ' de ' + pub.getFullYear() 
                         + ' às ' + this.getHoras(pub.getHours(),pub.getMinutes());
               } else {
                    let hours = (now - pub) / oneHour;
                    // console.log('horas: ' + hours)
                    if (hours > 1) {
                         let hora = String(hours).length > 1 ?  Math.round(Math.abs(hours))  : '0' + Math.round(Math.abs(hours));
                         return ' Há ' + hora + ' horas';
                    } else {
                         let minutes = (now - pub) / oneMinute;
                         // console.log('minutos: ' + minutes)
                         if (minutes > 1) {
                              let min = String(minutes).length > 1 ? Math.round(Math.abs(minutes)) : '0' + Math.round(Math.abs(minutes));
                              return ' Há ' + min + ' minutos';
                         } else {
                              let secs = (now - pub) / oneSecond;
                              // console.log('segundos: ' + secs)
                              let sec = String(secs).length > 1 ? Math.round(Math.abs(secs)) : '0' + Math.round(Math.abs(secs));
                              return ' Há ' + sec + ' segundos';
                         }
                    }
               }
          },
          getMes(mes) {
               switch(mes) {
                    case 1:
                         return 'Janeiro';
                    case 2:
                         return 'Fevereiro';
                    case 3:
                         return 'Março';
                    case 4:
                         return 'Abril';
                    case 5:
                         return 'Maio';
                    case 6:
                         return 'Junho';
                    case 7:
                         return 'Julho';
                    case 8:
                         return 'Agosto';
                    case 9:
                         return 'Setembro';
                    case 10:
                         return 'Outubro';
                    case 11:
                         return 'Novembro';
                    case 12:
                         return 'Dezembro';
               }
          }, 
          getHoras(h, m) {
               let hora = String(h).length>1 ? h : '0'+h;
               let min = String(m).length>1 ? m : '0'+m;
               return hora + ':' + min + 'h';
          },
          getUrl(idx) {
               if (idx < 1) {
                    idx = 1;
               }
               if (idx > this.getPageCount()) {
                    idx = this.getPageCount();
               }
               return window.location.pathname + '?page=' + idx;
          },
          getPageCount() {
               return Math.ceil(this.count / 50)
          }
     }
    }
</script>
<style scoped lang="scss">
    @import '@/assets/sass/variables.scss';
    @import '@/assets/scss/news.scss';

    // .imagem-noticia {
    //      max-width: 20em;
    // }
    .titulo-noticia {
         font-weight: bold;
         margin-bottom: 0px;
         text-transform: uppercase;
         padding-bottom: .2em;
         border-bottom: 3px solid #ECECEC;
    }
    .tempo-noticia {
         margin-top: 0px;
         font-size: .7em;
    }
    .corpo-titulo {
     //     border-bottom: 2px dotted #CCC;
         padding-bottom: .4em;
    }
    .corpo-noticia {
         padding-top: 1em;
     //     border-bottom: 1px solid #CCC;
         padding-bottom: 2em;
         margin-bottom: 4em;
    }
    .noticia {
     border: 1px solid #F0F3F4;
     padding-left: 1em;
     padding-right: 1em;
     padding-top: 2em;
    }
    .imagem-noticia {
     max-width: 100%;
    }
    .page-link {
     color: green
    }
    .mobile-header {
     border-bottom: 3px solid #EA6803;
     margin-bottom: 1em;
    }
</style>