<template>
     <div>
          <customHeader title="Vincular Conta" subtitle="" :mobile="mobile"></customHeader>
          <div class="col-12 text-center" v-if="user">
               <h3>Você está logado com a seguinte conta:</h3>
                    <h1 class="nomeTime">
                         <img :src="user.escudo" class="img img-fluid" style="max-width:50px;">
                         {{user.nomeTime}}
                    </h1>
                    <h6 class="email">{{user.email}}</h6>
               <hr style="border-bottom:1px solid #F47607;" class="w-100"/>
               <!-- <p>Entre com as credenciais da conta que deseja vincular à sua:</p> -->
               <loginVincular :mobile="mobile"></loginVincular>
          </div>
          <div v-else>
               <login :mobile="mobile"></login>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import login from '@/components/login.vue';
     import loginVincular from '@/components/vincularWizard.vue';
     import util from '@/mixin/util';

     export default {
          name: 'ContasVincular',
          components:{
               customHeader,login,loginVincular
          },
          mixins: [util],
          data() {
               return {
                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          created() {
               //windowWidth defining mobile and fieldView
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

               var vm = this;

               //auth user
               vm.user = vm.get_usuario_logado();
               
          }
     }
</script>