<template>
  <div class="row">
    <div v-if="!mobile" :class="[mobile ? 'col-12' : 'col-12']" class="text-center">
      <p class="d-flex bg-grass-light bg-verde-full align-items-center justify-content-center ranking">
        <!-- <span class="rodada">Rodada</span>
        <span class="badge badge-secondary" id="range">{{ currentRound }}</span> -->
        <span class="rodada" id="range"> Rodada {{ currentRound }}</span>
      </p>
      <button class="btn-rodada btn btn-sm small" v-for="i in 38" :key="i" @click="getRanking(i)" :class="{ rodadaAtual: i === currentRound }">
        {{ i }}
      </button>
    </div>
    <div class="row col-12 d-flex justify-content-center m-0" v-else>
        <p class="text-left" style="color:#4F4F4F;">Escolha a rodada para ver nossa seleção:</p>
        <select class="form-control" v-model="currentRound" @change="getRankingFromSelect($event)">
            <option v-for="r in market_status.rodada_atual" :value="r" :key="r">{{r}}</option>
        </select>
    </div>
    <div class="col-12">
      <h5 v-if="user_team_at_ranking" class="text-center" style="margin:50px;">
        Seu time
        <span class="pos_info">{{ user_team_at_ranking.nomeTime }}</span> 
        ficou na 
        <span class="pos_info">{{ posicao }}ª</span> 
        posição na rodada
        <span class="pos_info">{{ user_team_at_ranking.rodada }}</span>.
        <br />
        Você fez
        <span class="pos_info">{{ user_team_at_ranking.pontuacao.toFixed(2) }}</span> 
        pontos no cartola.
      </h5>
      <div v-else class="d-flex align-items-center justify-content-center mt-4 mb-4">
        <span>Para participar do nosso ranking e concorrer aos prêmios, você precisar fazer parte do&nbsp;
          <a :href="selling_page" target="_blank" class="guru-link">TIME GURU</a>.
        </span>
      </div>
    </div>
    <div :class="[mobile ? 'col-12' : 'col-6']" style="margin: 0 auto;">
      <table class="ranking-table">
        <thead>
          <th>#</th>
          <th></th>
          <th></th>
          <th>Pontos Cartola</th>
        </thead>
        <tbody>
          <tr v-for="(r, index) in ranking" :key="r.uuid" >
            <td>
              {{ index + 1 }}
            </td>
            <td>
              <template v-if="r.member == 1">
                <img src="https://images2.imgbox.com/b2/9e/b6UDNUZ8_o.png" style="width:25px;" />
              </template>
            </td>
            <td style="max-width: 50px;">
              <p class="text-left">
                {{ r.nome }}<br />
                <small>({{ r.nomeTime }})</small>
              </p>
            </td>
            <td>
              {{ r.pontuacao.toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      required: true
    },
    user: {
      required: true
    }
  },
  data() {
    return {
      //Dados
      ranking: [],

      //Rodada selecionada
      currentRound: null,
      user_team_at_ranking: null,
      posicao: null,

      //Status do mercado via API do CARTOLA
      market_status: {},

      //Auxiliar
      i: null
    };
  },

  created() {
    //Status Mercado
    this.getMercadoStatus();
  },
  methods: {
    getMercadoStatus() {
      this.$http
      //.get(this.$_PATH+'/mercado/status')  
        .get(this.$API+'/mercado-status')
        .then(response => {
          this.market_status = response.data;
          this.currentRound = this.market_status.rodada_atual-1;
          if (this.currentRound < 1) {
            this.currentRound = 1;
          }
          this.getRanking(this.currentRound)
        });
    },
    getRankingPorRodada(rodada) {
      this.$http
        // .get(this.PATH+'/ranking/rodada/'+rodada+'/limit/50/')  
        .get(this.$API+'ranking/rodada/'+rodada+'/limit/50/')
        .then(response => {
          this.ranking = response.data;
          this.currentRound = rodada;
        });
    },
    getRankingPorUsuarioNaRodada(rodada, uuid) {
      this.$http
      // .get(this.$_PATH+'/ranking/rodada/'+rodada+'/usuario/'+uuid)  
        .get(this.$API+'ranking/rodada/'+rodada+'/usuario/'+uuid)
        .then(response => {
          this.ranking = response.data.rank;
          this.user_team_at_ranking = response.data.time;
          this.currentRound = response.data.rodada;
          this.posicao = response.data.posicao;
        });
    },
    getRankingFromSelect() {
      return this.getRanking(this.currentRound);
    },
    getRanking(rodada) {
      if (this.user && this.user.membro == 1) {
        return this.getRankingPorUsuarioNaRodada(rodada, this.user.uuid);
      } else {
        return this.getRankingPorRodada(rodada);
      }
    },
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
@import "@/assets/scss/general.scss";
@import "@/assets/scss/ranking.scss";

.btn-rodada {
  width: 24px;
  margin: 2px;
  border: 0px;
  background-color: #4e4e4e;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0px;
  padding-bottom: 4px;
  padding-top: 4px;
}

.rodadaAtual {
  background-color: #228F3B;
}

.p-rodada {
    background-color: #0b0b0b;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    border-radius: 5px;
    padding-top: 5px;
    min-height: 54px;
}

.rodada {
  color: white;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.6em;
  margin-right: 5px;
  letter-spacing: 5px;
  text-shadow: 0px 3px 2px rgba(0,0,0,.95);
}

.ranking{
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
}

.badge-secondary {
  background-color: $laranja;
}

.attention-bar {
  color: $laranja;
  background-color: #0b0b0b !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 10px;
}

h6 > a {
  color: white;
}

.guru-link {
  color: #228F3B;
  font-weight: bold;
}

.pos_info {
  font-weight: bold;
  color: green;
}

.ranking-table thead {
  background-color: #228F3B;
}
.ranking-table thead th {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ranking-table tr {
  border-bottom: 1px solid #D3D3D3;
}

</style>
