<template>
  <div class="row">
      Blog
  </div>
</template>

<script>
export default {
 props: {
    mobile: {
      type: Boolean,
      required: true
    }
  },
}
</script>

<style>

</style>