<template>
<div>
    <div id="options2" v-if="loading"> 
        <!-- NavTabs -->
        <ul class="nav nav-tabs" id="tab" role="tablist" style="border-bottom: 1px solid #edf1f2;margin-bottom:0px;">
                <li class="nav-item">
                    <a class="nav-link active" :id="'scouts1-tab-'+athlete.id" data-toggle="tab" :href="'#scouts1-'+athlete.id" role="tab" :aria-controls="'scouts1-'+athlete.id" aria-selected="true">Scouts Jogador</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" :id="'scouts2-tab-'+athlete.id" data-toggle="tab" :href="'#scouts2-'+athlete.id" role="tab" :aria-controls="'scouts2-'+athlete.id" aria-selected="false">Scouts Cedidos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" :id="'data-tab-'+athlete.id" data-toggle="tab" :href="'#data-'+athlete.id" role="tab" :aria-controls="'data-'+athlete.id" aria-selected="false">Ficha Completa</a>
                </li>
        </ul>
        <div v-if="athlete.af == 0 || (user != null && user.membro == 1)" class="tab-content" id="myTabContent" :style="[mobile ? {'width':'90%'} : {}]">
                <div class="tab-pane fade show active" :id="'scouts1-'+athlete.id" role="tabpanel" :aria-labelledby="'scouts1-tab-'+athlete.id">
                    <table class="table table-borderless">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Mapa de calor</th>
                            <th scope="col">Pontuação</th>
                            <th scope="col">Scouts</th>
                            <th scope="col">Minutos Jogados</th>
                            <th scope="col">Rodada</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!hasScoutJogador">
                                <td colspan="6"><br><span style="font-weight:bold;">Não há estatísticas para esse jogador.</span></td>
                            </tr>
                            <template v-for="(i,n) in indexes">
                                <tr v-if="scouts != undefined
                                && scouts[i] != undefined
                                && (mandoCampo == 0 || scouts[i]['local_filtro_scouts'] == 1)" 
                                :key="n" >
                                    <td>
                                        <img :src="scouts[i].escudoCasa" class="club">

                                        <small>
                                            &nbsp; x &nbsp; 
                                        </small> 

                                        <img :src="scouts[i].escudoFora" class="club">
                                    </td>
                                    <td @click="heatmap(athlete.id,i)">
                                        <img src="https://d2w9rnfcy7mm78.cloudfront.net/2646125/large_2f7d07b5a2091be7445da4668cdc805b.png?1536030693" class="img img-fluid rounded-circle" style="width:30px;">
                                    </td>
                                    <td>
                                        {{scouts[i].pontos}}
                                    </td>
                                    <td>
                                        <small>
                                            <span style="color:green;">
                                                    {{scouts[i].scoutsVerde}}
                                            </span>
                                            <span style="color:red;">
                                                    {{scouts[i].scoutsVermelho}}
                                            </span>
                                        </small>
                                    </td>
                                    <td>
                                        <span v-if="scouts[i].minutosJogados != undefined">
                                            {{scouts[i].minutosJogados}}
                                        </span>
                                        <span v-else>
                                            <small>
                                                    Indisponível
                                            </small>
                                        </span>
                                    </td>
                                    <td>
                                        {{i}}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <!-- SCOUTS CEDIDOS -->
                <div class="tab-pane fade" :id="'scouts2-'+athlete.id" role="tabpanel" :aria-labelledby="'scouts2-tab-'+athlete.id">
                    <p>
                        Histórico de atletas que também tiveram confronto direto contra o(s) jogadores:
                        <span id="enemies">
                            <span>
                                    <template v-for="(nome,id) in athlete.cdpr">
                                        <span v-if="id != 0" :key="id">
                                             <b>{{ nome }}</b>
                                        </span>
                                    </template>
                            </span>
                        </span>
                    </p>
                    <!-- {{ clubes }} -->
                    <table class="table table-borderless">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Mapa de calor</th>
                            <th scope="col">Pontuação</th>
                            <th scope="col">Scouts</th>
                            <th scope="col">Minutos Jogados</th>
                            <th scope="col">Rodada</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!hasScoutCedido">
                                <td colspan="6"><br><span style="font-weight:bold;">Não há estatísticas de jogadores que enfrentaram esse adversário.</span></td>
                            </tr>
                        <template v-for="rodada in rodadasCedidas">
                            <template v-if="indexes.includes(rodada)">
                                <template  v-for="adversario in scoutsCedidos[rodada]">
                                    <tr :key="rodada+'_'+adversario.id"> 
                                         <!-- :key="rodada+'_'+adversario.id+'_'+allHomeFilter">  -->
                                        <td>
                                            <img v-if="clubes[adversario.cb]" :src="clubes[adversario.cb].escudo" class="club" :title="clubes[adversario.cb].nome">
                                            <!-- {{adversario.cb}}  =  {{clubes[adversario.cb]}} -->
                                            <small>
                                                &nbsp; x &nbsp; 
                                            </small> 
                                            <!-- {{adversario.ca}}  = {{clubes[adversario.ca]}} -->
                                            <img v-if="clubes[adversario.ca]" :src="clubes[adversario.ca].escudo" class="club" :title="clubes[adversario.ca].nome">
                                            <!-- {{rodada+'_'+adversario.id}} -->
                                        </td>
                                        <td>
                                            <img :src="adversario.ft" class="athlete"> <br/>
                                            <!-- <span class="small">{{adversario}}</span> -->
                                            <!-- {{adversario.id}} -->
                                        </td>
                                        <td @click="heatmap(adversario.id,rodada)">
                                            <img src="https://d2w9rnfcy7mm78.cloudfront.net/2646125/large_2f7d07b5a2091be7445da4668cdc805b.png?1536030693" class="img img-fluid rounded-circle" style="width:30px;">
                                            
                                        </td>
                                        <td>
                                            {{ adversario.pt }}
                                        </td>
                                        <td>
                                            <small>
                                                <span style="color:green;">
                                                    {{ adversario.vd }}
                                                </span>
                                                <span style="color:red;">
                                                    {{ adversario.vr }}
                                                </span>
                                            </small>
                                        </td>
                                        <td>
                                            <span v-if="adversario.mj != undefined">
                                                {{adversario.mj}}
                                            </span>
                                            <span v-else>
                                                <small>
                                                        Indisponível
                                                </small>
                                            </span>
                                        </td>
                                        <td>
                                            <!-- {{rival.split('_')[1]}} -->
                                            {{rodada}}
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                        </tbody>
                    </table>
                </div>
                <div class="tab-pane fade" :id="'data-'+athlete.id" role="tabpanel" :aria-labelledby="'data-tab-'+athlete.id">
                    <!-- Ficha Completa -->
                    <br>
                    <p v-if="athlete.tx != undefined">
                        {{athlete.tx}}
                    </p>

                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                    <th scope="row">Pontuação Esperada</th>
                                    <td>
                                        <span>
                                            {{parseFloat(athlete.pe).toFixed(2)}}
                                        </span>
                                    </td>
                            </tr>
                            <tr>
                                    <th scope="row">Valorização Esperada</th>
                                    <td>
                                        <span>
                                            {{parseFloat(athlete.ve).toFixed(2)}}
                                        </span>
                                    </td>
                            </tr>
                            <tr>
                                    <th scope="row">Confronto Direto</th> 
                                    <td id="enemies">
                                        <span v-if="!isEmpty(athlete.cdpr)">
                                            <template v-for="nome in athlete.cdpr">
                                                <span :key="nome">
                                                   {{nome}}
                                                </span>
                                            </template>
                                        </span>
                                        <span v-else>
                                            <small>Não encontrado</small>
                                        </span>
                                    </td>
                            </tr>
                            <tr>
                                    <th scope="row">Ritmo de jogo</th>
                                    <td>
                                        <span>
                                            {{athlete.rj}}
                                        </span>
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-borderless">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Geral</th>
                            <th scope="col">
                                    <span v-if="athlete.lc=='Fora'">
                                        Fora de casa
                                    </span>
                                    <span v-else>
                                        Em casa
                                    </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            <!--Media 5-->
                            <tr>
                                    <th scope="row">Pontuação: 5 últimas rodadas</th>
                                    <!-- Media 5 Geral -->
                                    <td>
                                        {{parseFloat(athlete.m5g).toFixed(2)}}
                                        <small>
                                            ({{athlete.m5gj}} jogos)
                                        </small>
                                    </td>

                                    <!-- Media 5 Mando -->
                                    <td>
                                        {{parseFloat(athlete.m5m).toFixed(2)}}
                                        <small>
                                            ({{athlete.m5mj}} jogos)
                                        </small>
                                    </td>
                            </tr>
                            <!--Media 10-->
                            <tr>
                                    <th scope="row">Pontuação: 10 últimas rodadas</th>
                                    <!-- Media 10 Geral -->
                                    <td>
                                        {{parseFloat(athlete.m10g).toFixed(2)}}
                                        <small>
                                            ({{athlete.m10gj}} jogos)
                                        </small>
                                    </td>

                                    <!-- Media 10 Mando -->
                                    <td>
                                        {{parseFloat(athlete.m10m).toFixed(2)}}
                                        <small>
                                            ({{athlete.m10mj}} jogos)
                                        </small>
                                    </td>
                            </tr>
                            <!-- Media Geral -->
                            <tr>
                                    <th scope="row">Pontuação: Todas as rodadas</th>
                                    <!-- Media Geral -->
                                    <td>
                                        {{parseFloat(athlete.mg).toFixed(2)}}
                                        <small>
                                            ({{athlete.mgj}} jogos)
                                        </small>
                                    </td>

                                    <!-- Media Mando -->
                                    <td>
                                        {{parseFloat(athlete.mm).toFixed(2)}}
                                        <small>
                                            ({{athlete.mmj}} jogos)
                                        </small>
                                    </td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
        </div> 
        <div v-else class="text-center" :style="[mobile ? {'width':'90%'} : {}]">
                <div class="swal2-icon swal2-warning swal2-animate-warning-icon" style="display: flex;"><span class="swal2-icon-text">!</span></div><br>
                <h6>
                    As estatísticas desse jogador são exclusivas para assinantes do Time Guru.
                </h6><br>
                <a :href="selling_page">
                    <button class="btn btn-md btn-success">
                        QUERO SER TIME GURU
                    </button>
                </a>
        </div>
    </div>
    <loading v-else :loading="true"></loading>
        
</div>
</template>

<script>
import util from '@/mixin/util';
import loading from '@/components/Loading.vue';

export default {
    components:{
        loading
    },
    mixins: [util],
    props: [
        'mobile',
        'athlete',
        'clubes',
        'rodadas',
        'mandoCampo',
        'market_status',
        'isDataLoaded',
        'user'
    ],
    data() {
        return {
            indexes: [],
            scoutsCedidos: [],
            rodadasCedidas: [],
            hasScoutJogador: false,
            hasScoutCedido: false,
            scouts: [],
            //mandoCampoProxJogo: 0,

        }
    },
    created() {
        this.calculaRodadasDoFiltro();
        this.buscaScoutsAtleta(this.athlete);
        //this.mandoCampoProxJogo = this.athlete && this.athlete.lc == 'Fora' ? 1 : 0;
    },
    watch: {
        scouts: function(newValue) {
            this.scoutsCedidos = newValue.cdpr;
            this.rodadasCedidas = [];
            for (var rod in this.scoutsCedidos) {
                this.rodadasCedidas.push(parseInt(rod));
            }
            //ordena da rodada mais recente pra mais antiga 
            //(ja vem assim do backend mas se perde na conversão por alguma razão)
            this.rodadasCedidas.sort(function(a, b){return b-a}); 
        },
        rodadas: function(){
            this.calculaRodadasDoFiltro();
        },
        mandoCampo: function() {
            this.buscaScoutsAtleta(this.athlete)
        }
        
    },
    methods: {
        calculaRodadasDoFiltro() {
            const rodada_anterior = this.market_status.rodada_atual-1==0 ? 38 : this.market_status.rodada_atual-1;
            var count = 0;
            this.indexes = [];
            for (var i=rodada_anterior; i>rodada_anterior-this.rodadas; i--) {
                count++;
                if (i==0) {
                    for (var j=38; j>=38-(this.rodadas-count); j--) {
                        this.indexes.push(j);
                    }
                    break;
                }
                this.indexes.push(i);
            }
        },
        heatmap(athlete_id, round){
            var vm = this;

            this.$http
            // .get(this.$_PATH+'/mapacalor/'+athlete_id+'/'+round)
            .get(this.$API+'mapacalor/'+athlete_id+'/'+round+'/')
            .then(response => {
                    vm.heatmap_coordinates = [];
                    vm.heatmap_coordinates = response.data;

                    $('.heatmap').empty();
                    // eslint-disable-next-line no-undef
                    var heatmapInstance = h337.create({container: document.querySelector('.heatmap')});
                    var width = 320;
                    var height = 500;

                    if(vm.mobile){
                        width= 225;
                        height= 350;
                    }

                    var points = [];
                    if(vm.heatmap_coordinates != null){
                        $.each(vm.heatmap_coordinates,function(index,value){
                            points.push({
                                    y: (1-value.x/100) * height,
                                    x: value.y/100 * width,
                                    value: 1
                            });
                        });
                    }

                    heatmapInstance.setData({
                        max: 3, 
                        data: points 
                    });

                    $('#heatmapModal').modal('show');
            })
            .catch(function (error) {
                    console.log("# erro ao buscar mapa de calor do jogador ("+athlete_id+") na rodada ("+round+"):");
                    console.log(error);
            });
        },
        buscaScoutsAtleta(athlete) {
            var vm = this;

            var scouts_cedidos_str = ''; 
            for (var idx in athlete['pcs'+vm.mandoCampo]) {
                if (scouts_cedidos_str != '') {
                    scouts_cedidos_str+=',';
                }
                scouts_cedidos_str+=athlete['pcs'+vm.mandoCampo][idx];
            }

            // var url = this.$_PATH+'/scouts/player/'+athlete.id;
            var url = this.$API+'/scouts/player/'+athlete.id;
            if (scouts_cedidos_str != '') {
                url+='?scoutsCedidos='+scouts_cedidos_str;
            }

            vm.loading = true;
            this.$http
            .get(url)
            .then(response => {
                vm.scouts = response.data;
                vm.loading = true;
                //this.scouts[i] != undefined && (this.mandoCampo == 0 || this.scouts[i]['local_filtro_scouts'] == 1)
                
                //Verifica se o atleta detalhado possui Scout e ScoutCedido
                vm.hasScoutJogador = false;
                vm.hasScoutCedido = false;
                if (response.data) {
                    for (var i=0; i<vm.indexes.length; i++) {
                        if (!vm.hasScoutJogador && response.data[vm.indexes[i]]) {
                            vm.hasScoutJogador = true;
                        }
                        if (!vm.hasScoutCedido && response.data.cdpr && response.data.cdpr[vm.indexes[i]]) {
                            vm.hasScoutCedido = true;
                        }
                        if (vm.hasScoutJogador && vm.hasScoutCedido) {
                            break;
                        }
                    }
                }
                
            })
            .catch(function (error) {
                console.log("# erro ao buscar scouts do jogador ("+athlete.id+"):");
                console.log(error);
            });  
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/variables.scss';
@import '@/assets/scss/field.scss';
// @import '@/assets/scss/field_table.scss';
@import '@/assets/scss/main_table.scss';

.club {
    width: 20px;
}
.athlete {
    width: 40px;
}
</style>