<template>
     <div>
          <customHeader title="Política de privacidade" subtitle="" :mobile="mobile"></customHeader>
          <div class="container">
               <p style="text-align: justify;">N&oacute;s, do GURU DO CARTOLA, estamos comprometidos em resguardar sua privacidade. O intuito deste documento &eacute; esclarecer quais informa&ccedil;&otilde;es s&atilde;o coletadas dos usu&aacute;rios do nosso site e de que forma esses dados s&atilde;o manipulados e utilizados.</p>
               <p style="text-align: justify;">Nunca iremos repassar as informa&ccedil;&otilde;es dos usu&aacute;rios a parceiros ou mesmo vend&ecirc;-las para terceiros.</p> 
               <p style="text-align: justify;">N&atilde;o temos acesso a conta do Google ou do Facebook de nossos usu&aacute;rios.<br /> 
               <p style="text-align: justify;">Não temos acesso à senha do usuário no Cartola. O login é feito no Cartola e somente intermediamos o seu acesso ao time. O site, por si só, não executa nenhuma atividade diretamente na conta do cartola do usuário.</p>
               <p style="text-align: justify;">Alertamos que se você não concorda com o conteúdo desta pol&iacute;tica, n&atilde;o &eacute; recomend&aacute;vel fazer login em nosso site.</p>
               <p><strong>Sobre a coleta de dados</strong></p>
               <p style="text-align: justify;">Em nosso site, as informa&ccedil;&otilde;es s&atilde;o coletadas das seguintes formas:</p>
               <p style="text-align: justify;">Informa&ccedil;&otilde;es fornecidas por voc&ecirc; &ndash; coletamos o e-mail de todos os usu&aacute;rios logados no site.</p>
               <p style="text-align: justify;">Informa&ccedil;&otilde;es de navega&ccedil;&atilde;o no site &ndash; Quando voc&ecirc; visita nosso site, h&aacute; um &lsquo;cookie&rsquo; (pequeno texto que os sites podem enviar aos navegadores) por meio do software <em>Google Analytics</em>, para identificar quantas vezes voc&ecirc; retorna ao nosso site. S&atilde;o coletadas, anonimamente, informa&ccedil;&otilde;es, como, localiza&ccedil;&atilde;o geogr&aacute;fica, fonte de refer&ecirc;ncia, tipo de navegador, dura&ccedil;&atilde;o da visita e p&aacute;ginas visitadas.</p>
               <p style="text-align: justify;">N&atilde;o &eacute; permitido que os usu&aacute;rios do Guru do Cartola escolham como login para registro palavras, express&otilde;es ou conjuntos gr&aacute;ficos denominativos que j&aacute; tenham sido escolhidos anteriormente por outros usu&aacute;rios, bem como &eacute; vedado a esses usu&aacute;rios a escolha de express&otilde;es malsoantes, injuriosas, coincidentes com marcas de produtos ou servi&ccedil;os de terceiros, denomina&ccedil;&otilde;es sociais, express&otilde;es publicit&aacute;rias, nomes ou pseud&ocirc;nimos de personalidades p&uacute;blicas, de pessoas famosas ou registrados por terceiros, ou que n&atilde;o remetam &agrave; identidade do registrante e, em geral, contr&aacute;rios &agrave; lei, &agrave; ordem ou &agrave;s exig&ecirc;ncias da moral e dos bons costumes comumente aceitos.</p>
               <p style="text-align: justify;">O usu&aacute;rio que desobedecer ao estabelecido no par&aacute;grafo acima poder&aacute; ter o seu login cancelado e bloqueado sem aviso pr&eacute;vio e a crit&eacute;rio exclusivo do Guru do Cartola.</p>
               <p><strong>Sobre o uso e acesso de suas Informa&ccedil;&otilde;es Pessoais</strong></p>
               <p style="text-align: justify;">O presente termo permite que o Guru do Cartola use suas informa&ccedil;&otilde;es pessoais para finalidade de divulgar as novidades do site, aplicativo ou redes sociais, utilizando-se do envio de e-mails.</p>
               <p style="text-align: justify;">Ao receber um e-mail, o usu&aacute;rio sempre ter&aacute; a op&ccedil;&atilde;o de n&atilde;o fazer mais parte da lista de e-mails do Guru do Cartola.</p>
               <p style="text-align: justify;">Poder&atilde;o ver suas informa&ccedil;&otilde;es pessoais apenas os funcion&aacute;rios do Guru do Cartola.</p>
               <p style="text-align: justify;">O Guru do Cartola tamb&eacute;m se compromete a n&atilde;o vender, alugar ou repassar suas informa&ccedil;&otilde;es para terceiros. A &uacute;nica exce&ccedil;&atilde;o est&aacute; em casos em que essas informa&ccedil;&otilde;es forem exigidas judicialmente.</p>
               <p style="text-align: justify;">Al&eacute;m disso, embora trabalhemos com boas pr&aacute;ticas de prote&ccedil;&atilde;o e seguran&ccedil;a, nenhum servi&ccedil;o web possui 100% de garantia contra invas&otilde;es e n&atilde;o podemos nos responsabilizar caso isso ocorra.</p>
               <p><strong>Sobre o compartilhamento de conte&uacute;do nas redes sociais</strong></p>
               <p style="text-align: justify;">Ao clicar nos bot&otilde;es de compartilhamento de conte&uacute;do nas redes sociais dispon&iacute;veis em nossas p&aacute;ginas, o usu&aacute;rio estar&aacute; publicando o conte&uacute;do por meio de seu perfil na rede selecionada. O Guru do Cartola n&atilde;o tem acesso ao login e senha dos usu&aacute;rios nessas redes, nem publicar&aacute; conte&uacute;do em nome do usu&aacute;rio sem que ele realize esta a&ccedil;&atilde;o.</p>
               <p><strong>Servi&ccedil;os de Terceiros</strong></p>
               <p style="text-align: justify;">No geral, os fornecedores terceirizados usados por n&oacute;s ir&atilde;o apenas coletar, usar e divulgar suas informa&ccedil;&otilde;es na medida do necess&aacute;rio para permitir que eles realizem os servi&ccedil;os que eles nos fornecem.</p>
               <p style="text-align: justify;">Entretanto, certos fornecedores de servi&ccedil;os terceirizados, tais como gateways de pagamento e outros processadores de transa&ccedil;&atilde;o de pagamento, t&ecirc;m suas pr&oacute;prias pol&iacute;ticas de privacidade com respeito &agrave; informa&ccedil;&atilde;o que somos obrigados a fornecer para eles de suas transa&ccedil;&otilde;es relacionadas com compras.<br /> &nbsp;<br /> Para esses fornecedores, recomendamos que voc&ecirc; leia suas pol&iacute;ticas de privacidade para que voc&ecirc; possa entender a maneira na qual suas informa&ccedil;&otilde;es pessoais ser&atilde;o usadas por esses fornecedores.</p>
               <p><strong>Mudan&ccedil;as na Pol&iacute;tica de Privacidade</strong></p>
               <p style="text-align: justify;">Essa Pol&iacute;tica de Privacidade pode passar por atualiza&ccedil;&otilde;es. Desta forma, recomendamos visitar periodicamente esta p&aacute;gina para que voc&ecirc; tenha conhecimento sobre as modifica&ccedil;&otilde;es.</p>
               <p style="text-align: justify;">Antes de usar informa&ccedil;&otilde;es para outros fins que n&atilde;o os definidos nesta Pol&iacute;tica de Privacidade, solicitaremos sua autoriza&ccedil;&atilde;o.</p>
               <p style="text-align: justify;">Qualquer d&uacute;vida em rela&ccedil;&atilde;o &agrave; nossa pol&iacute;tica de privacidade pode ser esclarecida entrando em contato conosco. Envie um email para contato@gurudocartola.com.</p>

          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';

     export default {
          name: 'Privacidade',
          components:{
               customHeader
          },
          data(){
               return {
                    
               }
          },
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     p, ul li{
          color: #4E4E4E;
     }
     strong{
          display: block;
          margin-top: 75px;
     }
</style>