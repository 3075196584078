<template>
  <div class="container">
    <br/><br/><br/><br/><br/>
    <div class="row">
      <div class="col-12" >
        <div class="d-flex justify-content-center" > 
          <div class="flex-column">
            <h5 class="date-title">Escolha uma data:</h5>
            <datepicker v-model="date"></datepicker>
          </div>
        </div>
        <hr >
        
      </div>
      <div class="col-12" id="chart">
        <canvas id="lineChart" width="100%" height__="80%" stlye="min-height:1024px;"></canvas>
      </div>
      <div class="col-12" v-if="date">
        <hr >
        <table class="table endpoints">
          <tr>
              <th>Endpoint</th>
              <th class="text-center">Chamadas</th>
          </tr>
          <tr v-for="c in contagens" :key="c.path">
              <td>{{c.path}}</td>
              <td class="text-center">{{c.count}}</td>
          </tr>
        </table>
      </div>
      
    </div>
  </div>
</template>

<script>
import datepicker from 'vuejs-datepicker';

export default {
  name: "metrica-date",
  components: {
    datepicker
  },
  data() {
    return {
      mobileAt: 1000, //Mobile a partir de qual width?
      mobile: false, //Status inicial do mobile
      contagens: [],
      date: null,
      dataset: null,
      colors: ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', 
              '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'],
      
    };
  },
  watch:{
      date: function (newValue) {
          var vm = this;
          var newDate = null;
          if (newValue != null & newValue != undefined ) {
            newDate = newValue.getFullYear() + '-' + ('0'+(newValue. getMonth()+1)).slice(-2) + '-' + ('0'+newValue.getDate()).slice(-2)

            // console.log(this.$PATH+'/metrica/date/' + newDate)
            this.$http
            .get(this.$PATH+'/metrica/date/' + newDate)
            .then(response => {
                console.log(response)
                vm.contagens = response.data.contagens
                vm.data = response.data
                var idx = 0
                for (var c of vm.data.datasets) {
                  c['backgroundColor'] = vm.colors[idx]
                  c['borderColor'] = vm.colors[idx]
                  idx++
                  if (idx>=(vm.colors.length-1)) {
                    idx=0
                  }
                }

                //line
                $("canvas#lineChart").remove();
                $("div#chart").append('<canvas id="lineChart" width="100%" height__="80%" stlye="min-height:1024px;"></canvas>');
                var ctxL = document.getElementById("lineChart").getContext('2d');
                
                // eslint-disable-next-line 
                var myLineChart = new Chart(ctxL, {
                  type: 'bar',
                  data: response.data,
                  options: {
                    responsive: true,
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true
                      }
                    }
                  }
                })
            })
            .catch(function (error) {
                console.log('[error #3]:')
                console.log(error);
            });   

          }
        
      },
  },
  mounted() {
      
  },
  created() {
    //Verificação mobile
    window.innerWidth < this.mobileAt
      ? (this.mobile = true)
      : (this.mobile = false);
  },

  methods: {
      desenhaGrafico() {

      }
  }
};
</script>

<style scoped lang="scss">
#login-popin-iframe {
  height: 800px;
}
.endpoint {
  font-size: 10px;
}
.endpoint-check {
  margin-right: 5px;
}
.endpoints td {
  font-size: 10px;
  padding: 1px;
}

.endpoints tr:hover {
  color: white;
  background-color: #4F4F4F;
}

.date-title {
  color: #4F4F4F;
  font-weight:  bold;
}
</style>
