import { render, staticRenderFns } from "./TermosApp.vue?vue&type=template&id=279535f0&scoped=true"
import script from "./TermosApp.vue?vue&type=script&lang=js"
export * from "./TermosApp.vue?vue&type=script&lang=js"
import style0 from "./TermosApp.vue?vue&type=style&index=0&id=279535f0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "279535f0",
  null
  
)

export default component.exports