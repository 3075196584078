<template>
     <div>
          <customHeader title="Avaliação de times - Cartola FC" subtitle="Resenha e avaliações da elite Cartoleira" :mobile="mobile"></customHeader>
          <div class="">
               <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                         <a :class="{ smallFont: mobile }" class="nav-link" id="meutime-tab" data-toggle="tab" href="#meutime" role="tab" aria-controls="meutime" aria-selected="true">
                              Meu Time
                         </a>
                    </li>
                    <li class="nav-item">
                         <a :class="{ smallFont: mobile }" class="nav-link active" id="timeguruzada-tab" data-toggle="tab" href="#timeguruzada" role="tab" aria-controls="timeguruzada" aria-selected="false">
                              Times da Guruzada
                         </a>
                    </li>
               </ul>
               <div class="tab-content" id="myTabContent">
                    
                    <!-- MEU TIME ::: Time do usuário -->
                    <div class="tab-pane fade show" id="meutime" role="tabpanel" aria-labelledby="meutime-tab">
                         <div v-if="userTeam">
                              <div style="margin: 30px 0px">
                                   <h2 class="text-center">
                                        Time de {{userTeam.nome_usuario.split(' ')[0]}}
                                   </h2>
                                   <hr>
                                   <div id="field" :class="[mobile ? 'col-12' : 'col-9']" style="margin:0 auto;" @mouseover="enable_tooltip();">
                                        <br><br>
                                        <div id="team">
                                             <div id="attack" class="row">
                                                  <template v-for="atleta_id in userTeam.atletas" >
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 5" class="col"
                                                       data-toggle="tooltip" data-placement="top" data-html="true"
                                                       :title="get_tooltip_html(atleta_id)" :key="atleta_id">
                                                            <i v-if="userTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)" class="img img-fluid">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template>
                                             </div>
                                             <div id="medium" class="row">
                                                  <template v-for="atleta_id in userTeam.atletas" >
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 4" :key="atleta_id"
                                                       class="col" data-toggle="tooltip" data-placement="top" data-html="true"
                                                       :title="get_tooltip_html(atleta_id)">
                                                            <i v-if="userTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)" class="img img-fluid">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template>
                                             </div>
                                             <div id="back" class="row">
                                                  <template v-for="(atleta_id,key,index) in userTeam.atletas">
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 2" :key="index"
                                                            class="col" :class="[key%2==0 ? 'order-first' : 'order-last']"
                                                            data-toggle="tooltip" data-placement="top" data-html="true"
                                                            :title="get_tooltip_html(atleta_id)">
                                                            <i v-if="userTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 3" :key="index"
                                                            class="col order-2" 
                                                            data-toggle="tooltip" data-placement="top" data-html="true"
                                                            :title="get_tooltip_html(atleta_id)">
                                                            <i v-if="userTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template> 
                                             </div>
                                             <div id="goalkeeper" class="row">
                                                  <template v-for="atleta_id in userTeam.atletas" >
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 1" :key="atleta_id"
                                                       class="col" data-toggle="tooltip" data-placement="top" data-html="true"
                                                       :title="get_tooltip_html(atleta_id)">
                                                            <i v-if="userTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)" class="img img-fluid">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template>
                                             </div>
                                             <div id="coach" class="row">
                                                  <template v-for="atleta_id in userTeam.atletas" >
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 6" :key="atleta_id"
                                                       class="col" data-toggle="tooltip" data-placement="top" data-html="true"
                                                       :title="get_tooltip_html(atleta_id)">
                                                            <i v-if="userTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)" class="img img-fluid">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div id="comments" class="col-12">
                                   <div v-if="market_status.status_mercado == 1">
                                        <p>Deixe seu comentário...</p>
                                        <div class="row">
                                             <div :class="[mobile ? 'col-10' : 'col-11']" style="padding-right:0px;">
                                                  <textarea class="form-control" :id="'comment_'+userTeam.uuid" style="height: 75px;"></textarea>
                                             </div>
                                             <div :class="[mobile ? 'col-2' : 'col-1']" style="padding-left:0px;">
                                                  <button class="btn btn-md btn-success" @click="add_new_comment(userTeam.uuid)" style="height: 75px;">
                                                       <i class="far fa-paper-plane"></i>
                                                  </button>
                                             </div>
                                        </div>
                                   </div>
                                   <div v-else>
                                        <p>Impossível comentar. Mercado não está aberto.</p>
                                   </div>
                                   <hr>
                                   <div class="row">
                                        <template v-for="(comment,i) in userTeam.comentarios">
                                             <div class="col-12" v-if="i == 0 || i == 1 || load_all_comments == index" :key="comment.id">
                                                  <div class="comment">
                                                       <span style="float:right" v-if="user != null && user.uuid == comment.uuid">
                                                            <button class="btn btn-sm btn-danger" @click="remove_comment(userTeam.uuid,comment.id)">
                                                                 <i class="fas fa-trash"></i>
                                                            </button>
                                                       </span>
                                                       <span v-if="new Date(comment.data_envio) - new Date(userTeam.data) < 0" class="warning_updated_team" > 
                                                            <small>Time alterado após esse comentário</small>
                                                            <br>
                                                       </span>
                                                       {{comment.nome.split(' ')[0]}}  - <small> comentou em {{format_date(comment.data_envio)}}</small>
                                                       <p>{{comment.resposta}}</p>
                                                       <i class="fas fa-thumbs-up text-success" @click="vote_on_comment(userTeam.uuid,comment.id,1)"></i> 
                                                       &nbsp;
                                                       <span class="badge badge-success">
                                                            {{Object.keys(comment.likes).length + votes_map(votes_on_page['1'].indexOf(comment.id))}}
                                                       </span>
                                                       &nbsp;
                                                       <i class="fas fa-thumbs-down text-danger" @click="vote_on_comment(userTeam.uuid,comment.id,0)"></i> 
                                                       &nbsp;
                                                       <span class="badge badge-danger">
                                                            {{Object.keys(comment.deslikes).length + votes_map(votes_on_page['0'].indexOf(comment.id))}}
                                                       </span>
                                                       &nbsp;
                                                       <a v-if="market_status.status_mercado == 1" @click=" answer_section == i ? answer_section = -1 : answer_section = i">
                                                            Responder
                                                       </a>
                                                       <div v-if="user != null && answer_section == i && market_status.status_mercado == 1">
                                                            <p class="text-center">Responder o comentário de {{comment.nome.split(' ')[0]}}</p>
                                                            <div class="row">
                                                                 <div :class="[mobile ? 'col-10' : 'col-11']" style="padding-right:0px;">
                                                                      <textarea class="form-control" :id="'answer_'+userTeam.uuid+'_'+comment.id" style="height:75px;"></textarea>
                                                                 </div>
                                                                 <div :class="[mobile ? 'col-2' : 'col-1']" style="padding-left:0px;">
                                                                      <button class="btn btn-md btn-success" @click="add_new_answer(userTeam.uuid,comment.id); answer_section=-1" style="height:75px;">
                                                                           <i class="far fa-paper-plane"></i>
                                                                      </button>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div v-else-if="answer_section == i">
                                                            <p>Você precisa logar para responder a algum comentário...</p>
                                                       </div>
                                                       <div v-if="Object.keys(comment.respostas).length > 0" class="text-center">
                                                            <a @click = "load_all_answers == i ? load_all_answers = -1 : load_all_answers = i">
                                                                 <span v-if="load_all_answers != i">
                                                                      Ver
                                                                      ({{Object.keys(comment.respostas).length}})
                                                                 </span>
                                                                 <span v-else>Ocultar</span>
                                                                  Respostas
                                                            </a>
                                                       </div>
                                                       <div id ="answers" v-if="load_all_answers == i">
                                                            <!-- <template v-for="answer in comment.respostas"> -->
                                                                 <div class="answer" v-for="answer in comment.respostas" :key="answer">
                                                                      <span v-if="new Date(answer.data_envio) - new Date(userTeam.data) < 0" class="warning_updated_team" > 
                                                                           <small>Time alterado após essa resposta</small>
                                                                           <br>
                                                                      </span>
                                                                      {{answer.nome.split(' ')[0]}} - 
                                                                      <small> 
                                                                           respondeu em {{format_date(answer.data_envio)}}
                                                                           &nbsp;
                                                                           <i class="fas fa-thumbs-up text-success" @click="vote_on_answer(userTeam.uuid,comment.id,answer.id,1)"></i> 
                                                                           &nbsp;
                                                                           <span class="badge badge-success">
                                                                                {{Object.keys(answer.likes).length}}
                                                                           </span>
                                                                           &nbsp;
                                                                           <i class="fas fa-thumbs-down text-danger" @click="vote_on_answer(userTeam.uuid,comment.id,answer.id,0)"></i> 
                                                                           &nbsp;
                                                                           <span class="badge badge-danger">
                                                                                {{Object.keys(answer.deslikes).length}}
                                                                           </span>
                                                                           &nbsp;
                                                                      </small>
                                                                      <p>{{answer.resposta}}</p>
                                                                 </div>
                                                            <!-- </template> -->
                                                       </div>
                                                  </div>
                                                  <hr>
                                             </div>
                                        </template>
                                        <div v-if="
                                        load_all_comments != index && 
                                        userTeam.comentarios != null && 
                                        Object.keys(userTeam.comentarios).length > 2" class="col-12 text-center">
                                             <a @click = "load_all_comments = index" style="font-size:25px;">
                                                  <i class="fas fa-plus-circle"></i>
                                             </a>
                                        </div>
                                        <div v-else-if="
                                        userTeam.comentarios != undefined && 
                                        Object.keys(userTeam.comentarios).length > 2" class="col-12 text-center">
                                             <a @click = "load_all_comments = -1" style="font-size:25px;">
                                                  <i class="fas fa-minus-circle"></i>
                                             </a>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div v-else>
                              <template v-if="user">
                                   <!-- <h3 class="text-center">Você ainda não enviou seu time para avaliação</h3> -->
                                   <div class="d-flex mb-5 row col-12 justify-content-center">
                                        <h3 class="ops w-100 text-center">Ops!</h3>
                                        <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" class="cartao"/>
                                        <h5 class="w-100 text-center">Você ainda não enviou seu time para avaliação!</h5>
                                        <a id="submit_1" class="styled_button btn btn-warning mb-2" :class="{'mobile-btn':mobile}" href="/escale-seu-time-cartola" >
                                             Clique aqui para escalar o seu time e enviá-lo agora mesmo!
                                        </a>
                                   </div>
                              </template>
                              <template v-else>
                                   <div class="d-flex mb-5 row col-12 justify-content-center">
                                        <h3 class="ops w-100 text-center">Ops!</h3>
                                        <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" class="cartao"/>
                                        <h5 class="w-100 text-center">Você precisa logar para que os dados de seu time sejam carregados!</h5>
                                   </div>
                                   <login :mobile="mobile" :redirect="'avaliacao-time-cartola'"></login>
                              </template>
                         </div>
                    </div>

                    <!-- Times da guruzada -->
                    <div class="tab-pane fade show active" id="timeguruzada" role="tabpanel" aria-labelledby="timeguruzada-tab">
                         <div v-if="guruTeams">
                         <template v-for="(guruTeam,index) in guruTeams">
                              <div class="time" :key="'time_'+index">
                                   <div class="bg-grass bg-verde">
                                        <h2 class="text-center">
                                             Time de {{guruTeam.nome_usuario.split(' ')[0]}}
                                        </h2>
                                   </div>

                                   <!-- DEBUG 
                                   <p style="color:red;">
                                        <small>
                                             {{guruTeam.atletas}}
                                             <ul>
                                                  <li v-for="(a,key) in guruTeam.atletas">
                                                       [{{key}}] {{a}} : 
                                                       <span v-if="athletes[a]">
                                                            {{athletes[a].apelido}} -
                                                            {{athletes[a].idPosicao}}
                                                       </span>
                                                  </li>
                                             </ul>
                                        </small>
                                   </p>-->
                                   
                                   <!-- <hr> -->
                                   
                                   <div id="field" :class="[mobile ? 'col-12' : 'col-9']" style="margin:0 auto;" @mouseover="enable_tooltip();">
                                        <br><br>
                                        <div id="team">
                                             <div id="attack" class="row">
                                                  <template v-for="atleta_id in guruTeam.atletas" >
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 5" class="col"
                                                       data-toggle="tooltip" data-placement="top" data-html="true"
                                                       :title="get_tooltip_html(atleta_id)" :key="atleta_id">
                                                            <i v-if="guruTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)" class="img img-fluid">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template>
                                             </div>
                                             <div id="medium" class="row">
                                                  <template v-for="atleta_id in guruTeam.atletas" >
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 4" class="col"
                                                       data-toggle="tooltip" data-placement="top" data-html="true"
                                                       :title="get_tooltip_html(atleta_id)" :key="atleta_id">
                                                            <i v-if="guruTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)" class="img img-fluid">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template>
                                             </div>
                                             <div id="back" class="row">
                                                  <template v-for="(atleta_id,key) in guruTeam.atletas.filter(x => athletes[x] && athletes[x].idPosicao == 2)">
                                                        <!-- && athletes[atleta_id].idPosicao == 2" -->
                                                       <div v-if="athletes[atleta_id]"
                                                            class="col"
                                                            :class="[key%2==0 ? 'order-first' : 'order-last']"
                                                            data-toggle="tooltip" data-placement="top" data-html="true"
                                                            :title="get_tooltip_html(atleta_id)" :key="atleta_id">
                                                            <i v-if="guruTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template>
                                                  <template v-for="atleta_id in guruTeam.atletas.filter(x => athletes[x] && athletes[x].idPosicao == 3)">
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 3"
                                                            class="col order-1" 
                                                            data-toggle="tooltip" data-placement="top" data-html="true"
                                                            :title="get_tooltip_html(atleta_id)" :key="atleta_id">
                                                            <i v-if="guruTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template> 
                                             </div>
                                             <div id="goalkeeper" class="row">
                                                  <template v-for="atleta_id in guruTeam.atletas" >
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 1" class="col"
                                                       data-toggle="tooltip" data-placement="top" data-html="true"
                                                       :title="get_tooltip_html(atleta_id)" :key="atleta_id">
                                                            <i v-if="guruTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)" class="img img-fluid">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template>
                                             </div>
                                             <div id="coach" class="row">
                                                  <template v-for="atleta_id in guruTeam.atletas" >
                                                       <div v-if="athletes[atleta_id] && athletes[atleta_id].idPosicao == 6" class="col"
                                                       data-toggle="tooltip" data-placement="top" data-html="true"
                                                       :title="get_tooltip_html(atleta_id)" :key="atleta_id">
                                                            <i v-if="guruTeam.capitao == atleta_id" 
                                                                 class="fab fa-cuttlefish capitao"></i>
                                                            <img :src="athlete_photo(athletes[atleta_id].foto,220)" class="img img-fluid">
                                                            <img v-if="clubs[athletes[atleta_id].idClube] != undefined" :src="clubs[athletes[atleta_id].idClube].ec" class="club2">
                                                            <br>
                                                            <span>{{athletes[atleta_id].apelido}}</span>
                                                       </div>
                                                  </template>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div id="comments" class="col-12" :key="'comments_'+index">
                                   <div v-if="market_status.status_mercado == 1">
                                       <p>Deixe seu comentário...</p>
                                       <div class="row">
                                            <div :class="[mobile ? 'col-10' : 'col-11']" style="padding-right:0px;">
                                                 <textarea class="form-control" :id="'comment_'+guruTeam.uuid" style="height: 75px;"></textarea>
                                            </div>
                                            <div :class="[mobile ? 'col-2' : 'col-1']" style="padding-left:0px;">
                                                 <button class="btn btn-md btn-success" @click="add_new_comment(guruTeam.uuid)" style="height: 75px;">
                                                      <i class="far fa-paper-plane"></i>
                                                 </button>
                                            </div>
                                       </div> 
                                   </div>
                                   <div v-else class="text-center">
                                        <p>Impossível comentar. Mercado não está aberto.</p>
                                   </div>
                                   <hr>
                                   <div class="row">
                                        <template v-for="(comment,i) in guruTeam.comentarios">
                                             <div class="col-12" v-if="i == 0 || i == 1 || load_all_comments == index" :key="i">
                                                  <div class="comment">
                                                       <span style="float:right" v-if="user != null && user.uuid == comment.uuid">
                                                            <button class="btn btn-sm btn-danger" @click="remove_comment(guruTeam.uuid,comment.id)">
                                                                 <i class="fas fa-trash"></i>
                                                            </button>
                                                       </span>
                                                       <span v-if="new Date(comment.data_envio) - new Date(guruTeam.data) < 0" class="warning_updated_team" > 
                                                            <small>Time alterado após esse comentário</small>
                                                            <br>
                                                       </span>
                                                       <b>{{comment.nome.split(' ')[0]}}</b> - <small> comentou em {{format_date(comment.data_envio)}}</small>
                                                       <p>{{comment.resposta}}</p>
                                                       <i class="fas fa-thumbs-up text-success" @click="vote_on_comment(guruTeam.uuid,comment.id,1)"></i> 
                                                       &nbsp;
                                                       <span class="badge badge-success">
                                                            {{Object.keys(comment.likes).length + votes_map(votes_on_page['1'].indexOf(comment.id))}}
                                                       </span>
                                                       &nbsp;
                                                       <i class="fas fa-thumbs-down text-danger" @click="vote_on_comment(guruTeam.uuid,comment.id,0)"></i> 
                                                       &nbsp;
                                                       <span class="badge badge-danger">
                                                            {{Object.keys(comment.deslikes).length + votes_map(votes_on_page['0'].indexOf(comment.id))}}
                                                       </span>
                                                       &nbsp;
                                                       <a v-if="market_status.status_mercado == 1" @click=" answer_section == i ? answer_section = -1 : answer_section = i">
                                                            Responder
                                                       </a>
                                                       <div v-if="user != null && answer_section == i && market_status.status_mercado == 1">
                                                            <p class="text-center">Responder o comentário de {{comment.nome.split(' ')[0]}}</p>
                                                            <div class="row">
                                                                 <div :class="[mobile ? 'col-10' : 'col-11']" style="padding-right:0px;">
                                                                      <textarea class="form-control" :id="'answer_'+guruTeam.uuid+'_'+comment.id" style="height:75px;"></textarea>
                                                                 </div>
                                                                 <div :class="[mobile ? 'col-2' : 'col-1']" style="padding-left:0px;">
                                                                      <button class="btn btn-md btn-success" @click="add_new_answer(guruTeam.uuid,comment.id); answer_section=-1" style="height:75px;">
                                                                           <i class="far fa-paper-plane"></i>
                                                                      </button>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div v-else-if="answer_section == i">
                                                            <p>Você precisa logar para responder a algum comentário...</p>
                                                       </div>
                                                       <div v-if="Object.keys(comment.respostas).length > 0" class="text-center">
                                                            <a @click = "load_all_answers == i ? load_all_answers = -1 : load_all_answers = i">
                                                                 <span v-if="load_all_answers != i">
                                                                      Ver
                                                                      ({{Object.keys(comment.respostas).length}})
                                                                 </span>
                                                                 <span v-else>Ocultar</span>
                                                                  Respostas
                                                            </a>
                                                       </div>
                                                       <div id ="answers" v-if="load_all_answers == i">
                                                            <template v-for="(answer,n) in comment.respostas">
                                                                 <div class="answer" :key="n">
                                                                      <span v-if="new Date(answer.data_envio) - new Date(guruTeam.data) < 0" class="warning_updated_team" > 
                                                                           <small>Time alterado após essa resposta</small>
                                                                           <br>
                                                                      </span>
                                                                      {{answer.nome.split(' ')[0]}} - 
                                                                      <small> 
                                                                           respondeu em {{format_date(answer.data_envio)}}
                                                                           &nbsp;
                                                                           <i class="fas fa-thumbs-up text-success" @click="vote_on_answer(guruTeam.uuid,comment.id,answer.id,1)"></i> 
                                                                           &nbsp;
                                                                           <span class="badge badge-success">
                                                                                {{Object.keys(answer.likes).length + votes_map(votes_on_page['1'].indexOf(comment.id+'_'+answer.id))}}
                                                                           </span>
                                                                           &nbsp;
                                                                           <i class="fas fa-thumbs-down text-danger" @click="vote_on_answer(guruTeam.uuid,comment.id,answer.id,0)"></i> 
                                                                           &nbsp;
                                                                           <span class="badge badge-danger">
                                                                                {{Object.keys(answer.deslikes).length + votes_map(votes_on_page['0'].indexOf(comment.id+'_'+answer.id))}}
                                                                           </span>
                                                                           &nbsp;
                                                                      </small>
                                                                      <p>{{answer.resposta}}</p>
                                                                 </div>
                                                            </template>
                                                       </div>
                                                       <div id="answers">
                                                            <template v-for="(answer,i) in answers_on_page">
                                                                 <div class="col-12 comment" v-if="answer.guru_team_uuid == guruTeam.uuid && answer.guru_team_comment_id == comment.id" :key="i">
                                                                      {{user.nome.split(' ')[0]}}  - 
                                                                      <small> 
                                                                           respondeu agora
                                                                           &nbsp;
                                                                           <i class="fas fa-thumbs-up text-success" @click="vote_on_answer(guruTeam.uuid,comment.id,answer.id,1)"></i> 
                                                                           &nbsp;
                                                                           <span class="badge badge-success">
                                                                                {{votes_map(votes_on_page['1'].indexOf(comment.id+'_'+answer.id))}}
                                                                           </span>
                                                                           &nbsp;
                                                                           <i class="fas fa-thumbs-down text-danger" @click="vote_on_answer(guruTeam.uuid,comment.id,answer.id,0)"></i> 
                                                                           &nbsp;
                                                                           <span class="badge badge-danger">
                                                                                {{votes_map(votes_on_page['0'].indexOf(comment.id+'_'+answer.id))}}
                                                                           </span>
                                                                           &nbsp;
                                                                      </small>
                                                                      <p>{{answer.comentario}}</p>
                                                                 </div>
                                                                 <hr :key="'hr_'+i"/>
                                                            </template>
                                                       </div>
                                                  </div>
                                                  <hr>
                                             </div>
                                        </template>
                                        
                                        <template v-for="(comment,i) in comments_on_page">
                                             <div class="col-12 comment" v-if="comment.guru_team_uuid == guruTeam.uuid" :key="i">
                                                  <span style="float:right" v-if="user != null && user.uuid == comment.uuid">
                                                       <button class="btn btn-sm btn-danger" @click="remove_comment(guruTeam.uuid,comment.id)">
                                                            <i class="fas fa-trash"></i>
                                                       </button>
                                                  </span>
                                                  {{user.nome.split(' ')[0]}}  - <small> comentou agora</small>
                                                  <p>{{comment.comentario}}</p>
                                                  <i class="fas fa-thumbs-up text-success" @click="vote_on_comment(guruTeam.uuid,comment.id,1)"></i> 
                                                  &nbsp;
                                                  <span class="badge badge-success">
                                                       {{votes_map(votes_on_page['1'].indexOf(comment.id))}}
                                                  </span>
                                                  &nbsp;
                                                  <i class="fas fa-thumbs-down text-danger" @click="vote_on_comment(guruTeam.uuid,comment.id,0)"></i> 
                                                  &nbsp;
                                                  <span class="badge badge-danger">
                                                       {{votes_map(votes_on_page['0'].indexOf(comment.id))}}
                                                  </span>
                                                  &nbsp;
                                             </div>
                                             <hr :key="'hr_'+i">
                                        </template>
                                        
                                        <div v-if="
                                        load_all_comments != index && 
                                        guruTeam.comentarios != null && 
                                        Object.keys(guruTeam.comentarios).length > 2" class="col-12 text-center">
                                             <a @click = "load_all_comments = index" style="font-size:25px;">
                                                  <i class="fas fa-plus-circle"></i>
                                             </a>
                                        </div>
                                        <div v-else-if="
                                        guruTeam.comentarios != undefined && 
                                        Object.keys(guruTeam.comentarios).length > 2" class="col-12 text-center">
                                             <a @click = "load_all_comments = -1" style="font-size:25px;">
                                                  <i class="fas fa-minus-circle"></i>
                                             </a>
                                        </div>
                                   </div>
                              </div>
                         </template>
                              <div class="col-12 text-center">
                                   <br><hr><br>
                                   <button class="btn btn-md btn-success" @click="load_more_teams()">
                                        Carregar mais times
                                   </button>
                              </div>
                         </div>
                         <!-- <div class="text-center" v-else>
                              <h3>Carregando times...</h3>
                              <img src="/img/loader.gif" style="width:300px;" />
                         </div> -->
                         <loading v-else :loading="true"></loading>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import customHeader from '@/components/header.vue';
     import login from '@/components/login.vue';
     import loading from '@/components/Loading.vue';
     import util from '@/mixin/util';
     // import clubs_json from '@/json/clubes.json';

     export default {
          name: 'Avaliacao',
          components:{
               customHeader, login, loading
          },
          mixins: [util],
          data(){
               return{
               
                    //Dados
                    user:null,
                    athletes:null,
                    userTeam:null,
                    guruTeams:null,
                    loadedTeams:[],
                    market_status:null,
                    approvals:[],
                    clubs:[],
                    athletes_stats:[],

                    comments_on_page:[],
                    answers_on_page:[],
                    votes_on_page:{
                         '1':[],
                         '0':[]
                    },

                    //Inputs
                    answer_section:-1,
                    comment_text:'',
                    answer_text:'',

                    //Visualization
                    current_page:1,
                    load_all_comments:-1,
                    load_all_answers:-1,

                    //Helpers
                    cookies:null,
                    team_data:null,
                    comment_data:null,
                    like:'',
                    deslike:'',
                    laterais:0,

                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile

                    index:null,
               }
          },
          created(){
               //Verificação mobile
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;
               
               var vm = this;

               //market_status
               this.$http
               .get(this.$API+'mercado-status')
               .then(response => {
                    vm.market_status = response.data
               })
               .catch(function (error) {
                    console.log('[error #B]:')
                    console.log(error);
               });  

               //Usuário Autenticado
               vm.user = vm.get_usuario_logado();
               
               //Carragando Atletas
               this.$http
               .get(this.$API+'/meutime/atletas/')
               .then(response => {
                    vm.athletes = response.data
               })
               .catch(function (error) {
                    console.log('[error #A]:')
                    console.log(error);
               });  

               //Carregando Stats dos Atletas
               this.$http
               .get(this.$API+'escalar/rodadas_anteriores/10')
               .then(response => {
                    vm.athletes_stats = response.data.jogadores;
                    vm.clubs = response.data.clubes
               })
               .catch(function (error) {
                    console.log('[error #0]:')
                    console.log(error);
               });  
          },
          watch:{
               //Quando o status do marketing for carregado via API do CARTOLA, execute o método load_data
               market_status: function(newValue){
                    if(newValue){
                         this.load_data();
                    }
               },
          },
          methods:{
               //Recarregue dados necessários
               update_data(uuid){
                    var vm = this;
                    $.each(vm.guruTeams,function(index,value){
                         if(value.uuid == uuid){
                              this.$http
                              .get(this.$API+'avaliacao/'+vm.market_status.rodada_atual+'/'+uuid)
                              .then(response => {
                                   vm.guruTeams[index].comentarios = response.data.comentarios;
                              })
                              .catch(function (error) {
                                   console.log('[error #1]:')
                                   console.log(error);
                              });  
                         }
                    });
               },

               //Carregue dados necessários
               load_data(){
                    var vm = this;

                    //Carregando aprovações
                    this.$http
                    .get(this.$API+'/aprovacao/')
                    .then(response => {
                         vm.approvals = response.data
                    })
                    .catch(function (error) {
                         console.log('[error #2]:')
                         console.log(error);
                    });  

                    //Caso o usuário esteja logado, carregue o time dele
                    this.url_times = ''
                    if(this.user){
                         this.$http
                         .get(this.$API+'avaliacao/'+vm.market_status.rodada_atual+'/'+vm.user.uuid)
                         .then(response => {
                              if (response && response.data) {
                                   vm.userTeam = response.data
                              }
                         })
                         .catch(function (error) {
                              console.log('[error #3]:')
                              console.log(error);
                         });  
                         this.url_times = this.$API+'avaliacao/times/'+vm.market_status.rodada_atual+'/'+vm.user.grupo_id
                    } else {
                         this.url_times = this.$API+'avaliacao/times/'+vm.market_status.rodada_atual+'/0'
                    }

                    //Carregando os times enviados para avaliação na rodada atual
                    this.$http
                    .get(this.url_times)
                    .then(response => {
                         vm.guruTeams = response.data;
                         //Evitando carregamento de times já carregados
                         $.each(response.data,function(index,value){
                              if(!vm.loadedTeams.includes(value.uuid)){
                                   vm.loadedTeams.push(value);
                              } 
                         });
                    })
                    .catch(function (error) {
                         console.log('[error #4]:')
                         console.log(error);
                    });  
               },

               //Cria o tooltip no hover de algum atleta
               get_tooltip_html(athlete_id){
                    if(this.athletes[athlete_id]){
                         var html ='<b>Nome:</b> '+this.athletes[athlete_id].apelido+' <br>';
                         
                         if (this.athletes_stats[athlete_id]) {
                              if (this.athletes_stats[athlete_id].pr) {
                                   html += '<b>Preço:</b> '+this.athletes_stats[athlete_id].pr+' <br>';
                              } else {
                                   html += '<b>Preço:</b> Indisponível <br>';
                              }

                              if (this.athletes_stats[athlete_id].cb && this.clubs[this.athletes_stats[athlete_id].cb] && this.clubs[this.athletes_stats[athlete_id].cb].nm) {
                                   html += '<b>Clube:</b> '+this.clubs[this.athletes_stats[athlete_id].cb].nm+' <br>';
                              } else {
                                   html += '<b>Clube:</b> Indisponível <br>';
                              }

                              if (this.athletes_stats[athlete_id].pm) {
                                   html += '<b>Pont. Mínima:</b> '+this.athletes_stats[athlete_id].pm+' <br>';
                              } else {
                                   html += '<b>Pont. Mínima:</b> Indisponível <br>';
                              }
                         } else {
                              html +=   '<b>Preço:</b> Indisponível <br>'+
                                        '<b>Clube:</b> Indisponível <br>'+
                                        '<b>Pont. Mínima:</b> Indisponível <br>'
                         }

                         var aprovacao = 0;

                         if(this.approvals){
                              aprovacao = this.approvals[athlete_id]!=undefined ? this.approvals[athlete_id].apv : 50;
                              if (aprovacao <= 0) {
                                   aprovacao = 50;
                              }

                         } else {
                              aprovacao = 50;
                         }

                         html = html + '<b>Aprovação:</b> '+aprovacao+' % <br>';

                         return html;
                    }
               },
               //Carregando mais times
               load_more_teams(){
                    var vm = this;

                    vm.current_page += 1;
                    
                    
                    var url = null;
                    if (vm.user) {
                      url = this.$API+'avaliacao/times/'+vm.market_status.rodada_atual+'/'+vm.user.grupo_id+'?page='+vm.current_page
                    } else {
                      url = this.$API+'avaliacao/times/'+vm.market_status.rodada_atual+'/0'+'?page='+vm.current_page
                    }

                    this.$http
                    .get(url)
                    .then(response => {
                         $.each(response.data,function(index,value){
                              if(!vm.loadedTeams.includes(value.uuid)){
                                   vm.loadedTeams.push(value.uuid);
                                   vm.guruTeams.push(value);
                                   vm.adjust_back_team_positioning(vm.guruTeams.length - 1);
                              }
                         });
                    })
                    .catch(function (error) {
                         console.log('[error #5]:')
                         console.log(error);
                    });  
               },
               //API - Adiconar / remover
                    //Adiciona um novo comentário
                    add_new_comment(user_id){
                         var vm = this;
                         vm.comment_text = $("#comment_"+user_id).val();

                         if(this.user != null){
                              var data = {
                                   'uuid' : vm.user.uuid,
                                   'comentario' : vm.comment_text
                              };                              
                              
                              this.$http
                              .post(this.$API+'avaliacao/'+vm.market_status.rodada_atual+'/'+user_id+'/comentario', data)
                              .then(response => {
                                   // eslint-disable-next-line no-undef
                                   Swal.fire('',response.data.mensagem,'success');
                                   
                                   vm.comments_on_page.push({
                                        'id' : response.data.id,
                                        'guru_team_uuid' : user_id,
                                        'uuid' : vm.user.uuid,
                                        'comentario' : vm.comment_text
                                   });

                                   vm.comment_text = '';
                                   $("#comment_"+user_id).val('');
                              })
                              .catch(function (error) {
                                   console.log('[error #6]:')
                                   console.log(error);
                              });  

                         }else{
                              // eslint-disable-next-line no-undef
                              Swal.fire('Você não está logado.','Você precisa logar poder comentar!','warning');
                         }
                    },
                    //Remove um comentário
                    remove_comment(user_id,comment_id){
                         var vm = this;
                         // eslint-disable-next-line no-undef
                         Swal.fire({
                              title: 'Deletar comentário.',
                              text: "Você tem certeza que deseja deletar esse comentário?",
                              type: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#dc3545',
                              cancelButtonColor: '#28a745',
                              confirmButtonText: 'Sim',
                              cancelButtonText: 'Não'
                         }).then((result) => {
                              if (result.value) {
                                   this.$http
                                   .post(this.$API+'avaliacao/comentario/'+comment_id+'/delete', {})
                                   .then(() => {
                                        // eslint-disable-next-line no-undef
                                        Swal.fire('Deletado.',
                                                  'Seu comentário foi deletado!',
                                                  'success');

                                        var i = 0;
                                        $.each(vm.comments_on_page, function(index,value){
                                             if(value.id == comment_id){
                                                  i = index;
                                                  return false;
                                             }
                                        });

                                        vm.comments_on_page.splice(i,1);
                                   })
                                   .catch(function (error) {
                                        console.log('[error #7]:')
                                        console.log(error);
                                   });  

                              }
                         })
                    },
                    //Adiciona uma nova resposta
                    add_new_answer(user_id,comment_id){
                         var vm = this;
                         this.answer_text = $("#answer_"+user_id+"_"+comment_id).val();
                         
                         if(this.user != null){
                              var data = {
                                   'uuid' : vm.user.uuid,
                                   'comentario' : vm.answer_text
                              };

                              this.$http
                              .post(this.$API+'avaliacao/'+vm.market_status.rodada_atual+'/'+user_id+'/comentario/'+comment_id+'/resposta', data)
                              .then(response => {
                                   vm.answers_on_page.push({
                                        'id' : response.data.id,
                                        'guru_team_uuid' : user_id,
                                        'guru_team_comment_id' : comment_id,
                                        'uuid' : vm.user.uuid,
                                        'comentario' : vm.answer_text
                                   });

                                   vm.answer_text = '';
                                   $("#answer_"+user_id+"_"+comment_id).val('');
                              })
                              .catch(function (error) {
                                   console.log('[error #8]:')
                                   console.log(error);
                              });  
                         }else{
                              // eslint-disable-next-line no-undef
                              Swal.fire('Você não está logado.','Você precisa logar responder comentários','warning');
                         }
                    },
                    //Vota em um comentário
                    vote_on_comment(user_id,comment_id,action){
                         var vm = this;
                         if(this.user != null){

                              var reversed_action = 0;
                              if(action == 0)
                                   reversed_action = 1;

                              //Visibility of vote
                              if(JSON.stringify(vm.votes_on_page[reversed_action]).includes(comment_id)){

                                   var i = 0;
                                   $.each(vm.votes_on_page[reversed_action], function(index,value){
                                        if(value == comment_id){
                                             i = index;
                                             return false;
                                        }
                                   });

                                   vm.votes_on_page[reversed_action].splice(i,1);

                                   vm.votes_on_page[action].push(comment_id);
                              }else if(!JSON.stringify(vm.votes_on_page[action]).includes(comment_id)){
                                   vm.votes_on_page[action].push(comment_id);
                              }else{
                                   var idx = 0;
                                   $.each(vm.votes_on_page[action], function(index,value){
                                        if(value == comment_id){
                                             idx = index;
                                             return false;
                                        }
                                   });

                                   vm.votes_on_page[action].splice(idx,1);
                              }

                              //Working with API and Data
                              var data = {
                                   'rating' : action,
                              };
                              
                              this.$http
                              .post(this.$API+'avaliacao/'+vm.market_status.rodada_atual+'/'+user_id+'/comentario/'+comment_id+'/'+vm.user.uuid+'/'+action, data)
                              .catch(function (error) {
                                   console.log('[error #9]:')
                                   console.log(error);
                              });  


                         }else{
                              // eslint-disable-next-line no-undef
                              Swal.fire('Você não está logado.','Você precisa logar para votar em comentários','warning');
                         }
                    },
                    //Vota em uma resposta
                    vote_on_answer(user_id,comment_id,answer_id,action){
                         var vm = this;
                         if(this.user != null){
                                   var data = {
                                        'rating' : action,
                                   };

                                   var reversed_action = 0;
                                   if(action == 0)
                                        reversed_action = 1;

                                   //Visibility of vote
                                   if(JSON.stringify(vm.votes_on_page[reversed_action]).includes(comment_id+'_'+answer_id)){

                                        var i = 0;
                                        $.each(vm.votes_on_page[reversed_action], function(index,value){
                                             if(value == comment_id+'_'+answer_id){
                                                  i = index;
                                                  return false;
                                             }
                                        });

                                        vm.votes_on_page[reversed_action].splice(i,1);

                                        vm.votes_on_page[action].push(comment_id+'_'+answer_id);
                                   }else if(!JSON.stringify(vm.votes_on_page[action]).includes(comment_id+'_'+answer_id)){
                                        vm.votes_on_page[action].push(comment_id+'_'+answer_id);
                                   }else{
                                        // var i = 0;
                                        $.each(vm.votes_on_page[action], function(index,value){
                                             if(value == comment_id+'_'+answer_id){
                                                  i = index;
                                                  return false;
                                             }
                                        });

                                        vm.votes_on_page[action].splice(i,1);
                                   }
                                   
                                   this.$http
                                   .post(this.$API+'avaliacao/'+vm.market_status.rodada_atual+'/'+user_id+'/comentario/'+comment_id+'/resposta/'+answer_id+'/'+vm.user.uuid+'/'+action,  data)
                                   .catch(function (error) {
                                        console.log('[error #10]:')
                                        console.log(error);
                                   });  
                              }else{
                                   // eslint-disable-next-line no-undef
                                   Swal.fire('Você não está logado.','Você precisa logar para votar em comentários','warning');
                              }
                    },
               //Ajustes
                    //Retorna a data no formato DD/MM/YYYY
                    get_date_now(){
                         var today = new Date();
                         var dd = today.getDate();
                         var mm = today.getMonth() + 1;
                         var hh = today.getHours();
                         var min = today.getMinutes();
                         var ss = today.getSeconds();


                         var yyyy = today.getFullYear();
                         if (dd < 10) {
                              dd = '0' + dd;
                         } 
                         if (mm < 10) {
                              mm = '0' + mm;
                         }

                         return (yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + ss);
                    },
                    votes_map(i){
                         if(i == -1)
                              return 0;
                         else
                              return 1;
                    },
                    format_date(date){
                         var array_date = date.split('-');
                         array_date[2] = array_date[2].split(' ')[0];
                         var string_date = array_date[2] +'/'+ array_date[1] +'/'+ array_date[0]; 
                         return string_date;
                    },
                    //Retorna a foto do atleta baseado no tamanho
                    athlete_photo(src,size){
                         if(src)
                              return src.replace('FORMATO',size+'x'+size);
                         else
                              return 'https://s.glbimg.com/es/sde/f/2019/02/15/7f94dd0a492218c6dcc1fbc02473637c_220x220.png';
                    },
                    //Habilita o funcionamento do tooltip
                    enable_tooltip(){
                         $('[data-toggle="tooltip"]').tooltip();
                    },
                    //Ajusta a posição de zagueiros e laterais
                    adjust_back_team_positioning(i){
                         var vm = this;
                         if(this.athletes){
                              var obj = vm.guruTeams[i].atletas;
                              var l_counter = 1;

                              $.each(obj,function(index,value){

                                   if(vm.athletes[value] && vm.athletes[value].idPosicao == 2){
                                        l_counter += 1;
                                        Object.defineProperty(obj, 10+l_counter, Object.getOwnPropertyDescriptor(obj, index));
                                        delete obj[index];
                                   }
                              });

                              vm.guruTeams[i].atletas = obj;
                         }
                    }
          },
     }
</script>

<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     @import '@/assets/scss/general.scss';
     @import '@/assets/scss/field.scss';
     
     .capitao {
          background-color: $laranja;
     }
     .warning{
          color: $light_text_color;
     }
     h1,h2,h3{
          // color: $light_text_color;
          color: $laranja;
          text-transform: uppercase;
     }
     #field{
          background-color:transparent;
     }
     #meutime p{
          color: $cinza_escuro;
     }
     #comments{
          padding:25px;
          // background-color:#2f354f;
          margin: 20px auto;
          // color:$light_text_color;
          color: #4E4E4E;
          a{
               font-size:$table_header_font_size;
               color: $laranja;
               font-weight: bolder;
               text-transform: uppercase;
               text-decoration: none;
               letter-spacing: 2px;
               transition: all .3s ease;
          }
          a:hover{
               cursor:pointer;
               // color:  $light_text_color;
               letter-spacing: 3px;
          }
          i{
               transition: all .3s ease;
          }
          i:hover{
               color: $body_background_color;
               transform: translateY(-5px);
          }
          .comment{
               margin-top: 30px;
          }
          #answers{
               margin-left: 2.5%;
               border-left: 1px solid $ul_li_active;
               .answer{
                    margin-top: 15px;
                    margin-left: 15px;
                    padding-left: 15px;
               }
          }
          textarea, .btn{
               border-radius:0px;
          }
          textarea{
               border-top-left-radius: 5px;
               border-bottom-left-radius: 5px;
          }
          .btn-success{
               background-color: $laranja;
               border-top-right-radius: 5px;
               border-bottom-right-radius: 5px;
               width:100%;
          }
          .btn-danger{
               border-radius:5px;
          }
          .btn:hover{
               background-color:$light_text_color;
               color: $laranja;
          }

          
     }
     .warning_updated_team small{
          background-color: $verde;
          padding: 3px 10px;
          border-radius: 5px;
          // font-weight:bolder;
          color: white;
     }

     #coach{
          bottom: 40px !important;
          left: 100px !important;
     }
     @media only screen and (max-width: 1000px) {
          #coach{
               bottom: 50px !important;
               left: 30px !important;
          }
     }

     .nav-tabs {
          border-bottom: 2px solid $laranja;
     }

     .time {
          // background-color: #0b0b0b;
          // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          // background: radial-gradient(circle, rgba(217,217,217,1) 0%, rgba(245,245,245,1) 100%);
          // background: radial-gradient(circle, rgba(145,130,162,1) 0%, rgba(233,233,233,1) 51%, rgba(245,245,245,1) 100%);
          background: radial-gradient(circle, $verde 10%,  white 80%);
          margin: 20px 0px;
     }

     .time h2 {
          // background-color: #0b0b0b;
          // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          // background: url("http://localhost/banner-laranja-sm.gif");
          //  -webkit-background-size: auto;
          // -moz-background-size: auto;
          // -o-background-size: auto;
          // background-size: auto;
          margin: 30px 0px;
          padding-top:5px;
          padding-bottom:5px;
          text-transform: uppercase;
          // border-top: 3px solid $laranja;
          // border-bottom: 3px solid $laranja;
          color: white;
          text-shadow: 0px 3px 2px rgba(0,0,0,.95);
     }
</style>