<template>
<div>
  <div class="text-center">
    <h4>Para concorrer aos nossos prêmios, basta você fazer login no aplicativo do Guru do Cartola usando a conta que assinou o Time Guru, nosso plano de Dicas.</h4>
  </div>
  <div id="section_comments" class="mb-4 mt-4">
      <div class="card-deck text-center">
            <div class="card mb-6 box-shadow">
                <div class="card-header">
                      <div data-v-f52fcba4="" id="BOX01" class="bg-grass-light bg-verde-full box-header d-flex flex-row w-100 box-shadow"> 
                          <div class="d-flex flex-row w-100 justify-content-center">
                              <span class="card-title">OS 3 MELHORES DO MÊS RECEBEM UMA CAMISA OFICIAL</span>
                          </div>
                      </div>
                </div>
                <div class="card-body d-flex flex-column ">
                  <h4>
                    Os 3 maiores pontuadores do mês no Time Guru vão receber em casa a camisa oficial do clube do coração!
                  </h4>
                  <br />
                  <h6>Serão 3 camisas por mês, de abril até dezembro!</h6>
                  <p></p>
                  <div>
                    <img src="https://images2.imgbox.com/00/e9/ABqFQb2P_o.png" class="img img-fluid"/>
                  </div>
                  <br />
                  <p style="font-size: .8em;">
                    Premiação exclusiva para o Time Guru
                  </p>
                </div>
            </div>
            <div class="card mb-6 box-shadow">
                <div class="card-header">
                    <div data-v-f52fcba4="" id="BOX02" class="bg-grass-light bg-verde-full box-header d-flex flex-row w-100 box-shadow">
                        <div class="d-flex flex-row w-100 justify-content-center">
                                <span class="card-title" >CENZÃO POR RODADA <br /> PARA O MELHOR  <br/>DO TIME GURU</span>
                        </div>
                    </div>
                </div>
                <div class="card-body d-flex flex-column justify-content-center">
                  <h4>O maior pontuador da rodada no Time Guru vai receber um pix de <span style="color:#ff9500;font-weight:bold;">R$100,00</span>!</h4> 
                  <br />
                  <p></p><p></p>
                  <h6>Todas as rodadas tem PIX de R$ 100,00 para o Time Guru!</h6>
                  <div>
                    <img src="https://images2.imgbox.com/99/95/0T2qyBBj_o.png" class="img img-fluid" :style="mobile?'':'max-width:65%;'" />
                  </div>
                  <!-- <br /> -->
                  <p style="font-size: .8em;">
                    Premiação exclusiva para o Time Guru
                  </p>
                </div>
            </div>
            <!-- <div class="card mb-6 box-shadow">
                <div class="card-header">
                    <div data-v-f52fcba4="" id="BOX02" class="bg-grass-light bg-verde-full box-header d-flex flex-row w-100 box-shadow">
                        <div class="d-flex flex-row w-100 justify-content-center">
                                <span class="card-title">R$2.000 no gurubets <br/> TODO MÊS!</span>
                        </div>
                    </div>
                </div>
                <div class="card-body d-flex flex-column">
                  <p style="font-size:1.3em; font-weight: bold; ">COPA RESENHA GURUBETS</p>
                  <br />
                  <img
                    src="https://images2.imgbox.com/6b/c0/y2dbRTmd_o.png"
                    class="img img-fluid"
                    style="margin-top:-30px; margin-bottom: -20px;"
                  />
                  <p style="font-size: .9em;">
                    Cada grupo de resenha assinante do Guru tem um time. Em cada mês, o grupo que ficar em 1° lugar vai ganhar R$2.000 em bônus para apostar no gurubets.
                  </p>
                  <p style="font-size: .9em;">
                    No 1° mês do Brasileirão, o grupo vencedor será o que mais ganhar cartoletas. A partir do 2° mês o vencedor será pela pontuação.
                  </p>
                  <p style="font-size: .8em;">
                    Premiação exclusiva para o Time Guru
                  </p>
                </div>
            </div> -->
      </div>
  </div>


  <div class="card-deck text-center mb-4 mt-4">
    <div class="card mb-12 box-shadow">
      <div class="card-header">
          <div data-v-f52fcba4="" id="BOX03" class="bg-grass-light bg-verde-full box-header d-flex flex-row w-100 box-shadow">
              <div class="d-flex flex-row w-100 justify-content-center">
                  <span class="card-title">COMO GANHAR OS PRÊMIOS?</span>
              </div>
          </div>
           
      </div>
      <div class="card-body d-flex flex-column text-justify">
          <p>
            <strong>1)</strong>Para ganhar a camisa oficial você deve estar entre os 3 maiores pontuadores da liga mensal do Time Guru. 
          </p>
          <p>
           <strong>2)</strong> Para ganhar o PIX de R$ 100,00 você deve ser o maior pontuador de uma rodada entre os participantes da liga do Time Guru.
          </p>
          <p>
           <strong>3)</strong> Para participar da liga basta você logar no aplicativo Guru do Cartola pelo time que você fez sua assinatura do Time Guru e já estará concorrendo. Não é necessário entrar em uma liga do Cartola.
          </p>
          <!-- <p>
           <strong>3)</strong> Para ganhar o super bônus no gurubets, você precisa estar em um grupo de resenha. 
            <br/> Após o time do grupo ser o 1° do mês, o prêmio será dividido entre todos os participantes interessados.
          </p> -->
      </div>
    </div>
  </div>

  <div data-v-f52fcba4="" id="CTA01" class="bg-grass-dark bg-verde-full d-flex flex-row w-100 box-shadow">
      <div class="d-flex flex-row w-100 justify-content-center">
          <a href="http://gurudocartola.com/timeguru" target="_blank" id="CTA01-btn" type="button" class="btn-cta btn mb-2"> 
            Quero participar do Guru em 2024! 
          </a>
      </div>
  </div>
    

  <div class="card-deck text-center mb-4 mt-4">
    <div class="card mb-12 box-shadow">
      <div class="card-header">
          <div data-v-f52fcba4="" id="BOX04" class="bg-grass-light bg-verde-full box-header d-flex flex-row w-100 box-shadow">
              <div class="d-flex flex-row w-100 justify-content-center">
                  <span class="card-title">MAIS INFORMAÇÕES SOBRE A PREMIAÇÃO</span>
              </div>
          </div>
           
      </div>
      <div class="card-body d-flex flex-column text">
          <p>
            <b>Não é necessário entrar em nossa liga no Cartola.</b> As pontuações são registradas pelo aplicativo do Guru do Cartola para facilitar a sua participação e não ocupar espaço de liga em sua conta no game.
          </p>
          <p>
            Você pode acompanhar os rankings, da rodada e do mensal, pelo <b>aplicativo do Guru do Cartola</b>, disponível em Android ou iOS.
          </p>
          <p>Cada time (que corresponde a uma assinatura do Time Guru) pode ganhar no máximo uma vez cada prêmio na mesma temporada. Caso aconteça do mesmo time vencer duas vezes a premiação por rodada ou a premiação mensal, o time seguinte será o premiado.</p>
          <p>Caso o mês só tenha uma rodada, cada um dos 3 primeiros colocados poderão escolher o prêmio de sua preferência entre as 2 opções disponíveis.</p>
          <p>A sua pontuação é contabilizada pelo time registrado no e-mail que você comprou o Time Guru.</p>

      </div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
  @import '@/assets/sass/variables.scss';
  @import '@/assets/scss/general.scss';

  .box-header {
    margin-top:0px;
    margin-bottom:0px;
  }

.card-header {
  padding: 0px;
}

.card-title {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.6em;
    margin-bottom: 0px;
}

.box-shadow {
  box-shadow: 0 0 20px 5px rgba(91,91,91,.37);
}

.btn-cta {
  border-radius:10px;
  color: white;
  background-color: #F47607;
  border-color: white;
  box-shadow: 0 0 20px 5px rgba(91,91,91,.37);
  margin-top: 8px;
}

</style>
