var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"mb-4 mt-4",attrs:{"id":"section_comments"}},[_c('div',{staticClass:"card-deck text-center"},[_vm._m(1),_c('div',{staticClass:"card mb-6 box-shadow"},[_vm._m(2),_c('div',{staticClass:"card-body d-flex flex-column justify-content-center"},[_vm._m(3),_c('br'),_c('p'),_c('p'),_c('h6',[_vm._v("Todas as rodadas tem PIX de R$ 100,00 para o Time Guru!")]),_c('div',[_c('img',{staticClass:"img img-fluid",style:(_vm.mobile?'':'max-width:65%;'),attrs:{"src":"https://images2.imgbox.com/99/95/0T2qyBBj_o.png"}})]),_c('p',{staticStyle:{"font-size":".8em"}},[_vm._v(" Premiação exclusiva para o Time Guru ")])])])])]),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h4',[_vm._v("Para concorrer aos nossos prêmios, basta você fazer login no aplicativo do Guru do Cartola usando a conta que assinou o Time Guru, nosso plano de Dicas.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card mb-6 box-shadow"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"bg-grass-light bg-verde-full box-header d-flex flex-row w-100 box-shadow",attrs:{"data-v-f52fcba4":"","id":"BOX01"}},[_c('div',{staticClass:"d-flex flex-row w-100 justify-content-center"},[_c('span',{staticClass:"card-title"},[_vm._v("OS 3 MELHORES DO MÊS RECEBEM UMA CAMISA OFICIAL")])])])]),_c('div',{staticClass:"card-body d-flex flex-column"},[_c('h4',[_vm._v(" Os 3 maiores pontuadores do mês no Time Guru vão receber em casa a camisa oficial do clube do coração! ")]),_c('br'),_c('h6',[_vm._v("Serão 3 camisas por mês, de abril até dezembro!")]),_c('p'),_c('div',[_c('img',{staticClass:"img img-fluid",attrs:{"src":"https://images2.imgbox.com/00/e9/ABqFQb2P_o.png"}})]),_c('br'),_c('p',{staticStyle:{"font-size":".8em"}},[_vm._v(" Premiação exclusiva para o Time Guru ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"bg-grass-light bg-verde-full box-header d-flex flex-row w-100 box-shadow",attrs:{"data-v-f52fcba4":"","id":"BOX02"}},[_c('div',{staticClass:"d-flex flex-row w-100 justify-content-center"},[_c('span',{staticClass:"card-title"},[_vm._v("CENZÃO POR RODADA "),_c('br'),_vm._v(" PARA O MELHOR "),_c('br'),_vm._v("DO TIME GURU")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_vm._v("O maior pontuador da rodada no Time Guru vai receber um pix de "),_c('span',{staticStyle:{"color":"#ff9500","font-weight":"bold"}},[_vm._v("R$100,00")]),_vm._v("!")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-deck text-center mb-4 mt-4"},[_c('div',{staticClass:"card mb-12 box-shadow"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"bg-grass-light bg-verde-full box-header d-flex flex-row w-100 box-shadow",attrs:{"data-v-f52fcba4":"","id":"BOX03"}},[_c('div',{staticClass:"d-flex flex-row w-100 justify-content-center"},[_c('span',{staticClass:"card-title"},[_vm._v("COMO GANHAR OS PRÊMIOS?")])])])]),_c('div',{staticClass:"card-body d-flex flex-column text-justify"},[_c('p',[_c('strong',[_vm._v("1)")]),_vm._v("Para ganhar a camisa oficial você deve estar entre os 3 maiores pontuadores da liga mensal do Time Guru. ")]),_c('p',[_c('strong',[_vm._v("2)")]),_vm._v(" Para ganhar o PIX de R$ 100,00 você deve ser o maior pontuador de uma rodada entre os participantes da liga do Time Guru. ")]),_c('p',[_c('strong',[_vm._v("3)")]),_vm._v(" Para participar da liga basta você logar no aplicativo Guru do Cartola pelo time que você fez sua assinatura do Time Guru e já estará concorrendo. Não é necessário entrar em uma liga do Cartola. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-grass-dark bg-verde-full d-flex flex-row w-100 box-shadow",attrs:{"data-v-f52fcba4":"","id":"CTA01"}},[_c('div',{staticClass:"d-flex flex-row w-100 justify-content-center"},[_c('a',{staticClass:"btn-cta btn mb-2",attrs:{"href":"http://gurudocartola.com/timeguru","target":"_blank","id":"CTA01-btn","type":"button"}},[_vm._v(" Quero participar do Guru em 2024! ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-deck text-center mb-4 mt-4"},[_c('div',{staticClass:"card mb-12 box-shadow"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"bg-grass-light bg-verde-full box-header d-flex flex-row w-100 box-shadow",attrs:{"data-v-f52fcba4":"","id":"BOX04"}},[_c('div',{staticClass:"d-flex flex-row w-100 justify-content-center"},[_c('span',{staticClass:"card-title"},[_vm._v("MAIS INFORMAÇÕES SOBRE A PREMIAÇÃO")])])])]),_c('div',{staticClass:"card-body d-flex flex-column text"},[_c('p',[_c('b',[_vm._v("Não é necessário entrar em nossa liga no Cartola.")]),_vm._v(" As pontuações são registradas pelo aplicativo do Guru do Cartola para facilitar a sua participação e não ocupar espaço de liga em sua conta no game. ")]),_c('p',[_vm._v(" Você pode acompanhar os rankings, da rodada e do mensal, pelo "),_c('b',[_vm._v("aplicativo do Guru do Cartola")]),_vm._v(", disponível em Android ou iOS. ")]),_c('p',[_vm._v("Cada time (que corresponde a uma assinatura do Time Guru) pode ganhar no máximo uma vez cada prêmio na mesma temporada. Caso aconteça do mesmo time vencer duas vezes a premiação por rodada ou a premiação mensal, o time seguinte será o premiado.")]),_c('p',[_vm._v("Caso o mês só tenha uma rodada, cada um dos 3 primeiros colocados poderão escolher o prêmio de sua preferência entre as 2 opções disponíveis.")]),_c('p',[_vm._v("A sua pontuação é contabilizada pelo time registrado no e-mail que você comprou o Time Guru.")])])])])
}]

export { render, staticRenderFns }