<template>
  <footer class="bg-grass bg-verde guru-footer">
    <div class="container">
      <div class="row">
        <div class="text-center foot-scr" :class="[mobile ? 'col-12 foot-mob' : 'col-4 ']">
          <h5>REDES SOCIAIS</h5>
          <div class="row item-menu-corpo">
            <div :class="[mobile ? 'col-12 small-font' : 'col-6']">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCVNTtGWmiOlYo3GxWd1lyzw"
                id="youtube"
                ><i class="fab fa-youtube"></i> Youtube</a
              >
            </div>
            <div :class="[mobile ? 'col-12 small-font' : 'col-6']">
              <a
                target="_blank"
                href="https://instagram.com/gurudocartola/"
                id="instagram"
                ><i class="fab fa-instagram"></i> Instagram</a
              >
            </div>
            <div :class="[mobile ? 'col-12 small-font' : 'col-6']">
              <a
                target="_blank"
                href="https://twitter.com/Gurudocartola_"
                id="twitter"
                ><i class="fab fa-twitter"></i> Twitter</a
              >
            </div>
            <div :class="[mobile ? 'col-12 small-font' : 'col-6']">
              <a
                target="_blank"
                href="https://www.facebook.com/gurucartola/"
                id="facebook"
                ><i class="fab fa-facebook"></i> Facebook</a
              >
            </div>
          </div>
        </div>

        <hr />

        <div class="text-center foot-scr" :class="[mobile ? 'col-12 foot-mob' : 'col-4 ']">
          <h5>LINKS</h5>
          <div class="row item-menu-corpo">
            <div class="col-12" :class="[mobile ? 'small-font' : '']">
              <a :href="$router.resolve({ name: 'Faq' }).href">
                Perguntas frequentes
              </a>
            </div>

            <div class="col-12" :class="[mobile ? 'small-font' : '']">
              <a :href="$router.resolve({ name: 'Privacidade' }).href">
                Política de privacidade
              </a>
            </div>

            <div class="col-12" :class="[mobile ? 'small-font' : '']">
              <a :href="$router.resolve({ name: 'Termos' }).href">
                Termos de uso
              </a>
            </div>
          </div>
        </div>

        <div class="text-center foot-scr" :class="[mobile ? 'col-12 foot-mob' : 'col-4 ']">
          <h5>CONTATO</h5>
          <div class="row item-menu-corpo">
            <div class="col-12" :class="[mobile ? 'small-font' : '']">
              <p>contato@gurudocartola.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/sass/variables.scss';

.guru-footer {
  border-top: 3px solid $laranja;
  // background:url("/img/soccer_horizontal_field_sm.jpg") no-repeat center center fixed;
  background: url("http://localhost/banner-verde-sm.gif");
  // background-size: cover;
}
.small-font {
  font-size: 0.9em;
}
.guru-footer-hr {
  margin-bottom: 40px;
  margin-top: 40px;
  color: white;
}
.foot-mob {
    margin-bottom: 1em;
}
.foot-scr > h5 {
    padding-top: 1em;
    padding-bottom: 1em;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin: 15px;
    margin-bottom: 0px;
    font-weight: 200;
    letter-spacing: 5px;
    color: #f5f5f5;
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.95);
    background-color:  rgb(0, 0, 0, 0.5);
    border-top: 3px solid $laranja;
}
.item-menu-corpo {
  border-top: 3px solid $laranja;
  background-color: rgb(0, 0, 0, 0.3);
  padding: 1em;
  margin: 0 1em 0 1em;
  min-height: 107px;
}
</style>
