<template>
  <div class="container">
    <br/><br/><br/><br/><br/>
    <div class="row">
      <div class="col-12" >
        <div class="d-flex justify-content-center" > 
          <div class="flex-column">
            <h5 class="date-title">Escolha um endpoint:</h5>
              <select v-model="path">
                <option v-for="p in paths" :key="p" :value="p">{{ p }}</option>
              </select>
              <button @click="buscar()" style="margin-left:10px;">Buscar</button>
          </div>
        </div>
        <!-- {{path}} -->
        <hr >
      </div>
      <div class="col-12" id="chart">
        <canvas id="lineChart" width="100%" height__="80%" stlye="min-height:1024px;"></canvas>
      </div>
      <!-- <div class="col-12" v-if="data">
        <hr >
        <table class="table endpoints">
          <tr>
              <th>Endpoint</th>
              <th class="text-center">Chamadas</th>
          </tr>
          <tr v-for="c in contagens" :key="c.path">
              <td>{{c.path}}</td>
              <td class="text-center">{{c.count}}</td>
          </tr>
        </table>
      </div> -->
      
    </div>
  </div>
</template>

<script>
export default {
  name: "metrica-path",

  data() {
    return {
      mobileAt: 1000, //Mobile a partir de qual width?
      mobile: false, //Status inicial do mobile
      
      paths:[],
      path: '',
      data: null,

      contagens: [],
      // date: null,
      
      colors: ['#28a745', '#f47607', '#FF0000', "#000000"],
      
    };
  },
  methods:{
    buscar(){
      var vm = this
      this.$http
      .get(this.$PATH+'/metrica/path?name=' + encodeURIComponent(vm.path))
      .then(response => {
          vm.contagens = response.data.contagens
          vm.data = response.data
          // console.log(vm.data.datasets)
          
          var idx = 0
          for (var c of vm.data.datasets) {
            c['backgroundColor'] = vm.colors[idx]
            c['borderColor'] = vm.colors[idx]
            idx++
            if (idx>(vm.colors.length-1)) {
              idx=0
            }
          }

          //line
          $("canvas#lineChart").remove();
          $("div#chart").append('<canvas id="lineChart" width="100%" height__="80%" stlye="min-height:1024px;"></canvas>');
          var ctxL = document.getElementById("lineChart").getContext('2d');
          // eslint-disable-next-line 
          var myLineChart = new Chart(ctxL, {
            type: 'line',
            data: response.data,
          })
      })
      .catch(function (error) {
          console.log('[error #1]:')
          console.log(error);
      });
    },
  },
  mounted() {
      var vm = this
      this.$http
      .get(this.$PATH+'/metrica/paths')
      .then(response => {
          vm.paths = response.data
      })
      .catch(function (error) {
          console.log('[error #2]:')
          console.log(error);
      });  
      
  },
  created() {
    //Verificação mobile
    window.innerWidth < this.mobileAt
      ? (this.mobile = true)
      : (this.mobile = false);
  },

};
</script>

<style scoped lang="scss">
#login-popin-iframe {
  height: 800px;
}
.endpoint {
  font-size: 10px;
}
.endpoint-check {
  margin-right: 5px;
}
.endpoints td {
  font-size: 10px;
  padding: 1px;
}

.endpoints tr:hover {
  color: white;
  background-color: #4F4F4F;
}

.date-title {
  color: #4F4F4F;
  font-weight:  bold;
}
</style>
