<template>
  <div class="offside-div">
      <h3 class="manutencao text-center"> Mercado Fechado</h3>
      <img src="https://images2.imgbox.com/11/86/u7fpusqz_o.png" class="offside">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
@import '@/assets/sass/variables.scss';

.offside-div {
    text-align: center;
    margin-top: 10%;
}
.offside {
    margin-top: 2em;
    width: 50%;
}
.manutencao {
    color: $laranja;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 2px dotted $laranja;
    padding-bottom: .5em;
    font-size: 2em;
}
</style>