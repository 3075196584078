<template>
     <div>
          <customHeader title="Bem Vindo!" subtitle="" :mobile="mobile"></customHeader>
          <div class="container" style="min-height: 550px;">
               <div class="row">
                    <div class="card col-12 col-md-8 offset-md-2" style="width: 18rem;">
                         <div class="card-body">
                              <div class="col-12 text-center tag-pedido">
                                   <h5 class="card-title">Compra Aprovada</h5>
                                   <h6 class="card-subtitle mb-2">{{pedido.dataCompra}}</h6>
                              </div>
                              <div class="row mt-4">
                                   <div class="col-12">
                                        <h4 class="nome-produto">Time Guru com MK</h4>
                                        <span>{{  itemProduto.descricao }}</span>
                                   </div>
                              </div>
                              <div class="row mt-4">
                                   <div class="col-12 col-md-4 label-pedido" :class="{'lbl-mob':mobile, 'text-right':!mobile}">
                                        Código do Pedido:
                                   </div>
                                   <div class="col-12 col-md-8" :class="{'vlw-mob':mobile}">{{ pedido.orderId }}</div>
                              </div>
                              <div class="row">
                                   <div class="col-4 text-right label-pedido">Email:</div>
                                   <div class="col-8">{{ pedido.email }}</div>
                              </div>
                              <div class="row">
                                   <div class="col-4 text-right label-pedido">Nome<span v-if="!mobile"> Completo</span>:</div>
                                   <div class="col-8">{{ pedido.nome }}</div>
                              </div>
                              <div class="row">
                                   <div class="col-4 text-right label-pedido">CPF:</div>
                                   <div class="col-8">{{ pedido.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") }}</div>
                              </div>
                              <div class="row">
                                   <div class="col-12 col-md-4 label-pedido" :class="{'lbl-mob ass-mob':mobile, 'text-right':!mobile}">
                                        Código da Assinatura:
                                   </div>
                                   <div class="col-12 col-md-8" :class="{'vlw-mob':mobile}">{{ pedido.assinaturaId }}</div>
                              </div>
                              <div class="row">
                                   <div class="col-4 text-right label-pedido">Data<span v-if="!mobile"> da Assinatura</span>:</div>
                                   <div class="col-8">{{ pedido.dataCriacao }}</div>
                              </div>
                              <div class="row">
                                   <div class="col-4 text-right label-pedido">Valor:</div>
                                   <div class="col-8">R$ {{ (pedido.valor/100).toFixed(2) }}</div>
                              </div>
                              <div class="row">
                                   <div class="col-4 text-right label-pedido">Status:</div>
                                   <div class="col-8 pedido-status pedido-aprovado" v-if="pedido.status==='ATIVO'"><b class="ativo">Ativo</b></div>
                                   <div class="col-8 pedido-status pedido-autorizado" v-else-if="pedido.status==='INATIVO'"><b class="inativo">Inativo</b></div>
                                   <div class="col-8 pedido-status pedido-carregando" v-else><b>{{pedido.status}}</b></div>
                              </div>
                              <div class="row">
                                   <div class="col-12 mt-3 text-center">
                                        <a class="btn btn-primary btn-entrar" target="_blank" href="https://api.whatsapp.com/send?phone=5591984279012&text=Oi%2C%20agora%20fa%C3%A7o%20parte%20do%20Time%20Guru%20com%20o%20Meio%20Kilo%20e%20quero%20entrar%20nos%20grupos">
                                             ENTRAR NO GRUPO
                                        </a>
                                   </div>

                              </div>
                         </div>
                    </div> 
               </div>
           </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import util from '@/mixin/util';
     export default {
          name: 'Compra',
          props: ['id'],
          mixins: [util],
          components:{
               customHeader,
          },
          data(){
               return{
                    //Dados via API
                    data:null,
                    status: '',
                    pedido: {},
                    produto: '',
                    itemProduto: {},
                    dataCompra: null,
                    produtos: [],
                    pag : 'credito',
                    // path: 'http://192.168.1.240:8444/',
                    path: 'https://gurudocartola.com/app/',

                    API_URL: 'https://pb89hpsof3.execute-api.us-east-1.amazonaws.com/prod/',
                    
                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          mounted(){
               var vm = this;

               this.$http
               .get(vm.path+'getnet/pedido/'+vm.id)
               .then(response => {
                    var resposta = response.data;
                    if (resposta) {
                         vm.pedido = resposta
                    }
               })
               .catch(function (error) {
                    console.log(error);
               });

          },
          created(){
               //Verificação mobile
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

          },
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     @import '@/assets/scss/news.scss';
     .btn-entrar {
          background-color: #27a745;
          border: none;
     }
     .ass-mob {
          margin-top: 1em;
     }
     .vlw-mob {
          font-size: .8em;
          text-align: center;
          margin-bottom: 1em;
     }
     .lbl-mob {
          text-align: center;
     }
     .tag-pedido {
          background-color: $verde_claro;
          padding: 1em;
          color: white;
     }
     .tag-pedido h5 {
          font-weight: bold;
          font-size: 1.5em;
     }
     .label-pedido {
          font-weight: bold;
     }
     .pay-button-getnet {
          background-color: $verde_claro;
          border: none;
          color: white !important;
     }
     .pay-button-getnet:hover {
          background-color: $verde;
     }
     .nome-produto {
          text-align: center;
          font-weight: bold;
     }
     .info-cartao {
          margin-bottom: 1em;
     }
     .info-dados-cartao {
          font-size: 1.2em;
     }
     .info-campos-obrigatorios {
          font-size: .8em;
     }
     .pay-select {
          background-color: #CCC;
          color: white;
          padding: 8px;
          border-radius: 10px;
          
     }

     .pay-select.selected {
          background-color: $verde_claro;
     }
     .pay-select:hover {
          cursor: pointer;
          background-color: $verde;
     }
     .method-box, .method-box p {
          text-decoration: none;
          color: white;
     }
     .footer {
          padding: 1rem;
     }
     .ativo {
          color: $verde_claro;
          text-transform: uppercase;
          font-weight: bold;
     }
     .inativo {
          color: $laranja;
          text-transform: uppercase;
          font-weight: bold;
     }
  
</style>
