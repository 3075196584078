<template>
     <div>
          <customHeader title="Ajuda" subtitle="Tire suas dúvidas sobre o funcionamento do Guru!" :mobile="mobile"></customHeader>
          <div class="container">
               <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-12" style="margin: 0 auto">
                         <div class="p">
                              <h4 id="p_0">
                                   Comprei o Time Guru, e agora?
                              </h4>
                              <p id="r_0" class="r" style="display:none;">
                                   Você pagou por boleto?<br>
                                   Se sim, é só aguardar o boleto cair, assim que cair iremos te enviar um e-mail com as instruções. Geralmente os boletos caem no dia seguinte ao pagamento.
                                   <br><br>
                                   Você não pagou por boleto?<br>
                                   Sua compra será aprovada com mais rapidez. Assim que recebermos a sua compra em nosso sistema iremos te enviar um e-mail. 
                                   <br><br>
                                   <b>Confira a caixa de spam do e-mail que você informou na compra.</b>
                              </p>
                         </div>
                         <div class="p">
                              <h4 id="p_1">
                                   Por onde recebo as dicas?
                              </h4>
                              <p id="r_1" class="r" style="display:none;">
                                   O Guru do Cartola é a plataforma mais rica em estatísticas e informações para Cartola FC. Então, use o nosso site ou aplicativo para escalar o seu time no Cartola FC. Você poderá usar as melhores estatísticas.
                                   <br><br>
                                   Você também pode tirar ideia de algumas opções para o seu time vendo as nossas escalações. 
                                   <br><br>
                                   Você pode conferir em "Escalar" -> "Times do Guru" os nossos times exclusivos (Seleção do Guru e Time Valorização) e também o Time da Guruzada, para tirar ideias de jogadores para a sua escalação.
                                   <br><br>
                                   Além disso tudo, também temos nossos grupos de Whatsapp onde mandamos várias análises, considerando os confrontos, os jogadores e seus adversários.
                              </p>
                         </div>
                         <div class="p">
                              <h4 id="p_2">
                                   Como entrar nos grupos de dicas do Whatsapp?
                              </h4>
                              <p id="r_2" class="r" style="display:none;">
                                   <a href="#" data-toggle="modal" data-target="#videoModal" @click="video='YwKkZxtCw14'">Ver video</a>
                                   <br>
                                   Os nossos grupos de Whatsapp são exclusivos para quem faz parte do Time Guru (nosso programa de assinantes).
                                   <br><br>
                                   <ul style="list-style-type:none;">
                                        <li>
                                             1) Para entrar nos grupos, os assinantes devem fazer login no aplicativo do Guru do Cartola (usando o mesmo time que são assinantes);
                                        </li>
                                        <li>
                                             <!-- 2) Clique no menu onde está escrito “Olá, [seu nome]” e depois clique em Grupo do Whatsapp. OU entre no endereço <a href="https://gurudocartola.com/Whatsapp" target="_blank">https://gurudocartola.com/Whatsapp</a> após fazer login; -->
                                             2) Clique no menu onde está escrito “MAIS” e depois clique em "Grupos de Whatsapp". 
                                        </li>
                                        <li>
                                             3) Coloque e envie o seu número de telefone para entrar nos grupos exclusivos do Guru;
                                        </li>
                                        <li>
                                             4) Aguarde ser adicionado. Você será adicionado em até 48 horas.
                                        </li>
                                   </ul>
                                   <br>
                                   Você será adicionado em 2 grupos.
                                   <br><br>
                                   <b>Grupo ADM</b> - Nesse grupo apenas os administradores enviam mensagens. Aqui você receberá dicas, análises e notícias.
                                   <br>
                                   <b>Grupo Geral</b> - Nesse grupo você poderá enviar mensagens. O objetivo desse grupo é discutir sobre a próxima rodada e de ajuda mútua entre os membros do Time Guru. Porém, lembre que há regras. Assuntos fora de cartola e falta de respeito com os demais não serão tolerados e passíveis de banimento.
                              </p>
                         </div>
                         <div class="p">
                              <h4 id="p_3">
                                   Como concorrer aos nossos prêmios?
                              </h4>
                              <p id="r_3" class="r" style="display:none;">
                                   <a href="#" data-toggle="modal" data-target="#videoModal" @click="video='JIdB4yOr4mg'">Ver video</a>
                                   <br>
                                   Se você já faz parte do Time Guru e seu acesso foi liberado, já está concorrendo aos nossos prêmios.<br>
                                   Não precisa entrar em nenhuma liga no Cartola. <br/>
                                   Nosso ranking é feito direto pelo nosso aplicativo Guru do Cartola e você consegue ver em "Parciais" > "Grupos".<br/>

                                   <ul style="list-style-type:none;">
                                        <li>
                                             1) <a href="https://login.globo.com/login/438?url=https://cartolafc.globo.com" target="_blank">Clique aqui</a> e realize o login; 
                                        </li>
                                        <li>
                                             2) Após login realizado, <a href="https://cartolafc.globo.com/#!/liga/gurudocartola-com" target="_blank">Clique aqui</a>;
                                        </li>
                                        <li>
                                             3) Clique no botão verde “Participar”;
                                        </li>
                                        <li>
                                             4) Pronto, você já faz parte da Liga Guru;
                                        </li>
                                        <li>
                                             5) Acessando <a href="https://gurudocartola.com/premios-guru" target="_blank">esse link</a> você pode acompanhar todas as regras da premiação e também o nosso ranking.
                                        </li>
                                   </ul>
                              </p>
                         </div>
                         <div class="p">
                              <h4 id="p_4">
                                   Como escalar o meu time pelo site?
                              </h4>
                              <p id="r_4" class="r" style="display:none;">
                                   <!-- <a href="#" data-toggle="modal" data-target="#videoModal" @click="video='KUkUrNVWFbQ'">Ver video</a> -->
                                   <!-- <br> -->
                                   Para logar no nosso site com o seu time do Cartola, você precisa fazer o login com ele no nosso aplicativo Guru do Cartola.
                                   <br>
                                   No aplicativo, você clica em:
                                   <br><br>
                                   <ul style="list-style-type:none;">
                                        <li>
                                             1) "Mais"
                                        </li>
                                        <li>
                                             2) "Gerar código Guru Web"
                                        </li>
                                        <li>
                                             3) A sua camêra irá abrir para captar o código que estará na página “Escalar” no menu de Escalação do site. (https://gurudocartola.com/escale-seu-time-cartola)
                                        </li>
                                        <li>
                                             4) Aponte a câmera do seu celular para o código que aparece no site pelo seu computador.
                                        </li>
                                   </ul>
                                   <br>
                                   Depois disso, você terá acesso ao seu time pelo nosso site Guru do Cartola.
                              </p>
                         </div>
                         <!-- <div class="p">
                              <h4 id="p_5">
                                   Como usar o site?
                              </h4>
                              <div id="r_5" class="r" style="display:none;">
                                   <a href="#" data-toggle="modal" data-target="#videoModal" @click="video='GC0UawFxfb4'">Ver video</a>
                                   <br>
                              </div>
                         </div> -->
                         <div class="p">
                              <h4 id="p_6">
                                   Avaliação do meu time
                              </h4>
                              <p id="r_6" class="r" style="display:none;">
                                   Se você é membro do Time Guru, você pode enviar seu time para avaliação e receber comentários do Guru e dos demais membros.
                                   <br><br>
                                   Para ser avaliado, é simples:
                                   <br><br>
                                   <ul style="list-style-type:none;">
                                        <li> 1) Clique em “Enviar para: Avaliação Guru” quando acabar a sua escalação no aplicativo Guru do Cartola. </li>
                                        <li> 2) Clique em “Avaliação” no menu de Escalação e depois em "Meu time" para ver os comentários. Quanto antes do mercado fechar você enviar, mais fácil de ser avaliado.</li>
                                        <li> 3) E para avaliar o time dos outros membros do Time Guru, é só clicar em “Times da Guruzada”. Se você quiser que eles comentem o seu, é sempre bom ajudar também.</li>
                                        <!-- <li> 4) Nessa página de avaliação tem um link para você compartilhar o seu time no Whatsapp. Você pode enviar nos grupos de Whatsapp do Time Guru ou em qualquer grupo de Cartola que você tenha.</li> -->
                                        <!-- <li> 5) E para avaliar o time dos outros membros do Time Guru, é só clicar em “Times da Guruzada”. Se você quiser que eles comentem o seu, é sempre bom ajudar também.</li> -->
                                   </ul>
                                   <br>
                              </p>
                         </div>
                    </div>
               </div>
               <!-- Modal de videos -->
               <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                         <div class="modal-content">
                              <div class="modal-header">
                                   <h5 class="modal-title" id="exampleModalLabel">Video</h5>
                                   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                   </button>
                              </div>
                              <div class="modal-body">
                                   <div class="embed-responsive embed-responsive-16by9">
                                        <iframe class="embed-responsive-item" :src="'https://www.youtube.com/embed/'+video" allowfullscreen></iframe>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';

     export default {
          name: 'Ajuda',
          components:{
               customHeader
          },
          data(){
               return{
                    video:null,

                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          mounted(){
               $("h4").click(function(){
                    $("#r_"+this.id.split('_')[1]).fadeToggle('slow');
               })
          },
          created(){
               //Verificação mobile
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;
          },
          methods:{

          }
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     @import '@/assets/scss/news.scss';

     h4{
          // color: #fff;
          text-align: left;
          cursor: pointer;
          padding: 10px;
          font-size: 1.3em;
     }
     a{
          color: $laranja;
     }
     .p{
          background-color: #C9C9C9;
          border-left: 10px solid $laranja;
     }
     .r{
          background-color: lightgray;
          padding: 20px;
     }
</style>