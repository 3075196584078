function loadChartsMyTeamPage(graph1, graph2, data,current_round){
     $(document).ready(function() {
        //   Chart.defaults.global.defaultFontColor = "#4E4E4E";

          var ctx1 = document.getElementById(graph1);
          var ctx2 = document.getElementById(graph2);

          var labels = [];
          var data1 = [];
          var data2 = [];

          for (var i = 0; i < current_round - 1; i++) {
               labels.push("Rodada " + (i+1));

               if(i in data){
                    if(data[i].pontuacao != null)
                         data1.push(data[i].pontuacao.toFixed(1));
                    else
                         data1.push(parseFloat(0.0));

                    if(data[i].valorizacao != null){
                         if(i == 0){
                              data2.push((parseFloat(110) + data[i].valorizacao).toFixed(1));
                         }else{
                              data2.push((parseFloat(data2[i-1]) + parseFloat(data[i].valorizacao)).toFixed(1));
                         }
                    }else{
                         data2.push(parseFloat(0.0));
                    }
                    
               }else{
                    data1.push(parseFloat(0.0));
                    data2.push(parseFloat(0.0));
               }
          }

          var chart1 = new Chart(ctx1, {
              type: 'bar',
              data: {
                  labels: labels,
                  datasets: [{
                      label: 'Pontuação',
                      data: data1,
                      backgroundColor: '#009865',
                      borderWidth: 1
                  }]
              },
            //   options: {
            //       scales: {
            //           yAxes: [{
            //               ticks: {
            //                   beginAtZero:true
            //               }
            //           }],
            //           xAxes: [{
            //                 ticks: {
            //                     display: false
            //                 }
            //             }]
            //       }
            //   }
          });
          var chart1 = new Chart(ctx2, {
              type: 'bar',
              data: {
                  labels: labels,
                  datasets: [{
                      label: 'Patrimônio',
                      data: data2,
                      backgroundColor: '#f37608',
                      borderWidth: 1
                  }]
              },
            //   options: {
            //       scales: {
            //           yAxes: [{
            //               ticks: {
            //                   beginAtZero:true
            //               }
            //           }],
            //           xAxes: [{
            //                 ticks: {
            //                     display: false
            //                 }
            //             }]
            //       }
            //   }
          });
          
     });
}

export default loadChartsMyTeamPage;
