<template>
     <div>
          <customHeader title="Perguntas Frequentes" subtitle="" :mobile="mobile"></customHeader>
          <div class="container">
               <p style="text-align: left;">&nbsp;</p>
               <p style="text-align: left;"><strong>O que é o Guru do Cartola?</strong></p>
               <p style="text-align: justify;">O Guru do Cartola é um site que buscar passar as informações mais valiosas sobre o <em>fantasy game</em> CartolaFC, trazendo informações extremamente ricas e que inovam completamente a forma do Cartoleiro escalar o time.</p>
               <p style="text-align: justify;">Com base no conhecimento aprofundado do algoritmo de valoriza&ccedil;&atilde;o do Cartola, o Guru faz diversas análises dos números do jogo e transmite de forma simples para que o Cartoleiro tenha uma experiência única, seja capaz de escalar com inteligência e se torne um verdadeiro MITO.</p>
               <p style="text-align: justify;">&nbsp;</p>
               <p style="text-align: left;"><strong>Quem está por trás do Guru do Cartola?</strong></p>
               <p style="text-align: justify;">Luís Otávio, fundador do Guru do Cartola, é estatístico e apaixonado por futebol e números. Estudou de forma profunda o algoritmo de valorização do Cartola durante 9 meses, até descobrir exatamente como ele funciona e todos os fatores que são levados em consideração. Um trabalho extremamente sofisticado para possibilitar uma experiência única ao Cartoleiro.</p>
               <p style="text-align: justify;">Hoje a equipe conta com 3 desenvolvedores, visando fornecer a melhor experiências nas diversas plataformas, site e aplicativos, para o usuário.</p>
               <p style="text-align: justify;">&nbsp;</p>
               <p style="text-align: left;"><strong>Quais informações o Guru do Cartola disponibiliza?</strong></p>
               <p style="text-align: justify;">O Guru do Cartola mostra para os Cartoleiros as seguintes informações:</p>
               <ul>
                   <li style="text-align: left;">Previsões antes da Rodada (Pontuação Mínima para Valorizar, Pontuação e Valorização Esperadas)</li>
                   <li style="text-align: left;">Parciais e Valorização em Tempo Real</li>
                   <li style="text-align: left;">Time da Guruzada</li>
                   <li style="text-align: left;">Time Valorização</li>
                   <li style="text-align: left;">Seleção do Guru</li>
                   <li style="text-align: left;">Prêmios do Guru</li>
                   <li style="text-align: left;">Avaliação do Time</li>
                   <li style="text-align: left;">Comparação de Jogadores</li>

                   <li style="text-align: left;">Histórico e Estatísticas do Time do Cartoleiro</li>
                   <li style="text-align: left;">Análise Completa de cada Jogador.</li>
               </ul>
               <p style="text-align: left;">&nbsp;</p>
               <p style="text-align: left;"><strong>O que é o Time Guru e como faço para ser membro?</strong></p>
               <p style="text-align: justify;">O Time Guru é um programa de membros para os Cartoleiros que querem levar o jogo ainda mais a sério e ter acesso a conteúdo exclusivo e inédito, nunca visto em outro lugar, além de ganhar prêmio incríveis todas as rodadas na Liga do Guru. Para saber mais e SER TIME GURU vá na página Faça parte do Time Guru, localizada na parte superior do site.</p>
               <p style="text-align: justify;"> Em 2019, teremos dois tipos de planos: Time Guru Básico e Time Guru Chile. A diferença é que os membros do segundo terão direito a: avaliação do time pelo site Guru do Cartola e participação em liga valendo camisa oficial toda rodada e uma viagem para o Chile para a final da Libertadores. </p>
               <p style="text-align: justify;">&nbsp;</p>
               <p style="text-align: left;"><strong>Como funciona a Liga do Guru e como faço para participar?</strong></p>
               <p style="text-align: justify;">Todos os usuários poderão participar da Liga do Guru e ganhar prêmios todas as rodadas. Para saber todos os detalhes vá na página Prêmios do Guru</p>
               <p style="text-align: justify;">&nbsp;</p>
               <p style="text-align: left;"><strong>Que história é essa de usar estatística para prever resultado?</strong></p>
               <p style="text-align: justify;">É isso mesmo! Hoje em dia está cada vez mais forte o uso de métodos estatísticos nos esportes. Já é impossível pensar em times bem estruturados que não acompanhem os scouts dos jogadores. Nossa intenção é trazer a informação já trabalhada e poder te auxiliar na escalação do cartola.</p>
               <p style="text-align: justify;">&nbsp;</p>
               <p style="text-align: left;"><strong>Como é possível prever a atuação do jogador?</strong></p>
               <p style="text-align: justify;">Realmente essa é uma tarefa muito difícil! Criamos um banco de dados extremamente rico e com informações de várias fontes. Então vamos analisar onde será o jogo, a força de cada time, as habilidades dos jogadores que entrarão em campo e o retrospecto de cada jogador no cartola. Essa análise é feita por modelos de aprendizado de máquina (<em>machine learning</em>) e buscam fazer a previsão mais certeira possível para nos ajudar na escalação.</p>
               <p style="text-align: justify;">&nbsp;</p>
               <p style="text-align: left;"><strong>O que é pontuação esperada?</strong></p>
               <p style="text-align: justify;">Se fosse possível repetir o mesmo jogo, por várias vezes, o jogador iria fazer, em média o que chamamos de pontuação esperada. Ou seja, é uma pontuação média que o jogador faria contra aquele time, naquele local, com as mesmas condições de jogo.</p>
               <p style="text-align: justify;">&nbsp;</p>
               <p style="text-align: left;"><strong>E a valorização esperada?</strong></p>
               <p style="text-align: justify;">A valorização esperada antes da rodada é a variação do valor do jogador caso ele faça exatamente a pontuação esperada. Já a valorização durante ou depois da rodada corresponde a pontuação que o jogador tiver feito até o momento.</p>
               <p style="text-align: justify;">&nbsp;</p>
               <p style="text-align: left;"><strong>Minha pergunta não está aqui, o que eu faço</strong></p>
               <p style="text-align: justify;">Fale com a gente! É só mandar um e-mail para <a href="mailto:contato@gurudocartola.com">contato@gurudocartola.com</a>.</p>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';

     export default {
          name: 'Faq',
          components:{
               customHeader
          },
          data(){
               return {
                    
               }
          },
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     p, ul li{
          color: #4E4E4E;
     }
</style>
