<template>
     <div id="app">
          <Menu v-if="!$route.meta.webview" :mobile="mobile" :user="user"></Menu>
          <div id="guru-layer" class="guru-layer">
               <div id="guru-body" class="guru-body">
                    <div class="container page-content">
                         <router-view :user="user" @update-user="onUpdateUser"/>
                    </div>
               </div>
          </div>
          <Footer v-if="!$route.meta.webview" :mobile="mobile"></Footer>
     </div>
</template>
<script>
     import util from '@/mixin/util'
     import Menu from '@/components/Menu.vue'
     import Footer from '@/components/Footer.vue'
     
     // import * as firebase from 'firebase/app';
     // import 'firebase/messaging';
     // import NotificationBox from '@/components/NotificationBox.vue';
     
     export default {
          components:{
               Menu,
               Footer,
               // eslint-disable-next-line vue/no-unused-components
               // NotificationBox
          },
          mixins: [util],
          name: 'App',
          data(){
               return {
                    user:null,
                    
                    //Mobile
                    mobileAt:1248,//When mobile starts? < 1248 in that case
                    mobile:false,
                    // selling_page: '/timeguru',
               }
          },
          // mounted() {
          //      if (!(this.user && this.user.membro == 1)) {
          //           let adSense = document.createElement('script');
          //           adSense.setAttribute('src', 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js');
          //           adSense.setAttribute('data-ad-client', 'ca-pub-9819836167373922');
          //           adSense.setAttribute('async','');
          //           document.head.appendChild(adSense);
          //      }
          // },
          created(){
               //windowWidth defining mobile and fieldView
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

               var vm = this;

               //auth user
               vm.user = vm.get_usuario_logado();

               if (this.$route.meta.webview) {
                    document.body.style.backgroundColor = "white";
               }

               // try{
               //      firebase.messaging().requestPermission().then(() => {
               //           console.log("Notification permission granted.")
               //           return firebase.messaging().getToken()
               //           .then((token) => {
               //                     console.log('Token as Requested:')
               //                     console.log(token)
               //                     // window.console.log(token)
               //           })
               //           .catch((err)=> {
               //                console.log("# [APPFCM] ERROR: Unable to get token.")
               //                console.log(err)
               //           })
               //      })
               // } catch (e) {
               //      console.log(e)
               // }

          },
          methods:{
               smooth_scroll_to_top(){
                    $('html, body').scrollTop(0);
               },
               logout(){
                    this.reset_usuario_logado()
                    document.location.reload(true)
               },
               swal_jogos(){
                    // eslint-disable-next-line no-undef
                    Swal.fire(
                         'Opa!',
                         'Quer acompanhar as parciais de pontuação e valorização em tempo real do seu time? Então pera ai que a rodada já vai começar!',
                         'warning',
                    );
               },
               swal_meu_time(){
                    // eslint-disable-next-line no-undef
                    Swal.fire(
                         'Opa!',
                         'Um cartoleiro de carteirinha sabe a importância de analisar cada detalhe do seu time!<br><br>Aqui você poderá conferir o histórico e todas as estatísticas do seu time.<br><br>É só voltar depois da primeira rodada.',
                         'warning',
                    );
               },
               onUpdateUser(newValue){
                    this.user = newValue
               }
          }
     }
</script>
<style lang="scss">
     @import url('https://fonts.googleapis.com/css?family=Open+Sans|Ubuntu');
     @import '@/assets/sass/variables.scss';
     body{
          // background-color: #f5f5f5 !important;
          // background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e3e3e3' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
          font-family: 'Ubuntu', sans-serif;
     }
     h1,h2,h3,h4,h5,h6{
          font-family: 'Open Sans', sans-serif;
     }
     .tooltip-inner {
          text-align: left;
     }
     .nav-tabs{
          border-bottom: 2px solid $ul_li_desactive;
          margin-bottom: 2.5%;
          a{
               color: $light_text_color;
          }
          .nav-link.active{
               color: $light_text_color;
               border:none;    
               background-color: $ul_li_active;
          }
          .nav-item {
               color: $light_text_color;
               // border:none;
               background-color: $ul_li_desactive;
          }
          .nav-link{
               border:none;
               transition: all .2s;
          }
          .nav-link:hover{
               // letter-spacing: 1px;
               background-color: $ul_li_hover;
          }
     }

     .dropdown-menu{
          font-size: 0.9rem;
          padding: 0.5rem 1rem;
          border: none;
          border-radius: 0px;
     }

     .btn-sell{
          color: white;
          font-weight: bolder;
          background-color: #f47607;
          border: none;
          box-shadow: 0 4px 12px 0px rgba(0, 0, 0, 0.1);
          transition: 0.2s all ease;
          margin: 0px 25px;
     }
     .btn-sell:hover{
          letter-spacing: 1px;
          color: white;
     }

     .btn-logout{
          border:none;
          background-color:#e4e4e4;
          padding:5px;
          width:100%;
          transition:0.33s ease all;
          a{
               padding:0px !important;
          }
          a:hover{
               cursor:pointer;
          }

     }

      .btn-menu-right{
          border:none;
          background-color: #f47607;
          padding:5px;
          width:100%;
          transition:0.33s ease all;
          a{
               padding:0px !important;
               color: white;
          }
          a:hover{
               cursor:pointer;
          }

     }

     footer{
          // margin-top:50px;
          background-color:#2e3348;
          padding:35px 0px;
          color: $light_text_color;
          a{
               display:block;
               color: $laranja;
               transition: .2s ease all;
          }
          a:hover{
               text-decoration: none;
               letter-spacing: 1px;
               color: $laranja;
               transform: translateX(10px);
          }
     }
     
     .page-content {
          padding-bottom: 50px;
          // background-color: #F5F5F5;
          background-color: white;
          min-height: 650px;
          color: #212529;
          // box-shadow: 1px 3px black;
     }

     .guru-body {
          // background-image: url('https://images2.imgbox.com/43/85/WhdwCLVZ_o.png');
          background-image: repeating-linear-gradient(to right, rgba(255,0,0,0) , rgba(255,255,255,1) 18%, rgba(255,255,255,1) 82%, rgba(255,0,0,0)) ;
     }

     .guru-layer {
          background-image: url('https://images2.imgbox.com/43/85/WhdwCLVZ_o.png');
          // background-color: black;
     }
</style>
