<template>
     <div id="filters">
          <p class="text-right" 
               @click="resetFilters()"
          >
               <small id="cleanFilters">Limpar Filtros</small>
          </p>
          <input @input="changeSearch" v-model="filterSearch" type="text" placeholder="Buscar Jogador" class="form-control">
          <br>
          <button class="btn btn-sm btn-block btn-outline-light" @click="[showClubs ? showClubs = false : showClubs = true]">
               Times 
               <span v-if="filterCurrentClub.length > 0">
                    <i class="fas fa-filter"></i>
               </span>
          </button>
          <br>
          <div v-if="showClubs" id="filterClubs" class="row clubs">
               <div class="col-12">
                    <button @click="allClubs()" class="btn btn-sm btn-block btn-dark">
                         <span>
                              Todos os times 
                         </span>
                    </button>
                    <hr>
               </div>
               <template v-for="club_id in idClubes">
                    <div class="col-3" v-if="club_id>1  && clubs[club_id] && clubs[club_id].ativo && clubs[club_id].escudo != ''" :key="club_id">
                         <img :src="clubs[club_id].escudo" 
                              class="club"
                              :class="[filterCurrentClub.indexOf(String(club_id)) != -1 ? 'active_club' : '']"
                              :id="club_id"  @click="changeCurrentClub">
                    </div>
               </template>
          </div>
          <div class="row">
               <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center">
                    <label>Posição</label><br>
                    <div :class="[mobile ? 'btn-group-horizontal' : 'btn-group-vertical']" class="btn-group-toggle" data-toggle="buttons">
                         <label class="btn btn-secondary btn-sm" id="1" @click="changeCurrentPos"
                              :class="{ ativo : filterCurrentPos.indexOf('1') != -1 }">
                         <input type="checkbox" autocomplete="off"> GOL
                         </label>
                         <label class="btn btn-secondary btn-sm" id="2" @click="changeCurrentPos"
                              :class="{ ativo : filterCurrentPos.indexOf('2') != -1 }">
                         <input type="checkbox" autocomplete="off"> LAT
                         </label>
                         <label class="btn btn-secondary btn-sm" id="3" @click="changeCurrentPos"
                              :class="{ ativo : filterCurrentPos.indexOf('3') != -1 }">
                         <input type="checkbox" autocomplete="off"> ZAG
                         </label>
                         <label class="btn btn-secondary btn-sm" id="4" @click="changeCurrentPos"
                              :class="{ ativo : filterCurrentPos.indexOf('4') != -1 }">
                         <input type="checkbox" autocomplete="off"> MEI
                         </label>
                         <label class="btn btn-secondary btn-sm" id="5" @click="changeCurrentPos"
                              :class="{ ativo : filterCurrentPos.indexOf('5') != -1 }">
                              <input type="checkbox" autocomplete="off"> ATA
                         </label>
                         <label class="btn btn-secondary btn-sm" id="6" @click="changeCurrentPos"
                              :class="{ ativo : filterCurrentPos.indexOf('6') != -1 }">
                         <input type="checkbox" autocomplete="off"> TÉC
                         </label>
                    </div>
               </div>
               <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 text-center">
                    <label>Status</label><br>
                    <div :class="[mobile ? 'btn-group-horizontal' : 'btn-group-vertical']" class="btn-group-toggle" data-toggle="buttons">
                         <label class="btn btn-secondary btn-sm" id="7" @click="changeCurrentStatus"
                              :class="{ ativo : filterCurrentStatus.indexOf('7') != -1 }">
                              <input type="checkbox" autocomplete="off"> Provável
                         </label>
                         <label class="btn btn-secondary btn-sm" id="5" @click="changeCurrentStatus"
                              :class="{ ativo : filterCurrentStatus.indexOf('5') != -1 }">
                         <input type="checkbox" autocomplete="off"> Contundido
                         </label>
                         <label class="btn btn-secondary btn-sm" id="2" @click="changeCurrentStatus"
                              :class="{ ativo : filterCurrentStatus.indexOf('2') != -1 }">
                         <input type="checkbox" autocomplete="off"> Dúvida
                         </label>
                         <label class="btn btn-secondary btn-sm" id="3" @click="changeCurrentStatus"
                              :class="{ ativo : filterCurrentStatus.indexOf('3') != -1 }">
                         <input type="checkbox" autocomplete="off"> Suspenso
                         </label>
                         <label class="btn btn-secondary btn-sm" id="6" @click="changeCurrentStatus"
                              :class="{ ativo : filterCurrentStatus.indexOf('6') != -1 }">
                         <input type="checkbox" autocomplete="off"> Nulo
                         </label>
                    </div>
               </div>
          </div>
          <br>
          <template>
               <p>
                    <span data-toggle="tooltip" data-placement="bottom" title="Escolhe qual valor deve ser exibido na última coluna: Média, Pontuação Esperada ou Valorização Esperada.">
                         Customizar tabela <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </span>
               </p>
               <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-secondary btn-sm" style="display:flex; align-items:center;font-size:0.8em;"
                         :class="{ ativo : filterField == 0 }" id="0" @click="changeCurrentField">
                         <input type="radio" autocomplete="off" checked> Média
                    </label>
                    <label class="btn btn-secondary btn-sm"  style="display:flex; align-items:center;font-size:0.8em;"
                         :class="{ ativo : filterField == 1 }" id="1" @click="changeCurrentField">
                         <input type="radio" autocomplete="off"> Pontuação<br/>Esperada
                    </label>
                    <label class="btn btn-secondary btn-sm"  style="display:flex; align-items:center;font-size:0.8em;"
                         :class="{ ativo : filterField == 2 }" id="2" @click="changeCurrentField">
                         <input type="radio" autocomplete="off"> Valorização<br/>Esperada
                    </label>
               </div>
               <br /><br />
               <p>
                    <span data-toggle="tooltip" data-placement="bottom" title="Considerar na análise apenas jogos com o mesmo mando de campo da próxima partida.">
                         Considerar mando de campo <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </span>
               </p>
               <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-secondary btn-sm"
                         :class="{ ativo : filterHome == 1 }" id="1" @click="changeCurrentHome">
                         <input type="radio" autocomplete="off" checked> Sim
                    </label>
                    <label class="btn btn-secondary btn-sm"
                         :class="{ ativo : filterHome == 0 }" id="0" @click="changeCurrentHome">
                         <input type="radio" autocomplete="off"> Não
                    </label>
               </div>
               <br><br>
               <p>
                    Usar as últimas <span class="badge badge-secondary" id="range">{{filterRound}}</span> rodadas
               </p>
               <input type="range" class="custom-range" min="5" :max="38" step="5" :value="filterRound" id="customRange" @change="changeCurrentRound">
          </template>
          <!-- <template v-else>
               <div class="text-center" @click="signature()">
                    <img src="/img/filters_restriction.jpg" class="img img-fluid">
               </div>
          </template> -->
          <div class="text-center" v-if="mobile=='true'">
               <br>
               <button class="btn btn-md col-6 btn-light">Filtrar</button>
          </div>
     </div>
</template>

<script>
// import clubs_json from '@/json/clubes.json';
     import util from '@/mixin/util'; 
     export default {
          name: 'filters',
          mixins: [util],
          props: {
               mobile: {
                    type: Boolean,
                    required: true
               },
               currentPos: {
                    type: Array,
                    required: false
               },
               currentStatus: {
                    type: Array,
                    required: false
               },
               currentField: {
                    default: 0
               },
               clubs: {
                    required: true
               }

          },
          data(){
               return{
                    user:null,

                    showClubs:false,
                    idClubes: [],
                    filterSearch:'',
                    filterRound:'10',
                    filterHome:0,
                    filterCurrentClub:[],
                    filterCurrentPos:[],
                    filterCurrentStatus:['7'],

                    filterField: 0,

               }
          },
          watch:{
               currentPos(newValue){
                    this.filterCurrentPos = newValue;
               },
               currentStatus(newValue){
                    this.filterCurrentStatus = newValue;
               }
          },
          created(){
               var vm = this;

               vm.user = vm.get_usuario_logado();

               if(vm.user && vm.user.membro == 1){
                    vm.filterHome = 1;
               }
               
               
               //Carrega o id dos clubes por ordem alfabetica
               this.$http
               .get(this.$API+'twitter/clubes')
               .then(response => {
                    vm.idClubes = response.data;
               })
               .catch(function (error) {
                    console.log(error);
               }); 

               $(document).mouseup(function(e) 
               {
                   var container = $("#filterClubs");

                   // if the target of the click isn't the container nor a descendant of the container
                   if (!container.is(e.target) && container.has(e.target).length === 0) 
                   {
                       container.hide();
                   }
               });
          },
          methods:{
               signature(){
                    var vm = this;
                    // eslint-disable-next-line no-undef
                    Swal.fire({
                         title: 'Ops!',
                         text: 'Essa função é exclusiva para quem faz parte do Time Guru. Quer saber todas as vantagens de fazer parte do nosso time?',
                         type: 'warning',
                         showCancelButton: true,
                         confirmButtonColor: '#28a745',
                         cancelButtonColor: '#dc3545',
                         confirmButtonText: 'SIM, EU QUERO',
                         cancelButtonText: 'Não'
                    }).then((result) => {
                         if (result.value) {
                              window.location.href = vm.selling_page;
                         }
                    })
               },
               allClubs(){
                    this.filterCurrentClub = [];
                    this.$emit('changedCurrentClub',this.filterCurrentClub);
               },
               resetFilters(){
                    this.filterSearch = ''; 
                    this.filterCurrentClub = []; 
                    this.filterCurrentPos = []; 
                    this.filterCurrentStatus = ['7'];
                    this.$emit('changedSearch',this.filterSearch);
                    this.$emit('changedCurrentClub',this.filterCurrentClub);
                    this.$emit('changedCurrentPos',this.filterCurrentPos);
                    this.$emit('changedCurrentStatus',this.filterCurrentStatus);
               },
               changeSearch(event){
                    this.filterSearch = event.target.value;
                    this.$emit('changedSearch',this.filterSearch);
               },
               changeCurrentRound(event){
                    if (this.isMembro()) {
                         this.filterRound = event.target.value;
                         this.$emit('changedRound',this.filterRound);
                    } else {
                         this.signature();
                    }
               },
               changeCurrentHome(event){
                    if (this.isMembro()) {
                         this.filterHome = event.target.id;
                         this.$emit('changedHome',this.filterHome);
                    } else {
                         this.signature();
                    }
               },
               changeCurrentField(event){
                    if (this.isMembro()) {
                         this.filterField = event.target.id;
                         this.$emit('changedField',this.filterField);
                    } else {
                         this.signature();
                    }
               },
               changeCurrentClub(){
                    if(this.filterCurrentClub.indexOf(event.target.id) == -1){
                         this.filterCurrentClub.push(event.target.id);
                    }else{
                         this.filterCurrentClub.splice(this.filterCurrentClub.indexOf(event.target.id),1);
                    }
                    this.$emit('changedCurrentClub',this.filterCurrentClub);
               },
               changeCurrentPos(){

                    if(this.filterCurrentPos.indexOf(event.target.id) == -1){
                         this.filterCurrentPos.push(event.target.id);
                    }else{
                         this.filterCurrentPos.splice(this.filterCurrentPos.indexOf(event.target.id),1);
                    }

                    this.$emit('changedCurrentPos',this.filterCurrentPos);
               },
               changeCurrentStatus(){

                    if(this.filterCurrentStatus.indexOf(event.target.id) == -1){
                         this.filterCurrentStatus.push(event.target.id);
                    }else{
                         this.filterCurrentStatus.splice(this.filterCurrentStatus.indexOf(event.target.id),1);
                    }

                    this.$emit('changedCurrentStatus',this.filterCurrentStatus);
               },
               isMembro() {
                    return this.user && this.user.membro == 1
               }
          }
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     #cleanFilters:hover{
          cursor: pointer;
     }
     #filters{
          height: $main_table_height;
          background-color: $table_header_color;
          color: $light_text_color;
          font-size: $table_header_font_size;
          padding: 10px;
          button, label, input{
               font-size: $table_header_font_size;
          }
          .focus{
               outline: none !important;
               box-shadow: none;
          }
          .active{
               background-color: #6c757d;
               border: none;
          }
          .ativo{
               // background-color: #f47607;
               background-color: $verde_claro;
               border: none;
          }
     }
     .clubs{
          background-color: white;
          position: absolute;
          z-index: 2;
          padding: 10px;
          border-left: 1px solid #545b62;
          border-right: 1px solid #545b62;
          border-bottom: 1px solid #545b62;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
     }
     .clubs:before{
          content: ' ';
          top: -15px;
          left: 50%;
          margin-left: -10px;
          position: absolute;
          width: 0; 
          height: 0; 
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 15px solid white;
     }
     .club{
          width: 20px;
          margin-bottom: 15px;
          transition: .2s;
     }
     .active_club{
          width: 30px;
          padding: 3px;
          margin-left: -3.5px;
          box-shadow: 0px 1px 3px #0000008c;
          background-color: $laranja_claro;
     }
     .club:hover{
          cursor: pointer;
          transform: translateY(-2px);
     }
     @media only screen and (max-width: 1000px) {
          #filters{
               height: auto;
          }
     }
</style>
