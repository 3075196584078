import { render, staticRenderFns } from "./Compra.vue?vue&type=template&id=5981d702&scoped=true"
import script from "./Compra.vue?vue&type=script&lang=js"
export * from "./Compra.vue?vue&type=script&lang=js"
import style0 from "./Compra.vue?vue&type=style&index=0&id=5981d702&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5981d702",
  null
  
)

export default component.exports