<template>
    <div>
    <div v-if="show == true">
        <a :href="banner.clickUrl" target="_blank">
            <img :src="banner.imgUrl" class="w-100"/> 
        </a>
    </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            banner:{}
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    created() {
        var vm = this;
        this.$http
        .get(this.$API+'banner/')
        .then(response => {
             vm.banner = response.data;
        })
        .catch(function (error) {
            console.log(error);
        }); 
    }
}
</script>

<style>

</style>