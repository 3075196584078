<template>
     <div>
          <customHeader title="Termos de uso" subtitle="" :mobile="mobile"></customHeader>
          <div class="container">
               <p style="text-align: justify;"><strong style="margin-top:0px;">IMPORTANTE! ESSES TERMOS E CONDIÇÕES DE USO REGULAM O USO DO SITE GURU DO CARTOLA. AO ACESSAR ESSE SITE, VOCÊ ATESTA SEU CONHECIMENTO E CONCORDÂNCIA COM ESSES TERMOS E CONDIÇÕES DE USO. </strong></p>
               <p style="text-align: justify;"><strong style="margin-top:0px;">ESSES TERMOS E CONDIÇÕES DE USO PODEM SER ALTERADOS A QUALQUER TEMPO E SEM AVISO. A UTILIZAÇÃO DESTE SITE APÓS TAIS ALTERAÇÕES ENTRAREM EM VIGOR CONSTITUI SEU CONHECIMENTO E ACEITAÇÃO DAS MUDANÇAS. POR FAVOR, CONSULTE OS TERMOS E CONDIÇÕES DE USO FREQUENTEMENTE PARA AVERIGUAR MODIFICAÇÕES.</strong></p>
               <p style="text-align: justify;"><strong style="margin-top:0px;">AO ACESSAR O SITE GURU DO CARTOLA, VOCÊ SE COMPROMETE A NÃOO DIVULGAR OU UTILIZAR, EM NENHUMA HIPÓTESE, AS INFORMAÇÕES E AS TELAS DAS PÁGINAS EXCLUSIVAS DO TIME GURU. </strong></p>
               <p style="text-align: justify;"><strong style="margin-top:0px;">ALÉM DISSO, CASO COMPARTILHE INFORMAÇÕES E AS PÁGINAS DO SITE GURU DO CARTOLA QUE SÃO DISPONÍVEIS PARA TODOS OS USUÁRIOS, VOCÊ SE COMPROMETE E ENTENDE QUE É OBRIGATÓRIO DIVULGAR A FONTE, OU SEJA, DAR OS CR&Eacute;DITOS AO GURU DO CARTOLA.</strong></p>
               <p style="text-align: justify;"><strong style="margin-top:0px;">A RESPONSABILIDADE PELA ESCALAÇÃO DO TIME NO SITE OFICIAL DO CARTOLA FC É DO USUÁRIO. DESSA FORMA, O MESMO SE RESPONSABILIZA POR CONFERIR SE A ESCALAÇÃO FOI FEITA CORRETAMENTE.</strong></p>
               <hr>
               <p><strong>Acesso ao site</strong></p>
               <p style="text-align: justify;"> Para acessar esse site ou alguns dos recursos que ele oferece, pode ser requerido que você forneça alguns detalhes para inscrição ou outras informações. É uma condição de uso deste site que toda a informação que você forneça seja correta, atualizada e completa. Se a Empresa acreditar que a informação fornecida não é correta, atualizada ou completa, temos o direito de recusar o seu acesso ao site ou qualquer um de seus serviços e também de cancelar ou suspender seu acesso a qualquer tempo sem notificação prévia.</p>
               <p><strong>Restrições de uso</strong></p>
               <p style="text-align: justify;"> Você pode utilizar esse site para propósitos expressamente permitidos pelo mesmo. Você não pode utilizá-lo para qualquer outro objetivo, incluindo propósitos comerciais, sem o consentimento prévio expresso e escrito da nossa Empresa. Por exemplo, você não pode (e tampouco pode autorizar terceiros) (i) associar a marca da nossa Empresa a nenhuma outra, ou (ii) fazer um frame deste site em outro ou (iii) fazer um hiperlink para este site sem a autorização expressa prévia e escrita de um representante da nossa Empresa. Para efeitos deste Termo e Condições de Uso “associar a marca da nossa Empresa” significa expor nome, logo, marca comercial ou outros meios de atribuição ou identificação em fonte de maneira que dê ao usuário a impressão de que tal fonte tem o direito de expor, publicar ou distribuir este site ou o conteúdo disponibilizado por ele. Você concorda em cooperar com a Empresa para cessar qualquer associação, frame ou hiperlink não autorizados.</p>
               <p><strong>Propriedade da Informa&ccedil;&atilde;o</strong></p>
               <p style="text-align: justify;">O material e conteúdo (referido nesse documento como “conteúdo”) acessíveis neste site e qualquer outro web site ou aplicativo de propriedade, licenciado ou controlado pela nossa Empresa pertence à Empresa ou à fonte que forneceu o conteúdo a nossa Empresa, e ambas detém todo o direito, título e proveito do conteúdo. Da mesma forma, o conteúdo não pode ser copiado, distribuído, republicado, carregado, postado ou transmitido por qualquer meio sem o consentimento prévio escrito de nossa Empresa, ou, ao menos, que esteja autorizado de forma escrita no nosso site. Ao fazer isso, você não pode remover, alterar ou causar remoção ou alteração em qualquer copyright, marca comercial, nome comercial, marca de serviço ou qualquer outro aviso de propriedade no Conteúdo. Modificação ou uso do Conteúdo em qualquer outra forma que não as expressamente descritas nestes Termos e Condições de Uso viola os direitos de propriedade intelectual de nossa Empresa. Nem autoria nem a propriedade intelectual são transferidas para você ao acessar esse site.</p>
               <p style="text-align: justify;"> O site Guru do Cartola, poderá, a seu critério, divulgar e utilizar conteúdo exclusivo do Time Guru para fins de propaganda.</p>
               <p><strong>Registro e dados pessoais</strong></p>
               <p style="text-align: justify;">É dever do Usuário manter atualizados os dados pessoais fornecidos quando da utilização do site.</p>
               <p style="text-align: justify;"> Nossa Empresa tratará qualquer informação pessoal que você submeter por esse site de acordo com as Políticas de Privacidade do site.</p>
               <p style="text-align: justify;"><strong>Rescisão</strong>Você concorda que o Guru do Cartola, a seu único critério, poderá interromper seu acesso ou uso do Site e dos Serviços, em qualquer momento e por qualquer motivo, inclusive, sem limitações, caso acredite que você violou ou agiu de forma inconsistente com o texto ou o espírito destes Termos e Condições de Uso. Caso essa interrupção ocorra, seu direito de uso do Site e dos Serviços cessará imediatamente. Você concorda que qualquer interrupção do seu acesso ou uso do Site poderá entrar em vigor sem aviso prévio, e que o Guru do Cartola poderá desativar imediatamente ou excluir sua conta e senha, bem como todos os arquivos e informações relacionadas a elas, e/ou ainda impedir qualquer acesso posterior a esses arquivos ou informações. Você concorda em que o Guru do Cartola não será responsabilizado por você ou por terceiros por qualquer interrupção de seu acesso ao Site ou a esses arquivos ou informações, nem será solicitado a disponibilizar esses arquivos ou informações após a rescisão</p> <p><strong>Regras de conduta do Usuário</strong></p>
               <p>O Usuário se compromete a não utilizar o Site para a publica&ccedil;&atilde;o, cria&ccedil;&atilde;o, armazenamento e/ou divulga&ccedil;&atilde;o de:</p>
               <ul>
               <li>Conte&uacute;do abusivo, como textos, fotos e/ou v&iacute;deos que tenham car&aacute;ter difamat&oacute;rio, discriminat&oacute;rio, obsceno, ofensivo, amea&ccedil;ador, abusivo, vexat&oacute;rio, prejudicial, que contenha express&otilde;es de &oacute;dio contra pessoas ou grupos, ou que contenha pornografia infantil, pornografia expl&iacute;cita ou violenta, conte&uacute;do que possa ser danoso a menores, que contenha insultos ou amea&ccedil;as religiosas ou raciais, ou que incentive danos morais (incluindo os corporais) e patrimoniais, ou que possa violar qualquer direito de terceiro, notadamente os direitos humanos.</li>
               </ul>
               <p>Banners publicit&aacute;rios e/ou qualquer tipo de com&eacute;rcio eletr&ocirc;nico que seja considerado il&iacute;cito, assim entendidos os que sejam contr&aacute;rios &agrave; legisla&ccedil;&atilde;o ou ofendam direitos de terceiros.</p>
               <ul>
               <li>Qualquer tipo de material (textos, fotos e/ou v&iacute;deos) protegido por direitos autorais, copyright ou que, por qualquer raz&atilde;o, violem direitos de terceiros.</li>
               <li>Informa&ccedil;&otilde;es difamat&oacute;rias e caluniosas ou que sejam contr&aacute;rias &agrave; honra, &agrave; intimidade pessoal e familiar ou &agrave; imagem das pessoas (inclusive de pessoa jur&iacute;dicas, entidades e organiza&ccedil;&otilde;es e ela equiparadas).</li>
               <li>Material que incite &agrave; viol&ecirc;ncia e &agrave; criminalidade, bem como &agrave; pirataria de produtos.</li>
               <li>Conte&uacute;do que provoque, por suas caracter&iacute;sticas (como extens&otilde;es e formatos de arquivos) danos ao sistema do Guru do Cartola.</li>
               </ul>
               <p>&nbsp;</p>
               <p>O Usu&aacute;rio concorda que, ao usar o site, n&atilde;o ir&aacute;:</p>
               <ul>
               <li>violar qualquer um destes Termos e Condi&ccedil;&otilde;es de Uso;</li>
               <li>praticar falsidade, assim entendidas a falsidade de informa&ccedil;&otilde;es (ex: divulga&ccedil;&atilde;o proposital e volunt&aacute;ria de informa&ccedil;&otilde;es que o Usu&aacute;rio saiba ser falsa ou que sejam notoriamente falsas) e a falsidade ideol&oacute;gica;</li>
               <li>publicar ou transmitir qualquer conte&uacute;do abusivo ou ofensivo nos coment&aacute;rios;</li>
               <li>replicar ou armazenar conte&uacute;do abusivo nos servidores do Guru do Cartola;</li>
               <li>fazer qualquer coisa ou praticar qualquer ato contr&aacute;rio &agrave; boa-f&eacute; e aos usos e costumes das comunidades virtuais e que possam ofender qualquer direito de terceiros. Por viola&ccedil;&atilde;o aos usos e costumes de comunidades virtuais tamb&eacute;m deve ser entendido o uso excessivo de texto em letras mai&uacute;sculas, uso de cr&iacute;ticas ofensivas, a inclus&atilde;o desnecess&aacute;ria e imprudente de comunica&ccedil;&otilde;es anteriores em qualquer publica&ccedil;&atilde;o ou transmiss&atilde;o, bem como qualquer outro ato que esteja em desacordo com a etiqueta da Internet comumente aceita.</li>
               <li>cometer fraude;</li>
               <li>violar ou infringir direitos de propriedade intelectual, direitos fiduci&aacute;rios ou contratuais, direitos de privacidade ou publicidade de outros;</li>
               <li>propagar, distribuir ou transmitir c&oacute;digos destrutivos, quer tenham ou n&atilde;o causado danos reais;</li>
               <li>reunir dados pessoais ou comerciais, incluindo (mas n&atilde;o se limitando a) endere&ccedil;os de e-mail e/ou nomes de qualquer recurso da Internet, seja gerenciado por n&oacute;s ou por terceiros, para fins comerciais, pol&iacute;ticos, de benemer&ecirc;ncia ou outros, sem o consentimento dos propriet&aacute;rios desses dados;</li>
               <li>reproduzir, replicar, copiar, alterar, modificar, criar obras derivativas a partir de, vender ou revender qualquer um dos servi&ccedil;os do Guru do Cartola ou qualquer parte deles, incluindo websites e p&aacute;ginas web, ou as informa&ccedil;&otilde;es ou dados contidos nos servi&ccedil;os do site sem a autoriza&ccedil;&atilde;o expressa de um representante;</li>
               <li>realizar propaganda pol&iacute;tica nos coment&aacute;rios;</li>
               <li>usar rob&ocirc;s, &ldquo;spiders&rdquo; ou qualquer outro dispositivo, autom&aacute;tico ou manual, para monitorar ou copiar qualquer conte&uacute;do do servi&ccedil;o do Guru do Cartola;</li>
               <li>transmitir conte&uacute;do que n&atilde;o pertence ao Usu&aacute;rio ou que ele n&atilde;o tenha direito de publicar ou distribuir, seja sob lei ou contrato;</li>
               <li>acessar o site sem autoriza&ccedil;&atilde;o, por meio de pr&aacute;ticas de &ldquo;hacking&rdquo;, &ldquo;password mining&rdquo; ou qualquer outro meio fraudulento ou que represente viola&ccedil;&atilde;o a direito de terceiros;</li>
               <li>realizar ou incentivar atividades ilegais, incluindo (mas n&atilde;o se limitando a) promover ou facilitar o acesso, uso ou venda de subst&acirc;ncias ou dispositivos perigosos;</li>
               <li>deixar de cumprir com quaisquer leis, normas, regras, princ&iacute;pios e regulamenta&ccedil;&otilde;es aplic&aacute;veis;</li>
               <li>ajudar qualquer terceiro a realizar qualquer uma das a&ccedil;&otilde;es vedadas por estes Termos e Condi&ccedil;&otilde;es de Uso.</li>
               </ul>
               <p>&nbsp;</p>
               <p>O Guru do Cartola tem o direito de remover o(s) coment&aacute;rio(s) de Usu&aacute;rios se considerar que esse conte&uacute;do &eacute; impr&oacute;prio ou inadequado, que viole estes Termos e Condi&ccedil;&otilde;es de Uso, a legisla&ccedil;&atilde;o ou qualquer direito de terceiros.</p>
               <p>O Guru do Cartola se reserva o direito de verificar ou moderar o conte&uacute;do dos coment&aacute;rios publicados por meio de sua ferramenta de coment&aacute;rios a qualquer momento, caso julgue necess&aacute;rio, por&eacute;m n&atilde;o &eacute; respons&aacute;vel pela precis&atilde;o dos coment&aacute;rios feitos por terceiros.</p> <p><strong>Hiperlinks</strong></p>
               <p>Este site pode conter links para outros websites que n&atilde;o s&atilde;o mantidos ou mesmo relacionados &agrave; nossa&nbsp;Empresa. Hiperlinks para tais sites s&atilde;o providos como um servi&ccedil;o para usu&aacute;rios e n&atilde;o s&atilde;o afiliados a este site ou &agrave; nossa&nbsp;Empresa. Nossa&nbsp;Empresa&nbsp;n&atilde;o revisa todos ou mesmo nenhum desses sites e n&atilde;o &eacute; respons&aacute;vel pelo conte&uacute;do deles. O usu&aacute;rio assume o risco ao acessar esses hiperlinks e nossa&nbsp;Empresa n&atilde;o faz nenhuma representa&ccedil;&atilde;o ou d&aacute; garantias sobre a plenitude ou precis&atilde;o dos hiperlinks e os sites aos quais eles direcionam. Finalmente, a conclus&atilde;o de qualquer hiperlink para um site de terceiro n&atilde;o necessariamente implica endosso da nossa&nbsp;Empresa&nbsp;a esse site.</p>
               <p><strong>Exclus&atilde;o de Responsabilidade</strong></p>
               <p>Voc&ecirc; entende que nossa Empresa n&atilde;o pode e n&atilde;o garante que arquivos dispon&iacute;veis para download da Internet estejam livres de v&iacute;rus,&nbsp;<em>worms</em>, cavalos de Tr&oacute;ia ou outro c&oacute;digo que possa manifestar propriedades contaminadoras ou destrutivas. Voc&ecirc; &eacute; respons&aacute;vel por implementar procedimentos e checkpoints suficientes para satisfazer seus requisitos de seguran&ccedil;a e por manter meios externos a este site para reconstru&ccedil;&atilde;o de qualquer dado perdido. Nossa&nbsp;Empresa&nbsp;n&atilde;o assume nenhuma responsabilidade ou risco pelo uso da internet.</p>
               <p>Voc&ecirc; usar&aacute; o site ou qualquer software, servi&ccedil;os ou materiais dispon&iacute;veis atrav&eacute;s do site por seu pr&oacute;prio risco. O Guru do Cartola ou qualquer terceiro envolvido na cria&ccedil;&atilde;o, produ&ccedil;&atilde;o ou fornecimento do site n&atilde;o ser&aacute; respons&aacute;vel por danos diretos, indiretos, punitivos, incidentais, especiais, consequenciais ou de qualquer outra natureza decorrentes do uso deste site, servi&ccedil;os, software, conte&uacute;do ou conte&uacute;do do usu&aacute;rio seja com base em contrato, ato il&iacute;cito, responsabilidade estrita ou de qualquer outra forma, mesmo que tenha sido avisada sobre a possibilidade de ocorr&ecirc;ncia desses danos, inclusive, sem limita&ccedil;&otilde;es, danos por lucros cessantes, impossibilidade de uso, perda de dados ou outras perdas intang&iacute;veis, perda de seguran&ccedil;a de informa&ccedil;&otilde;es fornecidas por voc&ecirc; durante seu uso do site, servi&ccedil;os, software, conte&uacute;do ou conte&uacute;do do usu&aacute;rio, ou intercepta&ccedil;&atilde;o n&atilde;o autorizada dessas informa&ccedil;&otilde;es por terceiros, mesmo que avisada com anteced&ecirc;ncia sobre esses danos ou perdas. Em particular e sem limita&ccedil;&otilde;es, o Guru do Cartola n&atilde;o ser&aacute; respons&aacute;vel por qualquer tipo de danos resultantes do uso adequado ou n&atilde;o do site, dos servi&ccedil;os ou software, ou de qualquer conte&uacute;do ou conte&uacute;do do usu&aacute;rio publicado no site pelo Guru do Cartola ou por terceiros. Seu recurso exclusivo, caso voc&ecirc; n&atilde;o esteja satisfeito com o site, ser&aacute; a interrup&ccedil;&atilde;o de seu uso. Observe que algumas jurisdi&ccedil;&otilde;es poder&atilde;o n&atilde;o permitir a exclus&atilde;o de certos danos, portanto algumas das exclus&otilde;es acima talvez n&atilde;o se apliquem a voc&ecirc;.</p>
               <p>O Guru do Cartola n&atilde;o garante que as fun&ccedil;&otilde;es ou conte&uacute;do presente neste site ser&aacute; cont&iacute;nuo, livre de erros, que falhas ser&atilde;o corrigidas ou que este site e servidor que o torna dispon&iacute;vel est&atilde;o livres de v&iacute;rus ou outros componentes destrutivos. nossa&nbsp;empresa&nbsp;n&atilde;o garante ou faz qualquer representa&ccedil;&atilde;o relacionada ao uso ou resultados do uso do conte&uacute;do em termos de precis&atilde;o, confiabilidade ou outros. O conte&uacute;do pode incluir imprecis&otilde;es t&eacute;cnicas ou erros tipogr&aacute;ficos e a empresa pode fazer mudan&ccedil;as ou melhorias a qualquer momento.</p>
               <p>Tendo em vista que o conte&uacute;do do Guru do Cartola baseia-se no conte&uacute;do do site CartolaFC, erros ou problemas t&eacute;cnicos ocorridos no site do CartolaFC pode causar indisponibilidade de fornecimento de conte&uacute;do e servi&ccedil;os pelo Guru do Cartola.</p> <p><strong>Resultados</strong></p>
               <p>O Guru do Cartola est&aacute; sempre empenhado em fornecer conte&uacute;do cada vez mais preciso, por&eacute;m, considerando que todas as ferramentas oferecidas pelo site s&atilde;o baseadas em estat&iacute;stica aplicada ao futebol, esclarece-se que os dados fornecidos se tratam de previs&otilde;es, que podem sofrer altera&ccedil;&otilde;es na realidade. Dessa forma, o Guru do Cartola n&atilde;o se responsabiliza nem garante a exatid&atilde;o dos dados fornecidos. Como sabemos, o pr&oacute;prio esporte futebol &eacute; imprevis&iacute;vel e surpreendente.</p>
               <p>Muitos fatores ser&atilde;o importantes para determinar seus resultados reais e n&atilde;o damos garantias de que voc&ecirc; vai conseguir resultados iguais ou semelhantes aos esperados segundo as an&aacute;lises e previs&otilde;es fornecidas pelo site.</p>
               <p>Voc&ecirc; concorda e entende totalmente que o Guru do Cartola n&atilde;o &eacute; respons&aacute;vel por seu sucesso ou falha no CartolaFC e n&atilde;o faz nenhuma representa&ccedil;&atilde;o ou garantia de qualquer natureza que nossos servi&ccedil;os produzir&atilde;o resultado para voc&ecirc;.</p>
               <p><strong>Indenização</strong></p>
               <p>Você vai indenizar e isentar a Empresa, suas filiais, afiliados, licenciantes, provedores de serviço, provedores de conteúdo, empregados, agentes, administradores e diretores (referidas agora como Partes Isentas) de qualquer violação desses Termos e Condições de Uso por você, incluindo o uso do Conteúdo diferente do expresso aqui. Você concorda que as Partes Isentas não têm responsabilidade ou conexão com qualquer violação ou uso não autorizado e você concorda em remediar toda e qualquer perda, dano, julgamento, prêmios, custo, despesas e honorários advogatícios das Partes Isentas ligadas a violação. Você também indenizará e isentará as Partes Isentas de qualquer reivindicação de terceiros resultante do uso da informação contida neste site.</p>
               <p><strong>Marcas Registradas</strong></p>
               <p> Marcas e logos presentes neste site são propriedade da Empresa ou da parte que as disponibilizaram para a Empresa. A Empresa e as partes que disponibilizaram marca e logo detém todos os direitos sobre as mesmas.</p>
               <p><strong>Seguran&ccedil;a</strong></p>
               <p> Toda senha usada para este site é somente para uso individual. Você é responsável pela segurança de sua senha.</p>
               <p> É proibido usar qualquer serviço ou ferramenta conectada a este site para comprometer a segurança ou mexer com os recursos do sistema e/ou contas. O uso ou distribuição de ferramentas destinadas para comprometer a segurança (ex: programas para descobrir senha, ferramentas de crack ou de sondagem da rede) são estritamente proibidos. Se você estiver envolvido em qualquer violação da segurança do sistema, a Empresa se reserva o direito de fornecer suas informações para os administradores de sistema de outros sites para ajudá-los a resolver incidentes de segurança. A Empresa se reserva o direito de investigar potenciais violações a esses Termos e Condições de Uso.</p>
               <p>A Empresa se reserva o direito de cooperar totalmente com as autoridades competentes ou pedidos da justiça para que a Empresa revele a identidade de qualquer pessoa que publique e-mail, mensagem ou disponibilize qualquer material que possa violar esses Termos e Condições de Uso.</p>
               <p> Ao aceitar esse termo você isenta a empresa de qualquer consequência resultante de qualquer ação da empresa durante ou como resultado de suas investigações e/ou de ações tomadas resultantes de investigações tanto da empresa quanto das autoridades de justiça competentes.</p> <p><strong>Pre&ccedil;o e Processamento do Pagamento</strong></p>
               <p>Para participar do Time Guru, o usuário deve ir até a Aba Faça parte do Time Guru e realizar sua inscrição, efetuando o pagamento por meio da Plataforma Hotmart, através de cartão de crédito, boleto bancário ou paypal. Os Termos e Condições de Uso desse site podem ser acessados em <a href="https://www.hotmart.com/pt/tos/">https://www.hotmart.com/pt/tos/</a>.</p>
               <p> O preço pode ser alterado sem aviso prévio e estará informado na página. Uma vez que o usuário faça parte do Time Guru, eventuais aumentos de preço não serão repassados para ele, somente para novos membros.</p>
               <p><strong>Servi&ccedil;os Oferecidos e Disponibilizados</strong></p>
               <p>Os usuários free direito a usufruir dos seguintes serviços:</p>
               <p>De forma integral: Parciais e Valorização em Tempo Real, Pontuação Mínima para Valorizar, Time da Guruzada e Escalação do time.</p>
               <p>De forma parcial: análise de alguns jogadores.</p>
               <p>Os usuários free quando não estiverem logados no site no site não poderão: fazer comentários nos times, avaliar jogadores na coluna Você escalaria, escalar o time no cartola e salvar os times favoritos.</p>
               <p>Os membros do Time Guru terão direito a usufruir dos seguintes serviços todos de forma integral:</p>
               <p>Parciais e Valorização em Tempo Real, Previsões antes da Rodada (Pontuação Mínima para Valorizar, Pontuação e Valorização Esperadas), Time Valorização, Seleção do Guru, Prêmios do Guru, Avaliação do Time, Histórico e Estatísticas do Time do Cartoleiro, Análise Completa de cada Jogador, Comparação de Jogadores.</p>
               <p>Os membros do Time Guru Chile, além dos serviços do Time Guru Básico, participarão de liga valendo uma camisa oficial toda rodada e uma viagem para o Chile. </p>
               <p>As ferramentas do Time Guru estarão disponíveis para os membros durante toda a temporada do Campeonato Brasileiro 2019.</p> <p><strong>Direito de Arrependimento e Política de Cancelamento</strong></p>
               <p>Os novos membros do Time Guru terão 15 (quinze) dias para testar as ferramentas oferecidas.</p>
               <p>Caso o usuário, por qualquer motivo, não ache que as ferramentas trouxeram múltiplas vezes mais valor para ele do que o investimento feito, basta ele enviar um email para nossa equipe (contato@gurudocartola.com) solicitando o cancelamento da sua assinatura dentro dos quinze primeiros dias. Nesse momento será efetuado o cancelamento e devolvido 100% do dinheiro pago. Cada centavo, preto no branco, sem letra miúda de contrato. Sem ressentimentos. A partir daí, o acesso do usuário ao Time Guru será cancelado.</p>
               <p><strong>Regras de Sorteios, Ligas e Promoções Semelhantes</strong></p>
               <p>Qualquer sorteio, liga ou promoção disponibilizados no site serão regidos por regras específicas separadas destes Termos e Condições de Uso. Ao participar de qualquer sorteio, liga ou promoção, você estará; sujeito a essas regras, que poderão diferir dos termos e condições aqui estabelecidos. O Guru do Cartola recomenda que você leia as regras aplicáveis, associadas &agrave; atividade específica e que revise a Política de Privacidade que, com estes Termos e Condições de Uso, rege as informações enviadas por você; relacionadas a essas atividades.</p>
               <p><strong>Legislação aplicável</strong></p>
               <p>Estes Termos e Condições de Uso serão governados e interpretados segundo as leis da República Federativa do Brasil e todas as disputas, ações e outros assuntos relacionados serão determinados de acordo com essa legislação.</p>
               <p>&nbsp;</p>

          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     export default {
          name: 'Termos',
          components:{
               customHeader
          },
          data(){
               return {
                    
               }
          },
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     p, ul li{
          color: #4E4E4E;
     }
     strong{
          display: block;
          margin-top: 75px;
     }
</style>