<template>
    <ul class="timeline">
            <!-- <li 
                v-for="item in items" 
                is="timeline-item" 
                :item="item" :key="item.id">
            </li> -->
            <timelineItem  v-for="item in items" :item="item" :key="item.id" ></timelineItem>
            
    </ul>
</template>

<script>
import timelineItem from '@/components/TimelineItem.vue';
export default {
    props: ['items'],
    components: {timelineItem},
    data() {
        return {
            // clubs:{
            //     1:{'id':1,'ft':'https://s.glbimg.com/es/sde/f/organizacoes/2019/09/09/Athletico-PR-65x65.png', 'cor1':'red','cor2':'black','cor3':''},
            //     2:{'id':2,'ft':'https://s.glbimg.com/es/sde/f/organizacoes/2017/04/10/Atletico-GO-65.png', 'cor1':'red','cor2':'black','cor3':'red'},
            //     3:{'id':3,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/atletico_mg_60x60.png', 'cor1':'white','cor2':'black','cor3':'white'},
            //     4:{'id':4,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/bahia_60x60.png', 'cor1':'red','cor2':'white','cor3':'blue'},
            //     5:{'id':5,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/botafogo_60x60.png', 'cor1':'white','cor2':'black','cor3':'white'},
            //     6:{'id':6,'ft':'https://s.glbimg.com/es/sde/f/organizacoes/2020/01/01/45.png', 'cor1':'red','cor2':'black','cor3':'white'},
            //     7:{'id':7,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/ceara_60x60.png', 'cor1':'white','cor2':'black','cor3':'white'},
            //     8:{'id':8,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/corinthians_60x60.png', 'cor1':'white','cor2':'black','cor3':'white'},
            //     9:{'id':9,'ft':'https://s.glbimg.com/es/sde/f/equipes/2017/03/27/coritiba65.png', 'cor1':'white','cor2':'gree','cor3':'white'},
            //     10:{'id':10,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/flamengo_60x60.png', 'cor1':'red','cor2':'black','cor3':'red'},
            //     11:{'id':11,'ft':'https://s.glbimg.com/es/sde/f/equipes/2015/07/21/fluminense_60x60.png', 'cor1':'#820928','cor2':'white','cor3':'green'},
            //     12:{'id':12,'ft':'https://s.glbimg.com/es/sde/f/organizacoes/2018/06/10/fortaleza-ec-65px.png', 'cor1':'blue','cor2':'white','cor3':'red'},
            //     13:{'id':13,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/goias_60x60.png', 'cor1':'white','cor2':'green','cor3':'white'},
            //     14:{'id':14,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/gremio_60x60.png', 'cor1':'white','cor2':'blue','cor3':'black'},
            //     15:{'id':15,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/palmeiras_60x60.png', 'cor1':'green','cor2':'white','cor3':'green'},
            //     16:{'id':16,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/santos_60x60.png', 'cor1':'black','cor2':'white','cor3':'black'},
            //     17:{'id':17,'ft':'https://s.glbimg.com/es/sde/f/equipes/2014/04/14/sao_paulo_60x60.png', 'cor1':'white','cor2':'red','cor3':'black'},
            //     18:{'id':18,'ft':'https://s.glbimg.com/es/sde/f/equipes/2015/07/21/sport65.png', 'cor1':'black','cor2':'red','cor3':'black'},
            //     19:{'id':19,'ft':'https://s.glbimg.com/es/sde/f/equipes/2016/07/29/Vasco-65.png', 'cor1':'white','cor2':'black','cor3':'white'},
            // }
        }
    }
}
</script>

<style scoped lang="scss">
.timeline {
    list-style: none;
    padding: 10px 0 10px;
    position: relative;
    width: 420px;

    &:before {
        background-color: #eee;
        bottom: 0;
        content: " ";
        left: 50px;
        margin-left: -1.5px;
        position: absolute;
        top: 0;
        width: 3px;
    }

    > li {
        margin-bottom: 10px;
        position: relative;

        &:before,
        &:after {
            content: " ";
            display: table;
        }

        &:after {
            clear: both;
        }

        > .timeline-panel {
            border-radius: 2px;
            border: 1px solid #d4d4d4;
            box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
            margin-left: 100px;
            padding: 20px;
            position: relative;
          
          .timeline-heading {
            .timeline-panel-controls {
              position: absolute;
              right: 8px;
              top: 5px;
              
              .timestamp {
                display: inline-block;
              }
              
              .controls {
                display: inline-block;
                padding-right: 5px;
                border-right: 1px solid #aaa;
                
                a {
                  color: #999;
                  font-size: 11px;
                  padding: 0 5px;
                  
                  &:hover {
                    color: #333;
                    text-decoration: none;
                    cursor: pointer;
                  }
                }
              }
              
              .controls + .timestamp {
                padding-left: 5px;
              }
            }
          }
        }

        .timeline-badge {
            background-color: #999;
            border-radius: 50%;
            color: #fff;
            font-size: 1.4em;
            height: 50px;
            left: 50px;
            line-height: 52px;
            margin-left: -25px;
            position: absolute;
            text-align: center;
            top: 16px;
            width: 50px;
            z-index: 100;
        }

        .timeline-badge + .timeline-panel {
            &:before {
                border-bottom: 15px solid transparent;
                border-left: 0 solid #ccc;
                border-right: 15px solid #ccc;
                border-top: 15px solid transparent;
                content: " ";
                display: inline-block;
                position: absolute;
                left: -15px;
                right: auto;
                top: 26px;
            }

            &:after {
                border-bottom: 14px solid transparent;
                border-left: 0 solid #fff;
                border-right: 14px solid #fff;
                border-top: 14px solid transparent;
                content: " ";
                display: inline-block;
                position: absolute;
                left: -14px;
                right: auto;
                top: 27px;
            }
        }
    }
}

.timeline-badge {
    &.primary {
        background-color: #2e6da4 !important;
    }

    &.success {
        background-color: #0196a3 !important;
    }

    &.warning {
        background-color: #000000 !important;
    }

    &.danger {
        background-color: #d9534f !important;
    }

    &.info {
        background-color: #5bc0de !important;
    }
  
    &.planning {
        background-color: #00629c !important;
    }
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body {
    > p,
    > ul {
        margin-bottom: 0;
    }

    > p + p {
        margin-top: 5px;
    }
}

.copy {
  position: absolute; 
  top: 5px;
  right: 5px;
  color: #aaa;
  font-size: 11px;
  > * { color: #444; }
}


/*============================
				ANIMATIONS
=============================*/
.pulse_wrap {
	animation: pulse 1.5s ease-in-out alternate infinite;

}

@keyframes pulse {
	0% {
		opacity: 0.8;
		transform: scale(0.95);
    //margin-left: -20px;
	}
  
  100% {
		opacity: 1;
		transform: scale(1);
	}
  

}

//

#timeline-header {
    font-size: 26px;
}

.cancelled {
    text-decoration: line-through;
}

.timeline-panel.today {
  height: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px;
  margin-bottom: 10px;
  background: #000;
  &:before {
    visibility: hidden !important;
    display: none !important;
  }
  &:after {
    visibility: hidden !important;
    display: none !important;
  }
}

.timeline-badge.warning {
  top: -20px !important;
}

.timeline-panel.past {
  background: #eee;
  &:after {
    border-right: 14px solid #eee !important;
  }
}

.timeline-panel.main_element {
    font-weight: bolder;
    color: #FFFFFF !important;
    background: #0196a3;
    border-color: #0196a3 !important;
  &:after {
    border-right: 14px solid #0196a3 !important;
  }
}

.timeline-panel.selected_past {
    font-weight: bolder;
    color: #FFFFFF !important;
    background: #333;
    border-color: #333 !important;
  &:after {
    border-right: 14px solid #333 !important;
  }
}


</style>