<template>
    <div class="w-100"> 
        <div class="round-time-bar" data-style="smooth" :style="'--duration: '+countdown+';'">
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        countdown: {
            type: Number,
            required: false,
            default: 60
        }
    },
    created: function () {
        window.setInterval(() => {
            this.$emit('onTimer')
            
            const bars = document.querySelectorAll(".round-time-bar");
            bars.forEach((bar) => {
                    bar.classList.remove("round-time-bar");
                    bar.offsetWidth;
                    bar.classList.add("round-time-bar");
            });
        }, this.countdown * 1000) //60000
    },
}
</script>

<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
    /* Countdown Bar */

    .round-time-bar {
        margin-bottom: 0.5rem;
        overflow: hidden;
    }
    .round-time-bar div {
        height: 5px;
        animation: roundtime calc(var(--duration) * 1s) steps(var(--duration))
        forwards;
        transform-origin: left center;
        background: linear-gradient(to bottom, $laranja_claro, $laranja);
    }

    .round-time-bar[data-style="smooth"] div {
        animation: roundtime calc(var(--duration) * 1s) linear forwards;
    }

    .round-time-bar[data-style="fixed"] div {
    width: calc(var(--duration) * 5%);
    }

    .round-time-bar[data-color="blue"] div {
        background: linear-gradient(to bottom, #64b5f6, #1565c0);
    }

    @keyframes roundtime {
        to {
            /* More performant than `width` */
            transform: scaleX(0);
        }
    }
</style>