<template>
     <div>
          <customHeader title="COMO LIBERAR O SEU ACESSO" subtitle="" :mobile="mobile"></customHeader>
          <div class="container text-center">
               <!-- <template v-if="status == 0"> -->
                    <p>Agora você terá acesso às ferramentas que fizeram os cartoleiros alcançarem resultado excepcionais nos últimos anos.</p>
                    <p>Nesse vídeo explico como é simples fazer para liberar o seu acesso no Guru do Cartola.  Você precisa seguir esses passos para entrar em nossos grupos de Telegram, ter acesso completo a nossas estatísticas, participar da nossa premiação e todos os outros benefícios. </p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/MxL9jL8Xxk4?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <!-- <login :mobile="mobile" :redirect="'boas-vindas'"></login> -->
               <!-- </template> -->
               <!-- <template v-else-if="status == 1">
                    <h5>Ops!</h5>
                    <p>{{user.nome ? user.nome : 'Olha'}}, parece que não conseguimos localizar nenhuma compra feita com o e-mail "{{user.email}}".</p>
                    <p>Por favor, informe qual e-mail você utilizou na compra:</p>
                    <input id="user-mail" type="email" v-model="userMail" placeholder="E-mail"/>
                         <button class="btn btn-sm btn-success m-4" @click="processaEmailInformado()">Enviar</button>
               </template> -->
               <!-- <template v-else-if="status == 2">
                    <h5>Excelente!</h5>
                    <h5>Obrigado por fazer parte da nossa comunidade!</h5>
                    <p>{{user.nome ? user.nome+', ' : ''}}sua compra já foi processada e você já pode aproveitar todos os benefícios de ser um Membro Guru!</p>
                    <div v-if="user && user.telefone"> 
                         <p>Vi aqui que você também já se cadastrou nos grupos de Telegram com o número <b>{{user.telefone}}</b>.</p>
                         <p>Você já sabe mas é sempre bom lembrar das regras de participação:</p>
                         <ul style="list-style:none; text-align:left; margin-left: 11%;">
                              <li><b>a) Proibido provocação/zoação entre times ou ficar comemorando vitória do seu time.</b></li>
                              <li>b) O assunto aqui é exclusivamente Cartola FC.</li>
                              <li>c) É proibido fazer qualquer tipo de propaganda – grupos, páginas, ligas, sites, casa de apostas, etc.</li>
                              <li>d) Proibido Fake News.</li>
                         </ul>
                         <div class="mt-4">
                              <a id="submit_1" class="styled_button btn btn-warning mb-2" :class="{'mobile-btn':mobile}" href="/escale-seu-time-cartola">
                                   CLIQUE AQUI PARA ESCALAR O SEU TIME
                              </a>
                         </div>
                    </div>
                    <div v-else>
                         <p>E agora que você tornou-se um membro, que tal participar dos nossos grupos exclusivos no <b>Whatsapp</b>?</p>
                         <p>Em um dos grupos, só os administradores irão enviar mensagens. Esse é o grupo exclusivo para te enviar as dicas de cada rodada.</p>
                         <p>O segundo grupo é aberto. Feito para você interagir com outros membros do Time Guru sobre <b>CARTOLA</b>.</p>
                         <small>Somente números</small>
                         <div class="row" style="margin: 0 auto;" :class="[mobile ? 'col-12' : 'col-4']">
                              <div class="col-12">
                                   <hr>
                              </div> 
                              <div class="col-3">
                                   <input type="text" placeholder="DDD" maxlength="2" v-model="ddd" class="form-control" style="margin:0 auto;" @keypress="isNumber($event)">
                              </div>
                              <div class="col-9">
                                   <input type="text" placeholder="Número do Telegram" maxlength="9" v-model="whatsapp" class="form-control" style="margin:0 auto;" @keypress="isNumber($event)">
                              </div>
                              <div class="col-12">
                                   <br>
                                   <button style="margin:0 auto;" class="btn btn-block btn-primary" @click="save_user_whatsapp()">Cadastrar</button>
                              </div>
                         </div>
                    </div>
               </template>
               <template v-else-if="status == 3">
                    <h5>Ops!</h5>
                    <p>Infelizmente não conseguimos liberar seu usuário de imediato mas não se procupe!</p>
                    <p>Pedimos que nos envie, por gentileza, o nome do seu time e o seu comprovante de pagamento ou número da transação (enviado pela Hotmart) para o e-mail <b>contato@gurudocartola.com</b>.</p>
                    <p>Assim poderemos localizar a sua compra e liberar o seu acesso</p>
               </template> -->
               <!-- <template v-else>
                    <loading :loading="true"></loading>
               </template> -->
               <hr>
               <p>Grande abraço - Luís - Equipe Guru do Cartola</p>
          </div>
     </div>
</template>
<script>
     import util from '@/mixin/util';
     import customHeader from '@/components/header.vue';
     // import login from '@/components/login.vue';
     // import loading from '@/components/Loading.vue';
     export default {
          name: 'BoasVindas',
          mixins: [util],
          components:{
               customHeader 
               // login, 
               // loading
          },
          data(){
               return {
                    user: null,
                    status: null,
                    userMail: '',
                    ddd: '',
                    whatsapp: '',
                    numero: null,
                    
                    //Mobile Check
                    mobileAt:1000,
                    mobile:false,
               }
          },
          created() {
               var vm = this;
               
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;
               
               vm.user = vm.get_usuario_logado();
               if (vm.user) {
                    if (vm.user.telefone) {
                         vm.numero = vm.user.telefone;
                    }
                    if (vm.user.membro == 1) {
                         vm.status = 2;
                    } else if (vm.user.email) {
                         // console.log('vm.user.email: '+vm.user.email)
                         vm.verificaTransacoresPendentesEmail(vm.user.email, false);
                    }
                    
               } else {
                    vm.status = 0;
               }
          },
          mounted() {
               !function(a,b){"use strict";var c=b.createElement("iframe"),d="https://api.klicktest.com.br";c.setAttribute("src",d+'/client/conversion/#!/{"location": "'+a.location+'", "apiEndPoint": "https://api.klicktest.com.br/v1/abtests", "testId": "76de9ccc-bca6-11eb-bb13-15eb557fbbda"}'),c.style.width="0",c.style.height="0",c.style.display="none",b.body.appendChild(c)}(window,document);
          },
          methods: {
               processaEmailInformado() {
                    
                    var isEmailValid = this.validEmail(this.userMail);
                    // console.log('isEmailValid: '+isEmailValid)
                    if (isEmailValid) {
                         this.verificaTransacoresPendentesEmail(this.userMail, true);
                    } else {
                         // eslint-disable-next-line no-undef
                         Swal.fire('Ops!','O e-mail informado não é válido', 'warning');
                    }
               },
               verificaTransacoresPendentesEmail(email, isHotmart) {
                    var vm = this;
                    this.$http
                    .get(this.$PATH+'/hotmart/verify-buy/mail/'+encodeURI(email)+'/')
                    .then(response => {
                         vm.data = response.data;
                         if (response.data > 0) {
                              vm.processarTransacoesUsuarioEmail(vm.user.uuid, isHotmart ? email : '');
                         } else {
                              vm.status = isHotmart ? 3 : 1;
                         }
                    })
                    .catch(function (error) {
                         console.log('erro ao consultar as transações pendentes do usuário:')
                         console.log(error);
                         vm.status = 3;
                    });
               },
               processarTransacoesUsuarioEmail(uuid, email) {
                    var vm = this;
                    var urlRequest = this.$PATH+'/hotmart/process-buy/user/'+uuid+'/';
                    if (email) {
                         urlRequest += 'mail/'+encodeURI(email)+'/'
                    } 

                    this.$http
                    .get(this.$PATH+urlRequest)
                    .then(response => {
                         vm.user = response.data;
                         vm.set_usuario_logado(response.data);
                         vm.status = 2;
                    })
                    .catch(function (error) {
                         console.log('erro ao processar as transações do usuário/email:');
                         console.log(error);
                         vm.status = 3;
                    });
               },
               validEmail: function (email) {
                    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
               },
               save_user_whatsapp(){
                    var vm = this;
                    if(this.user != null){
                         if (this.user.membro == 1) {
                              var isnum1 = /^\d+$/.test(this.ddd);
                              var isnum2 = /^\d+$/.test(this.whatsapp);
                              if(isnum1 && isnum2){
                                   if(this.whatsapp.length >= 9 && this.ddd.length >= 2){
                                        var data = {
                                             'uuid' : this.user.uuid,
                                             'celular' : this.ddd + this.whatsapp
                                        };
                                      
                                        this.$http
                                        .post(this.$PATH+'/user/whatsapp', data)
                                        .then(() => {
                                             vm.numero = vm.ddd+vm.whatsapp;
                                             vm.user['telefone']=vm.numero;
                                             localStorage.setItem('whatsapp', vm.numero);
                                             // localStorage.setItem('auth_user', JSON.stringify(vm.user));
                                             this.set_usuario_logado(vm.user);
                                             
                                             // eslint-disable-next-line no-undef
                                             Swal.fire(
                                                  'Tudo certo!',
                                                  'Em breve você será adicionado em um de nossos grupos.',
                                                  'success'
                                             );
                                        })
                                        .catch(function (error) {
                                             console.log('error');
                                             console.log(error);
                                             // eslint-disable-next-line no-undef
                                             Swal.fire(
                                                  'Ops!',
                                                  'Ocorreu um erro. Tente novamente mais tarde.',
                                                  'error'
                                             );
                                        });

                                        // this.load_user_whatsapp();
                                        // this.modify = false;

                                   }else{
                                        // eslint-disable-next-line no-undef
                                        Swal.fire(
                                             'Ops!',
                                             'Confere o seu número aí por favor.',
                                             'error'
                                        );
                                   }
                              }else{
                                   // eslint-disable-next-line no-undef
                                   Swal.fire(
                                        'Ops!',
                                        'Por favor, digite somente números',
                                        'error'
                                   );
                              }
                         } else {
                              // eslint-disable-next-line no-undef
                              Swal.fire(
                                   'Ops!',
                                   'Os grupos de Whatsapp são exclusivos para os membros do Time Guru',
                                   'error'
                              );
                         }
                    }
               },
               isNumber: function(evt) {
                    evt = (evt) ? evt : window.event;
                    var charCode = (evt.which) ? evt.which : evt.keyCode;
                    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                         evt.preventDefault();
                    } else {
                         return true;
                    }
               }
          },
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';
     // p,h3,h5,ul li{
     //      color:$light_text_color;
     // }
     h5 {
          color: $laranja;
          font-weight: bolder;
     }
     strong{
          display: block;
          margin-top: 75px;
     }
</style>