<template>
  <div class="container">
    <br/><br/><br/><br/><br/>
    <div class="row">
      <div class="col-12">
        <table class="table endpoints">
          <tr>
            <th>Endpoint</th>
            <th>Acessos</th>
          </tr>
          <tr v-for="c in contagens" :key="c.path">
            <td>{{c.path}}</td>
            <td>{{c.count}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "metrica-count",
  data() {
    return {
      mobileAt: 1000, //Mobile a partir de qual width?
      mobile: false, //Status inicial do mobile
      contagens: [],
    };
  },
  mounted() {
      var vm = this;
      this.$http
      .get(this.$PATH+'/metrica/counts')
      .then(response => {
            vm.contagens = response.data
      })
      .catch(function (error) {
            console.log('[error #3]:')
            console.log(error);
      });   


      
  },
  created() {
    //Verificação mobile
    window.innerWidth < this.mobileAt
      ? (this.mobile = true)
      : (this.mobile = false);
  },

  methods: {


  }
};
</script>

<style scoped lang="scss">
.endpoints td {
  font-size: 10px;
  padding: 1px;
}

.endpoints tr:hover {
  color: white;
  background-color: #4F4F4F;
}
</style>
