<template>
  <div id="intro">
    <div id="bg_header" class="title-header">
      <div class="mask">
        <br />
        <h1>RECRUTAMENTO</h1>
        <br />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <!-- <div class="col-12">
          <h1 class="text-center">Guru do Cartola 2020</h1>
          <hr />
        </div> -->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <p style="text-align:center;"><b style="text-transform:uppercase;">Estágio – Criação de artes e edição de vídeo</b></p>
          <p>
            Você é apaixonado(a) por edição de imagem e vídeo, gosta de futebol e de Cartola? Então o Guru do Cartola é o seu lugar. <br/><br/>
            O Guru do Cartola surgiu em maio/2017 com uma proposta totalmente inovadora para ajudar os amantes do Cartola FC (fantasy game de Futebol - organizado pela Globo/SporTV).<br/><br/>
            O projeto se propôs a usar Machine Learning para prever a performance dos jogadores no game Cartola FC.<br/><br/>
            Em seu primeiro ano, o projeto contou com mais de 720 mil usuários.<br/><br/>
            Hoje o projeto foi estendido, ganhou nova cara e já acumulou um total de 3mi usuários.<br/><br/>
            São mais de 250 mil seguidores no Instagram, 80 mil curtidas no Facebook e 50 mil inscritos no Youtube.<br/><br/>
            Agora, em 2021, precisamos de você para nos ajudar a elevar nosso projeto para um nível ainda maior!<br/><br/>
            O nosso ambiente de trabalho é saudável e completamente aberto a sugestões/palpites!<br/><br/>
            O profissional irá atuar em nossa área de criação de artes e edição de vídeos, garantindo a melhor qualidade audiovisual possível para nossos usuários.<br/><br/>
            Além disso, é importante que goste de redes sociais e futebol!<br/><br/>
            Também é indispensável que você tenha muita disciplina e comprometimento, pois o trabalho será remoto.<br/><br/>
            SIM, VOCÊ FARÁ HOME-OFFICE! :)
          </p>
          <br />
        </div>
        <div
          id="CTA_1"
          class="form-inline custom_CTA w-75 justify-content-center"
          style="z-index:1;"
          :style="mobile?'margin-bottom:-55px':'margin-bottom:-32px'"
        >
            Topa o desafio? Então queremos você em nosso time!
        </div>
      </div>
    </div>
  <section id="section_0" :style="mobile?'padding-top:80px;':''">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12"></div>
          <div class="col-lg-8 col-md-8 col-sm-12">
            <p>
              Atividades a serem executadas:
              <ul>
                <li>* Criação de artes para redes sociais</li>
                <li>* Edição de vídeo para o Youtube e redes sociais</li>
                <li>* Interação com os seguidores nas postagens e mensagens privadas</li>
              </ul>
            </p>

            <p>
              Requisitos:<br/>
              <ul>
                <li>- Comprometimento</li>
                <li>- Proatividade</li>
                <li>- Gostar de redes sociais</li>
                <li>- Acompanhar futebol</li>
                <li>- Edição de foto avançado (Ex. Photoshop)</li>
                <li>- Edição de vídeo intermediário/avançado (Ex. Adobe Premiere)</li>
              </ul>
            </p>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12"></div>
          <div
          id="CTA_2"
          class="form-inline custom_CTA w-75 justify-content-center"
          style="z-index:1;"
          :style="mobile?'margin-bottom:-65px':'margin-bottom:-57px'"
        >
            Chegou a sua hora de ser titular...
        </div>
        </div>
      </div>
      
    </section>

    <section id="section_1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p style="text-align:center;">
                Interessados, favor enviar currículo para <b>contato@gurudocartola.com</b> com o assunto <b>ESTÁGIO</b>. 
            </p>
        </div>
      </div>
      </div>
    </section>


  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {

      //Verificação mobile
      mobileAt: 1000, //Mobile a partir de qual width?
      mobile: false, //Status inicial do mobile
      col: null
    };
  },
  created() {
    //Verificação mobile
    window.innerWidth < this.mobileAt
      ? (this.mobile = true)
      : (this.mobile = false);
  },
  mounted() {
    //Depois que a página estiver com o DOM implementado, defina o valor de intervalo do slider
    //     $(".carousel").carousel({
    //       interval: 3000
    //     });
    /* Image Desactivation */
    if ($(window).width() <= 825) {
      $("#desktop_extension").remove();
    }
  },
  methods: {
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

/*Fonts*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i|Ubuntu:300,300i,400,400i,500,500i,700,700i");

body {
  font-family: "Ubuntu", sans-serif;
  background-color: #f5f5f5 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e3e3e3' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

/*Background header with image and title*/
.title-header {
  margin-top: 67px;
  border-bottom: 3px solid orange;
}
#btn-yt:hover {
     color: white;
}

#intro {
  background-color: #f5f5f5 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e3e3e3' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
#bg_header {
  background: url("/images/soccer_horizontal_field.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-top: 3px solid orange;
}
#bg_header .mask {
  background-color: rgba(0, 0, 0, 0.45);
}
#bg_header .mask h1 {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-transform: uppercase;
  padding: 35px;
  font-weight: 200;
  letter-spacing: 5px;
  color: white;
  text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.95);
}

.styled_title {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-transform: uppercase;
  padding: 35px;
  font-weight: 200;
  letter-spacing: 5px;
}

/*main div with background white*/
#tool {
  background-color: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
}
#tool h3,
p {
  padding: 10px 20px;
}
#tool h3 {
  font-weight: 400;
}
#tool button {
  border: 0px;
  border-radius: 0px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
}

#section_0 {
  background-color: #f29c00;
  color: white;
  // background-image: linear-gradient(#f29c00 40%, #f26500);
}
#section_0:before {
  content: "";
  position: absolute;
  // border-top: 50px solid #f29c00;
  width: 100%;
  // transform-origin: 0% 0%;
}

#section_0,
#section_1,
#section_2 {
  padding-top: 50px;
  padding-bottom: 25px;
}
#section_1 {
  background-color: #0aa75e;
  color: white;
  background-image: linear-gradient(#0aa75e 60%, #077140);
}
#section_1:before {
  content: "";
  position: absolute;
  // border-top: 50px solid #0aa75e;
  width: 100%;
  // transform-origin: 0% 0%;
  margin-top: -30px;
}
#section_1 span {
  border-bottom: 2px solid #2d2d2d;
}
#section_0 form,
#section_1 form,
.custom_CTA {
  padding: 20px;
  margin: 0 auto;
  // margin-bottom: -32px;
  z-index: 1;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  color: orange; 
  text-transform: uppercase;

  font-family: "Montserrat", sans-serif;
  text-align: center;
  // padding: 35px;
  font-weight: bolder;
  // letter-spacing: 5px;
  // color: white;
  // text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.95);

}
#section_0 form,
.custom_CTA {
  background-color: #0b0b0b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
#section_1 form {
  background-color: #0b0b0b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23006723' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
#section_1 form button {
  border-radius: 5px;
  color: #023c21;
  font-weight: bolder;
  letter-spacing: 1px;
  background-color: #0aa75e;
  border-bottom: 2px solid #023c21;
  white-space: normal;
}
#section_0 form button,
.custom_CTA button {
  border-radius: 5px;
  color: #753403;
  font-weight: bolder;
  letter-spacing: 1px;
  background-color: #f29c00;
  border-bottom: 2px solid #a74903;
  white-space: normal;
}
#section_0 ul li {
  list-style-type: none;
}
#section_2 {
  background-color: #2d2d2d;
  color: white;
  background-image: linear-gradient(#2d2d2d 60%, #151515);
}
#section_2:before {
  content: "";
  position: absolute;
  border-top: 50px solid #2d2d2d;
  width: 100%;
  // transform-origin: 0% 0%;
}
#section_2 h5 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  color: white;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.95);
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  border-bottom: none !important;
}
.carousel-control-next-icon {
  margin-right: -150px;
}
.carousel-control-prev-icon {
  margin-left: -150px;
}
.carousel-inner {
  -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.75);
}
#section_2 .carousel-item img {
  width: 400px !important;
  height: 400px !important;
}
#section_2 span {
  border-bottom: 2px solid #0aa75e;
}
.styled_button {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 3px;
  border-radius: 0px;
}

#social {
  background-color: orange;
}
#social i {
  margin: 0 auto;
  color: #543701;
  font-size: 25px;
  background-color: #d08702;
  padding: 15px;
}

#social i:hover {
  color: orange;
  background-color: #543701;
}

@media only screen and (max-width: 500px) {
  #bg_header .mask h1 {
    font-size: 30px !important;
  }
  #section_0 form {
    width: 80%;
  }
  #section_1 form {
    width: 80%;
  }
}
</style>
