<template>
  <div id="guru-vip">
        <section id="header" class="mt-5 mb-3 pb-3" style="border-bottom: 2px solid orange;">
               <div class="text-center">
                <div class="row">
                        <div class="col-12">
                            <h1 style="font-family: 'Ubuntu', sans-serif; color:#ff7e00; font-size: 2.5em">
                                <img src="https://images2.imgbox.com/b2/9e/b6UDNUZ8_o.png" class="img img-fluid">
                                Guru do Cartola
                            </h1>
                        </div>
                </div>
            </div>
        </section>
        <section id="s_1" class="section pt-3">
            <!-- <div class="container"> -->
                <div class="text-center">
                    <div class="row justify-content-center barra-titulo" >
                        <h2 class="d-flex align-items-center text-center">
                            Chegou a sua hora de ficar na frente de milhões de cartoleiros
                        </h2>
                    </div>
                    <!-- <div class="row col-12 justify-content-center content"> -->
                    <!-- </div;> -->
                    <div class="row col-12 justify-content-center content">
                        <h3>Garanta agora as dicas e estatísticas que fazem milhares de cartoleiros ganharem diversas ligas no ano!</h3>
                        <p class="w-100">Junte-se à maior e mais vencedora comunidade cartoleira!</p>
                        <p class="w-100">O melhor conteúdo do Cartola para o <strong>final desse Brasileirão</strong> e <strong>TODO</strong> o <strong>Brasileirão <span class="destaque">2021</span></strong>!</p> 
                    </div>
                    <div class="d-flex" :class="mobile?'flex-column':'justify-content-center'" >
                        <img src="/img/sell/sellpage1.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                    </div>
                    <div class="row col-12 justify-content-center" >
                        <span class="d-flex align-items-center frase text-center" style="padding: 20px 50px; background-color:#FFA500;">
                            Esses cartoleiros e milhares de outros assinaram o Time Guru, seguiram as dicas e agora esbanjam títulos em ligas e muita grana que ganharam através delas!
                        </span>
                    </div>
                    <div class="d-flex justify-content-center p-3">
                        <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                            <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>Quero fazer parte do Time Guru agora
                        </a>
                    </div>
                </div>
        </section>

        <section id="s_3" class="section">
            <div class="container">
                <div class="text-center">
                    <div class="row justify-content-center barra-titulo">
                        <h2>E COMO SÃO AS DICAS?</h2>
                    </div>
                    
                    <!-- ANALISE DE CADA JOGADOR -->
                    <div class="row col-12 justify-content-center content mt-5">
                        <div class="section-title w-100">
                            <h2>Análise de cada jogador</h2>
                        </div>
                        <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                            <img src="/img/sell/sellpage2.gif" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                        </div>
                        <p class="w-100"><i>"Você sabia que o <span>Guru do Cartola</span> é a <strong>única</strong> comunidade do Cartola FC <strong>que disponibiliza um site + um aplicativo próprio + grupos de Whatsapp</strong> para os assinantes no mesmo plano de assinatura?"</i></p>
                        <p class="w-100">Isso aí, você vai ter disponível um <strong>site</strong> e um <strong>aplicativo</strong> do Guru, fora os grupos de Whatsapp, porque aqui não brincamos em serviço! E você terá o acesso completo ao site e app:</p>

                        <p class="w-100 card-title">
                            <span class="d-flex justify-content-center text-center w-100">
                                <span>Por lá, <strong>você vai ver a média recente de todos os jogadores</strong>.</span>
                            </span>
                        </p>
                        <p class="w-100 card-box">Isso te ajuda a escolher melhores opções e melhores apostas</p>
                        
                        <p class="w-100 card-title">
                            <span class="d-flex justify-content-center text-center w-100">
                                <span>Com o acesso completo, você vai poder &nbsp;<strong>filtrar por mando de campo</strong>.</span>
                            </span>
                        </p>
                        <p class="w-100 card-box">Ou seja, você vai conseguir analisar se ele está em boa fase, má fase ou se só pontua dentro ou fora de casa</p>
                        
                        <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                            <img src="/img/sell/sellpage3.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                        </div>
                        
                        <p class="w-100 card-title">
                            <span class="d-flex justify-content-center text-center w-100">
                                <span>Você vai ver a &nbsp;<strong>pontuação cedida</strong>!</span>
                            </span>
                        </p>
                        <div class="w-100 card-box">
                            <p><i style="border-bottom:2px dotted orange;"><strong>Mas o que é isso?</strong></i> <br/> Pontuação cedida é a média de pontos que os jogadores fizeram quando jogaram contra os mesmos adversários</p>
                            <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                                <img src="/img/sell/sellpage4.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                            </div>

                        </div>
                        
                        <p class="w-100"><strong>Tudo isso em menos de 10 segundos, em todas as rodadas na sua tela!</strong></p>
                    </div>
                    
                    <!-- GRUPOS DE WHATSAPP -->
                    <div class="row col-12 justify-content-center content  mt-5">
                        <div class="section-title w-100">
                            <h2>Grupos de Whatsapp</h2>
                        </div>
                        <p class="w-100">O Guru do Cartola não brinca em serviço...</p>
                        <p class="w-100">Você terá acesso a <strong>2 grupos</strong> de Whatsapp exclusivos!</p>
                        <p class="w-100"><i style="border-bottom:2px dotted orange;"><strong>“Mas pra que 2?”</strong></i></p>
                        <p class="w-100">Em um deles você ficará em contato com diversos outros membros do Time Guru</p>
                        <p class="w-100">Nesse, vocês trocarão sugestões e informações sobre jogadores e sobre sua escalação</p>
                        <p class="w-100">É impressionante como a guruzada se ajuda e como a discussão é de alto nível!</p>
                        <p class="w-100"><i style="border-bottom:2px dotted orange;"><strong>“E o outro grupo?”</strong></i></p>
                        <p class="w-100">O outro é o <i>“só adm”</i>. É por ali onde os ADM´s mandam dicas e informações valiosas para te ajudar a mitar!</p>
                        <p class="w-100">É nesse que você vai receber:</p>
                    </div>

                    <!-- CRUZAMENTO DE DADOS QUE MOSTRAM OTIMAS OPCOES PARA A RODADA -->
                    <div class="row col-12 justify-content-center content  mt-5">
                        <div class="section-title w-100">
                            <h2 style="font-size: 1.2em;">Cruzamento de dados que mostram ótimas opções para a rodada</h2>
                        </div>
                        <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                            <img src="/img/sell/sellpage5.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                        </div>

                        <p>Como você pode ver, o documento mostra, nesse exemplo, quais goleiros têm mais chance de fazer defesa difícil e segurar o saldo de gol!</p>
                        <p>Qualquer dúvida sobre scout ou nomenclatura, é só falar com um ADM que vamos te ajudar.</p>
                    </div>

                    <!-- LISTA DE COBRADORES DE FALTAS, PÊNALTIS E LÍDERES EM PASSES PARA FINALIZAÇÃO -->
                    <div class="row col-12 justify-content-center content mt-5">
                        <div class="section-title w-100">
                            <h2 style="font-size:1.2em;">Lista de cobradores de faltas, pênaltis e líderes em passes para finalização</h2>
                        </div>
                        <p>Aqui você fica sabendo quantas faltas o cobrador já bateu, quantas foram gol e a média de faltas perigosas que o adversário oferece!</p>
                        <p>Isso é importantíssimo, pois escalar cobradores é uma ótima arma para conseguir pontuações maiores</p>
                        <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                            <img src="/img/sell/sellpage6.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                        </div>
                        <p>Você vai saber quem bate os pênaltis e se o jogador costuma converter!</p>
                         <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                            <img src="/img/sell/sellpage7.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                        </div>
                        <p>Você vai saber quem tem maior chance de dar assistência!</p>
                        <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                            <img src="/img/sell/sellpage8.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                        </div>
                        <p>Essa é outra arma importantíssima e que vai te ajudar a escalar um time melhor que o de seus adversários!</p>
                        <p>Esse conteúdo te deixará na frente de milhões de adversários no cartola!</p>
                        <p>você vai ver isso tudo <strong>em menos de 1 minuto</strong>! Na palma da sua mão ou na tela do seu computador</p>
                        
                        <div class="d-flex justify-content-center mt-3">
                            <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                                <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>Quero garantir minha vaga agora
                            </a>
                        </div>
                    </div>

                    <!-- ANÁLISE DE ARBITRAGEM -->
                    <div class="row col-12 justify-content-center content mt-5">
                        <div class="section-title w-100">
                            <h2>Análise de arbitragem</h2>
                        </div>

                        <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                            <img src="/img/sell/sellpage9.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                        </div>

                        <p>Saber quais juízes marcam mais faltas, pênaltis e dão mais cartões, por exemplo, também vai te ajudar no momento da sua escalação!</p>
                        <p>O juiz que marca menos falta favorece os ladrões de bola</p>
                        <p>O juiz que dá mais cartão te deixa com o alerta ligado sobre os jogadores nervosinhos</p>
                        <p>O juiz que marca mais pênalti deixa claro que os cobradores daquela partida têm maior chance de conseguirem essa super chance de +8 pontos</p>
                    </div>

                    <!-- TOP 5 GURU DO CARTOLA -->
                    <div class="row col-12 justify-content-center content mt-5">
                        <div class="section-title w-100">
                            <h2>Top 5 Guru do Cartola</h2>
                        </div>
                        <div class="d-flex" :class="{'col-3':!mobile}">
                            <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                                <img src="/img/sell/sellpage10.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                            </div>
                        </div>
                        <div :class="{'col-8':!mobile}">
                            <p>Você vai receber as 5 melhores opções de todas as posições!</p>
                            <p>A análise é feita pelos 3 melhores cartoleiros da equipe!</p>
                            <p>Um já ganhou vários prêmios do Guru antes de trabalhar aqui, o outro joga Cartola desde 2009 e o outro sou eu, Luís, que já faturei milhares de reais ganhando ligas!</p>
                            <p>Ou seja, em segundos você vai ver os 5 melhores jogadores de cada posição toda rodada!</p>
                        </div>
                    </div>

                    <!-- DE ONDE SAIRAM OS GOLS -->
                    <div class="row col-12 justify-content-center content mt-5">
                        <div class="section-title w-100">
                            <h2>De onde saíram os gols</h2>
                        </div>

                        <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                            <img src="/img/sell/sellpage11.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;max-height:500px;">
                        </div>

                       <p>Você vai saber por onde o time faz mais gol e por onde leva mais gol</p>
                        <p>Assim, poderá cruzar os dados e saber, em questão de segundos, se um time leva muito gol do lado esquerdo, por exemplo, e se o adversário faz muito gol pelo mesmo lado</p>
                        <p>Nesse caso, a análise mostra que 3 dos últimos 5 gols do Sport foram perto da pequena área…</p>
                        <p>...E 3 dos últimos 5 gols sofridos do Bahia também foram por ali.</p>
                        <p>Nesse jogo, o Sport ganhou de 2x0 com 2 gols feitos próximos da pequena área ;)</p>
                    </div>

                    <!-- NOTICIAS E DICAS BONUS -->
                    <div class="row col-12 justify-content-center content mt-5">
                        <div class="section-title w-100">
                            <h2>Notícias e dicas bônus</h2>
                        </div>
                        <div class="d-flex" :class="{'col-4':!mobile}">
                            <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                                <img src="/img/sell/sellpage12.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange;">
                            </div>
                        </div>
                        <div :class="{'col-8':!mobile}">
                            <p>Você vai receber os desfalques de cada time e informações valiosas sobre cada equipe</p>
                            <p>você vai ver os melhores cruzamento de dados e estatísticas de todos os times, mostrando quais são os melhores jogadores a serem escalados e os motivos.</p>
                            <p>Isso vai fazer você escalar as apostas e opções que têm maior chance de mitar!</p>
                            <!-- <p>Além disso, quando tiver jogo de mata-mata no meio da semana, você vai saber com quantos minutos os melhores jogadores foram substituídos. Sem precisar pesquisar, tudo estará em suas mãos nesse conteúdo!</p>
                            <p>Assim, você sabe quem está mais cansado e tem mais chance de ser poupado e quem teve mais minutos de descanso e tem mais chance de mitar no final de semana do cartola!</p> -->
                            <div class="d-flex justify-content-center mt-3">
                                <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                                    <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>Quero receber essas dicas
                                </a>
                            </div>
                            <br/>
                            <!-- <p>Tá achando bom né? Mas ainda tem muito benefício por vir!</p>
                            <p>Lembra do acesso completo ao site + app??</p>
                            <p>Por lá você ainda vai poder:</p> -->
                        </div>
                    </div>

                    <!-- NOTICIAS E DICAS BONUS -->
                    <div class="row col-12 justify-content-center content">
                        <p class="w-100" style="border-bottom:2px dashed orange;">&nbsp;</p>
                        <p class="w-100">Tá achando bom né? Mas ainda tem muito benefício por vir!</p>
                        <p class="w-100">Lembra do acesso completo ao site + app?? Por lá você ainda vai poder:</p>
                        
                        <p class="w-100 card-title">
                            <span class="d-flex justify-content-center text-center w-100">Enviar seu time para avaliação</span>
                        </p>
                        <p class="w-100 card-box" >Isso mesmo! Receber a minha análise toda rodada!</p>
                        
                        <p class="w-100 card-title">
                            <span class="d-flex justify-content-center text-center w-100">Escalar seu time direto no site/app</span>
                        </p>
                        <p class="w-100 card-box">Escalar já com estatísticas na sua frente, de forma muito mais eficaz para quem quer mitar</p>
                        
                        <p class="w-100 card-title">
                            <span class="d-flex justify-content-center text-center w-100">Ver estatísticas do seu time</span>
                        </p>
                        <div class="w-100 card-box">
                            <p class="w-100">Você vai ter acesso a mais uma área exclusiva!</p>
                            <div class="d-flex">
                                <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                                    <img src="/img/sell/sellpage16.jpg" class="img img-fluid adjusted_4" style="border:0.15em solid orange; max-height:500px;">
                                </div>
                            </div>
                            <p class="w-100">Você vai poder ver quais posições sua estratégia de escalação está dando certo e quais você precisa mudar a estratégia!</p>
                        </div>
                        
                        <div class="d-flex justify-content-center mt-3">
                            <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                                <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>Quero receber essas dicas
                            </a>
                        </div>
                        <br/><br/>
                        
                        <p class="w-100 mt-4"><i style="border-bottom:2px dotted orange;"><strong>“Ah Guru, mas tenho pouco dinheiro”</strong></i></p>
                        <p class="w-100">Rapaz, assim como milhares de sócios nossos, esse investimento no Time Guru pode ser triplicado ou até quintuplicado em menos de 1 ano!</p>
                        <!-- <p class="w-100">Alguns dos membros:</p> -->
                        <p class="w-100"><i style="border-bottom:2px dotted orange;"><strong>Alguns dos membros:</strong></i></p>
                        <div class="d-flex">
                            <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                                <img :src="mobile?'/img/sell/sellpage13_v.png':'/img/sell/sellpage13_h.png'" class="img img-fluid adjusted_4">
                            </div>
                        </div>
                        <p class="w-100"><i style="border-bottom:2px dotted orange;"><strong>“Ah Guru, mas eu nem jogo liga apostando. O Time Guru é pra mim?”</strong></i></p>
                        <p class="w-100">Sim, porque você vai ter direito a vários <strong style="border-bottom:2px dotted orange;">BÔNUS</strong></p>
                        
                        <p class="w-100 card-title">Toda rodada você vai concorrer a uma <span  style="border-bottom:2px dotted black;">camisa oficial</span>!</p>
                        <div class="w-100 card-box">
                            <div class="col-12">
                                <img src="/img/sell/sellpage14.gif" class="img img-fluid adjusted_4" style="border:0.15em solid orange; max-width:300px;">
                                <TabHallFama :mobile="mobile"></TabHallFama>
                            </div>
                        </div>

                        <p class="w-100 card-title">
                            <span><strong>Todo mês</strong> você vai concorrer a <strong>mil reais</strong>!</span>
                        </p>
                        <div class="w-100 card-box">
                            <div class="col-12">
                                <div class="d-flex w-100" :class="mobile?'flex-column':'justify-content-center'" >
                                    <img src="/img/money.png" class="img img-fluid adjusted_4">
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center mt-3">
                            <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                                <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>Quero concorrer aos prêmios!
                            </a>
                        </div>

                        
                        <p class="w-100 mt-4">Se você sabe que as estatísticas podem fazer você ganhar muito mais, o Time Guru é para você!</p>
                        <!-- <p class="w-100 mt-4">Como a gente sempre quer te ajudar, se liga no resumo do que vai te ajudar a ganhar as ligas:</p> -->
                        <!-- <p>((((https://www.instagram.com/stories/highlights/17945332012397894/))))</p> -->

                        <p class="w-100">Aproveite o MENOR PREÇO DO ANO</p>
                        <p class="w-100"><span style="color:green; font-weight:bold;">R$2,00</span> por rodada!</p>
                        <p class="w-100">Ou seja:</p>
                        <p class="w-100"><span style="color:green; font-weight:bold;">R$76,00</span></p>
                        <p class="w-100">Oferta especial por <span style="border-bottom:3px solid orange;">TEMPO LIMITADO</span></p>
                        <p class="w-100">De <span style="color:orange; font-weight:bold; text-decoration: line-through; ">R$97</span> por <span style="color:green; font-weight:bold;">R$76</span></p>
                        <p class="w-100">Pagamento:</p>
                        <p class="w-100">Cartão de Crédito, Transferência, PIX ou boleto</p>
                        <p class="w-100"><span style="border-bottom:3px solid orange;"><strong>RISCO ZERO</strong></span>: Se ainda com tudo isso você achar que o Time Guru não é para você, é só pedir reembolso em até 15 dias que te devolvo 100% do seu dinheiro! Sem burocracia!</p>
                        <p class="w-100">Se ainda com tudo isso você achar que o Time Guru não é pra você, é só pedir reembolso em até 15 dias que te devolvo 100% do seu dinheiro! Sem burocracia!</p>

                        <!-- <div class="d-flex justify-content-center mt-3">
                            <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://hotm.art/guru2021_">
                                <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>Fazer parte do time guru
                            </a>
                        </div> -->
                    </div>

                    
                    <!-- MAS QUEM SOU EU -->
                    <div class="row col-12 justify-content-center content mt-5">
                        <div class="section-title w-100">
                            <h2>Mas quem sou eu?</h2>
                        </div>


                         <div class="col-lg-4 col-md-4 col-sm-12">
                            <img src="http://static-public.klickpages.com.br/uploads/media/file/868080/sobre_o_guru_imagem.png" class="img img-fluid" style="border:0.15em solid orange;">
                            <br>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-12">
                            <p>
                                Estatístico e apaixonado por futebol, Luís Otávio resolveu unir suas duas paixões e, em agosto de 2016, começou a calcular as probabilidades dos times vencerem seus jogos e estudou de forma profunda o algoritmo de valorização do fantasy game Cartola FC, até descobrir, depois de 9 meses, exatamente como ele funciona e todos os fatores que são levados em consideração. Em maio de 2017, lançou o site Guru do Cartola.
                            </p>
                            <p>
                                O Guru do Cartola amplia a diversão do cartoleiro, dando informações incrivelmente valiosas e precisas com base no conhecimento aprofundado do algoritmo de valorização do Cartola FC e em diversas análises dos números do jogo e dos jogadores, transmitindo de forma simples para que você suba no ranking das ligas que disputa e também dando várias chances de ganhar prêmios sensacionais, como a camisa do seu tão amado time. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="s_4" class="section">
            <div class="container">
                <div class="text-center">
                    <div class="row col-12 justify-content-center content mt-5">
                        <div class="section-title w-100">
                            <h2>Faça como esses cartoleiros</h2>
                        </div>
                        <p class="w-100">Entre para o Time Guru, siga as dicas e termine o campeonato recheado de títulos!</p>
                        <div class="d-flex justify-content-center">
                            <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                                <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>Garantir minha vaga agora
                            </a>
                        </div>
                        <div class="col-12 mt-4">
                            <img src="/img/sell/sellpage15.gif" class="img img-fluid adjusted_4" style="border:0.15em solid orange; max-width:300px;">
                        </div>
                        <p class="w-100">Seguindo as estatísticas você tem uma chance muito maior de ganhar as ligas!</p>
                    </div>
                </div>
            </div>
        </section>

        <section id="s_14" class="section">
            <div class="container">
                <div class="section-title w-100">
                    <h2>Tem alguma dúvida?</h2>
                </div>
                <div class="text-left">
                        <div id="q_1" onClick="$('#q_1_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down" style="color:#F4762D;"></i>&nbsp;
                                O que é o Time Guru e para quem ele foi feito?
                            </h4>
                            <div id="q_1_content" style="display:none;">
                                <p>
                                    O Time Guru é um programa de membros válido por todo o Campeonato Brasileiro de Futebol e foi feito para os Cartoleiros que querem levar o jogo Cartola FC ainda mais a sério e ter acesso a conteúdo exclusivo e inédito, escalar com inteligência, virar MITO, se dar bem nas ligas que disputa, além de ganhar prêmios incríveis todas as rodadas na Liga do Guru.
                                </p>
                            </div>
                        </div>
                        <div id="q_2" onClick="$('#q_2_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down" style="color:#F4762D;"></i>&nbsp;
                                É preciso pagar mais de 1 vez?
                            </h4>
                            <div id="q_2_content" style="display:none;">
                                <p>NÃO! Você paga apenas 1 vez e tem acesso ao conteúdo para o ano inteiro!</p>
                            </div>
                        </div>
                        <div id="q_21" onClick="$('#q_21_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down" style="color:#F4762D;"></i>&nbsp;
                               Posso parcelar?
                            </h4>
                            <div id="q_21_content" style="display:none;">
                                <p>Sim! Você pode parcelar em até 4x!</p>
                            </div>
                        </div>
                        <div id="q_3" onClick="$('#q_3_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down" style="color:#F4762D;"></i>&nbsp;
                                Como posso realizar o pagamento para me tornar membro do Time Guru e em quanto tempo meu acesso aos serviços do Time Guru será liberado?
                            </h4>
                            <div id="q_3_content" style="display:none;">
                                <p>
                                    O pagamento de sua inscrição no Time Guru é processado pela plataforma de pagamentos Hotmart, que protege seus dados pessoais e de pagamento de forma 100% segura. O pagamento pode ser feito por cartão de crédito, boleto bancário ou débito bancário.<br>
                                    Assim que seu pagamento for processado e recebido pelo nosso banco de dados, você terá acesso imediato às ferramentas exclusivas dos membros do Time Guru.<br>
                                    Caso o email informado na compra seja o mesmo da conta do Cartola, basta fazer login no site. Se não, você receberá um email com informações para fazer login.
                                </p>
                            </div>
                        </div>
                        <div id="q_4" onClick="$('#q_4_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down" style="color:#F4762D;"></i>&nbsp;
                                É preciso entrar em liga para participar dos prêmios?
                            </h4>
                            <div id="q_4_content" style="display:none;">
                                <p>
                                   NÃO! Você não vai precisar se preocupar com espaço em liga.
                                </p>
                            </div>
                        </div>
                        <div id="q_5" onClick="$('#q_5_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down" style="color:#F4762D;"></i>&nbsp;
                                Como posso entrar em contato com o Guru para tirar dúvidas sobre o Time Guru? 
                            </h4>
                            <div id="q_5_content" style="display:none;">
                                <p>
                                    Qualquer dúvida que tiver, é só mandar um e-mail para contato@gurudocartola.com
                                </p>
                            </div>
                        </div>
                        <div id="q_6" onClick="$('#q_6_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down" style="color:#F4762D;"></i>&nbsp;
                                Quanto tempo depois do pagamento eu recebo os conteúdos? 
                            </h4>
                            <div id="q_6_content" style="display:none;">
                                <p>
                                    Pagando no cartão ou por transferência você garante tudo no mesmo dia. Já o boleto leva até 2 dias úteis para a confirmação. Ou seja, você recebe tudo em até 48 horas depois do pagamento do boleto
                                </p>
                            </div>
                        </div>
                         <div id="q_7" onClick="$('#q_7_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down" style="color:#F4762D;"></i>&nbsp;
                                Para quem não é o Time Guru?
                            </h4>
                            <div id="q_7_content" style="display:none;">
                                <p>
                                    Para ansiosos que não entendem que as estatísticas vão fazer eles terminarem o Cartola com mais prêmios do que se eles escalassem sem elas. <br/>
                                    Também não é para aqueles que compram, não leem nenhuma dica e depois querem botar na conta do Guru. <br/>
                                    <br/>
                                    Aproveite o conteúdo que você investiu para ter acesso e veja os resultados chegarem! <br/>
                                    Com nossas dicas e estatísticas, você estará na frente de milhões de cartoleiros! <br/>
                                </p>
                            </div>
                        </div>
                        <br><br>
                        <div class="text-center">
                            <!-- <h4 class="text-center">
                                Clique no Botão Abaixo e Garanta sua Vaga! <br><br>
                            </h4> -->
                            <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                                <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>Quero fazer parte do time guru
                            </a>
                        </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>
import TabHallFama from '@/views/tabs/tabHallFama.vue';
export default {
    components: {
        TabHallFama
    },
    data(){
        return {
            mobileAt:1000,//Mobile a partir de qual width?
            mobile:false,//Status inicial do mobile
        }
    },
    created(){
        //windowWidth defining mobile and fieldView
        (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;
    }

}
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
// @import "@/assets/scss/company.scss";

/*Fonts*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i|Ubuntu:300,300i,400,400i,500,500i,700,700i");
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i");

// #guru-vip {
     /* color: orange; */
    // font-family: "Montserrat", sans-serif;
    /* text-transform: uppercase; */
    /* font-weight: bolder; */
// }

h4 {
    // font-family: "Montserrat", sans-serif;
    font-weight: 200;
    letter-spacing: 1px;
}

.color-box {
    background-color: #D2CDD9 ;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 25px;
}

.barra-titulo {
          background-color: #0b0b0b;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          // border-bottom: 3px solid orange;
          // border-top: 3px solid orange;
          padding: 10px 10px 5px 10px;
          z-index: 1;
          -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
        //   margin-top: -32px;
          color: $laranja_claro;
          text-transform: uppercase; 
          margin-bottom: 20px;
          border-top: 3px solid $laranja_claro;
          border-bottom: 3px solid $laranja_claro;
}

.frase {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 1.7em;
}

.content {
    font-size: 1.3em;
}

.lista {
    list-style-type: none;
}
.lista li {
    margin: 15px 0px;
}

.section {
    margin-top:20px;
    margin-bottom: 20px;
}
.adjusted_4 {
    // margin-bottom:30px
    margin: 0px;
    padding: 0px;
    margin-bottom: 30px;
}

.btn-cta {
    background-color: #f47607;
    background: linear-gradient(to right, #FFA500, #f47607);
    color: #f5f5f5;
    font-weight: bolder;
    padding: 10px 20px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,.5);
    border-radius: 35px;
    border: 3px solid #f5f5f5;
    letter-spacing: 0.1em;
    font-size: 1.2em;
    white-space: normal;
    text-transform: uppercase;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 80%;
  height: 3px;
  background: #FFA500;
  bottom: 0;
  left: calc(10%);
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #f47607;
}

.destaque {
    text-decoration: underline;
    text-decoration-color: orange;
    text-decoration-thickness: 0.2em;
}
.section p {
    font-size: .9em;
}
.card-title {
    padding: 20px 50px; 
    background-color:#FFA500;
    margin-bottom: 0px !important;
    text-transform: uppercase;
    font-weight: bold;
}
.card-box {
    padding-top: 20px;
    border-bottom: 2px dotted orange;
    border-left: 2px dotted orange;
    border-right: 2px dotted orange;
    padding-bottom:10px;
    margin-bottom:20px;
}
</style>



