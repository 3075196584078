<template>
     <div>
          <customHeader title="Login" subtitle="" :mobile="mobile"></customHeader>
          <login :mobile="mobile"></login>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import login from '@/components/login.vue';

     export default {
          name: 'Login',
          components:{
               customHeader,login
          },
          data() {
               return {
                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          created() {
               //windowWidth defining mobile and fieldView
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;
          }
     }
</script>