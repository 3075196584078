<template>
  <div v-if="loading" class="w-100 text-center"> 
        <h3>
          Carregando...
        </h3>
         <img src="https://mir-s3-cdn-cf.behance.net/project_modules/max_632/3f3a3831234507.564a1d2338123.gif" />
  </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            required: true,
            default: false

        },
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

h3 {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: 1.3rem;
    color: $laranja;
    margin-top: 2em;
    font-weight: bolder;
}

</style>