<template>
  <div id="guru-vip">
        <section id="header" class="mt-5 mb-3 pb-3" style="border-bottom: 2px solid orange;">
               <div class="text-center">
                <div class="row">
                        <div class="col-12">
                            <h1 style="font-family: 'Ubuntu', sans-serif; color:#ff7e00; font-size: 2.5em">
                                <img src="https://images2.imgbox.com/b2/9e/b6UDNUZ8_o.png" class="img img-fluid">
                                Guru do Cartola
                            </h1>
                        </div>
                </div>
            </div>
        </section>
        <section id="s_1" class="section pt-3">
            <!-- <div class="container"> -->
                <div class="row justify-content-center barra-titulo" >
                    <h2 class="d-flex align-items-center text-center">
                        Você sabe o que esses mitos tem em comum?
                    </h2>
                </div>
                <div class="d-flex" :class="mobile?'flex-column':'justify-content-between'">
                    <img src="/img/top10-2019/farenaty_v.png" class="img img-fluid adjusted_4">
                    <img src="/img/top10-2019/cheirinhooo_v.png" class="img img-fluid adjusted_4">
                </div>
                <div class="row col-12 justify-content-center" >
                    <span class="d-flex align-items-center frase text-center" style="padding: 20px 50px; background-color:#FFA500;">
                        Todos eles ganharam suas ligas e sabem que os números são importantes. <br/> Cartola não é sorte.
                    </span>
                </div>
                <div class="d-flex" :class="mobile?'flex-column':'justify-content-between'">
                        <img src="/img/top10-2019/c4rtol31ro_v.png" class="img img-fluid adjusted_4">
                        <img src="/img/top10-2019/morro_bicudo_v.png" class="img img-fluid adjusted_4">
                </div>
                <div class="row col-12 justify-content-center" >
                    <span class="d-flex align-items-center frase text-center" style="padding: 20px 50px; background-color:#FFA500; "> 
                        Todos eles têm acesso aos nossos grupos exclusivos de Whatsapp
                    </span>
                </div>
                <div class="d-flex" :class="mobile?'flex-column':'justify-content-between'">
                        <img src="/img/top10-2019/urubu_v.png" class="img img-fluid adjusted_4">
                        <img src="/img/top10-2019/morro_bicudo_2_v.png" class="img img-fluid adjusted_4">
                </div>
                <div class="row col-12 justify-content-center">
                    <span class="d-flex align-items-center frase text-center" style="padding: 20px 50px; background-color:#FFA500;">
                        Todos eles fizeram um investimento e entraram para o nosso Time
                    </span>
                </div>
            <!-- </div> -->
        </section>
        <section id="s_2" class="mb-5">
            <div class="container">
                <div class="d-flex flex-column text-center color-box">
                        <span style="font-size:1.4em; padding: 10px 0px; background-color:#0b0b0b; color:white; border-radius:5px;"><i>Isso quer dizer que você precisa pagar para jogar cartola?</i></span>
                        <span style="font-size:1.6em; font-weight:bold; text-transform:uppercase; margin:20px 0px 10px 0px;">Claro que não...</span>
                        <span style="font-size:1.4em; margin:10px 10px;">Mas, o que eu tenho pra te falar é que se você quer ganhar suas ligas, <b>estar entre os melhores</b> faz uma diferença enorme!</span>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                            <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>Garantir as dicas até dezembro de 2021
                    </a>
                </div>
            </div>
        </section>
        <section id="s_3" class="section">
            <div class="container">
                <div class="text-center">
                    <div class="row justify-content-center barra-titulo" >
                        <i class="fab fa-whatsapp" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>
                        <h2 class="d-flex align-items-center" :class="{'w-100':mobile}">
                            GRUPOS DE WHATSAPP
                        </h2>
                    </div>
                    <div class="row col-12 justify-content-start content">
                        <ul class="lista">
                            <li>As discussões em nossos grupos são realmente de alto nível! É impressionante como os cartoleiros do Time Guru se ajudam.</li>
                            <li>Seja analisando os jogos, um jogador ou adversário específico e também fazendo sugestões nos times da galera.</li>
                            <li>Com certeza construímos uma comunidade muito forte!</li>
                            <li>E o que eles fazem para mitar tanto?</li>
                        </ul>
                     </div>
                </div>
            </div>
        </section>
        <section id="s_4" class="section">
            <div class="container">
                <div class="text-center">
                    <div class="row justify-content-center barra-titulo text-center">
                        <i class="fas fa-chart-line" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>
                        <h2 class=" d-flex align-items-center" :class="{'w-100':mobile}">
                            ANÁLISES E ESTATÍSTICAS DE CADA JOGADOR
                        </h2>
                    </div>
                    <div class="row col-12 justify-content-start content">
                        <ul class="lista">
                            <li>Em nosso site, podemos fazer várias análises...</li>
                            <li>Geralmente, em uma rodada do Cartola, temos 270 jogadores... Será que alguém sabe as características de todos eles?</li>
                            <li>Em que parte do campo jogam exatamente?</li>
                            <li><b>Por exemplo:</b> pela nossa análise, você consegue descobrir em 1 segundo se o Lateral é mais ofensivo (mais chances de assistência) ou defensivo (mais chances de roubar bolas, mas ataca pouco).</li>
                            <li>Pelas nossas estatísticas, você consegue analisar os jogos recentes de um jogador ou do adversário dele... Considerando o local exato da próxima partida.</li>
                            <!-- <li>Premiação todas as rodadas e mais R$5.000,00</li> -->
                        </ul>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                                <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>QUERO ESSA MITADA AGORA MESMO
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section id="s_3" class="section">
            <div class="container">
                <div class="row justify-content-center barra-titulo text-center">
                    <i class="far fa-thumbs-up" style="font-size:33px;margin-bottom:10px;">&nbsp;</i> 
                    <h2 class="d-flex align-items-center">
                        AVALIAÇÃO DO SEU TIME
                    </h2>
                </div>
                <div class="row col-12 justify-content-center content">
                    <span class="d-flex align-items-center text-center">
                         Sem contar que, se você quiser... vamos avaliar o seu time todas as rodadas. Vamos dar o nosso pitaco na sua escalação!
                    </span>
                </div>
            </div>
        </section>
        <section id="s_2" class="section">
            <div class="container">
                 <div class="row justify-content-center barra-titulo text-center">
                    <i class="fas fa-medal" style="font-size:33px;margin-bottom:10px;">&nbsp;</i> 
                    <h2 class="d-flex justify-content-center" :class="{'w-100':mobile}">
                       PREMIAÇÃO
                    </h2>
                </div>
                <div class="row col-12 justify-content-center content">
                    <ul class="lista text-center">
                        <li>1 Camisa oficial por rodada!</li>
                        <li>O maior pontuador do nosso ranking escolhe a camisa oficial que deseja ganhar.</li>
                        <li>
                            <!-- Serão 38 camisas durante o campeonato.<br/> -->
                            <img src="https://images2.imgbox.com/11/d7/ECWs5vBK_o.png" class="img img-fluid"/>
                        </li>
                        <!-- <li>
                            <b style="color:green">R$5.000,00 para o maior pontuador</b> do nosso ranking no segundo turno.<br/>
                            <img src="/img/money.png" class="img img-fluid"/>
                        </li> -->
                    </ul>
                </div>
            </div>
        </section>
        <section id="s_1" class="section">
            <div class="container">
                <div class="row justify-content-center barra-titulo text-center">
                    <i class="fas fa-dollar-sign" style="font-size:33px;">&nbsp;</i>
                    <h2 class="d-flex justify-content-center" :class="{'w-100':mobile}">
                       QUANTO CUSTA?
                    </h2>
                </div>
                <!-- <div class="text-center"> -->
                        <h3 class="row col-12 text-center" style="color:orange; font-weight:bold;" >
                            Imagino que você já esteja preocupado pensando quanto custa para participar disso tudo...
                        </h3>
                        
                        <ul class="text-left mt-4 mb-4" style="font-size:1.2em; list-style-type:square;">
                            <li>2 Grupos de Whatsapp (um com dicas e estratégias e outro de resenha)</li>
                            <li>Acesso completo a TODO o conteúdo do site e dos apps</li>
                            <li>Avaliação do seu time todas as rodadas</li>
                            <li>Premiação todas as rodadas</li>

                        </ul>

                        <div class="d-flex flex-column content mt-4 mb-4 p-2 text-center" style="border: 3px dotted orange;">
                            <!-- <span>Custará apenas <span style="color:green;font-weight:bold;">R$2,55</span> por rodada para quem garantir a vaga antes do campeonato.</span>
                            <span>Ou seja, como são 38 rodadas, o valor disso tudo que te falei é só <span style="color:green;font-weight:bold;">R$97,00</span> para as 38 rodadas.</span>
                            <span>Você paga 1 vez só e usará o campeonato <span style="color:green;font-weight:bold;">INTEIRO</span>.</span> -->
                            <span>Você paga <span style="color:green;font-weight:bold;">R$76,00</span> uma vez só e usará até o final DO PRÓXIMO CAMPEONATO!</span>
                            <span>Ou seja, as rodadas que ainda faltam mais todas as rodadas do Campeonato Brasileiro 2021.</span>
                        </div>

                        <div class="d-flex justify-content-center p-3">
                            <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                                <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>TÔ DENTRO, QUERO ENTRAR PARA O TIME GURU
                            </a>
                        </div>

                        <!-- <div class="d-flex flex-column">
                            <div class="col-12 color-box">
                                <span><b >Novidade 2020:</b> Dicas e <b>estratégias exclusivas</b> para montar times de TIRO CURTO. Ideal para jogar ligas por rodada ou quando precisar ir para o tudo ou nada em suas ligas.</span>
                                <br/><br/>
                                <span>(essas dicas extras NÃO fazem parte do plano Time Guru tradicional)</span>
                            </div>
                        </div>   -->

                        <!-- <div class="d-flex flex-column">
                            <div class="col-12 justify-content-center">
                                <img src="/img/tabela_time_guru.png" class="img-fluid"/>
                                <h4 class="m-3" style="font-weight:bolder;">Você joga ligas de Tiro Curto?</h4>
                                <span class="m-3">Em 2020, criamos um plano adicional com dicas e estratégias para disputar as maiores ligas de tiro curto (ligas por rodada) por apenas mais R$ 1,58 por rodada, você irá aumentar as suas chances de faturar uma bolada.</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center p-3">
                            <a class="btn btn-cta m-3" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/S12621466W">
                                <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>MEU OBJETIVO É GANHAR LIGAS DE TIRO CURTO
                            </a>
                        </div> -->

                <!-- </div> -->
            </div>
        </section>
        <section id="s_2" class="section">
            <div class="container">
                <div class="row justify-content-center barra-titulo text-center">
                    <i class="far fa-question-circle" style="font-size:33px;">&nbsp;</i>
                    <h2 class="d-flex justify-content-center" :class="{'w-100':mobile}">
                       ESTÁ NA DÚVIDA?
                    </h2>
                </div>
                <div class="d-flex flex-column text-center content">
                    <span>Não tem problema...</span>
                    <span>Eu entendo que você esteja pensando que é bom demais para ser verdade.</span>
                    <span>Então eu te dou a oportunidade de testar por 15 dias. Se você não gostar, é só me mandar um e-mail pedindo para cancelar (dentro dos 15 dias após sua compra). Eu devolvo 100% do seu dinheiro. Sem condição nenhuma e sem burocracia.</span>
                        
                </div>
                <div class="d-flex justify-content-center p-3">
                    <div class="mt-4 mb-4">
                        <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                            <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>QUERO ENTRAR PARA O TIME GURU
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section id="s_13" class="section">
            <div class="container">
                <div class="text-left">
                        <div class="row  color-box">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <img src="http://static-public.klickpages.com.br/uploads/media/file/868080/sobre_o_guru_imagem.png" class="img img-fluid">
                                <br>
                            </div>
                            <div class="col-lg-8 col-md-8 col-sm-12">
                                <h4 style="background-color: #0b0b0b; text-align:center; padding:10px 0px; color:white;">SOBRE O GURU</h4>
                                <p>
                                    Estatístico e apaixonado por futebol, Luís Otávio resolveu unir suas duas paixões e, em agosto de 2016, começou a calcular as probabilidades dos times vencerem seus jogos e estudou de forma profunda o algoritmo de valorização do fantasy game Cartola FC, até descobrir, depois de 9 meses, exatamente como ele funciona e todos os fatores que são levados em consideração. Em maio de 2017, lançou o site Guru do Cartola.
                                </p>
                                <p>
                                    O Guru do Cartola amplia a diversão do cartoleiro, dando informações incrivelmente valiosas e precisas com base no conhecimento aprofundado do algoritmo de valorização do Cartola FC e em diversas análises dos números do jogo e dos jogadores, transmitindo de forma simples para que você suba no ranking das ligas que disputa e também dando várias chances de ganhar prêmios sensacionais, como a camisa do seu tão amado time. 
                                </p>
                            </div>
                        </div>
                </div>
            </div>
        </section>
        <section id="s_14" class="section">
            <div class="container">
                <div class="row justify-content-center barra-titulo text-center">
                    <i class="far fa-question-circle" style="font-size:33px;">&nbsp;</i>
                    <h2 class="d-flex justify-content-center" :class="{'w-100':mobile}">
                       TEM ALGUMA DÚVIDA?
                    </h2>
                </div>
                <div class="text-left">
                        <div id="q_1" onClick="$('#q_1_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down"></i>&nbsp;
                                O que é o Time Guru e para quem ele foi feito?
                            </h4>
                            <div id="q_1_content" style="display:none;">
                                <p>
                                    O Time Guru é um programa de membros válido por todo o Campeonato Brasileiro de Futebol e foi feito para os Cartoleiros que querem levar o jogo Cartola FC ainda mais a sério e ter acesso a conteúdo exclusivo e inédito, escalar com inteligência, virar MITO, se dar bem nas ligas que disputa, além de ganhar prêmios incríveis todas as rodadas na Liga do Guru.
                                </p>
                            </div>
                        </div>
                        <!-- <div id="q_2" onClick="$('#q_2_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down"></i>&nbsp;
                                Qual a diferença dos serviços do Time Guru e do “free”?
                            </h4>
                            <div id="q_2_content" style="display:none;">
                                <p>
                                    Quem é usuário free tem acesso a alguns dados e estatísticas dos jogadores, consegue escalar seu time pelo site e pelo app, acompanhar a valorização do seu time em tempo real e também tem acesso ao Time da Guruzada.<br>
                                    Quem é membro do Time Guru, além de tudo isso, tem acesso à Avaliação do seu time toda rodada, Histórico e estatísticas do seu time, Análise completa de cada jogador, Time Valorização e Seleção do Guru, Previsões de pontuação e valorização esperadas e Comparação de Jogadores, além de 100% de acesso ao aplicativo. Ainda concorre a prêmios todas as rodadas e premiação especial. Em 2019 demos camisa oficial do seu time do coração toda rodada e uma viagem para o Peru para assistir a final entre Flamengo e River Plate.
                                </p>
                            </div>
                        </div> -->
                        <div id="q_3" onClick="$('#q_3_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down"></i>&nbsp;
                                Como posso realizar o pagamento para me tornar membro do Time Guru e em quanto tempo meu acesso aos serviços do Time Guru será liberado?
                            </h4>
                            <div id="q_3_content" style="display:none;">
                                <p>
                                    O pagamento de sua inscrição no Time Guru é processado pela plataforma de pagamentos Hotmart, que protege seus dados pessoais e de pagamento de forma 100% segura. O pagamento pode ser feito por cartão de crédito, boleto bancário ou débito bancário.<br>
                                    Assim que seu pagamento for processado e recebido pelo nosso banco de dados, você terá acesso imediato às ferramentas exclusivas dos membros do Time Guru.<br>
                                    Caso o email informado na compra seja o mesmo da conta do Cartola, basta fazer login no site. Se não, você receberá um email com informações para fazer login.
                                </p>
                            </div>
                        </div>
                        <!-- <div id="q_4" onClick="$('#q_4_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down"></i>&nbsp;
                                Até quando vai a promoção de pré-venda do Time Guru?
                            </h4>
                            <div id="q_4_content" style="display:none;">
                                <p>
                                    Esse lote promocional do Time Guru estará disponível somente até quinta-feira (21/11) às 23:59. Após quinta-feira as vagas da pré-venda para 2020 irão se ENCERRAR.
                                </p>
                            </div>
                        </div> -->
                        <div id="q_5" onClick="$('#q_5_content').fadeToggle();" style="cursor:pointer;">
                            <h4>
                                <i class="fas fa-caret-square-down"></i>&nbsp;
                                Como posso entrar em contato com o Guru para tirar dúvidas sobre o Time Guru? 
                            </h4>
                            <div id="q_5_content" style="display:none;">
                                <p>
                                    Qualquer dúvida que tiver, é só mandar um e-mail para contato@gurudocartola.com
                                </p>
                            </div>
                        </div>
                        <br><br>
                        <div class="text-center">
                            <h4 class="text-center">
                                Clique no Botão Abaixo e Garanta sua Vaga! <br><br>
                            </h4>
                            <!-- <button class="btn btn-success btn-lg col-lg-10 col-sm-12" style="cursor:pointer;">
                                QUERO SER TIME GURU !
                            </button> -->
                            <a class="btn btn-cta" :class="mobile?'btn-sm':'btn-md'" style="cursor: pointer;" href="https://pay.hotmart.com/F7682712E?checkoutMode=10">
                                <i class="fa fa-hand-point-right" style="font-size:33px;margin-bottom:10px;">&nbsp;</i>Quero mitar já na próxima rodada
                            </a>
                        </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>
export default {
    data(){
        return {
            mobileAt:1000,//Mobile a partir de qual width?
            mobile:false,//Status inicial do mobile
        }
    },
    created(){
        //windowWidth defining mobile and fieldView
        (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;
    }

}
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

/*Fonts*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i|Ubuntu:300,300i,400,400i,500,500i,700,700i");

// #guru-vip {
     /* color: orange; */
    // font-family: "Montserrat", sans-serif;
    /* text-transform: uppercase; */
    /* font-weight: bolder; */
// }

h4 {
    // font-family: "Montserrat", sans-serif;
    font-weight: 200;
    letter-spacing: 1px;
}

.color-box {
    background-color: #D2CDD9 ;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 25px;
}

.barra-titulo {
          background-color: #0b0b0b;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%23855200' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          // border-bottom: 3px solid orange;
          // border-top: 3px solid orange;
          padding: 10px 10px 5px 10px;
          z-index: 1;
          -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
        //   margin-top: -32px;
          color: $laranja_claro;
          text-transform: uppercase; 
          margin-bottom: 20px;
          border-top: 3px solid $laranja_claro;
          border-bottom: 3px solid $laranja_claro;
}

.frase {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 1.7em;
}

.content {
    font-size: 1.3em;
}

.lista {
    list-style-type: none;
}
.lista li {
    margin: 15px 0px;
}

.section {
    margin-top:20px;
    margin-bottom: 20px;
}
.adjusted_4 {
    // margin-bottom:30px
    margin: 0px;
    padding: 0px;
    margin-bottom: 30px;
}

.btn-cta {
    background-color: #f47607;
    background: linear-gradient(to right, #FFA500, #f47607);
    color: #f5f5f5;
    font-weight: bolder;
    padding: 10px 20px;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,.5);
    border-radius: 35px;
    border: 3px solid #f5f5f5;
    letter-spacing: 0.1em;
    font-size: 1.2em;
    white-space: normal;
    text-transform: uppercase;
}
</style>