<template>
    <li class="timeline-item">
        <div class="timeline-badge" :class="{'destaque-badge':item.acaoImportante == 'Gol','balao-guru-badge':item.isGuru}">
            {{ item.tempoDeJogoEmMinutos > 0 ? item.tempoDeJogoEmMinutos : ''}}
        </div>
        <div class="timeline-panel" :class="{'destaque':item.acaoImportante == 'Gol','balao-guru':item.isGuru}">
            <div class="timeline-heading">
                <h4 class="timeline-title">{{ item.acaoImportante }}</h4>
                <h6>{{item.jogador}}</h6>
                <div class="timeline-panel-controls" style="height:100%;">
                    <div class="d-flex align-items-center justify-content-center timestamp">
                        <span v-if="item.acaoImportante == 'Impedimento'"><i class="guru-icon icon-guru-offside"></i></span>
                        <span v-if="item.acaoImportante == 'Gol'" style="font-size:1.6em; color:white;"><i class="fas fa-futbol"></i></span>
                        <span v-if="item.acaoImportante == 'Substituição'" style="font-size:1.6em;"><i class="fas fa-exchange-alt"></i></span>
                        <span v-if="item.acaoImportante == 'Atendimento médico'" style="font-size:1.6em;"><i class="fas fa-ambulance"></i></span>
                        <span v-if="item.acaoImportante == 'Defesa do goleiro'" style="font-size:1.6em;"><i class="fas fa-hand-paper"></i></span>
                        <span v-if="item.acaoImportante == 'Começo de jogo' || item.acaoImportante == 'Intervalo' 
                                    || item.acaoImportante == 'Começo do segundo tempo' || item.acaoImportante == 'Fim de jogo'">
                          <i class="guru-icon icon-guru-whistle"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="timeline-body">{{ item.isGuru ? item.texto : item.narracao }}</div>
        </div>
    </li>
</template>

<script>
export default {
 props: ['item','badge','cor1','cor2','cor3'],
 
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.timeline {
    list-style: none;
    padding: 10px 0 10px;
    position: relative;
    width: 420px;

    &:before {
        background-color: #eee;
        bottom: 0;
        content: " ";
        left: 50px;
        margin-left: -1.5px;
        position: absolute;
        top: 0;
        width: 3px;
    }

    > li {
        margin-bottom: 10px;
        position: relative;

        &:before,
        &:after {
            content: " ";
            display: table;
        }

        &:after {
            clear: both;
        }

        > .timeline-panel {
            border-radius: 2px;
            background-color:white;
            border: 1px solid #d4d4d4;
            box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
            margin-left: 100px;
            padding: 20px;
            position: relative;
            
          
          .timeline-heading {
            .timeline-panel-controls {
              position: absolute;
              right: 8px;
              top: 5px;
              
              .timestamp {
                display: inline-block;
                // background-color: #FFA500;
                // color: white;
                color: $laranja;
                border-radius: 50%;
                // border: 2px solid white;
                // text-align: center;
                width: 25px;
                height: 25px;
                line-height: 25px;
                margin-bottom: 3px;
                margin-top: 5px;
                padding: 4px;
                
              }
              
              .controls {
                display: inline-block;
                padding-right: 5px;
                border-right: 1px solid #aaa;
                
                a {
                  color: #999;
                  font-size: 11px;
                  padding: 0 5px;
                  
                  &:hover {
                    color: #333;
                    text-decoration: none;
                    cursor: pointer;
                  }
                }
              }
              
              .controls + .timestamp {
                padding-left: 5px;
              }
            }
          }
        }

        .timeline-badge {
             background-color: #999;
             border-radius: 50%;
            color: #fff;
            font-size: 1.4em;
            height: 50px;
            left: 50px;
            line-height: 52px;
            margin-left: -25px;
            position: absolute;
            text-align: center;
            top: 16px;
            width: 50px;
            z-index: 100;
        }

        .destaque-badge {
          background-color: $laranja;
        }

        .balao-guru-badge{
          background-color: green;
        }

        .timeline-badge + .timeline-panel {
            &:before {
                border-bottom: 15px solid transparent;
                border-left: 0 solid #ccc;
                border-right: 15px solid #ccc;
                border-top: 15px solid transparent;
                content: " ";
                display: inline-block;
                position: absolute;
                left: -15px;
                right: auto;
                top: 26px;
            }

            &:after {
                border-bottom: 14px solid transparent;
                border-left: 0 solid #fff;
                border-right: 14px solid #fff;
                border-top: 14px solid transparent;
                content: " ";
                display: inline-block;
                position: absolute;
                left: -14px;
                right: auto;
                top: 27px;
            }
        }
    }
}

.timeline-badge {
    &.primary {
        background-color: #2e6da4 !important;
    }

    &.success {
        background-color: #0196a3 !important;
    }

    &.warning {
        background-color: #000000 !important;
    }

    &.danger {
        background-color: #d9534f !important;
    }

    &.info {
        background-color: #5bc0de !important;
    }
  
    &.planning {
        background-color: #00629c !important;
    }
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2em;
}

.timeline-body {
    // padding-right: 15px;
    > p,
    > ul {
        margin-bottom: 0;
    }

    > p + p {
        margin-top: 5px;
    }
}

.copy {
  position: absolute; 
  top: 5px;
  right: 5px;
  color: #aaa;
  font-size: 11px;
  > * { color: #444; }
}


/*============================
				ANIMATIONS
=============================*/
.pulse_wrap {
	animation: pulse 1.5s ease-in-out alternate infinite;

}

@keyframes pulse {
	0% {
		opacity: 0.8;
		transform: scale(0.95);
    //margin-left: -20px;
	}
  
  100% {
		opacity: 1;
		transform: scale(1);
	}
  

}

//

#timeline-header {
    font-size: 26px;
}

.cancelled {
    text-decoration: line-through;
}

.timeline-panel.today {
  height: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px;
  margin-bottom: 10px;
  background: #000;
  &:before {
    visibility: hidden !important;
    display: none !important;
  }
  &:after {
    visibility: hidden !important;
    display: none !important;
  }
}

.timeline-badge.warning {
  top: -20px !important;
}
.timeline-badge i {
  font-size: 0.5em;
}

.timeline-panel.past {
  background: #eee;
  &:after {
    border-right: 14px solid #eee !important;
  }
}

.timeline-panel.main_element {
    font-weight: bolder;
    color: #FFFFFF !important;
    background: #0196a3;
    border-color: #0196a3 !important;
  &:after {
    border-right: 14px solid #0196a3 !important;
  }
}

.timeline-panel.selected_past {
    font-weight: bolder;
    color: #FFFFFF !important;
    background: #333;
    border-color: #333 !important;
  &:after {
    border-right: 14px solid #333 !important;
  }
}

.destaque {
  background-color: $laranja !important;
  color: white;

  &:after {
    border-right: 14px solid $laranja !important;
  }
}

.balao-guru {
  background-color: $verde !important;
  color: white;

  &:after {
    border-right: 14px solid $verde !important;
  }
}


</style>