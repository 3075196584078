<template>
     <div>
          <customHeader title="Parciais e Valorização ao vivo" subtitle="Acompanhe quantas cartoletas o seu time está valorizando no Cartola FC em tempo real." 
               :mobile="mobile" :showBanner="showBanner"></customHeader>
          <div class="container">
               <div class="row">
                    <template v-if="market_status && market_status.status_mercado == 2">
                         <div :class="[mobile ? 'col-12' : 'col-6']" style="margin: 0 auto;">
                              <table id="searchTeamsTable">
                                   <thead>
                                        <th colspan="6">Times Favoritos</th>
                                   </thead>
                                   <tbody>
                                        <tr v-for="(favoriteTeam, index) in favoriteTeams" :key="index">
                                             <td colspan="6">
                                                  <div class="row" style="padding: 5px 25px;" @click="selectedTeam=favoriteTeam.time; scroll_to('selectedTeam');">
                                                       <div class="col-3 text-center">
                                                            <img :src="favoriteTeam.time.url_escudo_png" class="img img-fluid" style="width:30px;">
                                                       </div>
                                                       <div class="col-7">
                                                            {{favoriteTeam.time.nome}} 
                                                            <small>({{favoriteTeam.time.nome_cartola}})</small>
                                                       </div>
                                                       <div class="col-2">
                                                            <i data-toggle="tooltip" data-placement="top" title="Adicionar aos meus favoritos"
                                                            style="color: #f47607;" @click="save_to_local_storage(favoriteTeam.time.time_id)"
                                                            :class="[String(currentStorage).includes(favoriteTeam.time.time_id) ? 'fas fa-star' : 'far fa-star']"
                                                            ></i>
                                                       </div>
                                                  </div>
                                             </td>
                                        </tr>
                                        <tr>
                                             <td colspan="6">
                                                  <div class="row" style="padding: 10px 25px;">
                                                       <!-- <div class="col-12">
                                                            <hr>
                                                       </div> -->
                                                       <div :class="[mobile ? 'col-10' : 'col-11']" style="padding:0px">
                                                            <input type="text" v-model="userTeamSearch" 
                                                                 style="border-bottom-right-radius:0px; border-top-right-radius:0px;"
                                                                 class="form-control" 
                                                                 placeholder="Buscar Time">
                                                       </div>
                                                       <div :class="[mobile ? 'col-2' : 'col-1']" style="padding:0px">
                                                            <button 
                                                                 style="border-bottom-left-radius:0px; border-top-left-radius:0px;"
                                                                 class="btn btn-light btn-block" 
                                                                 @click="getTeams()">
                                                                 <i class="fas fa-search"></i>
                                                            </button>
                                                       </div>
                                                  </div>                                   
                                             </td>
                                        </tr>
                                        <div class="teamResult" >
                                             <!-- <template  > -->
                                             <tr v-for="team in teams" :key="team['time_id']" @click="selectedTeam = team; scroll_to('selectedTeam');" >
                                                  <td width="75">
                                                       <img :src="team.url_escudo_png" class="img img-fluid">
                                                  </td>
                                                  <td colspan="5">
                                                       <p style="margin:0px 25px;">
                                                            {{team.nome}} <span v-if="team.assinante" class="pro">PRO</span><br>
                                                            <small>({{team.nome_cartola}})</small>
                                                       </p>
                                                  </td>
                                             </tr>
                                             <!-- </template> -->
                                        </div>
                                   </tbody>
                              </table>
                         </div>

                         <div class="col-12">
                              <hr v-if="selectedTeam">
                              <br v-else>
                         </div>

                         <div v-if="selectedTeam" id="selectedTeam" class="text-center" :class="[mobile ? 'col-8' : 'col-4']">
                              <i data-toggle="tooltip" data-placement="top" title="Adicionar aos meus favoritos"
                              style="color: #f47607;" @click="save_to_local_storage(selectedTeam.time_id)"
                              :class="[String(currentStorage).includes(selectedTeam.time_id) ? 'fas fa-star' : 'far fa-star']"
                              ></i>
                              <p>
                                   <b>{{selectedTeam.nome}}</b><br>
                                   <small>
                                        ({{selectedTeam.nome_cartola}})
                                   </small>
                              </p>
                              <img :src="selectedTeam.url_escudo_png" class="img img-fluid">
                              <hr>
                              <p class="text-left">
                                   Pontuação: <span style="float:right;"> {{teamPoints ? teamPoints.toFixed(2) : 0.00}} </span><br>
                                   Valorização: <span style="float:right;"> {{teamVal ? teamVal.toFixed(2) : 0.00}} </span>
                              </p>
                              <p>
                                   {{totalPlayed}}/12
                              </p>
                         </div>

                         <div v-if="mobile" class="col-12">
                              <br>
                         </div>
                    </template>
                    <template v-else>
                         <div class="col-12 text-center warning" v-if="market_status && market_status.status_mercado == 1">
                              <br>
                              <router-link to="/escale-seu-time-cartola" style="color:orange;">
                                   MERCADO ABERTO, BORA ESCALAR O TIME PARA A PRÓXIMA RODADA!
                              </router-link>
                              <br><br>
                         </div>
                         <div class="offset-3 col-6 text-center warning" v-if="market_status && market_status.status_mercado == 4">
                             <mercado-manutencao></mercado-manutencao>
                         </div>
                    </template>
                    
                    <!-- Time com endpoint em pontuados -->
                    <div id="teamDetails"  :class="[mobile ? 'col-12' : 'col-8']">
                         <table v-if="teamDetails" class="table" :class="[mobile ? 'table-responsive' : '']">
                              <thead>
                                   <th colspan="3">
                                        <span v-if="mobile" style="color:orange;">
                                             Deslize para o lado ->
                                        </span>
                                   </th>
                                   <th>Preço</th>
                                   <th>Valorização</th>
                                   <th>Pontuação</th>
                              </thead>
                              <tbody>
                                   <tr v-for="(athlete, index) in pontuados_in_team_details" :key="index">
                                        <td>
                                             <div style="min-width:40px;">
                                                  <img :src="athlete_photo(athlete.foto,220)" class="img img-fluid player">
                                                  <img v-if="athlete.camisa" :src="athlete.camisa.sm" class="shirt">
                                             </div>
                                        </td>
                                        <td>
                                             <small style="text-transform:uppercase;">
                                                  ({{positions[athlete.posicao_id].abreviacao}})&nbsp;
                                             </small>
                                             {{athlete.apelido}}
                                             <i v-if="athlete.atleta_id == teamDetails.capitao_id" 
                                                  style="background-color:orange; padding: 3px; color:white; border-radius:5px;"
                                                  class="fab fa-cuttlefish"></i>
                                             <i  v-if="athlete.swap"
                                                  style="background-color:green; padding: 3px; color:white; border-radius:5px;"
                                                  class="fas fa-exchange-alt"></i>
                                             <br>
                                             <template  v-if="athlete.scout" >
                                                  <span v-for="(scout,index) in athlete.scout" :key="index">
                                                       <small :style="[neg_scouts.indexOf(index) == -1 ? 
                                                       {'color':'green'} : {'color':'red'}]">
                                                            {{index}}({{scout}}) 
                                                       </small>
                                                  </span>
                                             </template>
                                        </td>
                                        <td style="white-space: nowrap">
                                             <template v-if="athlete.escudoCasa && athlete.escudoAdversario">
                                                  <img :src="athlete.escudoCasa" class="club">
                                                  <small>&nbsp; X &nbsp;</small>
                                                  <img :src="athlete.escudoAdversario" class="club">
                                             </template>
                                        </td>
                                        <td class="text-center">
                                             {{athlete.preco_num}}
                                        </td>
                                        <td class="text-center">
                                             <span v-if="athlete.valorizacao && Math.abs(athlete.valorizacao) != 100.00">
                                                  {{athlete.valorizacao.toFixed(1)}}
                                             </span>
                                             <span v-else>
                                                  <small>Indisponível</small>
                                             </span>
                                        </td>
                                        <td class="text-center">
                                             <span v-if="athlete.pontuacao && Math.abs(athlete.pontuacao) != 100.00">
                                                  {{athlete.pontuacao.toFixed(1)}}
                                             </span>
                                             <span v-else>
                                                  <small>Indisponível</small>
                                             </span>
                                        </td>
                                   </tr>

                                   <template v-if="teamDetails && teamDetails.reservas">
                                        <tr>
                                             <td colspan="6">
                                                  <h5 style="color:#4F4F4F; border-bottom:2px solid #4F4F4F;">Reservas</h5>
                                             </td>
                                        </tr>

                                        <tr v-for="athlete in teamDetails.reservas" :key="athlete['id']">
                                             <td>
                                                  <div style="min-width:40px;">
                                                       <img :src="athlete_photo(athlete.foto,220)" class="img img-fluid player" :class="{'bw-img':!athlete.swap}">
                                                       <img v-if="athlete.camisa" :src="athlete.camisa.sm" class="shirt">
                                                  </div>
                                             </td>
                                             <td>
                                                  <small style="text-transform:uppercase;">
                                                       ({{positions[athlete.posicao_id].abreviacao}})&nbsp;
                                                  </small>
                                                  {{athlete.apelido}}
                                                  <i v-if="athlete.atleta_id == teamDetails.capitao_id" 
                                                       style="background-color:orange; padding: 3px; color:white; border-radius:5px;"
                                                       class="fab fa-cuttlefish"></i>
                                                  <i v-if="athlete.swap"
                                                       style="background-color:red; padding: 3px; color:white; border-radius:5px;"
                                                       class="fas fa-exchange-alt"></i>
                                                  <br>
                                                  <template  v-if="athlete.scout && !athlete.swap">
                                                       <span v-for="(scout,index) in athlete.scout" :key="index">
                                                            <small :style="[neg_scouts.indexOf(index) == -1 ? 
                                                            {'color':'green'} : {'color':'red'}]">
                                                                 {{index}}({{scout}}) 
                                                            </small>
                                                       </span>
                                                  </template>
                                             </td>
                                             <td style="white-space: nowrap">
                                                  <template v-if="athlete.escudoCasa && athlete.escudoAdversario">
                                                       <img :src="athlete.escudoCasa" class="club">
                                                       <small>&nbsp; X &nbsp;</small>
                                                       <img :src="athlete.escudoAdversario" class="club">
                                                  </template>
                                             </td>
                                             <td class="text-center">
                                                  {{athlete.preco_num}}
                                             </td>
                                             <td class="text-center">
                                                  <template v-if="!athlete.swap">
                                                       <span v-if="athlete.valorizacao && Math.abs(athlete.valorizacao) != 100.00">
                                                            {{athlete.valorizacao.toFixed(1)}}
                                                       </span>
                                                       <span v-else>
                                                            <small>Indisponível</small>
                                                       </span>
                                                  </template>
                                                  <template v-else>
                                                       -
                                                  </template>
                                             </td>
                                             <td class="text-center">
                                                  <template v-if="!athlete.swap">
                                                       <span v-if="athlete.pontuacao && Math.abs(athlete.pontuacao) != 100.00">
                                                            {{athlete.pontuacao.toFixed(1)}}
                                                       </span>
                                                       <span v-else>
                                                            <small>Indisponível</small>
                                                       </span>
                                                  </template>
                                                  <template v-else>
                                                       -
                                                  </template>
                                             </td>
                                        </tr>
                                   </template>
                              </tbody>
                         </table>
                    </div>

                    <div class="col-12">
                         <br>
                         <hr>
                         <br>
                    </div>

                    <!-- Geral com endpoint em pontuados -->
                    <div :class="[mobile ? 'col-12' : 'col-9']" style="padding: 0px;">
                         <div id="main_table" @scroll="increase_view()">
                              <table>
                                   <thead>
                                        <th colspan="3">
                                             <span v-if="mobile" style="color:orange;">
                                                  Deslize para o lado ->
                                             </span>
                                        </th>
                                        <th @click="order_by('preco')">
                                             Preço<br>
                                             <i class="fas fa-caret-up"></i>
                                             <i class="fas fa-caret-down"></i>
                                        </th>
                                         <th @click="order_by('valorizacao')">
                                             Valorização<br>
                                             <i class="fas fa-caret-up"></i>
                                             <i class="fas fa-caret-down"></i>
                                        </th>
                                        <th @click="order_by('pontuacao')">
                                            Pontuação<br>
                                             <i class="fas fa-caret-up"></i>
                                             <i class="fas fa-caret-down"></i>
                                        </th>
                                       
                                   </thead>
                                   <tbody>
                                        <tr v-for="(athlete,key) in computed_pontuados" :key="key">
                                             <td>
                                                  <div style="text-align:left; width: 50px;">
                                                       <img :src="athlete_photo(athlete.foto,220)" class="img img-fluid athlete">
                                                  </div>
                                             </td>
                                             
                                             <td style="white-space: nowrap">
                                                  <p class="text-left">
                                                       <small style="text-transform:uppercase;">
                                                            ({{positions[athlete.posicao_id].abreviacao}})&nbsp;
                                                       </small>
                                                       {{athlete.apelido}}
                                                       <br>
                                                       <template v-if="athlete.scout">
                                                            <span v-for="(scout,index) in athlete.scout" :key="index">
                                                                 <small :style="[neg_scouts.indexOf(index) == -1 ? 
                                                                 {'color':'green'} : {'color':'red'}]">
                                                                      {{index}}({{scout}}) 
                                                                 </small>
                                                            </span>
                                                       </template>
                                                  </p>
                                             </td>
                                             <td style="white-space: nowrap">
                                                  <template v-if="athlete.escudoCasa && athlete.escudoAdversario">
                                                       <img :src="athlete.escudoCasa" class="club">
                                                       <small>&nbsp; X &nbsp;</small>
                                                       <img :src="athlete.escudoAdversario" class="club">
                                                  </template>
                                             </td>
                                             <td class="text-center">
                                                  {{athlete.preco}}
                                             </td>
                                             <td class="text-center">
                                                  <span v-if="Math.abs(athlete.valorizacao) != 100.00">
                                                       {{athlete.valorizacao}}
                                                  </span>
                                                  <span v-else>
                                                       <small>Indisponível</small>
                                                  </span>
                                             </td>
                                              <td class="text-center">
                                                  <span v-if="Math.abs(athlete.pontuacao) != 100.00">
                                                       {{athlete.pontuacao}}
                                                  </span>
                                                  <span v-else>
                                                       <small>Indisponível</small>
                                                  </span>
                                             </td>
                                        </tr>
                                   </tbody>
                              </table>
                         </div>
                    </div>


                    <!-- Filtros -->
                    <div v-if="!mobile" id="filters" :class="[mobile ? 'col-12' : 'col-3']" style="padding: 0px;">
                         <div class="col-12" style="padding:35px;">
                              <input type="text" class="form-control" v-model="search" placeholder="Nome do jogador...">
                              <hr>
                              <div class="row">
                                   <div class="text-center" :class="[mobile ? 'col-12' : 'col-3']">
                                        <div class="btn-group-toggle" 
                                             :class="[mobile ? 'btn-group-horizontal' : 'btn-group-vertical']" 
                                             data-toggle="buttons">
                                        <label class="btn btn-secondary btn-sm" id="1" @click="changeCurrentPos(1)"
                                             :class="{ ativo : currentPos.indexOf(1) != -1 }">
                                        <input type="checkbox" autocomplete="off"> GOL
                                        </label>
                                        <label class="btn btn-secondary btn-sm" id="2" @click="changeCurrentPos(2)"
                                             :class="{ ativo : currentPos.indexOf(2) != -1 }">
                                        <input type="checkbox" autocomplete="off"> LAT
                                        </label>
                                        <label class="btn btn-secondary btn-sm" id="3" @click="changeCurrentPos(3)"
                                             :class="{ ativo : currentPos.indexOf(3) != -1 }">
                                        <input type="checkbox" autocomplete="off"> ZAG
                                        </label>
                                        <label class="btn btn-secondary btn-sm" id="4" @click="changeCurrentPos(4)"
                                             :class="{ ativo : currentPos.indexOf(4) != -1 }">
                                        <input type="checkbox" autocomplete="off"> MEI
                                        </label>
                                        <label class="btn btn-secondary btn-sm" id="5" @click="changeCurrentPos(5)"
                                             :class="{ ativo : currentPos.indexOf(5) != -1 }">
                                             <input type="checkbox" autocomplete="off"> ATA
                                        </label>
                                        <label class="btn btn-secondary btn-sm" id="6" @click="changeCurrentPos(6)"
                                             :class="{ ativo : currentPos.indexOf(6) != -1 }">
                                        <input type="checkbox" autocomplete="off"> TÉC
                                        </label>
                                        </div>
                                        <div v-if="mobile">
                                             <hr>
                                        </div>
                                   </div>
                                   <div :class="[mobile ? 'col-12' : 'col-9']">
                                        <div class="row">
                                             <!-- <template v-for="club in clubs">
                                                  <div class="col-3" v-if="club.escudos['30x30'] != ''">
                                                       <img :src="club.escudos['30x30']" 
                                                            class="club"
                                                            :class="[currentClub.indexOf(club.id) != -1 ? 'active_club' : '']"
                                                            :id="club.id"  @click="changeCurrentClub(club.id)">
                                                  </div>
                                             </template> -->
                                             <template v-for="club_id in idClubes">
                                                  <div class="col-3" v-if="club_id>1 &&  todosClubes[club_id] && todosClubes[club_id].ativo && todosClubes[club_id].escudo != ''" :key="club_id">
                                                       <img :src="todosClubes[club_id].escudo" 
                                                            class="club"
                                                            :class="[currentClub.indexOf(club_id) != -1 ? 'active_club' : '']"
                                                            :id="club_id"  @click="changeCurrentClub(club_id)">
                                                  </div>
                                             </template>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div v-else>
                         <a data-toggle="modal" data-target="#modalFilters" id="activate_modal">
                              <i class="fas fa-question-circle"></i> Filtros
                         </a>
                         <div class="modal fade" id="modalFilters" tabindex="-1" role="dialog" aria-labelledby="filter" aria-hidden="true">
                              <div class="modal-dialog modal-sm" role="document">
                                   <div class="modal-content">
                                        <div class="modal-header">
                                             <h5 class="modal-title" id="filter" style="color:black;">Filtrar Jogadores</h5>
                                             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                             <span aria-hidden="true">&times;</span>
                                             </button>
                                        </div>
                                        <div class="modal-body">
                                             <div id="filters" class="col-12" style="padding: 0px;">
                                                  <div class="col-12" style="padding:35px;">
                                                       <input type="text" class="form-control" v-model="search" placeholder="Nome do jogador...">
                                                       <hr>
                                                       <div class="row">
                                                            <div class="text-center" :class="[mobile ? 'col-12' : 'col-3']">
                                                                 <div class="btn-group-toggle" 
                                                                      :class="[mobile ? 'btn-group-horizontal' : 'btn-group-vertical']" 
                                                                      data-toggle="buttons">
                                                                 <label class="btn btn-secondary btn-sm" id="1" @click="changeCurrentPos(1)"
                                                                      :class="{ ativo : currentPos.indexOf(1) != -1 }">
                                                                 <input type="checkbox" autocomplete="off"> GOL
                                                                 </label>
                                                                 <label class="btn btn-secondary btn-sm" id="2" @click="changeCurrentPos(2)"
                                                                      :class="{ ativo : currentPos.indexOf(2) != -1 }">
                                                                 <input type="checkbox" autocomplete="off"> LAT
                                                                 </label>
                                                                 <label class="btn btn-secondary btn-sm" id="3" @click="changeCurrentPos(3)"
                                                                      :class="{ ativo : currentPos.indexOf(3) != -1 }">
                                                                 <input type="checkbox" autocomplete="off"> ZAG
                                                                 </label>
                                                                 <label class="btn btn-secondary btn-sm" id="4" @click="changeCurrentPos(4)"
                                                                      :class="{ ativo : currentPos.indexOf(4) != -1 }">
                                                                 <input type="checkbox" autocomplete="off"> MEI
                                                                 </label>
                                                                 <label class="btn btn-secondary btn-sm" id="5" @click="changeCurrentPos(5)"
                                                                      :class="{ ativo : currentPos.indexOf(5) != -1 }">
                                                                      <input type="checkbox" autocomplete="off"> ATA
                                                                 </label>
                                                                 <label class="btn btn-secondary btn-sm" id="6" @click="changeCurrentPos(6)"
                                                                      :class="{ ativo : currentPos.indexOf(6) != -1 }">
                                                                 <input type="checkbox" autocomplete="off"> TÉC
                                                                 </label>
                                                                 </div>
                                                                 <div v-if="mobile">
                                                                      <hr>
                                                                 </div>
                                                            </div>
                                                            <div :class="[mobile ? 'col-12' : 'col-9']">
                                                                 <div class="row">
                                                                      <template v-for="club in clubs">
                                                                           <div class="col-3" v-if="club.escudos['30x30'] != ''" :key="club['id']">
                                                                                <img :src="club.escudos['30x30']" 
                                                                                     class="club"
                                                                                     :class="[currentClub.indexOf(club.id) != -1 ? 'active_club' : '']"
                                                                                     :id="club.id"  @click="changeCurrentClub(club.id)">
                                                                           </div>
                                                                      </template>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div class="text-center">
                                                  <br>
                                                  <button class="btn bnt-md btn-dark" type="button" data-dismiss="modal" aria-label="Close">
                                                       Filtrar
                                                  </button>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

               </div>
          </div>
     </div>
</template>
<script>
     import customHeader from '@/components/header.vue';
     import util from '@/mixin/util';
     import MercadoManutencao from '@/components/MercadoManutencao.vue';

     // import clubs_json from '@/json/clubes.json';
     import shirts_json from '@/json/shirts.json';
     import positions_json from '@/json/positions.json';


     export default {
          name: 'ParciaisValorizacao',
          components: {
               customHeader, MercadoManutencao
          },
          mixins: [util],
          data(){
               return {
                    //Inputs do usuario
                    user:null,
                    userTeamSearch:'',
                    teams:null,
                    selectedTeam:null,
                    teamDetails:null,
                    teamSubstituicoes: null,

                    //reservas
                    substitutos: [],
                    substituidos: [],

                    //Dados
                    pontuados:null,
                    pontuadosArray:null,

                    //Mock
                    positions:positions_json,
                    shirts:shirts_json,
                    // clubs:clubs_json,
                    todosClubes: [], 
                    idClubes: [],
                    neg_scouts:['GC','CV','PP','CA','GS','FC','I','PE','PI', 'PC'],//Scouts negativos

                    //Wpp link
                    // wpp_group_link:null,

                    //Times que ja jogaram até o momento
                    teams_played: [],

                    //Calculations
                    totalPlayed:0,
                    teamVal:0,
                    teamPoints:0,

                    //Filtros - Não usamos o componente src/components/filters.vue pois esse filtro é simplicado
                    search:'',
                    currentClub:[],
                    currentPos:[],

                    //Configuração da tabela
                    currentSort:'',
                    currentOrder:1,
                    currentPage:1,
                    pageSize:25,

                    //Times favoritos
                    favoriteTeams:null,
                    currentStorage:null,

                    //Status do market atual - Necessário pois a página só funciona quando o mercado estiver fechado
                    market_status:null,

                    //Banner
                    showBanner: false,
                    
                    //Verificação mobile
                    mobileAt:1000,//Mobile a partir de qual width?
                    mobile:false,//Status inicial do mobile
               }
          },
          watch:{
               //O que acontece quando o Time selecionado mudar?
               selectedTeam: function(){
                    if(this.selectedTeam){
                         var vm = this;
                         // $.ajax({
                         //      type: "GET",
                         //      url: vm.cors_page+'https://api.cartolafc.globo.com/time/id/'+vm.selectedTeam.time_id,
                         //      contentType: "application/json; charset=utf-8",
                         //      dataType: "json",
                         //      success: function (result) {
                         //           vm.teamDetails = result;
                         //      },
                         //      error: function (error) {
                         //           console.log(error);
                         //      }
                         // });
                         this.$http
                         .get(vm.cors_page+vm.$API_GLOBO+'/time/id/'+vm.selectedTeam.time_id)
                         .then(response => {
                              vm.teamDetails = response.data
                         })
                         .catch(function (error) {
                              console.log(error); // eslint-disable-line
                         });   
                         //Busca as substituições do time 
                         // $.ajax({
                         //      type: "GET",
                         //      url: vm.cors_page+'https://api.cartolafc.globo.com/time/substituicoes/'+vm.selectedTeam.time_id,
                         //      contentType: "application/json; charset=utf-8",
                         //      dataType: "json",
                         //      success: function (result) {
                         //           vm.teamSubstituicoes = result;
                         //           vm.substituidos = [];
                         //           vm.substitutos = [];
                         //           for (var pos=1; pos<6; pos++) {
                         //                vm.substituidos[pos]==null;
                         //                for (var i=0; i<vm.teamSubstituicoes.length; i++) {
                         //                     var sub = vm.teamSubstituicoes[i];
                         //                     if (pos == sub.posicao_id) {
                         //                          vm.substituidos[pos] = sub.saiu;
                         //                          vm.substitutos[sub.saiu.atleta_id] = sub.entrou
                         //                     }
                         //                }
                         //                // if (vm.substituidos[pos]==null) {

                         //                // }
                         //           }
                         //      },
                         //      error: function (error) {
                         //           console.log(error);
                         //      }
                         // });
                         this.$http
                         .get(vm.cors_page+vm.$API_GLOBO+'/time/substituicoes/'+vm.selectedTeam.time_id)
                         .then(response => {
                              vm.teamSubstituicoes = response.data;
                              vm.substituidos = [];
                              vm.substitutos = [];
                              for (var pos=1; pos<6; pos++) {
                                   vm.substituidos[pos]==null;
                                   for (var i=0; i<vm.teamSubstituicoes.length; i++) {
                                        var sub = vm.teamSubstituicoes[i];
                                        if (pos == sub.posicao_id) {
                                             vm.substituidos[pos] = sub.saiu;
                                             vm.substitutos[sub.saiu.atleta_id] = sub.entrou
                                        }
                                   }
                              }
                         })
                         .catch(function (error) {
                              console.log(error); // eslint-disable-line
                         });   

                    }
               },
               //O que acontece quando o LocalStorage dos times favoritos for carregado
               currentStorage: function(){
                    var vm = this;
                    if(this.currentStorage){

                         var storage_ids = String(this.currentStorage).split(',');

                         this.favoriteTeams = [];
                         $.each(storage_ids,function(index,value){
                              if(value != ''){
                                   // $.ajax({
                                   //      type: "GET",
                                   //      url: vm.cors_page+'https://api.cartolafc.globo.com/time/id/'+value,
                                   //      contentType: "application/json; charset=utf-8",
                                   //      dataType: "json",
                                   //      success: function (result) {
                                   //           vm.favoriteTeams.push(result);
                                   //      },
                                   //      error: function (error) {
                                   //           console.log(error);
                                   //      }
                                   // });
                                   vm.$http
                                   .get(vm.cors_page+vm.$API_GLOBO+'/time/id/'+value)
                                   .then(response => {
                                        vm.favoriteTeams.push(response.data)
                                   })
                                   .catch(function (error) {
                                        console.log(error); // eslint-disable-line
                                   });   
                              }
                         });
                    }
               }
          },
          created(){
               //Verificação Mobile
               (window.innerWidth < this.mobileAt) ? this.mobile = true : this.mobile = false;

               var vm = this;

               //Carregando os times favoritos salvos no localStorage
               this.currentStorage = localStorage.getItem('favoriteTeams');

               //Usuário autenticado
               vm.user = vm.get_usuario_logado();

               //todos os clubes
               this.$http
               // .get(this.$_PATH+'/info-clubes/')
               .get(this.$API+'info-clubes/')
               .then(response => {
                    vm.todosClubes = response.data
               })
               .catch(function (error) {
                    console.log(error); // eslint-disable-line
               });   
               
               //Carrega o id dos clubes por ordem alfabetica
               this.$http
               // .get(this.$_PATH+'/twitter/clubes/') 
               .get(this.$API+'twitter/clubes')
               .then(response => {
                    vm.idClubes = response.data
               })
               .catch(function (error) {
                    console.log(error); // eslint-disable-line
               });   



               //Carregando os atletas que foram pontuados
               this.$http
               // .get(this.$_PATH+'/atletas/pontuados/secure/')
               .get(this.$API+'atletas-pontuados')
               .then(response => {
                    // vm.pontuadosArray = JSON.parse(vm.aesDencrypt(response.data));
                    vm.pontuadosArray = response.data;
                    vm.pontuados = Object.values(vm.pontuadosArray);

                    //Carrega todos os clubes que ja jogaram até o momento atual
                    $.each(vm.pontuados,function(index,player){
                         if(!vm.teams_played.includes(player.clube_id))
                              vm.teams_played.push(player.clube_id);
                    });
               })
               .catch(function (error) {
                    console.log(error); // eslint-disable-line
               });   

               //market_status
               this.$http
               // .get(this.$_PATH+'/mercado/status')
               .get(this.$API+'mercado-status')
               .then(response => {
                    vm.market_status = response.data
               })
               .catch(function (error) {
                    console.log(error); // eslint-disable-line
               });   

               //Banner
               if (!(this.user && this.user.membro == 1)) {
                    vm.showBanner = true; 
               }
          },
          methods:{
               //Realiza a busca na API do CARTOLA de acordo com o que o usuário digitar no campo de busca
               getTeams(){
                    var vm = this;
                    // $.ajax({
                    //      type: "GET",
                    //      url: vm.cors_page+'https://api.cartolafc.globo.com/times?q='+vm.userTeamSearch,
                    //      contentType: "application/json; charset=utf-8",
                    //      dataType: "json",
                    //      success: function (result) {
                    //           vm.teams = result;
                    //      },
                    //      error: function (error) {
                    //           console.log(error);
                    //      }
                    // });
                    this.$http
                    .get(vm.cors_page+vm.$API_GLOBO+'/times?q='+vm.userTeamSearch)
                    .then(response => {
                         vm.teams = response.data
                    })
                    .catch(function (error) {
                         console.log(error); // eslint-disable-line 
                    });   
               },
               //Salva o id de um time no localStorage realizando a verificação de sua pré-existência
               save_to_local_storage(time_id){
                    var vm = this;
                    if(vm.currentStorage){
                         if(!String(vm.currentStorage).includes(time_id))
                              vm.currentStorage = vm.currentStorage+','+time_id;
                         else
                              vm.currentStorage = String(vm.currentStorage).replace(time_id+',','');

                         localStorage.setItem('favoriteTeams',vm.currentStorage);
                    }
                    else{
                         localStorage.setItem('favoriteTeams',time_id);
                         vm.currentStorage = time_id;
                    }
               },
               //Retorna a foto do atleta baseado no tamanho definido
               athlete_photo(src,size){
                    if(src)
                         return src.replace('FORMATO',size+'x'+size);
                    else
                         return 'https://s.glbimg.com/es/sde/f/2019/02/15/7f94dd0a492218c6dcc1fbc02473637c_220x220.png';
               },
               //Atualiza o vetor de filtro de clubes 'currentClub'
               changeCurrentClub(club){
                    if(this.currentClub.indexOf(club) == -1){
                         this.currentClub.push(club);
                    }else{
                         this.currentClub.splice(this.currentClub.indexOf(club),1);
                    }
               },
               //Atualiza o vetor de filtro de clubes 'currentPos'
               changeCurrentPos(pos){
                    if(this.currentPos.indexOf(pos) == -1){
                         this.currentPos.push(pos);
                    }else{
                         this.currentPos.splice(this.currentPos.indexOf(pos),1);
                    }
               },
               //Scroll na página até a sessão de id = "table_id"
               scroll_to(table_id){
                    $('html, body').animate({
                         scrollTop: $("#"+table_id).offset().top - 100
                    }, 1000);
               },
               //Método para ordernar dados de uma tabela baseado na key = 'p'
               order_by(p) {
                    this.currentOrder *= -1;
                    this.currentSort = p;
                    var vm = this;
                    return this.pontuados.sort(function(a,b){
                         return (a[vm.currentSort] - b[vm.currentSort])*(vm.currentOrder);
                    })

               },
               //Aumenta a visualização de dados na tabela (main_table) baseado no scroll interno
               increase_view:function(){
                    this.scrollTopTable = $("#main_table").scrollTop();
                    if((-1)*($("#main_table").scrollTop() + $("#main_table").innerHeight() - $("#main_table")[0].scrollHeight) <= 5){
                         this.pageSize += 10;
                    }
               },
               //Remove acentos de uma string 's'
               remove_acentos(s){
                    return s.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
               },
          },
          computed:{
               //Retorna os pontuados baseado em um time selecionado
               pontuados_in_team_details(){
                    if(this.teamDetails){
                         //Calculo de pontuação, valorização e jogadores que já jogaram
                         this.teamPoints = 0; // eslint-disable-line
                         this.teamVal = 0; // eslint-disable-line
                         this.totalPlayed = 0; // eslint-disable-line

                         var posCapitao = 0;
                         this.teamDetails.atletas
                              .filter((item)=>{
                                   if(this.pontuadosArray[item.atleta_id]){
                                        this.totalPlayed += 1; // eslint-disable-line

                                        item.pontuacao = this.pontuadosArray[item.atleta_id].pontuacao;
                                        item.valorizacao = this.pontuadosArray[item.atleta_id].valorizacao;
                                        item.scout = this.pontuadosArray[item.atleta_id].scout;
                                        
                                        if(item.atleta_id == this.teamDetails.capitao_id) {
                                             this.teamPoints += item.pontuacao * 1.5; // eslint-disable-line
                                             posCapitao = item.posicao_id;
                                        } else {
                                             this.teamPoints += item.pontuacao; // eslint-disable-line
                                        }

                                        this.teamVal += item.valorizacao; // eslint-disable-line
                                   }else if(this.teams_played.includes(item.clube_id)){
                                        this.totalPlayed += 1; // eslint-disable-line
                                        item.pontuacao = 0;
                                        item.valorizacao = 0;
                                        item.scout = null;
                                   }else{
                                        item.pontuacao = this.currentOrder * 100.00;
                                        item.valorizacao = this.currentOrder * 100.00;
                                        item.scout = null;
                                   }
                                   item.swap = false;

                                   if(this.shirts[item.clube_id]){
                                        item.camisa = this.shirts[item.clube_id];
                                   }
                         });
                         
                         if (this.teamDetails.reservas) {
                              this.teamDetails.reservas
                                   .filter((item)=>{
                                        if(this.pontuadosArray[item.atleta_id]){
                                             item.pontuacao = this.pontuadosArray[item.atleta_id].pontuacao;
                                             item.valorizacao = this.pontuadosArray[item.atleta_id].valorizacao;
                                             item.scout = this.pontuadosArray[item.atleta_id].scout;
                                        }
                                        if(this.shirts[item.clube_id]){
                                             item.camisa = this.shirts[item.clube_id];
                                        }
                              });
                              
                              if (this.teamSubstituicoes) {
                                   this.teamSubstituicoes.filter((sub)=>{
                                        for (var res=0; res<this.teamDetails.reservas.length; res++) {
                                             if (this.teamDetails.reservas[res].atleta_id == sub.entrou.atleta_id) {
                                                  var entrou = this.teamDetails.reservas[res];
                                                  
                                                  for (var atl=0; atl<this.teamDetails.atletas.length; atl++) {
                                                       if (this.teamDetails.atletas[atl].atleta_id == sub.saiu.atleta_id) {
                                                            var saiu = this.teamDetails.atletas[atl];
                                                            
                                                            this.teamVal += entrou.valorizacao; // eslint-disable-line
                                                            if (sub.posicao_id == posCapitao) {
                                                                 this.teamPoints += entrou.pontuacao * 2 // eslint-disable-line
                                                            } else {
                                                                 this.teamPoints += entrou.pontuacao // eslint-disable-line
                                                            }
                                                            
                                                            entrou.swap = true;
                                                            saiu.swap = true;
                                                            this.teamDetails.reservas[res] = saiu // eslint-disable-line
                                                            this.teamDetails.atletas[atl] = entrou // eslint-disable-line
                                                       }
                                                  }
                                             }
                                        }

                                   })
                              }
                         }
                         
                         
                         //Return ordered team
                         return this.teamDetails.atletas.sort(function(a,b){ // eslint-disable-line
                              return (a.posicao_id - b.posicao_id);
                         })
                    } else {
                         return []
                    }
               },
               //Retorna todos os atletas pontudaos baseados em alguns filtros
                    //1º filtro: busca por apelido
                    //2º filtro: clubes selecionados
                    //3º filtro: posições selecionadas
                    //4º filtro: quantidade de itens a serem exibidos na tabela
               computed_pontuados(){
                    if(this.pontuados){
                         //se nao ha nenhuma ordenacao definida, ordena da maior pontuacao para a menor
                         if (this.currentSort == '') {
                              this.order_by('pontuacao')
                         }

                         return this.pontuados
                              .filter((item)=>{
                                   return String(this.remove_acentos(item.apelido)).toLowerCase().includes(this.remove_acentos(this.search.toLowerCase()));
                              })
                              .filter((item)=>{
                                   return (this.currentClub.length > 0) ? this.currentClub.indexOf(item.clube_id) != -1 : true;
                              })
                              .filter((item)=>{
                                   return (this.currentPos.length > 0) ? this.currentPos.indexOf(item.posicao_id) != -1 : true;
                              })
                              .filter((row, index) => {
                                   let start = (this.currentPage-1)*this.pageSize;
                                   let end = this.currentPage*this.pageSize;
                                   if(index >= start && index < end) return true;
                              });
                    } else {
                         return []
                    }
               },
          }
     }
</script>
<style scoped lang="scss">
     @import '@/assets/sass/variables.scss';

     @import '@/assets/scss/main_table.scss';

     @import '@/assets/scss/partials.scss';

     .gif{
          width: 225px;
     }
     .warning h3 {
          color: orange;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
     }

     .bw-img {
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
     }
</style>
