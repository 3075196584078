<template>
  <div class="container">
    <div v-if="user != null && loading == false">
        <div class="row d-flex tab-lines">
            <span class="tab-linha"></span>
            <div class="tab-indicador" :class="show==1?'tab-ativa':''">
              <span class="fas fa-futbol" aria-hidden="true" v-if="mobile"></span>
              <template v-else>
                Início
              </template>
            </div>
            <div class="tab-indicador" :class="show==2?'tab-ativa':''">{{mobile?"1":"Login"}}</div>
            <div class="tab-indicador" :class="show==3?'tab-ativa':''">{{mobile?"2":"Credenciais"}}</div>
            <div class="tab-indicador" :class="show==4?'tab-ativa':''">{{mobile?"3":"Confirmação"}}</div>
        </div>
        <!-- TAB 1 -->
        <div class="row" id="tab1" v-if="show==1">
            <div :class="[mobile ? 'col-12' : 'col-4']" style="margin:0 auto;"> 
                <span class="span0"> Início </span> <br/><br/>
                <span class="span1"> Olá cartoleiro! O processo para vincular as suas contas é bem rápido e em apenas 3 passos estará concluído.</span>
                <br />
                <br />
                <span class="span2"> Vamos lá? </span>
                <br /><br />
                <button class="btn btn-success btn-md btn-block" id="btn_login" @click="muda(2)">
                  Iniciar
                </button>
            </div>
        </div>
        
        <!-- TAB 2 -->
        <div class="row d-flex tab" id="tab2" v-if="show==2">
            <div>
                <span class="span0"> Passo 1 </span> <br/><br/>
                <span class="">O primeiro passo é confirmar a senha dessa conta que você já está logado:</span>
            </div>
            <br />
            <div :class="[mobile ? 'col-12' : 'col-3']" style="margin:0 auto;">
                <label>E-mail</label>
                <input type="text" v-model="emailLogado" class="form-control" disabled="true"/>

                <label>Senha</label>
                <input type="password" v-model="pwLogado" class="form-control" />
              
                <br />
            </div>  
            <br />
            <div class="d-flex btn-bar">
                <button class="btn btn-secondary btn-md" id="btn_login" @click="muda(1)">
                  Voltar
                </button>
                <button class="btn btn-success btn-md" id="btn_login" @click="validaTab2()">
                  Avançar
                </button>
            </div> 
        </div>

        <!-- TAB 3 -->
        <div class="row d-flex tab" id="tab3" v-if="show==3">
            <div>
                <span class="span0"> Passo 2 </span> <br/><br/>
                <span class="">Agora informe o e-mail e a senha da conta que você quer vincular:</span>

            </div>
            <br />
            <div :class="[mobile ? 'col-12' : 'col-3']" style="margin:0 auto;">
                <label>E-mail</label>
                <input type="text" v-model="emailNovo" class="form-control" />

                <label>Senha</label>
                <input type="password" v-model="pwNovo" class="form-control" />
              
                <br />
            </div>  
            <br />
            <div class="d-flex btn-bar">
                <button class="btn btn-secondary btn-md" id="btn_login" @click="muda(2)">
                  Voltar
                </button>
                <button class="btn btn-success btn-md" id="btn_login" @click="validaTab3()">
                  Avançar
                </button>
            </div> 
        </div>
        
        <!-- TAB 4 -->
        <div class="row d-flex tab" id="tab4" v-if="show==4">
            <div>
                <span class="span0"> Confirmação </span> <br/><br/>
                <span class="">Pronto! Agora é só revisar os dados abaixo e correr para o abraço:</span>

            </div>
            <br />
            <div class="col-12" style="margin:0 auto;">
                <span>Deseja vincular as seguinte contas?</span><br/><br/>
                <span class="span3">{{emailLogado}}</span> <br/>
                <span class="fas fa-link span-link" aria-hidden="true"></span> <br/>
                <span class="span3">{{emailNovo}}</span><br/>
                
                <br />
              
                
            </div>  
            <br />
            <div class="d-flex btn-bar">
                <button class="btn btn-secondary btn-md" id="btn_login" @click="muda(3)">
                  Voltar
                </button>
                <button class="btn btn-success btn-md" id="btn_login" @click="doLogin()">
                  Confirmar
                </button>
            </div> 
        </div>
        
        <!-- END TABS -->
    </div>
    <div v-else>
      <loading id="loading" class="col-12 text-center" :loading="loading" ></loading>
      <div v-if="loading == false">
        <h3>OPS!</h3>
        <img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" style="width:33%;"/> <br/><br/>
        <span>Aconteceu algum erro inesperado.</span>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/mixin/util';
import loading from '@/components/Loading.vue';

export default {
  mixins: [util],
  name: "loginVincular",
  components:{
    loading
  },
  props: {
    mobile: {
      type: Boolean,
      required: false,
      default: false
    },
    redirect: {
      type: String,
      required: false,
      default: 'contas'
    }
  },
  data() {
    return {
      show: 1,
      emailLogado: "",
      pwLogado: "",
      emailNovo: "",
      pwNovo: "",
      token: null,
      user: null,

      //Variável para permitir carregamento na página
      loading: false,
    };
  },
  created() {
    var vm = this;
    //auth user
    vm.user = vm.get_usuario_logado();
    vm.emailLogado = vm.user.email
  },
  methods: {
    //Pega o token via API para realizar o login
    doLogin() {
      if (this.emailLogado != "" && this.pwLogado != "" && this.emailNovo != "" && this.pwNovo != "") {
        this.loading = true;
        var vm = this;
        var data = {
          /*
          emailLogado: vm.aesEncrypt(vm.emailLogado),
          passwordLogado: vm.aesEncrypt(vm.pwLogado),
          emailNovo: vm.aesEncrypt(vm.emailNovo),
          passwordNovo: vm.aesEncrypt(vm.pwNovo),
          */
          emailLogado: vm.emailLogado,
          passwordLogado: vm.pwLogado,
          emailNovo: vm.emailNovo,
          passwordNovo: vm.pwNovo,
          plataforma: 1
        };

        let titulo = '<h3 style="color:orange;font-weight:bolder;font-family:"Montserrat",sans-serif";>OPS!</h3>'
        let redcard = '<img src="https://images2.imgbox.com/62/95/II0ciY6R_o.png" style="width:50%;"/> <br/><br/>'
        let yellowcard = '<img src="https://images2.imgbox.com/a9/d9/Y2dgRNpk_o.png" style="width:50%;"/> <br/><br/>'

        // var url = 'https://gurudocartola.com/app/user/contas/vincular';
        // var url = 'http://localhost:8444/user/contas/vincular';

        // var settings = {
        //     url: url,
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json"
        //     },
        //     data: vm.aesEncrypt(data) //JSON.stringify(data)
        //   };

        // $.ajax(settings)
        //   .done(function(response) {
        //     if (response && response != 'null' && response != null && response != {} ) {
        //       // var usuario = vm.get_usuario_logado();
        //       // usuario.contasVinculadas.push(JSON.parse(response));
        //       // vm.set_usuario_logado(usuario);
        //       var usuario =  vm.aesDencrypt(response);
        //       if (usuario != null && usuario != undefined && usuario != '') {
        //             vm.set_usuario_logado(usuario)
        //       }
        //       // vm.set_usuario_logado(response);
        //       window.location.href = "/"+vm.redirect;
        //     } else {
        //       vm.loading = false
        //       // eslint-disable-next-line no-undef
        //       Swal.fire(titulo, yellowcard+'Não foi possível realizar o login. Verifique suas credenciais e tente novamente.')
        //     }
        //   })
        //   .fail(function(error) {
        //     console.log('[error #3]:')
        //     console.log(error)

        //     vm.loading = false
        //     var  texto = 'Ocorreu um erro inesperado. Tente novamente e se o erro persistir contate o suporte.'
        //     if (error.responseText != undefined && error.responseText != '') {
        //       texto = error.responseText
        //     }
        //     // eslint-disable-next-line no-undef
        //     Swal.fire(titulo, redcard+texto)
        //   });
          this.$http
          .get(this.$PATH+'/user/contas/vincular')
          .then(response => {
              if (response.data && response.data != 'null' && response.data != null && response.data != {} ) {
                var usuario =  vm.aesDencrypt(response.data);
                if (usuario != null && usuario != undefined && usuario != '') {
                      vm.set_usuario_logado(usuario)
                }
                window.location.href = "/"+vm.redirect;
              } else {
                vm.loading = false
                // eslint-disable-next-line no-undef
                Swal.fire(titulo, yellowcard+'Não foi possível realizar o login. Verifique suas credenciais e tente novamente.')
              }
          })
          .catch(function (error) {
              console.log('[error #3]:')
              console.log(error)

              vm.loading = false
              var  texto = 'Ocorreu um erro inesperado. Tente novamente e se o erro persistir contate o suporte.'
              if (error.responseText != undefined && error.responseText != '') {
                texto = error.responseText
              }
              // eslint-disable-next-line no-undef
              Swal.fire(titulo, redcard+texto)
          });  

      } else {
        // eslint-disable-next-line no-undef
        Swal.fire(
          "Dados incompletos.",
          "Por favor, preencha corretamente os dados de login",
          "error"
        );
      }
    },
    //Pega o valor de um parâmetro get na URL atual
    get_url_param(param) {
      var url_string = window.location.href.replace("/#/", "/");
      var url = new URL(url_string);
      var p = url.searchParams.get(param);
      return p;
    },
    muda(param) {
     this.show = param
    },
    validaTab2() {
      if (this.pwLogado == null || this.pwLogado == undefined || this.pwLogado == '') {
        // eslint-disable-next-line no-undef
        Swal.fire("Dados incompletos.",
          "Por favor, informe o password antes de passar para a próxima etapa.",
          "error"
        );
      } else {
        this.muda(3)
      }
    },
    validaTab3() {
      if (this.emailNovo == null || this.emailNovo == undefined || this.emailNovo == '' || 
          this.pwNovo == null || this.pwNovo == undefined || this.pwNovo == '') {
        // eslint-disable-next-line no-undef
        Swal.fire("Dados incompletos.",
          "Por favor, informe o e-mail e o password antes de passar para a próxima etapa.",
          "error"
        );
      } else {
        this.muda(4)
      }
    }

  }
};
</script>
<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
#loading {
  color: $light_text_color;
  img {
    margin-top: 50px;
    width: 75px;
  }
}
#login {
  background-color: white;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  padding: 25px;
  // border: 2px solid green;
  // box-shadow: 2px 5px 5px #0000008c;
}
#socialLoginModal {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
}
.remember {
  font-size: 13px;
  margin-bottom: 15px;
}
.divider {
  padding: 20px 0px;
  text-align: center;
  span {
    position: absolute;
    left: 30%;
    background-color: white;
    padding: 0px 15px;
    margin-top: 2px;
  }
}

input,
button {
  border-radius: 3px !important;
}

.btn {
  font-weight: bolder;
  box-shadow: inset 0 -2px rgba(0, 0, 0, 0.25);
  font-size: 15px;
}
.btn-facebook {
  background-color: #3b5998;
  color: white;
  i {
    font-weight: normal;
  }
}
.btn-google {
  span {
    background: url("https://s.glbimg.com/pc/cf/images/google-icon.png")
      no-repeat;
    width: 10px;
  }
  background-color: white;
  color: #666;
}
.modal-content {
  height: 100%;
}
h3 {
  color: $laranja;
}
.span0 {
  font-family: "Open Sans",sans-serif;
  font-weight: bold;
  font-size: 2em;
  color: green;
  text-transform: uppercase;
  border-bottom: 1px dotted green;
}
.span1 {
  font-family: "Open Sans",sans-serif;
  color: $cinza_escuro;
}
.span2 {
  font-family: "Open Sans",sans-serif;
  font-weight: bold;
  font-size: 2em;
  color: $laranja;
}
.span3 {
  font-family: "Open Sans",sans-serif;
  font-weight: bold;
  font-size: 1.2em;
  color: green;
}
.span-link {
  font-size: 1.6em;
  color: green;
  margin: .2em;
}
.tab {
  flex-direction: column;
}
.btn-bar {
  flex-direction: row;
  justify-content: space-evenly;
}
.spanline {
  border-bottom: 1px solid #ced4da;
  padding-bottom: 1em;
}
.tab-lines {
  justify-content: space-between;
  padding: 20px 0;
  position: relative;
}
.tab-indicador {
  background-color: $cinza_muito_claro;
  padding-left: 1.2em;
  padding-right: 1.2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 10px !important;
  z-index: 2;
}
.tab-linha {
    position: absolute;
    height: 2px;
    top: 50%;
    left: 24px;
    right: 24px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    background: $cinza_muito_claro;
}
.tab-ativa {
  background-color: $verde;
  color: white;
}

</style>
